import * as React from "react";
import classnames from "classnames";
import ReactDOM from "react-dom";

import { Transition } from "react-transition-group";
import styles from "./DropdownWrapper.module.scss";

export const DropdownContext = React.createContext<{
  getPosition: any;
}>({
  getPosition: () => {},
});

type DropdownWrapperProps = {
  target: React.ElementRef<any>;
  open: boolean;
  allowOverflow?: boolean;
  minHeight?: number;
  width?: number;
  children: React.ReactNode;
};

const DropdownContainer = (props: DropdownWrapperProps) => {
  return ReactDOM.createPortal(
    <Transition in={props.open} timeout={250}>
      {(state) =>
        state !== "exited" && (
          <DropdownWrapperInner
            state={state}
            target={props.target}
            open={props.open}
            allowOverflow={props.allowOverflow}
            width={props.width}
            minHeight={props.minHeight}
          >
            {props.children}
          </DropdownWrapperInner>
        )
      }
    </Transition>,
    // @ts-expect-error - TS2345 - Argument of type 'HTMLElement | null' is not assignable to parameter of type 'Element | DocumentFragment'.
    document.getElementById("root")
  );
};

// @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
const DropdownWrapperInner = (props) => {
  const outerRef = React.useRef<any>();
  const innerRef = React.useRef<any>();

  const getPosition = React.useCallback(() => {
    const {
      x,
      y,
      width: _width,
      height,
    } = (props.target.current &&
      props.target.current.getBoundingClientRect()) ||
    {};

    const width = props.width || _width;
    const screenH = window.innerHeight;
    const rootH = outerRef.current.offsetHeight;

    const spaceBelow = screenH - y - 5;

    const fitsBelow = spaceBelow > rootH + 30;

    if (props.allowOverflow) {
      outerRef.current.style.minWidth = `${width}px`;
    } else {
      outerRef.current.style.width = `${width}px`;
    }

    const screenW = window.innerWidth;
    const fitsBeside = width + x < screenW;

    if (fitsBeside) {
      outerRef.current.style.left = `${x}px`;
    } else {
      outerRef.current.style.left = `${screenW - width - 8}px`;
    }

    // Set position to render below by default
    outerRef.current.style.top = `${y + height}px`;
    innerRef.current.style.maxHeight = `${Math.min(
      spaceBelow - height - 30,
      400
    )}px`;
    // If doesnt fit below AND space above is greater, then render above.

    if (!fitsBelow && y > spaceBelow) {
      innerRef.current.style.maxHeight = `400px`;
      outerRef.current.style.top = `${y - rootH}px`;
    }
  }, [props.target, props.allowOverflow, props.width]);

  React.useEffect(() => {
    if (props.open) {
      window.addEventListener("wheel", getPosition);
      window.addEventListener("resize", getPosition);

      return () => {
        window.removeEventListener("wheel", getPosition);
        window.removeEventListener("resize", getPosition);
      };
    }
  }, [props.open, getPosition]);

  React.useEffect(() => {
    if (props.open) getPosition();
  }, [getPosition, props.open, props.state]);
  return (
    <div
      data-cy="select-dropdown-container"
      data-testid="select-dropdown-container"
      ref={outerRef}
      className={classnames(styles.dropdown_fixed, {
        [styles.open!]: props.open,
      })}
    >
      <div ref={innerRef} className={styles.dropdown_container}>
        <DropdownContext.Provider value={{ getPosition }}>
          {props.children}
        </DropdownContext.Provider>
      </div>
    </div>
  );
};

export default DropdownContainer;
