import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  ContextType,
  FunctionComponent,
  useMemo,
} from "react";
import { useControllableState } from "../../../../global_functions/hooks";
import { RadioGroupContext } from "./RadioGroupContext";

interface RadioGroupProps extends ComponentPropsWithoutRef<"div"> {
  defaultValue?: string;
  value?: string | number | boolean | null; // Pass null for "no option selected"
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  dataTestId?: string;
}

/**
 * Wrap Radio components in a RadioGroup to control them from a single component
 */
const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  children,
  defaultValue,
  disabled,
  onChange,
  dataTestId,
  value: valueProp,
  ...divProps
}) => {
  const [value, setValue] = useControllableState(valueProp, defaultValue);

  const contextValue = useMemo<ContextType<typeof RadioGroupContext>>(
    () => ({
      value: String(value) || undefined,
      onChange: (e) => {
        setValue(e.target.value);

        if (onChange) {
          onChange(e);
        }
      },
      disabled,
    }),
    [value, onChange, setValue, disabled]
  );

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <div
        role="radiogroup"
        data-testid={dataTestId ?? "radio-group"}
        {...divProps}
      >
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
