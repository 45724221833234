import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import classNames from "classnames";
import moment from "moment";
import {
  usePropState,
  useDidClickOutsideElement,
} from "../global_functions/hooks";
import DayPickerNavBar from "./DayPickerNavBar";

const ESCAPE_KEYCODE = 27;

type Props = {
  date: string | null | undefined;
  className?: string | null;
  disabledDates?: Array<{
    before?: Date | null;
    after?: Date | null;
  }>;
  style?: Record<any, any>;
  onCancel: () => void;
  handleDateChange: (arg1: string) => void;
  defaultMonth?: Date;
};

const SingleDaySelector = ({
  date,
  className,
  handleDateChange,
  disabledDates,
  onCancel,
  defaultMonth,
  ...props
}: Props) => {
  const [selectedDate, setSelectedDate] = usePropState(date);
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const containerRef = useDidClickOutsideElement(onCancel);

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.keyCode === ESCAPE_KEYCODE) onCancel();
    };
    window.addEventListener("keyup", listener);
    return () => window.removeEventListener("keyup", listener);
  }, [onCancel]);

  const disabledDays = [{ after: moment().toDate() }];
  // @ts-expect-error - TS2345 - Argument of type '{ before?: Date | null | undefined; after?: Date | null | undefined; }' is not assignable to parameter of type '{ after: Date; }'.
  if (disabledDates) disabledDays.push(...disabledDates);

  const isTodayValidDate = disabledDays.every(
    (d) => !d.after || moment().isSame(d.after, "day")
  );

  const dayPickerProps = {
    selectedDays: selectedDate
      ? moment.tz(selectedDate, localTimezone).toDate()
      : null,
    modifiers: selectedDate
      ? moment.tz(selectedDate, localTimezone).toDate()
      : null,
    numberOfMonths: 2,
    todayButton: isTodayValidDate ? "Today" : null,
    navbarElement: DayPickerNavBar,
    showOutsideDays: true,
    enableOutsideDaysClick: false,
    disabledDays,
    month: selectedDate
      ? moment(selectedDate).toDate()
      : defaultMonth
      ? defaultMonth
      : moment().toDate(),
    captionElement: () => null,
    // @ts-expect-error - TS7006 - Parameter 'day' implicitly has an 'any' type. | TS7006 - Parameter 'modifiers' implicitly has an 'any' type. | TS7006 - Parameter 'e' implicitly has an 'any' type.
    onDayClick: (day, modifiers, e) => {
      const clickedDate = moment.tz(day, localTimezone).format("YYYY-MM-DD");

      if (modifiers && !modifiers.disabled) {
        // the enableOutsideDaysClick prop doesnt seem to do anything, so
        // manually preventing selection of disabled dates instead
        setSelectedDate(clickedDate);
        handleDateChange(clickedDate);
      }
    },
    onTodayButtonClick: () => {
      setSelectedDate(moment().format("YYYY-MM-DD"));
      handleDateChange(moment().format("YYYY-MM-DD"));
    },
  } as const;

  return (
    <div
      className={classNames(
        className,
        "date-selection--container single-date-selection--container"
      )}
      ref={containerRef}
      {...props}
    >
      {/* @ts-expect-error - TS2769 - No overload matches this call. */}
      <DayPicker {...dayPickerProps} value={selectedDate} />
    </div>
  );
};

export default SingleDaySelector;
