import * as React from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import UserContext from "../../context/UserContext";
import SwitchToButton from "./SwitchToButton";
import * as Icon from "../../icons";

import IPNav from "./IPNav";
import ValinorNav from "./ValinorNav";
import AdminNav from "./AdminNav";
import { VITE_SITE } from "../../env";

const blurNavLinks = () => {
  const focused = document.querySelector(".app-navigation :focus");
  if (focused) {
    // @ts-expect-error - TS2339 - Property 'blur' does not exist on type 'Element'.
    focused.blur();
  }
};

const AppNavigation = () => {
  const { currentUser } = React.useContext(UserContext);

  const history = useHistory();
  React.useEffect(() => history.listen(blurNavLinks), [history]);

  const { appNavClassName, softwareTitle, Component } = ((pageType) => {
    switch (pageType) {
      case "ip":
        return {
          appNavClassName: classnames("app-navigation", "--ip"),
          softwareTitle: "Bractlet",
          Component: IPNav,
        };
      case "admin":
        return {
          appNavClassName: classnames("app-navigation", "--admin"),
          softwareTitle: "Admin",
          Component: AdminNav,
        };
      default:
        return {
          appNavClassName: classnames("app-navigation"),
          softwareTitle: "Valinor",
          Component: ValinorNav,
        };
    }
  })(VITE_SITE);

  return (
    <nav className={appNavClassName}>
      <div className="nav-container">
        <div className="bractlet-symbol-container">
          <Icon.BractletSymbol width={40} height={40} />
          <h3 className="software-title">{softwareTitle}</h3>
        </div>
        <Component />
        {currentUser && currentUser.superAdmin && <SwitchToButton />}
      </div>
    </nav>
  );
};

export default AppNavigation;
