import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useContext, useEffect } from "react";
import SitesContext, {
  currentSiteIdAtom,
  useOptionalSiteIdParam,
} from "./context/SitesContext";
import { isDev } from "./env";
import { bugsnagGeneralErrorHandler } from "./global_functions/postgrestApi";
import { Account, User } from "./types";

interface Metadata {
  [key: string]: string | number | boolean | string[] | null;
}

export const identify = (currentUser: User, account: Partial<Account>) => {
  window.pendo?.initialize({
    visitor: {
      id: String(currentUser.id),
      name: `${currentUser.firstName || ""} ${currentUser.lastName || ""}`,
      email: currentUser.email,
      role: currentUser.superAdmin
        ? "admin"
        : currentUser.userRole || "unassigned",
      accountName: account.name || null,
      accountType: account.accountType || null,
      permissions: currentUser.userPermissions,
    },
    account: {
      id: String(account.id),
      name: account.name || null,
      accountType: account.accountType || null,
    },
  });

  window?.FS?.identify(currentUser.id, {
    email: currentUser.email,
    accountName_string: account.name,
  });
};

interface Event {
  trackType: string;
  metadata?: Metadata;
}

// Prefer `useTrackEvent` for Backpack events so current building data is automatically included
const track = ({ trackType, metadata }: Event) => {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.info("Track Event", {
      trackType,
      metadata,
    });
  }

  try {
    window.pendo?.track(trackType, metadata);
  } catch (e) {
    bugsnagGeneralErrorHandler(e);
  }
};

const telemetryEventQueueAtom = atom<Event[]>([]);
const pushTelemetryEventAtom = atom(null, (get, set, update: Event) => {
  set(telemetryEventQueueAtom, [...get(telemetryEventQueueAtom), update]);
});

// Returns track function that assigns common metadata from context
export const useTrackEvent = () => {
  const siteIdFromParam = useOptionalSiteIdParam();
  // Backup siteId stored in atom for components nested outside of SiteDashboard
  const siteIdFromAtom = useAtomValue(currentSiteIdAtom);
  const siteId = siteIdFromParam || siteIdFromAtom || undefined;

  const pushEvent = useSetAtom(pushTelemetryEventAtom);
  return useCallback(
    (trackType: string, metadata?: Metadata) => {
      pushEvent({
        trackType,
        metadata: siteId
          ? {
              // Cast to string since Pendo will not group by number types
              buildingId: String(siteId),
              ...metadata,
            }
          : metadata,
      });
    },
    [pushEvent, siteId]
  );
};

// Only sends one track event when mounted
export const useTrackOnMount = (trackType: string, metadata?: Metadata) => {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(trackType, metadata);
  }, [trackEvent, trackType, metadata]);
};

const hubspotOpenedClass = "hs-messages-widget-open";
const HubspotChatFeatureTracker = () => {
  const trackEvent = useTrackEvent();

  // Track Hubspot chat feature
  // Hubspot chat is in an iframe but it toggles a classname on the <html> element when opened
  useEffect(() => {
    let lastHubspotOpened = false;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          const hubspotOpened =
            document.documentElement.classList.contains(hubspotOpenedClass);
          if (hubspotOpened && !lastHubspotOpened) {
            trackEvent("Chat Feature");
          }
          lastHubspotOpened = hubspotOpened;
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, [trackEvent]);

  return null;
};

export const BackpackTelemetryManager = () => {
  const [eventQueue, setEventQueue] = useAtom(telemetryEventQueueAtom);
  const { allSites: sites, loading } = useContext(SitesContext);

  // Queue is used to hold events until sites data has finished loading, then flushes immediately
  // for each event thereafter
  useEffect(() => {
    if (
      !loading &&
      eventQueue.length > 0 &&
      typeof window.pendo?.track === "function"
    ) {
      eventQueue.forEach((event) => {
        const metadata = { ...event.metadata };
        const siteId = Number(event.metadata?.buildingId);

        // Supplement metadata with site data
        if (siteId) {
          const site = sites.find((s) => s.id === siteId);

          if (site) {
            metadata.buildingName = site.name;
          }
        }

        track({ ...event, metadata });
      });

      setEventQueue([]);
    }
  }, [eventQueue, sites, setEventQueue, loading]);

  return <HubspotChatFeatureTracker />;
};
