import * as React from "react";
import { useOnboardingGuideContext } from ".";
import { OnboardingStatusEnum } from "./completenessSchema";
import OnboardingGuideStep from "./OnboardingStep";

export default function IntervalDataSteps() {
  const { APIConnected, installPulseMeter } =
    useOnboardingGuideContext().state.onSite.intervalData;

  const showErrorText =
    APIConnected.status === OnboardingStatusEnum.Done_NA &&
    installPulseMeter.status === OnboardingStatusEnum.Done_NA;

  return (
    <>
      {showErrorText && (
        <p className="px-5 text-sm">
          Interval data integration is not currently available at your location.
        </p>
      )}
      <OnboardingGuideStep
        text="API Connected"
        errorText="API Unavailable"
        step={APIConnected}
      />
      <OnboardingGuideStep
        text="Install Pulse Meter"
        errorText="Unable to Install Pulse Meter"
        step={installPulseMeter}
      />
    </>
  );
}
