import Cookies from "js-cookie";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { VITE_TENANTS_AND_SPACES_BASE_URL } from "../../../env";
import { recursiveCamelCaseCipher } from "../../postgrestApi";
import request from "../../request";
import { Equipment } from "./types";

const site = {
  useQuery,
  useSyncEquipment,
};

export default site;

export const SITE_EQUIPMENT_QUERY_KEY = "site-equipment";

const getSiteEquipment = (
  siteId: number,
  include_preview: boolean
): Promise<Equipment[]> => {
  return request
    .get(`${VITE_TENANTS_AND_SPACES_BASE_URL}/equipment/site/${siteId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .query({ include_preview })
    .then((res) => recursiveCamelCaseCipher(res.body));
};

function useQuery(
  siteId: number,
  { poll, include_preview }: { poll?: boolean; include_preview: boolean }
) {
  return useReactQuery({
    queryKey: [SITE_EQUIPMENT_QUERY_KEY, siteId, include_preview],
    queryFn: async () => getSiteEquipment(siteId, include_preview),
    refetchInterval: poll ? 1000 * 10 : false,
  });
}

function syncSiteEquipment(siteId: number): Promise<void> {
  return request
    .post(`${VITE_TENANTS_AND_SPACES_BASE_URL}/equipment/sync/${siteId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .then(() => {});
}

function useSyncEquipment(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => syncSiteEquipment(siteId),
    onSettled: () =>
      queryClient.invalidateQueries([SITE_EQUIPMENT_QUERY_KEY, siteId]),
  });
}
