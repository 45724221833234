import Bugsnag, { Event } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React, { FunctionComponent } from "react";
import { isTest, VITE_APP_VERSION, VITE_SITE } from "./env";

const bugsnagKeys = Object.freeze({
  admin: "fb94fbbf410f8d92f0fc705c2fea8108",
  ip: "e2b7a86140eb7354c8d4a8dd901c2054",
  valinor: "c48699ff9beda7cc896bbcfdf6fe8a66",
  console: "131a05ce53e04e94fe5c3769ab00ea58",
});

// disable sending observation errors caused by react-virtual
// https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
// also disable other benign resize observer error: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
const RESIZE_OBSERVER_BENIGN_ERROR_MESSAGES = [
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications.",
] as const;

const eventMeetsDoNotSendConditions = (event: Event) =>
  [
    RESIZE_OBSERVER_BENIGN_ERROR_MESSAGES.some(
      (message) => message === event.errors[0]!.errorMessage
    ),
    // add any other event conditions we do not want to not send here...
  ].some(Boolean);

export const bugsnagClient = // disable bugsnag in test env
  // https://github.com/bugsnag/bugsnag-js/issues/452
  isTest
    ? null
    : Bugsnag.start({
        apiKey: bugsnagKeys[VITE_SITE],
        plugins: [new BugsnagPluginReact()],
        releaseStage: import.meta.env.MODE,
        appVersion: VITE_APP_VERSION,
        onError: (event) => {
          if (eventMeetsDoNotSendConditions(event)) {
            return false;
          }
        },
      });

const ErrorBoundary = isTest
  ? null
  : Bugsnag.getPlugin("react")?.createErrorBoundary(React);
const Passthrough: FunctionComponent = ({ children }) => <>{children}</>;

export default ErrorBoundary || Passthrough;
