import { useCallback, useEffect, useRef, useState } from "react";

import { atom, useAtomValue, useSetAtom } from "jotai";
import { Notifier } from "./Notifier";
import { Icon, Text } from "@src/straps/base";
import { IconNames } from "@src/straps/base/icons/Icon/types";

interface DownloadItem {
  name: string;
  icon?: IconNames;
  estimatedTimeMs: number;
}

interface QueuedDownloadItem extends DownloadItem {
  id: number;
  status: "in-progress" | "error" | "success";
}

type QueueDownloadFn = (item: DownloadItem, promise: Promise<void>) => void;

const downloadNotifierAtom = atom<{ queueDownload?: QueueDownloadFn }>({});

export function useDownloadNotifier() {
  return useAtomValue(downloadNotifierAtom);
}

let idCounter = 0;
function useDownloadQueue() {
  const [downloadQueue, setDownloadQueue] = useState<QueuedDownloadItem[]>([]);
  const setDownloadNotifier = useSetAtom(downloadNotifierAtom);

  useEffect(() => {
    setDownloadNotifier({
      // Promise should resolve when download starts, reject when download failed to prepare
      queueDownload: async (newItem, promise) => {
        const id = idCounter++;

        setDownloadQueue([
          ...downloadQueue,
          {
            ...newItem,
            id,
            status: "in-progress",
          },
        ]);

        let error = false;
        try {
          await promise;
        } catch (e) {
          error = true;
        }

        // Update status after promise completes
        setDownloadQueue((prevDownloadQueue) => {
          const idx = prevDownloadQueue.findIndex((item) => item.id === id);

          if (idx === -1) {
            return prevDownloadQueue;
          }

          const newDownloadQueue = [...prevDownloadQueue];
          newDownloadQueue[idx] = {
            ...prevDownloadQueue[idx]!,
            status: error ? "error" : "success",
          };

          return newDownloadQueue;
        });
      },
    });
  }, [setDownloadNotifier, downloadQueue]);

  const resetDownloadQueue = useCallback(() => {
    setDownloadQueue([]);
  }, []);

  return {
    downloadQueue,
    resetDownloadQueue,
  };
}

const DownloadStatus = ({
  downloadQueue,
}: {
  downloadQueue: QueuedDownloadItem[];
}) => {
  const inProgressCount = downloadQueue.filter(
    ({ status }) => status === "in-progress"
  ).length;
  if (inProgressCount > 0) {
    return (
      <Text
        variant="sb_t-16-500"
        className="h-11 border-b border-b-straps-accent-3 bg-straps-body px-5 py-3"
        data-testid="download-notifier-status"
      >
        Preparing{" "}
        {inProgressCount > 1 ? (
          <>
            <span className="font-bold">{inProgressCount}</span> downloads
          </>
        ) : (
          "download"
        )}
      </Text>
    );
  }

  if (downloadQueue[downloadQueue.length - 1]!.status === "error") {
    return (
      <Text
        variant="sb_t-14-500"
        className="flex h-11 items-center gap-2 border-b border-b-straps-accent-3 bg-straps-negative-bg px-5 py-3"
        data-testid="download-notifier-status"
      >
        <Icon name="warning" color="negative-hover" className="mb-1" />
        Download failed, please try again
      </Text>
    );
  }

  const successCount = downloadQueue.filter(
    ({ status }) => status === "success"
  ).length;

  return (
    <Text
      variant="sb_t-16-500"
      className="flex h-11 items-center gap-2 border-b border-b-straps-accent-3 bg-straps-positive-bg px-5 py-3"
      data-testid="download-notifier-status"
    >
      <Icon name="completed" color="positive-hover" className="mb-1" />
      {successCount > 1 ? (
        <>
          <span className="font-bold">{successCount}</span> downloads
        </>
      ) : (
        "Download"
      )}{" "}
      started
    </Text>
  );
};

const FREQ = 100;
// Create the fake progress with exponential decay
const EstimatedProgressBar = ({
  timeConstant,
}: {
  // Time constant (tc) - https://en.wikipedia.org/wiki/Time_constant
  // after tc milliseconds, progress will be 0.6321 ( = 1-Math.exp(-1) )
  // after 2*tc milliseconds, progress will be 0.8646 ( = 1-Math.exp(-2) )
  timeConstant: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const elapsed = useRef<number>(0);
  const intervalId = useRef<number>();

  useEffect(() => {
    intervalId.current = window.setInterval(() => {
      elapsed.current += FREQ;
      const progress = 1 - Math.exp((-1 * elapsed.current) / timeConstant);
      if (ref.current) {
        // Wait at 97% in case estimate was too low
        ref.current.style.width = `${Math.min(progress, 0.97) * 100}%`;
      }
    }, FREQ);

    return () => {
      clearInterval(intervalId.current);
      elapsed.current = 0;
    };
  }, [timeConstant]);

  return (
    <div className="h-1 w-full bg-straps-tertiary">
      <div ref={ref} className="h-1 w-0 bg-straps-positive transition-all" />
    </div>
  );
};

// This should only be rendered once in the app root
export const DownloadNotifier = () => {
  const [isRendered, setIsRendered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { downloadQueue, resetDownloadQueue } = useDownloadQueue();
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const downloadCount = downloadQueue.length;
  const allSucceeded = downloadQueue.every(
    ({ status }) => status === "success"
  );
  const anyInProgress = downloadQueue.some(
    ({ status }) => status === "in-progress"
  );

  useEffect(() => {
    // (Re)open notifier when download count changes to a non-zero number
    if (downloadCount > 0) {
      // Don't bother rendering anything until a download is queued
      setIsRendered(true);
      setIsOpen(true);
    } else {
      // Close when no downloads are queued
      setIsOpen(false);
    }
  }, [downloadCount]);

  const timeoutRef = useRef<number>();
  // Hide queue 5s after all finish, then reset
  useEffect(() => {
    if (downloadCount > 0 && !anyInProgress) {
      timeoutRef.current = window.setTimeout(() => {
        setIsOpen(false);
        timeoutRef.current = window.setTimeout(() => {
          resetDownloadQueue();
        }, 500);
      }, 5000);
    } else if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, [anyInProgress, resetDownloadQueue, downloadCount]);

  if (!isRendered) {
    return null;
  }

  return (
    <Notifier
      open={isOpen}
      onClose={onClose}
      titleIcon={allSucceeded ? "completed" : "move-down"}
      titleText="Downloads"
      disableMinimize={!anyInProgress}
      width={300}
    >
      <DownloadStatus downloadQueue={downloadQueue} />
      <div className="grow overflow-y-auto">
        {downloadQueue.map(({ id, name, status, estimatedTimeMs, icon }) => (
          <div
            key={id}
            className="flex-col justify-between border-b border-b-straps-accent-3 p-3"
          >
            <div className="flex justify-between gap-2">
              <Text
                variant="sb_t-14-500"
                className="flex min-w-0 items-center gap-2"
              >
                {icon && (
                  <Icon
                    name={icon}
                    color="secondary"
                    size="large"
                    className="shrink-0"
                  />
                )}
                <div className="truncate">{name}</div>
              </Text>
              {status === "error" && (
                <Icon name="warning" color="negative" className="shrink-0" />
              )}
              {status === "success" && (
                <Icon name="completed" color="positive" className="shrink-0" />
              )}
            </div>
            {status === "in-progress" && (
              <div className="pt-2">
                <EstimatedProgressBar timeConstant={estimatedTimeMs / 3} />
              </div>
            )}
          </div>
        ))}
      </div>
    </Notifier>
  );
};
