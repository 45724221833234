import * as R from "ramda";
import {
  getHotelSchema,
  getMultifamilySchema,
  getOfficeSchema,
  OnboardingStatusEnum,
  OnboardingStepType,
  OnboardingSubStep,
} from "./completenessSchema";

export function loopThroughSchema(
  schema: ReturnType<typeof getOfficeSchema>,
  callback: (step: OnboardingStepType | OnboardingSubStep) => void
) {
  Object.keys(schema.completeness).forEach((_key) => {
    const key = _key as keyof typeof schema.completeness;
    const section = schema.completeness[key];

    Object.keys(section).forEach((_stepKey) => {
      const stepKey = _stepKey as keyof typeof section;
      const step = section[stepKey] as OnboardingStepType<OnboardingSubStep>; // try to remove this assert
      callback(step);

      if (step.stepType === "aggregateStep") {
        Object.keys(step.subSteps).forEach((_subStepKey) => {
          const subStepKey = _subStepKey;
          const subStep = step.subSteps?.[subStepKey]!;
          callback(subStep);
        });
      }
    });
  });
}

export function decorateSchema(
  state: ReturnType<
    typeof getOfficeSchema | typeof getMultifamilySchema | typeof getHotelSchema
  >,
  onboardingGuideStateData:
    | {
        site_id: number;
        item_id: number;
        tab: 1 | 2;
        one: string;
        two: string;
        three: string | null;
        status: number;
      }[]
    | undefined
): ReturnType<typeof getOfficeSchema> {
  // @ts-expect-error - TS2769 - No overload matches this call
  return R.map(
    (_page) =>
      typeof _page === "string"
        ? _page
        : // @ts-expect-error - TS2769 - No overload matches this call.
          R.map(
            (group) =>
              // @ts-expect-error - TS2769 - No overload matches this call.
              R.map((defaultStep: OnboardingStepType) => {
                const status = (itemId: any) =>
                  (onboardingGuideStateData || []).find(
                    (step) => step.item_id === itemId
                  )?.status || OnboardingStatusEnum.Open; // question - should this default to NA instead? If that value is reached then it means its is not implemented in the backend

                const result = {
                  ...defaultStep,
                  status: status(defaultStep.itemId),
                  complete:
                    status(defaultStep.itemId) >= OnboardingStatusEnum.Done,
                };

                if (defaultStep.stepType === "aggregateStep") {
                  // @ts-expect-error - TS2769 - No overload matches this call.
                  const subSteps: OnboardingSubStep[] = R.map(
                    (defaultSubStep: OnboardingSubStep) => ({
                      ...defaultSubStep,
                      status: status(defaultSubStep.itemId),
                      complete:
                        status(defaultSubStep.itemId) >=
                        OnboardingStatusEnum.Done,
                    }),
                    defaultStep.subSteps
                  );

                  return {
                    ...result,
                    // Set the parent status dependent on children
                    status: determineParentStepStatus(subSteps),
                    complete: Object.values(subSteps).every(
                      (subStep) => subStep.complete
                    ),
                    subSteps,
                  };
                }

                return result;
              }, group),
            _page
          ),
    state
  );
}

function determineParentStepStatus(subSteps: OnboardingSubStep[]) {
  const steps = Object.values(subSteps);

  const statusesByCount = steps.reduce(
    (acc, val) => {
      acc[val.status]++;
      return acc;
    },
    {
      [OnboardingStatusEnum.Open]: 0,
      [OnboardingStatusEnum.Processing]: 0,
      [OnboardingStatusEnum.Done]: 0,
      [OnboardingStatusEnum.Done_NA]: 0,
      [OnboardingStatusEnum.OptOut]: 0,
      [OnboardingStatusEnum.N_A]: 0,
    }
  );

  // any status & open -> open
  if (statusesByCount[OnboardingStatusEnum.Open] > 0) {
    return OnboardingStatusEnum.Open;
  }
  // any status & processing -> processing
  if (statusesByCount[OnboardingStatusEnum.Processing] > 0) {
    return OnboardingStatusEnum.Processing;
  }

  // optout & done -> done
  if (statusesByCount[OnboardingStatusEnum.Done] > 0) {
    return OnboardingStatusEnum.Done;
  }

  // otherwise, return optout
  return OnboardingStatusEnum.OptOut;
}

export const isOptedOutStepOmittedFromCount = (
  step: OnboardingStepType | OnboardingSubStep
) =>
  step.status === OnboardingStatusEnum.OptOut && step.omitFromCountIfOptedOut;
