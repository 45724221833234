import * as React from "react";
import classNames from "classnames";
import { createSelector } from "reselect";
import Fuse from "fuse.js";
import * as R from "ramda";
import { get, sortBy, lowerCase } from "lodash";

import Icon from "../icons";
import Checkbox from "../global_components/Checkbox";

import Loader from "./Loader";
import DropdownContainer from "./DropdownWrapper";

import type { InputStatuses } from "./Input";

const KEYS = {
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
} as const;

const selectSelectedAsArray = createSelector(
  // @ts-expect-error - TS2571 - Object is of type 'unknown'.
  (props) => props.selected,
  (selected) => {
    if (Array.isArray(selected)) return [...selected];
    if (selected == null) return [];
    return [selected];
  }
);

const selectOptionsById = createSelector(
  // @ts-expect-error - TS2571 - Object is of type 'unknown'.
  (props) => props.options,
  // @ts-expect-error - TS2769 - No overload matches this call.
  R.indexBy(R.prop("id"))
);

const selectSelectedOptions = createSelector(
  selectOptionsById,
  selectSelectedAsArray,
  (props: React.ComponentProps<typeof Select>) => props.creatable,
  (options, selected, creatable) => {
    return selected
      .map(
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        (id) => options[id] || (id && creatable && { id, label: id, new: true })
      )
      .filter(Boolean);
  }
);

const selectSelectedById = createSelector(
  selectSelectedOptions,
  R.indexBy(R.prop("id"))
);

const selectFilteredUngroupedOptions = createSelector(
  (props: React.ComponentProps<typeof Select> & State) => props.options,
  selectOptionsById,
  selectSelectedOptions,
  (props) => props.query,
  (props) => props.creatable,
  (props) => props.groupBy,
  (props) => props.freezeFirstOptionOnSearch,
  (
    options,
    optionIds,
    selected,
    query,
    creatable,
    groupBy,
    freezeFirstOptionOnSearch
  ) => {
    const optionsWithSelected = [
      ...options,
      // @ts-expect-error - TS2571 - Object is of type 'unknown'.
      ...selected.filter(({ id }) => !optionIds[id]),
    ];

    if (!query) return optionsWithSelected;

    const keys = ["id", "label"];

    if (groupBy) keys.push(groupBy.headerKey);

    const optionsToQuery = freezeFirstOptionOnSearch
      ? optionsWithSelected.slice(1)
      : optionsWithSelected;

    const fuse = new Fuse(optionsToQuery, {
      shouldSort: true,
      threshold: 0.4,
      keys,
    });

    const searchResults = fuse.search(query).map(({ item }) => item);

    const filtered = freezeFirstOptionOnSearch
      ? [optionsWithSelected[0], ...searchResults]
      : searchResults;

    return creatable && !R.find(R.propEq("label", query), filtered)
      ? [...filtered, { id: query, label: query, new: true }]
      : filtered;
  }
);

const selectFilteredOptions = createSelector(
  selectFilteredUngroupedOptions,
  (props) => props.groupBy,
  (options, groupBy) => {
    if (!groupBy) return options;

    // groups should be sorted in the order received
    const groupOrders: Record<string, any> = {};
    const groups: Record<string, any> = {};
    let numGroups = 0;

    for (const option of options) {
      const group = get(option, groupBy.key);
      groups[group] = [...(groups[group] || []), option];

      if (!groupOrders[group]) {
        groupOrders[group] = ++numGroups;
      }
    }

    return sortBy(Object.keys(groups), (group) => groupOrders[group]).flatMap(
      (group) => groups[group]
    );
  }
);

type Props<
  OptionIDType,
  Option extends {
    id: OptionIDType;
    label: string;
  }
> = {
  selected?: OptionIDType | null | Array<OptionIDType>;
  loading?: boolean;
  onChange: <T extends Option>(
    option?: T,
    multiple?: boolean
  ) => void | Promise<void>;
  required?: boolean;
  multiple?: boolean;
  searchable?: boolean;
  freezeFirstOptionOnSearch?: boolean;
  creatable?: boolean;
  placeholder?: string;
  inputPlaceholder?: string;
  hideSelected?: boolean;
  tagsSelector?: boolean;
  inputLabel?: string | React.ReactNode;
  inputIcon?: string | React.ReactNode;
  label?: React.ReactNode;
  labelPosition?: "top" | "right" | "bottom" | "left";
  openOnLabelClick?: boolean;
  modifyQuery?: (value: string) => string;
  options: Array<Option>;
  renderGroupHeader?: (id: string | number, name: string) => React.ReactElement;
  groupBy?: {
    key: string;
    headerKey: string;
  } | null;
  small?: boolean;
  dropup?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  style?: unknown;
  maxHeight?: number;
  hideDropdown?: boolean;
  darkTheme?: boolean;
  whiteBackground?: boolean;
  isTableCell?: boolean;
  status?: {
    status: InputStatuses;
    hideIcon?: boolean;
    color?: string;
    message?: string | React.ReactNode;
  };
  dataCy?: string;
  dataTestId?: string;
  ["aria-label"]?: string;
};

type State = {
  open: boolean;
  query: string;
};

class Select<
  OptionIDType extends string | number,
  Option extends {
    readonly id: OptionIDType;
    readonly label: string;
  }
> extends React.Component<Props<OptionIDType, Option>, State> {
  state = {
    open: false,
    query: "",
  };

  container: {
    current: null | HTMLDivElement;
  } = React.createRef();
  dropdownRef: {
    current: null | HTMLElement;
  } = React.createRef();
  label: {
    current: null | HTMLElement;
  } = React.createRef();
  input: {
    current: null | HTMLInputElement;
  } = React.createRef();
  button: {
    current: null | HTMLDivElement;
  } = React.createRef();

  static getDerivedStateFromProps<
    OptionIDType extends string | number,
    Option extends {
      id: OptionIDType;
      label: string;
    }
  >(props: Props<OptionIDType, Option>, state: State) {
    if (state.query && !state.open) {
      return { query: "" };
    }

    return null;
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick, { capture: true });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick, { capture: true });
  }

  componentDidUpdate(prevProps: Props<OptionIDType, Option>, prevState: State) {
    // Force additional update if the options array changes - in order to preserve correct widths according to refs
    if (this.state.open && prevProps?.selected !== this.props?.selected) {
      this.forceUpdate();
    }
    if (this.input.current && prevState.open !== this.state.open) {
      if (this.state.open) {
        this.input.current.focus();
      } else {
        this.input.current.blur();
      }
    } else if (
      !this.props.multiple &&
      this.state.open &&
      prevProps.selected !== this.props.selected
    ) {
      this.setState({ open: false });
    }
  }

  handleClick = (e: MouseEvent) => {
    const dropdownRef = this.dropdownRef.current;
    const container = this.container.current;
    const label = this.label.current;
    const el = e && e.target;

    if (
      !container ||
      !dropdownRef ||
      !(el instanceof Node) ||
      !el.isConnected ||
      !(
        container.contains(el) ||
        dropdownRef.contains(el) ||
        (label && label.contains(el))
      )
    ) {
      this.setState({ open: false });
    }
  };

  selectOption = (option?: Option) => {
    this.props.onChange(option, this.props.multiple);
  };

  onLabelClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.openOnLabelClick) {
      // e.stopPropagation();
      this.toggleOpen();
    }
  };

  toggleOpen = (fromKeyDown?: true) => {
    if (!this.props.disabled && !this.props.readOnly && !this.props.loading) {
      this.setState((prevState) => {
        const open = !prevState.open;
        if (!open && fromKeyDown && this.button.current) {
          this.button.current.focus();
        }

        return { open };
      });
    }
  };

  updateQuery = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!this.props.disabled && !this.props.readOnly) {
      const { modifyQuery = R.identity } = this.props;
      this.setState({ query: modifyQuery(e.currentTarget.value) });
    }
  };

  get autocompleteOption(): Option | null | undefined {
    if (!this.props.searchable) return null;

    const options = selectFilteredOptions({ ...this.props, ...this.state });
    const { query } = this.state;

    if (!query || !options.length) return null;

    const selectedIds = selectSelectedById({ ...this.props, ...this.state });

    const autocompleteOptionIndex = this.props.freezeFirstOptionOnSearch
      ? 1
      : 0;

    const option = this.props.hideSelected
      ? options.find(({ id }) => !selectedIds[id])
      : options[autocompleteOptionIndex];

    if (
      option &&
      !selectedIds[option.id] &&
      option.label.toLowerCase().startsWith(this.state.query.toLowerCase())
    ) {
      return option;
    }

    return null;
  }

  get autocompleteSuggestion(): string {
    const option = this.autocompleteOption;

    if (option)
      return [
        this.state.query,
        option.label.slice(this.state.query.length),
      ].join("");

    return "";
  }

  render() {
    const {
      required,
      multiple,
      searchable,
      placeholder,
      inputPlaceholder,
      label,
      labelPosition = "top",
      inputIcon,
      small,
      dropup,
      readOnly = false,
      disabled = readOnly,
      hideSelected = false,
      tagsSelector = false,
      groupBy,
      renderGroupHeader = (id: any, name: any) => (
        <div className="bractlet-select--option-group-header">{name}</div>
      ),
      className,
      style,
      maxHeight,
      hideDropdown = false,
      darkTheme = false,
      whiteBackground = false,
      isTableCell = false,
      loading = false,
      status,
      dataCy,
      dataTestId,
    } = this.props;
    const { open, query } = this.state;

    const options = selectFilteredOptions({ ...this.props, ...this.state });
    const selectedById = selectSelectedById(this.props);
    const selected = selectSelectedOptions(this.props);

    const {
      inputLabel = selected.length
        ? selected.map(R.prop("label")).join(", ")
        : placeholder,
    } = this.props;

    // @ts-expect-error - TS7034 - Variable 'prevGroup' implicitly has type 'any' in some locations where its type cannot be determined.
    let prevGroup = null;

    const groupedOptions = options.flatMap((option, index) => {
      if (hideSelected && selectedById[option.id]) return null;

      let header = null;
      if (groupBy) {
        const group = get(option, groupBy.key);
        // @ts-expect-error - TS7005 - Variable 'prevGroup' implicitly has an 'any' type.
        if (group !== prevGroup) {
          prevGroup = group;
          header = (
            <li key={`grouping-${group}`}>
              {renderGroupHeader(group, get(option, groupBy.headerKey))}
            </li>
          );
        }
      }

      return [
        header,
        <li key={`${option.id}-${index}`}>
          <button
            type="button"
            className={classNames(
              {
                "bractlet-select--option": true,
                "bractlet-select--option-selected": selectedById[option.id],
                "bractlet-select--option-new": option.new,
              },
              option.className
            )}
            onClick={(e) => {
              e.stopPropagation();
              this.selectOption(option);
            }}
            style={option.style}
          >
            {multiple && (
              <Checkbox checked={Boolean(selectedById[option.id])} />
            )}
            <div className="bractlet-select--option-label">
              {option.nodeLabel || option.label}
            </div>
          </button>
        </li>,
      ];
    });

    return (
      <div
        data-cy={dataCy}
        data-testid={dataTestId}
        className={classNames(className, {
          "bractlet-select": true,
          "bractlet-select--open": open,
          "bractlet-select--multiple": multiple,
          "bractlet-select--selected": selected.length,
          "bractlet-select--small": small,
          "bractlet-select--dropup": dropup,
          "bractlet-select--tags-selector": tagsSelector,
          "bractlet-select--dark-theme": darkTheme,
          "bractlet-select--white-background": whiteBackground,
          "bractlet-select--table-cell": isTableCell,
        })}
        // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'CSSProperties | undefined'.
        style={style}
        data-label-position={labelPosition}
      >
        {label && (
          <label
            // @ts-expect-error - TS2322 - Type '{ current: HTMLElement | null; }' is not assignable to type 'LegacyRef<HTMLLabelElement> | undefined'.
            ref={this.label}
            className="bractlet-select--label"
            // @ts-expect-error - TS2322 - Type '(e: React.MouseEvent<HTMLDivElement>) => void' is not assignable to type 'MouseEventHandler<HTMLLabelElement>'.
            onClick={this.onLabelClick}
          >
            {label}
          </label>
        )}
        <div className="bractlet-select--container" ref={this.container}>
          <div
            ref={this.button}
            tabIndex={disabled ? -1 : 0}
            onClick={() => this.toggleOpen()}
            onKeyDown={(e) => {
              if (open && searchable && e.keyCode === KEYS.ENTER) {
                e.preventDefault(); // avoid triggering submit inside a form
              }

              if (open && searchable && !disabled && e.keyCode === KEYS.ENTER) {
                const { autocompleteOption } = this;

                if (autocompleteOption) {
                  this.selectOption(autocompleteOption);
                  return this.toggleOpen(true);
                }
              }

              if (
                // ESC should close if the dropdown is open
                (open && e.keyCode === KEYS.ESC) ||
                // SPACE and ENTER shouldn't do anything in an open input box, but should close otherwise
                (!(open && searchable) &&
                  // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type '13 | 32'.
                  [KEYS.SPACE, KEYS.ENTER].includes(e.keyCode))
              ) {
                return this.toggleOpen(true);
              }
            }}
            className={classNames({
              "bractlet-select--button": true,
              [`bractlet-select--${status ? lowerCase(status.status) : ""}`]:
                status && status.status,
              "bractlet-select--button--disabled": disabled,
            })}
          >
            {searchable && inputIcon && (
              <span className="bractlet-select--input-icon">
                {typeof inputIcon === "string" ? (
                  // @ts-expect-error - TS2741 - Property 'color' is missing in type '{ icon: string; }' but required in type '{ [x: string]: any; icon: any; color: any; }'.
                  <Icon icon={inputIcon} />
                ) : (
                  inputIcon
                )}
              </span>
            )}
            <span className="bractlet-select--button-label">
              {!searchable && inputLabel}
            </span>
            {searchable && (
              <React.Fragment>
                <span className="bractlet-select--autocomplete-suggestion">
                  {this.autocompleteSuggestion}
                </span>
                <input
                  tabIndex={open ? 0 : -1}
                  ref={this.input}
                  onClick={(e) => e.stopPropagation()}
                  className="bractlet-select--search-input"
                  // @ts-expect-error - TS2322 - Type '{} | undefined' is not assignable to type 'string | undefined'.
                  placeholder={inputLabel ?? inputPlaceholder}
                  value={query}
                  disabled={disabled}
                  // @ts-expect-error - TS2322 - Type '(e: React.KeyboardEvent<HTMLInputElement>) => void' is not assignable to type 'ChangeEventHandler<HTMLInputElement>'.
                  onChange={this.updateQuery}
                  aria-label={this.props["aria-label"]}
                />
              </React.Fragment>
            )}
            {!hideDropdown &&
              (loading ? (
                <div className="bractlet-select--loader">
                  <Loader size={14} color="#9a9eb2" />
                </div>
              ) : (
                <div className="icon--container">
                  {status &&
                    !status.hideIcon &&
                    status.status !== "Unevaluated" && (
                      <Icon
                        className={classNames(`bractlet-input--status-icon`, {
                          [`color--${
                            status.status === "Invalid" ? "red" : "yellow"
                          }`]:
                            status.status === "Invalid" ||
                            status.status === "Warning",
                          [`color--${status?.color || ""}`]: status.color,
                        })}
                        icon={
                          status?.status === "Warning"
                            ? "Invalid"
                            : status?.status
                        }
                        // onMouseLeave={() => setHovered(false)}
                        // onMouseOver={() => setHovered(true)}
                        // innerRef={messageRef}
                      />
                    )}
                  <Icon
                    icon="Caret"
                    className="bractlet-select--button-caret"
                  />
                </div>
              ))}
          </div>
          {!hideDropdown && (
            <DropdownContainer target={this.container} open={!disabled && open}>
              <ol
                // @ts-expect-error - TS2322 - Type '{ current: HTMLElement | null; }' is not assignable to type 'LegacyRef<HTMLOListElement> | undefined'.
                ref={this.dropdownRef}
                className={classNames("bractlet-select--options", {
                  open: open,
                })}
                style={maxHeight ? { maxHeight, overflow: "auto" } : {}}
              >
                <React.Fragment>
                  {!required && placeholder && (
                    <li>
                      <button
                        className="bractlet-select--option bractlet-select--option-placeholder"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!disabled) this.selectOption();
                        }}
                      >
                        <div className="bractlet-select--option-label">
                          {placeholder}
                        </div>
                      </button>
                    </li>
                  )}
                  {groupedOptions}
                </React.Fragment>
              </ol>
            </DropdownContainer>
          )}
        </div>
      </div>
    );
  }
}

export default Select;
