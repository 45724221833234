import FetchClients, { ClientOutput } from "../client";
import { useQuery as useReactQuery } from "react-query";
import { CamelCasedPropertiesDeep } from "type-fest";
import { recursiveCamelCaseCipher } from "@src/global_functions/postgrestApi";

// re-used floors viz components depend on having spaces field be defined, which is consistent with API response
import { FloorWithSpaces } from "@src/global_functions/fortyTwoApi/tenantsAndSpaces/hooks/useGetFloors";

type SnakeCaseFloorsResponse =
  ClientOutput["tenantsAndSpaces"]["FloorsHierarchy"];
type FloorsResponse = CamelCasedPropertiesDeep<
  Omit<SnakeCaseFloorsResponse, "floors"> & { floors: FloorWithSpaces[] }
>;

const floors = { useQuery } as const;

export default floors;

export const FLOORS_QUERY_KEY = "/floors";

const getBuildingFloors = async (
  site_id: number,
  building_id?: number | null
) => {
  if (typeof building_id !== "number") throw new Error("Invalid building id");
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/buildings/{building_id}/floors",
    {
      params: { path: { site_id, building_id } },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as FloorsResponse;
  throw new Error(error);
};

function useQuery(site_id: number, building_id?: number | null) {
  return useReactQuery(
    [FLOORS_QUERY_KEY, site_id, building_id],
    () => getBuildingFloors(site_id, building_id),
    {
      refetchOnWindowFocus: false,
    }
  );
}
