import request from "../../../global_functions/request";
import type { ResponseError } from "superagent";
import { BackpackResponseErrorBody } from "../errors";
import Cookies from "js-cookie";
import { useMutation } from "react-query";
import { useCurrentUser } from "@src/context/UserContext";
import type { User } from "@src/types";
import {
  formatDataObj,
  snakeCaseCipher,
  sendUserInvitation,
  createUser,
  formatDataArray,
} from "@src/global_functions/postgrestApi";

export const SIGNED_IN_USER_DELETE_ATTEMPT_ERROR_MESSAGE =
  "Cannot delete currently signed in user.";

const users = {
  mutations: {
    useDelete,
    useSendUserInvitation,
    useAddUser,
    useUpdateUser,
  },
} as const;

export default users;

const deleteUsers = (userIds: number[]) => {
  return request
    .patch(`/rest/users`)
    .query({ id: `in.(${userIds.join(",")})` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .send({ deleted_at: new Date() });
};

function useDelete() {
  const currentUser = useCurrentUser();
  return useMutation({
    mutationFn: (userIds: number[]) => {
      if (currentUser.id && userIds.includes(currentUser.id)) {
        throw new Error(SIGNED_IN_USER_DELETE_ATTEMPT_ERROR_MESSAGE);
      }
      return deleteUsers(userIds);
    },
  });
}

function useSendUserInvitation() {
  return useMutation({
    mutationFn: (userId: number) => sendUserInvitation(userId),
  });
}

// adding a userId/siteId entry blocks the user from access to the site
const addSiteUserBlacklists = (
  blacklists: {
    siteId: number;
    userId: number;
  }[]
) =>
  request
    .post("/rest/site_user_blacklists")
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .send(formatDataArray(blacklists, snakeCaseCipher));

// deleting a userId/siteId entry enables the user to access the site
const deleteSiteUserBlacklist = ({
  siteIds,
  userId,
}: {
  siteIds: number[];
  userId: number;
}) =>
  request
    .delete("/rest/site_user_blacklists")
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .query({ site_id: `in.(${siteIds.join(",")})`, user_id: `eq.${userId}` });

// arrays of siteIds
type Blocklists = {
  postToBlocklist: number[];
  removeFromBlocklist: number[];
};

type CoreUserProps = Pick<
  User,
  "accountId" | "email" | "firstName" | "lastName"
>;
const hasRequiredFields = (
  core: CoreUserProps
): core is Required<CoreUserProps> => {
  return (
    !!core.accountId && !!core.email && !!core.firstName && !!core.lastName
  );
};

interface CreateUserApiError extends ResponseError {
  response: {
    body?: {
      errors?: string[];
    };
  } & ResponseError["response"];
}

const isCreateUserApiError = (e: any): e is CreateUserApiError =>
  Boolean(
    Array.isArray(e?.response?.body?.errors) && e.response.body.errors.length
  );

const createUserAndSendInvite = async (
  core: CoreUserProps,
  additional: Partial<User>,
  // a user will not need to have any sites added to blocklist on creation - /api/users adds all sites in account to blocklist by default
  removeFromSiteBlocklist?: Blocklists["removeFromBlocklist"]
) => {
  if (!hasRequiredFields(core)) {
    throw new Error("Missing a required field to create user");
  }
  try {
    const userId = await createUser(core, additional);
    if (removeFromSiteBlocklist?.length) {
      await deleteSiteUserBlacklist({
        userId,
        siteIds: removeFromSiteBlocklist,
      });
    }
    await sendUserInvitation(userId);
  } catch (e: any) {
    if (isCreateUserApiError(e)) {
      throw new Error(e.response.body.errors[0]);
    }
    throw new Error("Failed to create user.");
  }
};

function useAddUser() {
  return useMutation({
    mutationFn: ({
      core,
      additional,
      removeFromSiteBlocklist,
    }: {
      core: CoreUserProps;
      additional: Partial<User>;
      removeFromSiteBlocklist?: Blocklists["removeFromBlocklist"];
    }) => createUserAndSendInvite(core, additional, removeFromSiteBlocklist),
  });
}

const updateUser = (userId: number, updatedProps: Partial<User>) =>
  request
    .patch("/rest/users")
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .query({ id: `eq.${userId}` })
    .send(formatDataObj(updatedProps, snakeCaseCipher, { allowNulls: true }));

interface UpdateUserError extends ResponseError {
  response: {
    body?: BackpackResponseErrorBody & { details?: string };
  } & ResponseError["response"];
}
const isUpdateUserError = (e: any): e is UpdateUserError =>
  Boolean(e?.response?.body?.details);
const updateUserAndBuildings = async (
  userId: number,
  updatedProps: Partial<User>,
  blocklists: Partial<Blocklists>
) => {
  const { postToBlocklist, removeFromBlocklist } = blocklists;
  try {
    await updateUser(userId, updatedProps);
    if (postToBlocklist?.length)
      await addSiteUserBlacklists(
        postToBlocklist.map((siteId) => ({ siteId, userId }))
      );
    if (removeFromBlocklist?.length) {
      await deleteSiteUserBlacklist({ siteIds: removeFromBlocklist, userId });
    }
  } catch (e) {
    if (isUpdateUserError(e)) {
      const message: string = e.response.body.details;
      // throw an error only if postgrest error is that email already exists
      if (/^(?=.*\bemail\b)(?=.*\balready\b)(?=.*\bexists\b).*/.test(message)) {
        throw new Error("Email has already been taken");
      }
    }
    throw new Error("Failed to update user.");
  }
};

function useUpdateUser() {
  return useMutation({
    mutationFn: ({
      userId,
      updatedProps,
      blocklists,
    }: {
      userId: number;
      updatedProps: Partial<User>;
      blocklists: Partial<Blocklists>;
    }) => updateUserAndBuildings(userId, updatedProps, blocklists),
  });
}
