import classnames from "classnames";
import Icon from "../../icons/Icon/Icon";
import Text from "../../type/Text/Text";
import Checkbox from "../Checkbox/Checkbox";
import { startCase } from "lodash";

import { DropdownVariant, Option } from "./Dropdown";

export function DropdownOptionButton<
  OptionIDType extends string | number,
  OptionMetadata extends {} | void = void
>({
  option,
  variant,
  isChecked,
  handleSelect,
}:
  | {
      option: Option<OptionIDType, OptionMetadata>;
      handleSelect: (option: Option<OptionIDType, OptionMetadata>) => void;
      variant: Exclude<DropdownVariant, "multiple">;
      isChecked?: (option: Option<OptionIDType, OptionMetadata>) => boolean;
    }
  | {
      variant: "multiple";
      option: Option<OptionIDType, OptionMetadata>;
      handleSelect: (option: Option<OptionIDType, OptionMetadata>) => void;
      isChecked: (option: Option<OptionIDType, OptionMetadata>) => boolean;
    }) {
  return (
    <button
      data-testid={`dropdown-option-${option.id}`}
      key={`option-${option.id}`}
      className={classnames(
        "group flex w-full shrink-0 cursor-pointer flex-row items-center bg-pure-white py-0 transition-all hover:bg-straps-body",
        {
          "gap-x-3 px-4": variant !== "multiple",
          "gap-x-2 pl-2 pr-4": variant === "multiple",
          "h-6": variant !== "largeSearch",
          "h-10 min-h-[40px] ": variant === "largeSearch",
        }
      )}
      onClick={(e) => {
        e.preventDefault();
        handleSelect(option);
      }}
    >
      {variant === "multiple" && (
        <Checkbox
          size="small"
          checked={!option.disabled ? isChecked(option) : false}
          onChange={() => {
            if (!option.disabled) handleSelect(option);
          }}
        />
      )}
      {!!option.icon && (
        <div className="flex w-3 min-w-[12px] flex-row items-center text-straps-primary group-hover:text-straps-hyperlink-hover">
          <Icon
            name={option.icon}
            size="small"
            className="text-straps-secondary group-hover:text-straps-hyperlink-hover"
          />
        </div>
      )}
      {option.nodeLabel ?? (
        <Text
          as="span"
          variant="sb_t-14-500"
          className="pointer-events-none block truncate text-left text-straps-primary transition-all group-hover:text-straps-hyperlink-hover"
        >
          {option.label ?? startCase(option.id.toString())}
        </Text>
      )}
    </button>
  );
}
