import * as R from "ramda";
import Cookies from "js-cookie";
import request from "../request";

import type {
  SiteRollupSummary,
  SiteEnergyStarScore,
  Project,
  ProjectGrouping,
} from "../../types";

import {
  bugsnagPostgrestErrorHandler,
  recursiveCamelCaseCipher,
} from "./common";
import {
  getBaselinesAndStatesForSites,
  getProjectPlans,
  getProjects,
  getProjectGroupingWithProjects,
} from "./";
import { getInteractiveEffectForSelectedProjects } from "../../site__projects/projectUtils";

import { demoSites } from "../../esgDemoData";
import { isDev } from "../../env";
const currentYear = new Date().getFullYear();

const getSavingsBySite = async (
  siteIds: number[]
  // @ts-expect-error - TS1064 - The return type of an async function or method must be the global Promise<T> type. Did you mean to write 'Promise<{ [siteId: number]: { totalForecastSavings: number; implementedSavings: number; plannedSavings: number; }; }>'?
): {
  [siteId: number]: {
    totalForecastSavings: number;
    implementedSavings: number;
    plannedSavings: number;
  };
} => {
  const states = await getBaselinesAndStatesForSites(siteIds);

  // @ts-expect-error - TS2345 - Argument of type '(number | undefined)[]' is not assignable to parameter of type 'string | number | (string | number)[]'.
  const projectPlans = await getProjectPlans(R.uniq(states.map((s) => s.id)));
  const statesWithLaunchedProjectPlan = states.map((s) => ({
    ...s,
    plan: projectPlans.find(
      (p) => p.stateOrVersionId === s.id && p.launched === true
    ),
  }));
  const statesBySite = R.groupBy(
    // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'string'.
    (state) => state.siteId,
    statesWithLaunchedProjectPlan
  );

  // @ts-expect-error - TS2769 - No overload matches this call.
  const sitesWithLatestLaunchedState = R.map((site) => {
    const sorted = Object.keys(site)
      // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
      .map((key) => site[key])
      .filter((state) => !!state.plan)
      .filter((state) => state.type !== "alternative")
      // @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
      .sort((a, b) => new Date(a.startDate) - new Date(b.stateDate));

    return sorted[0];
  }, statesBySite);

  const projectIds = R.flatten(
    Object.keys(sitesWithLatestLaunchedState).map((key) => {
      // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
      const site = sitesWithLatestLaunchedState[key];

      if (!site?.plan?.selectedProjectYears) return [];
      return Object.keys(site.plan.selectedProjectYears).filter(
        (projectId) => site.plan.selectedProjectYears[projectId] === currentYear
      );
    })
  );

  const [plannedProjects, implementedProjects, projectGroupings] =
    await Promise.all([
      getProjects(
        { projectIds: projectIds.map(Number) },
        { implemented: false }
      ),
      getProjects({ siteIds }, { implemented: true }),
      getProjectGroupingWithProjects({ siteIds }),
    ]);

  const sortedPlannedProjects: {
    [siteId: string]: Array<Project>;
    // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
  } = R.groupBy((p) => p.siteId, plannedProjects);

  const sortedImplementedProjects: {
    [siteId: string]: Array<Project>;
    // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
  } = R.groupBy((p) => p.siteId, implementedProjects);

  const sortedProjectGroupings: {
    [siteId: string]: Array<ProjectGrouping>;
    // @ts-expect-error - TS2339 - Property 'siteId' does not exist on type 'ProjectGrouping'.
  } = R.groupBy((p) => p.siteId, projectGroupings);

  const savingsBySite: Record<string, any> = {};
  siteIds.forEach((siteId) => {
    savingsBySite[siteId] = {
      totalForecastSavings: 0,
      implementedSavings: 0,
      plannedSavings: 0,
      totalForecastGhgSavings: 0,
      implementedGhgSavings: 0,
      plannedGhgSavings: 0,
    };
  });

  Object.keys(sortedPlannedProjects).forEach((siteId) => {
    const siteProjects = sortedPlannedProjects[siteId]!;
    const siteGroups = sortedProjectGroupings[siteId] || [];

    const interactiveEffect = getInteractiveEffectForSelectedProjects(
      siteProjects,
      siteGroups
    );

    for (const p of siteProjects) {
      const savings =
        interactiveEffect * (p.energySavings || 0) + (p.omSavings || 0);

      const ghgSavings = interactiveEffect * (p.netYearlyGhgSavings || 0);

      savingsBySite[siteId].totalForecastSavings += savings;
      savingsBySite[siteId].plannedSavings += savings;

      savingsBySite[siteId].totalForecastGhgSavings += ghgSavings;
      savingsBySite[siteId].plannedGhgSavings += ghgSavings;
    }
  });

  Object.keys(sortedImplementedProjects).forEach((siteId) => {
    const siteProjects = sortedImplementedProjects[siteId]!;
    const siteGroups = sortedProjectGroupings[siteId] || [];

    const interactiveEffect = getInteractiveEffectForSelectedProjects(
      siteProjects,
      siteGroups
    );

    for (const p of siteProjects) {
      const savings =
        interactiveEffect * (p.energySavings || 0) + (p.omSavings || 0);

      const ghgSavings = interactiveEffect * (p.netYearlyGhgSavings || 0);

      savingsBySite[siteId].totalForecastSavings += savings;
      savingsBySite[siteId].implementedSavings += savings;

      savingsBySite[siteId].totalForecastGhgSavings += ghgSavings;
      savingsBySite[siteId].implementedGhgSavings += ghgSavings;
    }
  });

  return savingsBySite;
};

const getPortfolioSummary = async (
  accountId: string | number
): Promise<Array<SiteRollupSummary>> => {
  try {
    const sites = await request
      .get(`/rest/portfolio_rollup_summary`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ account_id: `eq.${accountId}` })
      .then(({ body }) => body.map(recursiveCamelCaseCipher));

    const savingsBySite = await getSavingsBySite(sites.map(R.prop("id")));

    const isEsgDemo = localStorage.getItem("esgDemo");
    if (isEsgDemo) {
      return (
        sites
          // @ts-expect-error - TS7006 - Parameter 'site' implicitly has an 'any' type.
          .filter((site) => Object.keys(demoSites).includes(String(site.id)))
          // @ts-expect-error - TS7006 - Parameter 'site' implicitly has an 'any' type.
          .map((site) => ({
            ...site,
            ...savingsBySite[site.id],
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly "77": { readonly name: "10 Downing Street"; readonly arcAssetId: 9000000073; readonly arcAsset: DetailedArcAsset; readonly voc: readonly [ArcMeterConsumption, ArcMeterConsumption, ArcMeterConsumption]; ... 4 more ...; readonly operationalDays: readonly [...]; }; readonly "132": { ...; }; readonly "147": {...'.
            name: demoSites[site.id].name,
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly "77": { readonly name: "10 Downing Street"; readonly arcAssetId: 9000000073; readonly arcAsset: DetailedArcAsset; readonly voc: readonly [ArcMeterConsumption, ArcMeterConsumption, ArcMeterConsumption]; ... 4 more ...; readonly operationalDays: readonly [...]; }; readonly "132": { ...; }; readonly "147": {...'.
            streetAddress: demoSites[site.id].name,
            account: "Acme Asset Management",
            tags: [],
            imageURL: site.imageKey
              ? `https://bractlet-public${
                  isDev ? "-dev" : ""
                }.s3.amazonaws.com/valinor/building_images/${
                  site.imageKey
                }.jpeg`
              : null,
          }))
      );
    }

    // @ts-expect-error - TS7006 - Parameter 'site' implicitly has an 'any' type.
    return sites.map((site) => ({
      ...site,
      ...savingsBySite[site.id],
      imageURL: site.imageKey
        ? `https://bractlet-public${
            isDev ? "-dev" : ""
          }.s3.amazonaws.com/valinor/building_images/${site.imageKey}.jpeg`
        : null,
    }));
  } catch (e: any) {
    bugsnagPostgrestErrorHandler(e);
    throw e;
  }
};

type AccountEnergyStarScores = {
  accountId: number;
  id: number;
  name: string;
  energyStarScores: Array<SiteEnergyStarScore>;
}[];

const getAccountEnergyStarScores = (
  accountId: string | number
): Promise<AccountEnergyStarScores> =>
  request
    .get(`/rest/portfolio_rollup_energy_star`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ account_id: `eq.${accountId}` })
    .then(({ body }) => body.map(recursiveCamelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

export type AccountSiteStats = {
  siteId: number;
  accountId: number;
  completedSteps: number;
  percent: number;
  totalSteps: number;
};

type AccountSiteStatsResponse = AccountSiteStats & {
  status: "active" | "inactive";
};

/* depends on cache generated by f_refresh_onboarding_items. 
only call this function after calling the above function 
to refresh the cache*/
const getAccountSitesStats = async (
  accountId: string | number
): Promise<{
  [siteId: number]: AccountSiteStats;
}> => {
  const response = await request
    .get(`/rest/v_sites_stats`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ account_id: `eq.${accountId}`, status: "eq.active" });

  if (!response.ok) {
    throw response.error;
  }

  const siteStats = response.body.map(recursiveCamelCaseCipher);
  return siteStats.reduce(
    (
      result: { [siteId: number]: AccountSiteStats },
      { status, ...each }: AccountSiteStatsResponse
    ) => {
      result[each.siteId] = {
        ...each,
        percent: Math.round(each.percent),
      };
      return result;
    },
    {}
  );
};

export type AccountStats = {
  accountId: number;
  completedSteps: number;
  completedStepsPercent: number;
  totalSteps: number;
  completedSites: number;
  completedSitesPercent: number;
  totalSites: number;
};

/* depends on cache generated by f_refresh_onboarding_items. 
only call this function after calling the above function 
to refresh the cache*/
const getAccountStats = async (
  accountId: string | number
): Promise<AccountStats | void> => {
  const response = await request
    .get(`/rest/v_accounts_stats`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ account_id: `eq.${accountId}` });

  if (!response.ok) {
    throw response.error;
  }

  const [accountStats] = response.body.map(recursiveCamelCaseCipher);
  if (accountStats) {
    return {
      ...accountStats,
      completedStepsPercent: Math.round(
        (100 * accountStats.completedSteps) / accountStats.totalSteps || 0
      ),
      completedSitesPercent: Math.round(
        (100 * accountStats.completedSites) / accountStats.totalSites || 0
      ),
    };
  }
};

export {
  getPortfolioSummary,
  getAccountEnergyStarScores,
  getAccountStats,
  getAccountSitesStats,
};
