import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";
import * as Icons from "../icons";

import { useUUID } from "../global_functions/hooks";

export interface TextareaProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof TextareaAutosize>,
    "value" | "onChange" | "disabled" | "readOnly"
  > {
  value?: string | null;
  textAreaClass?: string;
  icon?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  gray?: boolean;
  autosize?: boolean;
  onChange?: React.ComponentProps<"textarea">["onChange"];
  dataTestId?: string;
}

const Textarea = ({
  value,
  className,
  textAreaClass,
  icon,
  rounded,
  gray,
  autosize,
  dataTestId,
  ...props
}: TextareaProps) => {
  const inputId = useUUID();
  const textareaProps = {
    id: inputId,
    value: value || "",
    ...props,
  } as const;

  return (
    <div
      className={classNames(className, "bractlet-textarea", {
        "--has-icon": icon,
        "--autosize": autosize,
        "--rounded": rounded,
        "--gray": gray,
      })}
    >
      {autosize ? (
        <TextareaAutosize
          data-testid={dataTestId ?? "textarea-autosize"}
          className={textAreaClass}
          {...textareaProps}
        />
      ) : (
        <textarea
          data-testid={dataTestId ?? "textarea-element"}
          className={textAreaClass}
          {...textareaProps}
        />
      )}
      {icon && !props.disabled && !props.readOnly && (
        <label htmlFor={inputId}>
          <Icons.Edit />
        </label>
      )}
    </div>
  );
};

export default Textarea;
