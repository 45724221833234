import * as React from "react";
import Icon, { IconsProps } from "../icons";
import classnames from "classnames";
import { kebabCase } from "lodash";

import {
  allUtilitiesById,
  systemsById,
  topLevelUtilitiesById,
} from "../global_functions/utilities";
import type { TopLevelUtility, Utility, System } from "../types";

interface Props
  extends Omit<React.ComponentPropsWithoutRef<typeof Icon>, "icon"> {
  topLevelUtility?: TopLevelUtility;
  utility?: Utility | null;
  system?: System | null;
}

const UtilitySystemIcon = ({
  topLevelUtility,
  utility,
  system,
  className,
  ...props
}: Props) => {
  if (system === "summary")
    return (
      <Icon
        {...props}
        icon="Home"
        className={classnames(className, "color--green")}
      />
    );

  let icon: IconsProps["icon"] | undefined;
  if (system && systemsById[system]) {
    icon = systemsById[system].icon;
  } else if (utility && allUtilitiesById[utility]) {
    icon = allUtilitiesById[utility].icon;
  } else if (topLevelUtility && topLevelUtilitiesById[topLevelUtility]) {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    icon = topLevelUtilitiesById[topLevelUtility].icon;
  }

  if (icon == null) return null;

  return (
    <Icon
      {...props}
      icon={icon}
      className={classnames(
        className,
        system && systemsById[system]
          ? `utility-system--${system}`
          : utility && allUtilitiesById[utility]
          ? `utility-system--${kebabCase(utility)}`
          : topLevelUtility && topLevelUtilitiesById[topLevelUtility]
          ? `utility-system--${kebabCase(topLevelUtility)}`
          : ""
      )}
    />
  );
};

export default UtilitySystemIcon;
