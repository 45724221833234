import Cookies from "js-cookie";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { VITE_UTILITIES_BASE_URL } from "../../../env";
import { Utility } from "../../../types";
import { CamelCasedProperties } from "type-fest";
import { ClientOutput } from "../client";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "../../postgrestApi";
import request from "../../request";
import moment from "moment";
import { throwBackpackSuperagentResponseError } from "../errors";
import { useUtilitiesArcadiaQAHeader } from "@src/utilitiesArcadiaQA";

export type BackpackMeter = Omit<
  CamelCasedProperties<ClientOutput["utilities"]["Meter"]>,
  "utilityType"
> & { utilityType: Utility };

const QUERY_KEY = "backpack-meters";

const meters = {
  useQuery,
  useQueryAll,
  mutations: {
    usePost,
    usePut,
    useDelete,
  },
} as const;

export default meters;

// GET

// SINGLE

const getMeter = (siteId: number, meterId: number, routeToHeader: string) =>
  request
    .get(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/meters/${meterId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("X-Route-To", routeToHeader)
    .then((res) =>
      formatMeter(recursiveCamelCaseCipher(res.body) as BackpackMeter, {
        direction: "inbound",
      })
    );

function useQuery(siteId: number, meterId: number) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useReactQuery({
    queryKey: [QUERY_KEY, siteId, meterId],
    queryFn: () => getMeter(siteId, meterId, routeToHeader),
  });
}

// ALL

const getAllMeters = (siteId: number, routeToHeader: string) =>
  request
    .get(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/meters`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("X-Route-To", routeToHeader)
    .then((res) => recursiveCamelCaseCipher(res.body) as BackpackMeter[])
    .then((m) =>
      m
        .map((meter) => formatMeter(meter, { direction: "inbound" }))
        .sort((a, b) => a.id - b.id)
    );

function useQueryAll(siteId: number) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useReactQuery({
    queryKey: [QUERY_KEY, siteId],
    queryFn: () => getAllMeters(siteId, routeToHeader),
  });
}

// POST

export type PostUtilityMeterRequest = Partial<
  Omit<BackpackMeter, "id" | "siteId">
>;

export interface PostUtilityMeterResponse {
  id: number;
}

const postUtilityMeter = (
  siteId: number,
  meter: PostUtilityMeterRequest,
  routeToHeader: string
): Promise<PostUtilityMeterResponse> =>
  request
    .post(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/meters`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("Content-Type", "application/json")
    .set("X-Route-To", routeToHeader)
    .send(
      recursiveSnakeCaseCipher(formatMeter(meter), {
        convertUtilityValues: true,
      })
    )
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(`Unable to create meter for site: ${siteId}`);
      }
      return recursiveCamelCaseCipher(res.body);
    })
    .catch(throwBackpackSuperagentResponseError);

function usePost(siteId: number) {
  const queryClient = useQueryClient();
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useMutation({
    mutationFn: (meter: PostUtilityMeterRequest) =>
      postUtilityMeter(siteId, meter, routeToHeader),
    onSuccess: async () =>
      await queryClient.invalidateQueries([QUERY_KEY, siteId]),
    onError: (e, { meterName }) => {
      throw new Error(
        `Unable to create meter "${meterName}"
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}

// PUT

type PutUtilityMeterRequest = Omit<BackpackMeter, "id">;

const putUtilityMeter = (
  siteId: number,
  meterId: number,
  meter: PutUtilityMeterRequest,
  routeToHeader: string
) =>
  request
    .put(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/meters/${meterId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("X-Route-To", routeToHeader)
    .send(
      recursiveSnakeCaseCipher(formatMeter(meter), {
        convertUtilityValues: true,
      })
    )
    .then(() => {
      return;
    })
    .catch(throwBackpackSuperagentResponseError);

function usePut(siteId: number, meterId: number) {
  const queryClient = useQueryClient();
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useMutation({
    mutationFn: (meter: PutUtilityMeterRequest) =>
      putUtilityMeter(siteId, meterId, meter, routeToHeader),
    onSuccess: async () =>
      await queryClient.invalidateQueries([QUERY_KEY, siteId]),
  });
}

// DELETE

const deleteUtilityMeter = (
  siteId: number,
  meterId: number,
  routeToHeader: string
) =>
  request
    .delete(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/meters/${meterId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("X-Route-To", routeToHeader)
    .then(() => {
      return;
    })
    .catch(throwBackpackSuperagentResponseError);

function useDelete(siteId: number, meterId: number) {
  const queryClient = useQueryClient();
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useMutation({
    mutationFn: () => deleteUtilityMeter(siteId, meterId, routeToHeader),
    onSuccess: async () =>
      await queryClient.invalidateQueries([QUERY_KEY, siteId]),
  });
}

// Enums
export const billsSourceOptions = [
  "automated_credentials",
  "manual_upload",
  "utility_provider_aggregate",
] as const;

export const energySourceOptions = [
  "electric_driven",
  "absorption",
  "engine_driven",
  "other",
] as const;

export const heatingSubTypeOptions = ["steam", "hot_water"] as const;

export const expectedBillFrequencyOptions = [
  "monthly",
  "quarterly",
  "annual",
  "other",
] as const;

export const waterUsageOptions = [
  "potable",
  "reclaimed",
  "well",
  "other",
] as const;

export const waterUsageTypeOptions = ["indoor", "outdoor", "mixed"] as const;

export const wasteUsageOptions = [
  "trash",
  "mixed_recyclables",
  "compostable",
] as const;

function formatMeter<T extends Partial<BackpackMeter>>(
  meter: T,
  { direction }: { direction: "inbound" | "outbound" } = {
    direction: "outbound",
  }
) {
  // since date is type-in, harder to manage with format/parse in form
  // easier to just convert here and use a consistent format
  const inboundDateFormatter = (date?: string | null) =>
    moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
  const outboundDateFormatter = (date?: string | null) =>
    moment(date, "MM/DD/YYYY", true).format("YYYY-MM-DD");

  const formatter =
    direction === "inbound" ? inboundDateFormatter : outboundDateFormatter;

  if (meter.inactiveDate) {
    return {
      ...meter,
      inactiveDate: formatter(meter.inactiveDate),
    };
  }
  return meter;
}
