import { atom, PrimitiveAtom } from "jotai";

// Returns a derived atom with a setter that applies the update to the current state
export function assignAtom<T>(anAtom: PrimitiveAtom<T>) {
  return atom(
    (get) => get(anAtom),
    (get, set, update: Partial<T>) => {
      set(anAtom, {
        ...get(anAtom),
        ...update,
      });
    }
  );
}
