import { useQuery, useMutation, useQueryClient } from "react-query";
import { useCallback, useMemo } from "react";
import {
  downloadProjectAttachment,
  getProjectAttachmentPreviews,
  uploadProjectAttachment,
  downloadRewardReceipt,
  getRewardReceiptPreviews,
  uploadRewardReceipt,
} from "./projectsAttachmentsApi";

import FetchClients, {
  ClientOutput,
} from "@src/global_functions/backpackSdk/client";
import { bugsnagPostgrestErrorHandler } from "@src/global_functions/postgrestApi";
import * as R from "ramda";
import { FileUpload } from "@src/straps/derived/FileUpload/FileUpload";
type AttachmentType = "projects" | "rewards";

const serviceMap = {
  projects: {
    download: downloadProjectAttachment,
    getPreviews: getProjectAttachmentPreviews,
    getInformation: getBackpackProjectAttachmentInformation,
    upload: uploadProjectAttachment,
  },
  rewards: {
    download: downloadRewardReceipt,
    getPreviews: getRewardReceiptPreviews,
    getInformation: getRewardReceiptInformation,
    upload: uploadRewardReceipt,
  },
} as const;

const textMap = {
  projects: { title: "Documents" },
  rewards: { title: "Receipts*" },
} as const;

export const PREVIEW_BASE_KEY = "attachment-preview";
export const useAttachmentPreviewQuery = (
  project_id: number,
  type: AttachmentType,
  reward_id?: number
) => {
  const getPreviews = serviceMap[type].getPreviews;
  const getInformation = serviceMap[type].getInformation;

  const handleGetPreviews = useCallback(async () => {
    if (type === "projects") {
      return await getPreviews(project_id);
    }
    if (type === "rewards") {
      if (!reward_id) return {};
      return await getPreviews(reward_id);
    }
    return {};
  }, [getPreviews, project_id, reward_id, type]);

  const baseQueryKey = [PREVIEW_BASE_KEY, type, project_id];
  const queryKey =
    type === "rewards" ? [...baseQueryKey, reward_id] : baseQueryKey;
  return useQuery(queryKey, async () => {
    const images = await handleGetPreviews();
    // this will be updated to reward_id once a reward needs to exist pre-upload in a later release
    const names = await getInformation(project_id);
    const allFileNames = names.map((name) => name.filename);
    return names.map((name) => ({
      ...name,
      preview: images[name.id.toString()],
      hasDuplicate:
        name.version > 1 ||
        allFileNames.filter((n) => n === name.filename).length > 1,
    }));
  });
};

const useAttachmentUploadMutation = (
  projectId: number,
  type: AttachmentType
) => {
  const upload = useMemo(() => serviceMap[type].upload, [type]);
  const queryClient = useQueryClient();
  return useMutation((files: File[]) => upload(projectId, files[0]!), {
    onSuccess: () =>
      queryClient.invalidateQueries([PREVIEW_BASE_KEY, type, projectId]),
  });
};

export default function ProjectsAttachments({
  projectId,
  type = "projects",
  reward_id,
}: Readonly<{
  projectId: number;
  type?: AttachmentType;
  reward_id?: number;
}>) {
  const { data: previews, isLoading } = useAttachmentPreviewQuery(
    projectId,
    type,
    reward_id
  );
  const { mutateAsync: uploadFile } = useAttachmentUploadMutation(
    projectId,
    type
  );

  async function handlePreviewClick(id: number) {
    return serviceMap[type].download(id).then((link) => {
      if (link) window.open(link, "_blank");
    });
  }

  return (
    <FileUpload
      documentType={textMap[type].title}
      onClickPreview={handlePreviewClick}
      isLoadingPreviews={isLoading}
      uploadFiles={uploadFile}
      previews={previews}
    />
  );
}

const attachmentCipher = (
  data:
    | Array<ClientOutput["projects"]["ProjectAttachment"]>
    | Array<ClientOutput["projects"]["RewardReceipt"]>
) => {
  return R.sortWith(
    [
      R.ascend(R.compose(R.toLower, R.prop("filename"))),
      R.ascend(R.prop("version")),
    ],
    data.map((attachment) => {
      const { project_id, uploaded_at, ...rest } = attachment;
      return {
        ...rest,
        uploaded_at: new Date(uploaded_at).toISOString(),
      };
    })
  );
};

export async function getBackpackProjectAttachmentInformation(
  project_id: number
) {
  const { data, error } = await FetchClients.projects.GET("/attachments", {
    params: {
      query: {
        project_id,
      },
    },
  });
  if (data) {
    return attachmentCipher(data);
  }
  // @todo throw an error and handle in component once projects-rewards flag is removed
  bugsnagPostgrestErrorHandler(error);
  return [];
}

async function getRewardReceiptInformation(project_id: number) {
  const { data, error } = await FetchClients.projects.GET(
    "/{project_id}/reward_receipts",
    {
      params: {
        path: {
          project_id,
        },
      },
    }
  );
  if (data) {
    return attachmentCipher(data);
  }
  // @todo throw an error and handle in component once projects-rewards flag is removed
  bugsnagPostgrestErrorHandler(error);
  return [];
}
