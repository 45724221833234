import * as React from "react";

import {
  redirectOnError,
  bugsnagGeneralErrorHandler,
} from "./postgrestApi/common";

export const CacheContext = React.createContext<{
  cache: {
    [key: string]: any;
  };
  setCache: any;
}>({
  cache: {},
  setCache: () => {},
});

export type AsyncState<T> = {
  loading: boolean;
  success: boolean;
  error: Error | null;
  data?: T;
};
export type UseAsyncReturnValues<T> = AsyncState<T> & {
  setData: (
    arg1:
      | T
      | null
      | undefined
      | ((arg1?: T | null | undefined) => T | null | undefined)
  ) => void;
  setState: (
    arg1: AsyncState<T> | ((arg1: AsyncState<T>) => AsyncState<T>)
  ) => void;
};

export type UseAsyncOptions = {
  key?: string;
};

export function useAsync<T, TArgs extends unknown[] = any>(
  callback: (...rest: TArgs) => Promise<T> | undefined | null,
  deps: Array<any>,
  options?: UseAsyncOptions
): [UseAsyncReturnValues<T>, (...rest: TArgs) => Promise<any>] {
  const { key } = options || {};

  const { cache = {}, setCache } = React.useContext(CacheContext);

  const cachedValue = key ? (cache[key] as T) : undefined;

  const [state, setState] = React.useState<AsyncState<T>>({
    loading: false,
    success: false,
    error: null,
    data: cachedValue,
  });

  React.useEffect(() => {
    if (key && cachedValue) {
      setState((prev) => ({
        ...prev,
        success: true,
        data: cachedValue,
      }));
    }
    // eslint-disable-next-line
  }, [key, setState, JSON.stringify(cachedValue)]);

  let didCancel = false;
  React.useEffect(() => {
    // eslint-disable-next-line
    didCancel = false;
    return () => {
      didCancel = true;
    };
  }, []);

  const cb = React.useCallback(callback, deps); // eslint-disable-line react-hooks/exhaustive-deps

  const action = React.useCallback(
    async (...args: TArgs) => {
      try {
        if (!didCancel) {
          setState((prev) => ({ ...prev, loading: true }));
        }

        const data = await cb(...args);

        if (key) {
          // @ts-expect-error - TS7006 - Parameter 'prev' implicitly has an 'any' type.
          setCache((prev) => Object.assign(prev, { [key]: data }));
        }

        if (!didCancel) {
          setState({
            // @ts-expect-error - TS2322 - Type 'Awaited<T> | null | undefined' is not assignable to type 'T | undefined'.
            data,
            success: true,
            loading: false,
            error: null,
          });
          return data;
        }
      } catch (error: any) {
        redirectOnError(error);
        bugsnagGeneralErrorHandler(error);

        if (!didCancel) {
          setState({
            success: false,
            loading: false,
            error,
          });
        }
      }
    },
    // eslint-disable-next-line
    [...deps, didCancel, cb, key]
  );

  const setData = React.useCallback(
    (updateData) =>
      setState((prev) => ({
        ...prev,
        data:
          typeof updateData === "function" ? updateData(prev.data) : updateData,
      })),
    []
  );

  return [{ ...state, setData, setState }, action];
}

export function useAsyncLazy<T>(
  callback: (...rest: any) => Promise<T> | undefined | null,
  deps: Array<any>,
  options?: UseAsyncOptions
): [UseAsyncReturnValues<T>, (...rest: any) => Promise<any>] {
  const [data, fetch] = useAsync(callback, deps, options); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const isCached = options?.key && data.data;
    if (deps.every((d) => d !== null && d !== undefined) && !isCached) {
      fetch();
    }
    // eslint-disable-next-line
  }, [fetch, ...deps]);

  return [data, fetch];
}

export default useAsync;
