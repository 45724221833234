import { useFileBrowser } from "@src/global_functions/hooks";
import {
  Alerts,
  Button,
  Icon,
  Skeleton,
  Text,
  Tooltip,
} from "@src/straps/base";
import { AlertEnum } from "@src/straps/base/dialogs/Alerts/Alerts";
import { IconNames } from "@src/straps/base/icons/Icon/types";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";
import classNames from "classnames";
import moment from "moment";
import { useCallback, useState } from "react";

const ATTACHEMENT_TYPES = [".pdf", "application/pdf"];
export type Attachment = {
  id: number;
  filename: string;
  version: number;
  has_preview: boolean;
  preview: string | undefined | null;
  hasDuplicate: boolean;
  uploaded_at: string;
};

/*
 * @param uploadFiles - must be wrapped in a useCallback
 */
export function FileUpload<T extends Attachment>({
  documentType,
  previews,
  isLoadingPreviews,
  uploadFiles,
  onClickPreview,
}: {
  documentType: string; // used for button text and title
  previews?: T[];
  isLoadingPreviews: boolean;
  uploadFiles: (files: File[]) => Promise<void>;
  onClickPreview: (fileId: number) => Promise<void> | void;
}) {
  const [isUploading, setIsUploading] = useState(false);
  return (
    <div className="flex flex-col" data-testid="file-uploader">
      {previews?.length ? (
        <Text variant="sb_t-12-500" color="secondary">
          {`${documentType} (${(previews ?? []).length})`}
        </Text>
      ) : null}
      <div
        className={classNames(
          "flex items-center overflow-hidden transition-all duration-500",
          {
            "h-0 opacity-0": !isLoadingPreviews,
            "h-5 opacity-100": isLoadingPreviews,
          }
        )}
      >
        <Text
          variant="sb_t-12-500"
          color="tertiary"
        >{`${documentType} loading...`}</Text>
      </div>
      <PreviewList
        onClickPreview={onClickPreview}
        isLoadingPreviews={isLoadingPreviews}
        isUploading={isUploading}
        previews={previews}
      />
      <UploadButton
        buttonText={`Upload ${documentType}`}
        setIsUploading={setIsUploading}
        uploadFiles={uploadFiles}
      />
    </div>
  );
}

export function UploadButton({
  buttonText,
  setIsUploading,
  uploadFiles,
  icon,
}: {
  buttonText: string;
  setIsUploading?: (isUploading: boolean) => void;
  uploadFiles: (files: File[]) => Promise<void>;
  icon?: IconNames;
}) {
  const [error, setError] = useState<string | null>(null);
  const [openFileBrowser] = useFileBrowser(
    ATTACHEMENT_TYPES,
    useCallback(
      async (files) => {
        try {
          setIsUploading?.(true);
          await uploadFiles(files);
        } catch (e) {
          setError(
            "Error uploading files. If problem persists please contact backpack support."
          );
        }
        setIsUploading?.(false);
      },
      [uploadFiles, setIsUploading]
    )
  );

  return (
    <div className="relative">
      {error && (
        <div className="absolte z-10">
          <Alerts
            message={<span className="flex items-center gap-2 ">{error}</span>}
            status={AlertEnum.Error}
          />
        </div>
      )}

      <Button
        rounded
        onClick={openFileBrowser}
        icon={icon ?? "add-file"}
        width="190px"
      >
        {buttonText}
      </Button>
    </div>
  );
}

export function PreviewList({
  previews,
  isLoadingPreviews,
  isUploading,
  onClickPreview,
}: {
  previews?: Attachment[];
  isLoadingPreviews?: boolean;
  isUploading: boolean;
  onClickPreview: (fileId: number) => Promise<void> | void;
}) {
  return (
    <div
      className={classNames("flex flex-wrap gap-4", {
        "mb-4 mt-2": previews?.length || isUploading,
      })}
    >
      {isUploading &&
        (previews ?? []).map((_, i) => (
          <Skeleton key={i} className="h-36 w-[200px] shadow" />
        ))}
      {isUploading && <Skeleton className="h-36 w-[200px] shadow" />}
      {!isUploading && !isLoadingPreviews && previews?.length
        ? previews.map(
            ({
              id,
              filename,
              version,
              has_preview,
              preview,
              hasDuplicate,
              uploaded_at,
            }) => (
              <ClickableDiv
                className="h-36 w-[200px] cursor-pointer bg-white shadow transition-all hover:shadow-md"
                key={id}
                data-testid="file-preview"
                onClick={async () => await onClickPreview(id)}
              >
                <div className="h-[104px]">
                  {!has_preview || !preview ? (
                    <Text variant="bc_s-12-300" className="p-3">
                      No preview available
                    </Text>
                  ) : (
                    <img
                      className="block h-full w-full object-cover object-left-top"
                      alt="A preview of the attachment"
                      src={preview}
                    />
                  )}
                </div>
                <div className="flex h-10 items-center gap-1.5 border-t px-2">
                  <Tooltip
                    content={`Uploaded on ${moment(uploaded_at).format(
                      "MMM DD, YYYY"
                    )}`}
                    align="bottom"
                    fixed
                  >
                    <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-straps-tertiary/30 transition-all hover:bg-straps-primary hover:text-white [&_div]:hover:!text-white [&_span]:hover:!opacity-100 [&_svg]:hover:!text-white">
                      {hasDuplicate ? (
                        <Text
                          variant="sb_t-12-500"
                          className="font-semibold hover:text-white"
                          color="secondary"
                        >{`v${version}`}</Text>
                      ) : (
                        <Icon
                          name="data-submissions"
                          color="secondary"
                          className="opacity-70 transition-all"
                          size="small"
                        />
                      )}
                    </div>
                  </Tooltip>
                  <Text className="truncate" variant="sa_t-12-700">
                    {filename}
                  </Text>
                </div>
              </ClickableDiv>
            )
          )
        : null}
    </div>
  );
}
