// Allow import here - this is the root request file
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import superagent, { SuperAgentRequest } from "superagent";
// Use getters for unit tests
import {
  VITE_ACTIVITY_LOGS_BASE_URL,
  VITE_ADMIN_URL,
  VITE_ARCADIA_BASE_URL,
  VITE_BACKPACK_URL,
  VITE_ENABLE_UNSAFE_REQUESTS,
  VITE_IP_URL,
  VITE_LEDGER_BASE_URL,
  VITE_REPORTER_BASE_URL,
  VITE_SITE_SERVICE_BASE_URL,
  VITE_SITE,
  VITE_TENANTS_AND_SPACES_BASE_URL,
  VITE_UPLOAD_BASE_URL,
  VITE_UTILITIES_BASE_URL,
  VITE_VALINOR_URL,
} from "../env";

/**
 * Pretty much everything in this file is written as a create function, so that we can mock the env variables in tests.
 * The actual value or function is then exported using the real env variables as the argument.
 */

const siteUrls = {
  VITE_IP_URL,
  VITE_VALINOR_URL,
  VITE_BACKPACK_URL,
  VITE_ADMIN_URL,
} as const;

const serviceUrls = {
  VITE_REPORTER_BASE_URL,
  VITE_LEDGER_BASE_URL,
  VITE_UPLOAD_BASE_URL,
  VITE_SITE_SERVICE_BASE_URL,
  VITE_TENANTS_AND_SPACES_BASE_URL,
  VITE_UTILITIES_BASE_URL,
  VITE_ACTIVITY_LOGS_BASE_URL,
  VITE_ARCADIA_BASE_URL,
} as const;

const env = {
  VITE_MODE: import.meta.env.MODE,
  VITE_SITE,
  VITE_ENABLE_UNSAFE_REQUESTS,
  ...serviceUrls,
  ...siteUrls,
} as const;

// Maps VITE_SITE to a key in siteUrls
const SITE_URL_ENV_KEYS = {
  ip: "VITE_IP_URL",
  valinor: "VITE_VALINOR_URL",
  console: "VITE_BACKPACK_URL",
  admin: "VITE_ADMIN_URL",
} satisfies Record<typeof VITE_SITE, keyof typeof siteUrls>;

export const createGetPrefixUrl = (_env: {
  VITE_SITE: typeof VITE_SITE;
  VITE_IP_URL: typeof VITE_IP_URL;
  VITE_VALINOR_URL: typeof VITE_VALINOR_URL;
  VITE_BACKPACK_URL: typeof VITE_BACKPACK_URL;
  VITE_ADMIN_URL: typeof VITE_ADMIN_URL;
}) => _env[SITE_URL_ENV_KEYS[_env.VITE_SITE]];

// This is the base url that the client is hosted from, as well as some other legacy services
// It changes depending on VITE_SITE
export const prefixUrl = createGetPrefixUrl(env);

const createAllUrlEnvsMap = (_env: typeof env) => {
  const backendEnvKey = SITE_URL_ENV_KEYS[_env.VITE_SITE];
  return {
    [backendEnvKey]: _env[backendEnvKey],
    VITE_REPORTER_BASE_URL: _env.VITE_REPORTER_BASE_URL,
    VITE_LEDGER_BASE_URL: _env.VITE_LEDGER_BASE_URL,
    VITE_UPLOAD_BASE_URL: _env.VITE_UPLOAD_BASE_URL,
    VITE_SITE_SERVICE_BASE_URL: _env.VITE_SITE_SERVICE_BASE_URL,
    VITE_TENANTS_AND_SPACES_BASE_URL: _env.VITE_TENANTS_AND_SPACES_BASE_URL,
    VITE_UTILITIES_BASE_URL: _env.VITE_UTILITIES_BASE_URL,
    VITE_ACTIVITY_LOGS_BASE_URL: _env.VITE_ACTIVITY_LOGS_BASE_URL,
    VITE_ARCADIA_BASE_URL: _env.VITE_ARCADIA_BASE_URL,
  } satisfies {
    [key in keyof typeof serviceUrls]: string;
  };
};

const createAllUrlEnvsArray = (_env: typeof env) =>
  Object.values(createAllUrlEnvsMap(_env));

export const allUrlEnvsMap = createAllUrlEnvsMap(env);

const stagingUrls = ["bpn-staging.com", "bractlet-staging.com"];

export const createEnvIsStaging = (_env: typeof env) => {
  return createAllUrlEnvsArray(_env).some((url) =>
    stagingUrls.some((domain) => url.includes(domain))
  );
};

export const envIsStaging = createEnvIsStaging(env);

const prodUrls = ["bractlet.com", "backpacknetworks.com"];

export const createEnvIsProd = (_env: typeof env) => {
  return createAllUrlEnvsArray(_env).some((url) =>
    prodUrls.some((domain) => url.includes(domain))
  );
};

export const envIsProd = createEnvIsProd(env);

export const createConsistentEnv = (_env: typeof env) => {
  return (
    createAllUrlEnvsArray(_env).every((url) =>
      stagingUrls.some((domain) => url.includes(domain))
    ) ||
    createAllUrlEnvsArray(_env).every((url) =>
      prodUrls.some((domain) => url.includes(domain))
    )
  );
};

export const consistentEnv = createConsistentEnv(env);

export const createDomainsFromEnv = (_env: typeof env) => {
  return createAllUrlEnvsArray(_env).reduce((acc, url) => {
    // make array of all domains, then we can check the size of array
    const domain = new URL(url).hostname;
    acc.add(domain);
    return acc;
  }, new Set<string>());
};

export const domainsFromEnv = createDomainsFromEnv(env);

export const createIsInExpectedEnv = (_env: typeof env) => {
  return (
    (createEnvIsStaging(_env) && _env.VITE_MODE === "staging") ||
    (createEnvIsProd(_env) && _env.VITE_MODE === "production")
  );
};

// bad name - this should be true for main deploys, but mostly false elsewhere and where the base urls are being modified
export const isInExpectedEnv = createIsInExpectedEnv(env);

export function createRequestShouldThrow(_env: typeof env) {
  return function (options?: {
    safe?: boolean;
    prodOnly?: boolean;
    isTestSite?: boolean;
  }) {
    const { safe, prodOnly, isTestSite } = options || {};
    // Should throw if prod only condition is violated
    if (prodOnly && !isTestSite && _env.VITE_MODE !== "production") {
      return "This request is only allowed in production or for Test Sites";
    }

    // If request is safe, or we have enabled unsafe requests, we can skip this block.
    if (!safe && !_env.VITE_ENABLE_UNSAFE_REQUESTS) {
      /**
       * Before throwing, we want to additionally checking that the app is
       * operating in intended environment - i.e. envIsProd is only an issue
       * if we are not in production.
       */
      if (!createIsInExpectedEnv(_env)) {
        return "Unsafe requests are not enabled.";
      }
    }

    return null;
  };
}

export const requestShouldThrow = createRequestShouldThrow(env);

// Prefixes relative urls and prevents unsafe requests
export const createSuperagentPlugin = (_env: typeof env) => {
  return function (options?: {
    safe?: boolean;
    prodOnly?: boolean;
    isTestSite?: boolean;
  }) {
    return function (r: SuperAgentRequest) {
      try {
        // Relative URLs - should be prefixed with the defaultBackendUrl
        if (r.url[0] === "/") {
          r.url = createGetPrefixUrl(_env) + r.url;
        }

        const errorMessage = createRequestShouldThrow(_env)(options);
        if (errorMessage) throw new Error(errorMessage);

        return r;
      } catch (e: any) {
        // eslint-disable-next-line
        console.error(r.method, r.url, e);
        r.url = "";
        return r;
      }
    };
  };
};

const superagentPlugin = createSuperagentPlugin(env);

type CallbackHandler = (err: any, res: superagent.Response) => void;

const request = {
  // Safe methods
  get: (url: string, callback?: CallbackHandler) =>
    superagent.get(url, callback).use(superagentPlugin({ safe: true })),
  options: (url: string, callback?: CallbackHandler) =>
    superagent.options(url, callback).use(superagentPlugin({ safe: true })),
  /** post_safe is a variation primarly to cover `post` requests which do not result in database mutations. For example when requesting data but a request body is required.  */
  post_safe: (url: string, callback?: CallbackHandler) =>
    superagent.post(url, callback).use(superagentPlugin({ safe: true })),
  // Unsafe methods
  /** The `post` function covers the default behaviours interacting with our database. Requests will work as normal in development, production and staging where the REACT_APP_BACKEND flag is NOT used.  */
  post: (url: string, callback?: CallbackHandler) =>
    superagent.post(url, callback).use(superagentPlugin()),
  /** post_prodOnly is a quite self-explanatory variation of `post`. This function should be used for prod-only interactions with 3rd party APIs where for example linking IDs need to be kept in our DB. */
  post_prodOnly: (
    url: string,
    callback?: CallbackHandler,
    isTestSite?: boolean
  ) =>
    superagent
      .post(url, callback)
      .use(superagentPlugin({ prodOnly: true, isTestSite })),
  put: (url: string, callback?: CallbackHandler) =>
    superagent.put(url, callback).use(superagentPlugin()),
  delete: (url: string, callback?: CallbackHandler) =>
    superagent.delete(url, callback).use(superagentPlugin()),
  patch: (url: string, callback?: CallbackHandler) =>
    superagent.patch(url, callback).use(superagentPlugin()),
  merge: (url: string, callback?: CallbackHandler) =>
    superagent.merge(url, callback).use(superagentPlugin()),
};

export default request;
