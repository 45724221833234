import * as React from "react";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import moment from "moment";
import * as R from "ramda";
import classnames from "classnames";
import { startCase } from "lodash";
import numeral from "numeral";

import UnitsContext from "../context/UnitsContext";
import * as Icons from "../icons";
import type { EngineeringCalculation } from "../types";
import { useScrollbarDimensions } from "../global_functions/hooks";
import {
  getEngineeringCalculations,
  saveEngineeringCalculation,
  updateEngineeringCalculation,
  deleteEngineeringCalculation,
} from "../global_functions/postgrestApi";
import { isValidFloat } from "../global_functions/util";
import { useAsync } from "../global_functions/useAsync";
import EngineeringCalculationModal from "./EngineeringCalculationModal";
import EngineeringCalculationNoteModal from "./EngineeringCalculationNoteModal";
import DeleteModal from "./DeleteModal";

import styles from "./EngineeringCalculations.module.scss";
import AsyncButton from "./AsyncButton";

import "handsontable/dist/handsontable.full.min.css";

const months = moment.monthsShort().map((month) => month.toLowerCase());
const santizeValue = (value: any) =>
  value == null || value === "" ? null : numeral(value).value();
const numberFormat = "0,0[.]00";

const startCaseRenderer = (
  instance: any,
  td: any,
  row: any,
  col: any,
  prop: any,
  value: any
) => {
  td.innerHTML = value && startCase(value);
};

const numericRenderer = (
  instance: any,
  td: any,
  row: any,
  col: any,
  prop: any,
  value: any
) => {
  td.innerHTML = value && numeral(value).format(numberFormat);
};

class CustomNumberEditor extends Handsontable.editors.NumericEditor {
  saveValue(newRowValues: Array<Array<string>>, ctrlDown: boolean) {
    super.saveValue(
      newRowValues.map((row) =>
        row.map((col) => (isValidFloat(col, true) ? numeral(col).value() : col))
      ),
      ctrlDown
    );
  }
  setValue = function (newValue: any) {
    // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
    this.TEXTAREA.value = numeral(newValue).value();
  };
}

type Props = {
  parentPropName: "projectId" | "stateOrVersionId";
  parentId: string | number;
  refresh: () => Promise<void>;
};

const EngineeringCalculations = ({
  parentPropName,
  parentId,
  refresh,
}: Props) => {
  const { unitsMap } = React.useContext(UnitsContext);
  const [calculationsState, fetchCalculations] = useAsync(
    () =>
      getEngineeringCalculations({ propName: parentPropName, id: parentId }),
    [parentId, parentPropName]
  );

  const [editingCalculation, setEditingCalculation] = React.useState<any>(null);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [noteModalOpen, setNoteModalOpen] = React.useState(false);
  const [deletingCalculation, setDeletingCalculation] = React.useState(false);

  React.useEffect(() => {
    fetchCalculations();
  }, [fetchCalculations]);

  const { height: scrollbarHeight } = useScrollbarDimensions();
  const [hoveredRow, setHoveredRow] = React.useState<any>(null);
  const handsontable = React.useRef(null);

  const unitRenderer = React.useCallback(
    (instance, td, row, col, prop, value) => {
      td.innerHTML = value && unitsMap[value]?.label;
    },
    [unitsMap]
  );

  React.useEffect(() => {
    if (handsontable.current) {
      // @ts-expect-error - TS2339 - Property 'hotInstance' does not exist on type 'never'.
      const root = handsontable.current.hotInstance.rootElement;
      for (const tbody of root.querySelectorAll("tbody")) {
        for (const row of tbody.querySelectorAll("tr.hover")) {
          row.classList.remove("hover");
        }

        if (hoveredRow !== null) {
          const hovered = tbody.querySelectorAll("tr")[hoveredRow];
          if (hovered) hovered.classList.add("hover");
        }
      }
    }
  }, [hoveredRow]);

  const [changeBatch, setChangeBatch] = React.useState({});

  const submitChanges = async () => {
    await Promise.all(
      Object.keys(changeBatch).map(async (id) => {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        await updateEngineeringCalculation(Number(id), changeBatch[id]);
      })
    );
    setChangeBatch({});
    refresh();
  };

  const clearChanges = async () => {
    await fetchCalculations();
    setChangeBatch({});
  };

  const calculationsStateData = calculationsState?.data || [];

  const ModalEditor = Handsontable.editors.BaseEditor.prototype.extend();
  // @ts-expect-error - TS2339 - Property 'prototype' does not exist on type 'Base'.
  Object.assign(ModalEditor.prototype, {
    beginEditing() {
      // @ts-expect-error - TS2339 - Property 'row' does not exist on type '{ beginEditing(): void; }'.
      setEditingCalculation(calculationsStateData[this.row]);
      setEditModalOpen(true);
    },
  });

  const updateCalculation = (updated: EngineeringCalculation) => {
    setEditingCalculation(updated);
    calculationsState.setData((prevCalculations) => {
      let exists = false;
      const nextCalculations = (prevCalculations || []).map((calculation) => {
        if (calculation.id === updated.id) {
          exists = true;
          return updated;
        }

        return calculation;
      });

      return exists ? nextCalculations : [...nextCalculations, updated];
    });
    setEditModalOpen(false);
    setNoteModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <h3>Engineering Calculations</h3>
      <div className={styles.engineering_calculations}>
        {calculationsStateData.length > 0 && (
          <div
            className={styles.table_container}
            onMouseLeave={() => setHoveredRow(null)}
            onMouseOver={(e) => {
              // @ts-expect-error - TS2339 - Property 'nodeName' does not exist on type 'EventTarget'.
              if (e.target.nodeName === "TD") {
                // @ts-expect-error - TS2339 - Property 'parentNode' does not exist on type 'EventTarget'.
                const row = e.target.parentNode;
                setHoveredRow(
                  Array.prototype.indexOf.call(row.parentNode.children, row)
                );
                // @ts-expect-error - TS2339 - Property 'nodeName' does not exist on type 'EventTarget'.
              } else if (e.target.nodeName === "TH") {
                setHoveredRow(-1);
              }
            }}
          >
            <table className={styles.floating_actions}>
              <thead>
                <tr>
                  <th />
                </tr>
              </thead>
              <tbody>
                {calculationsStateData.map((calculation, index) => (
                  <tr
                    key={calculation.id}
                    className={classnames({
                      [styles.hover!]: hoveredRow === index,
                    })}
                  >
                    <td
                      className={classnames({
                        [styles.has_note!]: calculation.note,
                      })}
                    >
                      <div>
                        <Icons.Delete
                          onClick={() => {
                            setEditingCalculation(calculation);
                            setDeletingCalculation(true);
                          }}
                        />
                        <Icons.Note
                          className={styles.note}
                          onClick={() => {
                            setEditingCalculation(calculation);
                            setNoteModalOpen(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.handsontable_container}>
              <HotTable
                licenseKey="d1001-409f1-37d73-14304-7530b"
                ref={handsontable}
                data={calculationsState.data}
                colHeaders
                fixedColumnsLeft={1}
                fixedColumnsRight={1}
                filters={false}
                rowHeaders={false}
                columnSorting={false}
                sortingIndicator={false}
                beforePaste={(data, coords) => {
                  for (let row = 0; row < data.length; row++) {
                    for (let col = 0; col < data[row]!.length; col++) {
                      if (col !== 0 || coords[0]!.startCol !== 0) {
                        // only allow 'Name' column to have commas
                        data[row]![col] = data[row]![col]!.replace(/,/g, "");
                      }
                    }
                  }
                }}
                afterChange={(changes) => {
                  if (changes) {
                    R.toPairs(
                      // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
                      R.groupBy((change) => change[0], changes)
                    ).forEach(([row, rowChanges]: [any, any]) => {
                      const { id } = calculationsStateData[Number(row)] || {};
                      if (id) {
                        const updates: Record<string, any> = {};
                        rowChanges.forEach(
                          ([_, prop, oldValue, newValue]: [
                            any,
                            any,
                            any,
                            any
                          ]) => {
                            const prevValue = santizeValue(oldValue);
                            const value = santizeValue(newValue);
                            if (prevValue !== value) updates[prop] = value;
                          }
                        );

                        if (Object.keys(updates).length) {
                          setChangeBatch((prev) => {
                            return {
                              ...prev,
                              [id]: {
                                // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
                                ...prev[id],
                                ...updates,
                              },
                            };
                          });
                        }
                      }
                    });
                  }
                }}
                columns={[
                  {
                    data: "description",
                    // ugly hack to set the column min-width
                    title: ["Name", ...Array(36).fill("&nbsp;")].join(""),
                    validator: (value, callback) => callback(Boolean(value)),
                    allowInvalid: false,
                  },
                  {
                    data: "system",
                    title: "Category",
                    renderer: startCaseRenderer,
                    // @ts-expect-error - TS2322 - Type 'Base' is not assignable to type 'string | boolean | typeof Base | undefined'.
                    editor: ModalEditor,
                    validator: () => false,
                    allowInvalid: false,
                  },
                  {
                    data: "utility",
                    title: "Utility",
                    renderer: startCaseRenderer,
                    // @ts-expect-error - TS2322 - Type 'Base' is not assignable to type 'string | boolean | typeof Base | undefined'.
                    editor: ModalEditor,
                    validator: () => false,
                    allowInvalid: false,
                  },
                  {
                    data: "unitId",
                    title: "Unit",
                    renderer: unitRenderer,
                    // @ts-expect-error - TS2322 - Type 'Base' is not assignable to type 'string | boolean | typeof Base | undefined'.
                    editor: ModalEditor,
                    validator: () => false,
                    allowInvalid: false,
                  },
                  ...months.map((month, index) => ({
                    data: month,
                    title: startCase(month),
                    renderer: numericRenderer,
                    allowInvalid: false,
                    editor: CustomNumberEditor,
                    validator: (
                      value: string | number,
                      cb: (arg1: boolean) => void
                    ) =>
                      cb(
                        typeof value === "number"
                          ? true
                          : isValidFloat(value || "", true)
                      ),
                  })),
                ]}
                width="100%"
                height={
                  40 * (calculationsStateData.length + 1) + scrollbarHeight + 1
                }
              />
            </div>
            <table className={styles.totals}>
              <thead>
                <tr>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {calculationsStateData.map((calculation) => (
                  <tr key={calculation.id}>
                    <td>
                      {numeral(
                        R.sum(
                          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'EngineeringCalculation'.
                          months.map((month) => Number(calculation[month]) || 0)
                        )
                      ).format(numberFormat)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className={styles.add_new}>
          <button
            onClick={() => {
              setEditingCalculation({} as Partial<EngineeringCalculation>);
              setEditModalOpen(true);
            }}
          >
            <Icons.Plus /> Add New
          </button>
          {!!Object.keys(changeBatch).length && (
            <>
              <AsyncButton onClick={submitChanges} noStyle>
                Save Changes
              </AsyncButton>
              <AsyncButton onClick={clearChanges} noStyle>
                Clear Changes
              </AsyncButton>
            </>
          )}
        </div>
      </div>
      <EngineeringCalculationModal
        open={editModalOpen}
        // @ts-expect-error - TS2322 - Type '(updates: NewEngineeringCalculation) => Promise<void>' is not assignable to type '(calculation: NewEngineeringCalculation) => Promise<undefined> | undefined'.
        onSave={async (updates) => {
          const updated =
            editingCalculation && editingCalculation.id
              ? await updateEngineeringCalculation(
                  editingCalculation.id,
                  updates
                )
              : await saveEngineeringCalculation(
                  { propName: parentPropName, id: parentId },
                  updates
                );

          updateCalculation(updated);
          refresh();
          setEditModalOpen(false);
        }}
        onCancel={() => setEditModalOpen(false)}
        onClose={() => setEditingCalculation(null)}
        calculation={editingCalculation}
      />
      <EngineeringCalculationNoteModal
        open={noteModalOpen}
        calculation={editingCalculation}
        onCancel={() => setNoteModalOpen(false)}
        // @ts-expect-error - TS2322 - Type '{ open: boolean; calculation: any; onCancel: () => void; onClose: () => void; onSave: (note: string | null | undefined) => Promise<void>; }' is not assignable to type 'IntrinsicAttributes & Props'.
        onClose={() => setEditingCalculation(null)}
        onSave={async (note) => {
          if (editingCalculation && editingCalculation.id) {
            const updated = await updateEngineeringCalculation(
              editingCalculation.id,
              { note }
            );

            updateCalculation(updated);
          }
        }}
      />
      <DeleteModal
        open={deletingCalculation}
        onCancel={() => setDeletingCalculation(false)}
        onClose={() => setEditingCalculation(null)}
        onDelete={async () => {
          if (editingCalculation) {
            calculationsState.setData((calcs) =>
              (calcs || []).filter(({ id }) => id !== editingCalculation.id)
            );
            await deleteEngineeringCalculation(editingCalculation.id);
            refresh();
            setDeletingCalculation(false);
          }
        }}
        title={editingCalculation && editingCalculation.description}
      />
    </div>
  );
};

export default EngineeringCalculations;
