import * as React from "react";
import { Modal, Textarea } from "../global_components";
import type { EngineeringCalculation } from "../types";

import styles from "./EngineeringCalculationNoteModal.module.scss";
type Props = {
  open: boolean;
  onSave: (note?: string | null | undefined) => Promise<void>;
  onCancel: () => void;
  calculation: EngineeringCalculation | null | undefined;
};

const EngineeringCalculationNoteModal = ({
  open,
  onSave,
  onCancel,
  calculation,
  ...props
}: Props) => {
  const [note, setNote] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState<any>(null);

  React.useEffect(() => {
    if (serverError) {
      const timeout = setTimeout(() => setServerError(null), 4000);

      return () => clearTimeout(timeout);
    }
  }, [serverError]);

  React.useEffect(() => {
    // @ts-expect-error - TS2345 - Argument of type '() => string | null | undefined' is not assignable to parameter of type 'SetStateAction<string>'.
    setNote(() => calculation && calculation.note);
  }, [calculation]);

  return (
    <Modal
      open={open}
      title={`${calculation && calculation.note ? "Edit" : "Add"} Note`}
      buttons
      // @ts-expect-error - TS2322 - Type '(() => void) | null' is not assignable to type '((arg1?: MouseEvent<HTMLDivElement, MouseEvent> | null | undefined) => Promise<undefined> | null | undefined) | undefined'.
      onCancel={loading ? null : onCancel}
      onSave={async () => {
        setLoading(true);

        try {
          await onSave(note || null);
        } catch (err: any) {
          setServerError(err.message);
        }

        setLoading(false);
      }}
      disableSave={
        loading || !open || !calculation || note === calculation.note
      }
      {...props}
    >
      <div className={styles.engineering_calculations__note_modal}>
        <Textarea
          value={note || ""}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Type Your Note..."
          gray
          rounded
          autosize
        />
        {serverError && <div className={styles.modal_error}>{serverError}</div>}
      </div>
    </Modal>
  );
};

export default EngineeringCalculationNoteModal;
