import * as React from "react";
import classnames from "classnames";
import Modal from "./Modal";

interface DeleteModalProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Modal>, "children"> {
  additionalContent?: React.ReactNode;
}

const DeleteModal = ({
  title,
  additionalContent,
  className,
  ...props
}: DeleteModalProps) => {
  return (
    <Modal
      centered
      includeDeleteButton
      includeSaveButton={false}
      className={classnames(className, "bractlet-modal--delete-modal")}
      {...props}
    >
      <div className="bractlet-modal--body-delete">
        <div>
          {"Are you sure you want to delete "}
          <br />
          <b>{title ? title : "this"}?</b>
          <div className="bractlet-modal--body-subtext">
            {additionalContent}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
