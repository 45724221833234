export type TenantsAndSpacesEntity =
  | "floor"
  | "building"
  | "detachedParking"
  | "surfaceLot"
  | "propertyUse"
  | "space"
  | "effectiveDate";

export const tenantsAndSpacesKeys = {
  all: (siteId: number) => ["tenantsAndSpaces", siteId] as const,
  entity: (siteId: number, entity: TenantsAndSpacesEntity) =>
    [...tenantsAndSpacesKeys.all(siteId), entity] as const,
  query: (siteId: number, entity: TenantsAndSpacesEntity, query: string) =>
    [...tenantsAndSpacesKeys.entity(siteId, entity), query] as const,
};
