import * as React from "react";
import classNames from "classnames";
import ResizeObserver from "react-resize-observer";

import { hexToRGB } from "../global_functions/util";
import type { Option } from "../types";

type Props<OptionIDType> = {
  options: Array<Option<OptionIDType>>;
  onSelect: (
    arg1: Option<OptionIDType>,
    event: React.MouseEvent<HTMLDivElement>
  ) => void;
  selected: OptionIDType | null | undefined | false;
  label?: React.ReactNode;
  labelPosition?: "top" | "right" | "bottom" | "left";
  type?: "switch" | "header" | "pill" | "tab";
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
};

const NavButtonGroup = <OptionIDType extends string | number | boolean>({
  options,
  onSelect,
  selected,
  label,
  labelPosition = "top",
  type = "switch",
  className,
  disabled = false,
  hidden,
}: Props<OptionIDType>) => {
  const selectedIndex = options.findIndex((option) => option.id === selected);
  const [rects, setRects] = React.useState({});

  let selectedOffset = 0;
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '0' can't be used to index type '{}'. | TS7053 - Element implicitly has an 'any' type because expression of type '0' can't be used to index type '{}'.
  let selectedWidth = rects[0] ? rects[0].width : 0;

  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '0' can't be used to index type '{}'. | TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
  if (rects[0] && rects[selectedIndex]) {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'. | TS7053 - Element implicitly has an 'any' type because expression of type '0' can't be used to index type '{}'.
    selectedOffset = rects[selectedIndex].x - rects[0].x;
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
    selectedWidth = rects[selectedIndex].width;
  }

  // @ts-expect-error - TS7031 - Binding element 'rect' implicitly has an 'any' type. | TS7031 - Binding element 'index' implicitly has an 'any' type.
  const updateRects = ({ rect, index }) => {
    setRects((prev) => ({ ...prev, [index]: rect }));
  };

  const [hiddenNextFrame, setHiddenNextFrame] = React.useState<any>(null);
  React.useEffect(() => {
    setHiddenNextFrame(hidden);
  }, [hidden]);

  return (
    <nav
      className={classNames(className, "nav-button-group", {
        disabled: disabled,
      })}
      data-label-position={labelPosition}
    >
      {label && <label className="nav-button-group--label">{label}</label>}
      <div
        className={classNames({
          "nav-button-group--container": true,
          "nav-button-group--has-selected": selectedIndex !== -1,
          "nav-button-group--header-type": type === "header",
          "nav-button-group--switch-type": type === "switch",
          "nav-button-group--pill-type": type === "pill",
          "nav-button-group--tab-type": type === "tab",
        })}
      >
        {/* @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '0' can't be used to index type '{}'. */}
        {selectedIndex !== -1 && type !== "pill" && rects[0] && (
          <div
            className="nav-button-group--selected-background-underlay"
            style={{
              transform: `translateX(${selectedOffset}px)`,
              width: selectedWidth,
              backgroundColor: options[selectedIndex]!.backgroundColor,
              boxShadow:
                options[selectedIndex]!.backgroundColor &&
                `0 10px 20px ${hexToRGB(
                  // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                  options[selectedIndex].backgroundColor,
                  0.2
                )}`,
            }}
          />
        )}
        {options.map((option, index) => (
          <div
            key={option.id.toString()}
            title={option.label}
            className={classNames({
              "nav-button-group--option": true,
              "nav-button-group--option--border-right":
                !["switch", "header"].includes(type) ||
                ![index, index + 1].includes(selectedIndex),
              "nav-button-group--option--selected": index === selectedIndex,
            })}
            onClick={(e) => !disabled && onSelect(option, e)}
          >
            {!hiddenNextFrame && type !== "pill" && (
              <ResizeObserver
                onReflow={(rect) => updateRects({ index, rect })}
              />
            )}
            {option.nodeLabel || option.label}
          </div>
        ))}
      </div>
    </nav>
  );
};

export default NavButtonGroup;
