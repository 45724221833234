import React, { useState, useEffect, ReactNode } from "react";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import classNames from "classnames";

import * as Icons from "../icons";
import Button from "./Button";
import AsyncButton from "./AsyncButton";

const ESCAPE_KEYCODE = 27;

type Props = {
  title?: ReactNode;
  open?: boolean;
  onCancel?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onClose?: () => void;
  onSave?: () => void | Promise<void>;
  onOpen?: () => void;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  centered?: boolean;
  includeCancelButton?: boolean;
  includeSaveButton?: boolean;
  includeTopRightClose?: boolean;
  includeDeleteButton?: boolean;
  warn?: boolean;
  disableCancel?: boolean;
  disableSave?: boolean;
  disableDelete?: boolean;
  backdropCancels?: boolean;
  buttons?: ReactNode;
  keyboard?: boolean;
  children?: ReactNode;
  saveButtonTextOverride?: string | React.ReactElement<any>;
  deleteButtonTextOverride?: string;
  cancelButtonTextOverride?: string;
  errorState?: boolean;
  className?: string;
  minWidth?: number | string;
  closeOnSave?: boolean;
  closeOnDelete?: boolean;
  backpackModal?: boolean;
  ["data-cy"]?: string;
};

type ModalProps = Props & {
  state: "exited" | "entering" | "entered" | "exiting";
  _setOpen: any;
};

const UnwrappedModal = (props: ModalProps) => {
  const {
    title,
    open,
    onCancel,
    onClose,
    onSave,
    onDelete,
    state,
    centered = false,
    keyboard = true,
    includeCancelButton = true,
    includeSaveButton = !!onSave,
    includeDeleteButton = false,
    includeTopRightClose = false,
    warn = false,
    disableCancel = false,
    disableSave = false,
    disableDelete = false,
    backdropCancels = true,
    children,
    saveButtonTextOverride,
    deleteButtonTextOverride,
    cancelButtonTextOverride,
    errorState = false,
    className,
    minWidth,
    backpackModal,
    buttons: buttonsProp,
    _setOpen,
    "data-cy": dataCy,
  } = props;

  const buttons = (
    <div
      className={classNames({
        "bractlet-modal--buttons": true,
        "--centered": centered,
        "--spread": !centered,
        "--backpack": backpackModal,
      })}
    >
      <div
        className={classNames({
          "bractlet-modal--save-buttons": includeSaveButton,
          "bractlet-modal--delete-buttons": includeDeleteButton,
        })}
      >
        {includeCancelButton && (
          <Button
            outline
            size={backpackModal ? "large" : "default"}
            color="gray"
            className={classNames("bractlet-modal--cancel-button", {
              "backpack-modal--cancel-button w-40": backpackModal,
            })}
            onClick={() => {
              _setOpen(false);
              if (onCancel) onCancel();
            }}
            disabled={disableCancel}
          >
            {cancelButtonTextOverride ? cancelButtonTextOverride : "Cancel"}
          </Button>
        )}
        {onSave && includeSaveButton && (
          <AsyncButton
            data-cy="modal-save-button"
            data-testid="modal-save-button"
            color="green"
            size={backpackModal ? "large" : "default"}
            className={classNames({
              "w-36": backpackModal,
            })}
            onClick={async () => {
              await onSave();
              if (props.closeOnSave) _setOpen(false);
            }}
            disabled={disableSave}
          >
            {saveButtonTextOverride ? saveButtonTextOverride : "Save"}
          </AsyncButton>
        )}
        {onDelete && includeDeleteButton && (
          <Button
            data-cy="modal-delete-button"
            data-testid="modal-delete-button"
            className="bractlet-modal--delete-button"
            onClick={(e) => {
              onDelete(e);
              if (props.closeOnDelete) _setOpen(false);
              e.stopPropagation();
            }}
            disabled={disableDelete}
            color={warn ? "yellow" : "red"}
          >
            {deleteButtonTextOverride
              ? deleteButtonTextOverride
              : warn
              ? "Deactivate"
              : "Delete"}
          </Button>
        )}
      </div>
      {buttonsProp !== undefined &&
        typeof buttonsProp !== "boolean" &&
        buttonsProp}
    </div>
  );

  const [hasOpened, setHasOpened] = useState(false);

  useEffect(() => {
    if (keyboard && onCancel && backdropCancels) {
      const listener = (e: any) => {
        if (e.keyCode === ESCAPE_KEYCODE) onCancel();
      };

      window.addEventListener("keyup", listener);

      return () => window.removeEventListener("keyup", listener);
    }
  }, [keyboard, onCancel, backdropCancels]);

  useEffect(() => {
    if (!hasOpened && state !== "exited") {
      setHasOpened(true);
    } else if (onClose && hasOpened && !open && state === "exited") {
      onClose();
      setHasOpened(false);
    }
  }, [onClose, hasOpened, open, state]);

  const { body } = document;

  if (!body || state === "exited") return null;

  return createPortal(
    <div
      className={classNames(className, {
        "bractlet-modal": true,
        "bractlet-modal--open": state === "entered",
      })}
      data-cy={dataCy}
      data-testid="modal"
    >
      <div
        className="bractlet-modal--backdrop"
        onClick={(e) => {
          if (backdropCancels && state === "entered") {
            _setOpen(false);
            onCancel && onCancel(e);
          }
        }}
      />
      <div
        className="bractlet-modal--card"
        style={minWidth ? { minWidth } : undefined}
      >
        <div
          className={classNames({
            "bractlet-modal--header": true,
            "bractlet-modal--header--empty": !title,
            "bractlet-modal--header--delete":
              (includeDeleteButton && !warn && !title) || errorState,
            "bractlet-modal--header--warn": warn,
          })}
        >
          {title}
          {includeTopRightClose && onClose && (
            <Icons.Close
              className="bractlet-modal--header-close-icon"
              onClick={onClose}
            />
          )}
        </div>
        <div className="bractlet-modal--body">{children}</div>
        {(props.buttons ||
          includeCancelButton ||
          includeSaveButton ||
          includeDeleteButton) &&
          // Render buttons component if any buttons are included, otherwise, dont
          buttons}
      </div>
    </div>,
    body
  );
};

const Modal = (
  allProps: Props & {
    as?: any;
  }
) => {
  const { as, ...props } = allProps;
  const [_open, _setOpen] = React.useState(false);
  const open = props.open === undefined ? _open : props.open;
  return (
    <>
      {as &&
        React.cloneElement(as, {
          onClick: (e) => {
            _setOpen(true);
            props.onOpen && props.onOpen();
            e.stopPropagation();
          },
        })}
      <Transition in={open} appear timeout={250}>
        {(state) => (
          // @ts-expect-error - TS2322 - Type '{ _open: boolean; _setOpen: React.Dispatch<React.SetStateAction<boolean>>; }' is not assignable to type '{ _setOpen: any; }'.
          <ModalContext.Provider value={{ _open, _setOpen }}>
            <UnwrappedModal
              {...props}
              // @ts-expect-error - TS2322 - Type 'TransitionStatus' is not assignable to type '"entering" | "entered" | "exiting" | "exited"'.
              state={state}
              open={open}
              _setOpen={_setOpen}
            />
          </ModalContext.Provider>
        )}
      </Transition>
    </>
  );
};

export const ModalContext = React.createContext<{
  _setOpen: any;
  // @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type '{ _setOpen: any; }'.
}>({});

export const ModalCloseButton = (props: any) => {
  const { _setOpen } = React.useContext(ModalContext);
  return (
    <Button outline onClick={() => _setOpen(false)} {...props}>
      Cancel
    </Button>
  );
};

Modal.Close = ModalCloseButton;

export default Modal;
