import classNames from "classnames";

export enum AlertEnum {
  Success = "success",
  Error = "error",
}

interface AlertsProps extends React.HTMLAttributes<HTMLDivElement> {
  message: React.ReactNode;
  status: AlertEnum;
}

const Alerts = ({ message, status, ...props }: AlertsProps) => {
  return (
    <div
      {...props}
      className={classNames(
        "z-10 inline-flex max-w-[260px] items-center rounded-sm bg-white p-3 shadow-md"
      )}
    >
      {message && <span className="ml-2 text-xs">{message}</span>}
    </div>
  );
};

export default Alerts;
