import * as React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import * as Icons from "../icons";
import { Modal, Button, Dropzone } from "../global_components";
import { useFileBrowser } from "../global_functions/hooks";
import Pica from "pica";

const pica = Pica();

const newImageElement = (src: string, width: number, height: number) =>
  new Promise(
    (
      // @ts-expect-error - TS2749 - 'Image' refers to a value, but is being used as a type here. Did you mean 'typeof Image'? | TS2749 - 'Image' refers to a value, but is being used as a type here. Did you mean 'typeof Image'?
      resolve: (result: Promise<Image> | Image) => void,
      reject: (error?: any) => void
    ) => {
      const img = new Image(width, height);
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    }
  );

type CropTypes = {
  aspect: number;
  unit: string;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
};

const initCropProps = { aspect: 1, unit: "px" } as const;

type Props = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSave: (arg1?: Blob | null | undefined) => Promise<undefined> | undefined;
  minSize: number;
  round?: boolean;
};

const ImageUploadModal = ({
  open,
  onClose,
  onCancel,
  onSave,
  minSize,
  round = false,
}: Props) => {
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [errorText, setErrorText] = React.useState<{
    description: string;
    reason: string;
    action: string;
  }>();
  const [newImageURL, setNewImageURL] = React.useState<any>();
  const [newImage, setNewImage] = React.useState<any>();
  const [finalImageBlob, setFinalImageBlob] = React.useState<any>();
  const [cropComponenetProps, setCropComponentProps] = React.useState({});
  const [crop, setCrop] = React.useState(initCropProps as CropTypes);

  React.useEffect(
    () => () => window.URL.revokeObjectURL(newImageURL),
    [newImageURL]
  );

  const handleFileSelection = React.useCallback(
    async (file: File) => {
      const imgURL = URL.createObjectURL(file);
      const img = await newImageElement(imgURL, minSize, minSize);
      if (img.naturalHeight >= minSize && img.naturalWidth >= minSize) {
        setNewImageURL(imgURL);
        setCrop(initCropProps);
        setNewImage(null);
        setFinalImageBlob(null);
      } else {
        setErrorText({
          description: "Selected image is too small",
          reason: `Minimum size required is ${minSize} x ${minSize} px`,
          action: "Please select a new image",
        });
        setOpenWarningModal(true);
      }
    },
    [minSize]
  );

  const [openFileBrowser] = useFileBrowser(
    "image/*",
    React.useCallback(
      (files) => handleFileSelection(files[0]!),
      [handleFileSelection]
    )
  );

  const createCroppedImage = async () => {
    const { width, height, x, y } = crop;
    // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'. | TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
    if (newImage && newImageURL && width && height && !isNaN(x) && !isNaN(y)) {
      const scaleX = newImage.naturalWidth / newImage.width;
      const scaleY = newImage.naturalHeight / newImage.height;

      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = width * scaleX;
      croppedCanvas.height = height * scaleY;

      const ctx = croppedCanvas.getContext("2d");

      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      ctx.drawImage(
        newImage,
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        x * scaleX,
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        y * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        width * scaleX,
        height * scaleY
      );

      let resizedCanvas;
      if (
        croppedCanvas.width > minSize * 2 ||
        croppedCanvas.height > minSize * 2
      ) {
        const resizedCanvasTemplate = document.createElement("canvas");
        resizedCanvasTemplate.width = minSize * 2; // double desired width for retina displays
        resizedCanvasTemplate.height = minSize * 2; // double desired width for retina displays

        // Intent is to not store images larger than what is needed to display on Building Dashboard page
        // I was having quality loss issues when down-sampling large images purely through canvas
        // and brought in the library below to improve image quality after resizing
        // stackoverflow.com/questions/17861447/html5-canvas-drawimage-how-to-apply-antialiasing
        resizedCanvas = await pica.resize(croppedCanvas, resizedCanvasTemplate);
      }

      const finalCanvas = resizedCanvas ? resizedCanvas : croppedCanvas;
      const blob = await pica.toBlob(finalCanvas, "image/jpeg");
      setFinalImageBlob(blob);
    }
  };

  return (
    <React.Fragment>
      {newImageURL ? ( // If there is an image, open Crop modal, else open image select modal
        <Modal
          open={open}
          title="Make Crop Selection For Image Upload"
          onCancel={onCancel}
          onClose={() => {
            setCrop(initCropProps);
            window.URL.revokeObjectURL(newImageURL);
            setNewImageURL(null);
            setNewImage(null);
            setFinalImageBlob(null);
            onClose();
          }}
          onSave={() => onSave(finalImageBlob)}
          disableSave={!Boolean(finalImageBlob)}
          includeCancelButton
          includeSaveButton
          buttons={
            <Button color="gray" onClick={openFileBrowser}>
              Select New Image
            </Button>
          }
          data-cy="react-crop-modal"
          data-testid="react-crop-modal"
        >
          <div className="image-upload-crop-modal-body">
            <ReactCrop
              src={newImageURL}
              // @ts-expect-error - TS2769 - No overload matches this call.
              crop={crop}
              // @ts-expect-error - TS2345 - Argument of type 'Crop' is not assignable to parameter of type 'SetStateAction<CropTypes>'.
              onChange={(e) => setCrop(e)}
              onComplete={createCroppedImage}
              imageStyle={{ maxWidth: "500px", maxHeight: "450px" }}
              circularCrop={round}
              onImageLoaded={(img) => {
                const scaleX = img.naturalWidth / img.width;
                const scaleY = img.naturalHeight / img.height;
                setNewImage(img);
                setCropComponentProps({
                  minWidth: Math.floor(minSize / scaleX),
                  minHeight: Math.floor(minSize / scaleY),
                });

                setCrop({
                  aspect: 1,
                  unit: "px",
                  width: Math.floor(minSize / scaleX) + 1,
                  height: Math.floor(minSize / scaleX) + 1,
                  x: Math.floor((img.width - minSize / scaleX) / 2),
                  y: Math.floor((img.height - minSize / scaleY) / 2),
                } as CropTypes);
                return false; // docs specify to return false if crop is modified
              }}
              {...cropComponenetProps}
            />
          </div>
        </Modal>
      ) : (
        <Modal
          open={open}
          title="Upload Photo"
          includeCancelButton={false}
          includeSaveButton={false}
          onClose={onClose}
          onCancel={onCancel}
          data-cy="upload-photo-modal"
          data-testid="upload-photo-modal"
        >
          <div className="image-upload-select-modal-body">
            <Dropzone
              className="image-upload-select-modal-dropzone"
              acceptedTypes={{ "image/*": [".png", ".gif", "jpeg", "jpg"] }}
              text={
                <div>
                  <Icons.Upload />
                  Upload or Drag and Drop your Photo
                </div>
              }
              onDrop={([file], [rejected]) => {
                if (file) {
                  handleFileSelection(file);
                }
                if (rejected) {
                  setErrorText({
                    description: "Invalid file type",
                    reason: rejected.errors
                      .map(({ message }) => message)
                      .join(", "),
                    action: "Please select an image",
                  });
                  setOpenWarningModal(true);
                }
              }}
            />
          </div>
        </Modal>
      )}

      <Modal
        open={openWarningModal}
        onCancel={() => setOpenWarningModal(false)}
        onDelete={() => {
          openFileBrowser();
          setOpenWarningModal(false);
        }}
        centered
        includeDeleteButton
        warn
        deleteButtonTextOverride="Select New Image"
        includeSaveButton={false}
      >
        <div className="image-upload-warning-modal-body">
          <p>
            {errorText?.description}
            <br />
            {errorText?.reason}
            <br />
            {errorText?.action}
          </p>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ImageUploadModal;
