import classNames from "classnames";
import { camelCase, startCase, upperFirst } from "lodash";
import * as React from "react";
import * as R from "ramda";
import {
  OnboardingGuideSchemaUnionType,
  OnboardingStatusEnum,
  OnboardingStepType,
  OnboardingSubStep,
} from "./completenessSchema";
import { isOptedOutStepOmittedFromCount } from "./utils";
import Icons, { BractletIconNames } from "../../../icons";

import styles from "./CompletenessSteps.module.scss";
import SiteVisitSteps from "./SiteVisitSteps";
import { useOnboardingGuideContext } from ".";
import IntervalDataSteps from "./IntervalDataSteps";
import OnboardingGuideStep from "./OnboardingStep";
import CollapsibleContainer from "@src/straps/utils/CollapsibleContainer/CollapsibleContainer";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";
import Icon from "@src/straps/base/icons/Icon/Icon";

const iconOverides: Partial<
  Record<
    keyof OnboardingGuideSchemaUnionType["completeness"],
    BractletIconNames
  >
> = {
  submissions: "Certifications",
  projects: "BackpackProjects",
} as const;

export type GroupType = {
  [part: string]: OnboardingStepType<OnboardingSubStep>;
};

export default function OnboardingGuideCompletenessSteps(
  props: Readonly<{
    steps: {
      [key: string]: GroupType;
    };
    page: "completeness" | "onSite";
  }>
) {
  const { setActiveStep, activeTab, setActiveTab } =
    useOnboardingGuideContext();

  const partsPerStep = React.useMemo(
    () =>
      R.mapObjIndexed((value: GroupType) => {
        const subSteps = Object.values(value)
          .map((step) => {
            if (step.stepType === "aggregateStep") {
              return Object.values(step?.subSteps);
            }
            return [];
          })
          .flat();

        const steps = Object.values(value);

        const totalCountable = [...steps, ...subSteps].filter(
          (part) =>
            part.countable &&
            !isOptedOutStepOmittedFromCount(part) &&
            part.status !== OnboardingStatusEnum.N_A
        );

        const totalCompleted = totalCountable.filter((step) => step.complete);

        return {
          totalSteps: totalCountable.length,
          completedSteps: totalCompleted.length,
        };
      }, props.steps),
    [props.steps]
  );

  const { APIConnected, installPulseMeter } =
    useOnboardingGuideContext().state.onSite.intervalData;

  const showIntervalDataError =
    APIConnected.status === OnboardingStatusEnum.Done_NA &&
    installPulseMeter.status === OnboardingStatusEnum.Done_NA;

  const isGatewayOmitted = (step: string) => {
    return (
      step === "gatewayInstall" &&
      props.steps?.["gatewayInstall"]?.BASIntegration?.status === 12
    );
  };

  return (
    <>
      {Object.keys(props.steps).map((_step, i) => {
        const group: GroupType = props.steps[_step]!;

        return (
          <div
            key={_step}
            className={classNames("relative", {
              "z-10 bg-white": _step === activeTab,
            })}
          >
            <div
              className={classNames(
                "t-0 absolute left-0 h-full w-1 bg-straps-accent-1 transition-all",
                {
                  "opacity-0": _step !== activeTab,
                }
              )}
            />

            <ClickableDiv
              onClick={() => setActiveTab(_step === activeTab ? null : _step)}
              data-cy="onboarding-group"
              data-testid={`onboarding-group-${_step}`}
              className={classNames(
                styles.step,
                "group cursor-pointer border-t border-straps-tertiary/20 px-5 py-2 pb-3 transition hover:bg-white",
                {
                  "bg-straps-body": _step !== activeTab,
                }
              )}
            >
              {_step !== activeTab && (
                <Icon
                  name="caret-down"
                  size="small"
                  color="accent-1"
                  className="absolute bottom-0 left-1/2 h-2 w-2 -translate-x-1/2 scale-75 transform text-straps-accent-1 opacity-0 transition-opacity group-hover:opacity-100"
                />
              )}
              <div className="my-2 flex">
                <Icons
                  icon={
                    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly ratings: "Certifications"; readonly projects: "BackpackProjects"; }'.
                    iconOverides[_step] ||
                    upperFirst(camelCase(_step)) ||
                    "BractletSymbol"
                  }
                  className={classNames("h-5 w-5", {
                    "text-straps-secondary": _step !== activeTab,
                    "text-straps-primary": _step === activeTab,
                  })}
                />
                <span
                  className={classNames("ml-2 mr-auto text-sm font-semibold", {
                    "text-gray300":
                      _step === "intervalData" && showIntervalDataError,
                  })}
                >
                  {startCase(_step)}
                </span>
                {_step === "intervalData" && showIntervalDataError ? (
                  <Icons icon="Ban" className="color--red h-4 w-4" />
                ) : (
                  <span>
                    {isGatewayOmitted(_step) ? (
                      <>-</>
                    ) : (
                      <>
                        {" "}
                        <strong>{partsPerStep[_step]!.completedSteps}</strong>/
                        {partsPerStep[_step]!.totalSteps}
                      </>
                    )}
                  </span>
                )}
              </div>
              <div
                className={classNames("flex w-full bg-gray150", {
                  "h-1": partsPerStep[_step]!.totalSteps !== 0,
                  "h-0": partsPerStep[_step]!.totalSteps === 0,
                })}
              >
                <div
                  className={"h-full bg-straps-accent-1/30"}
                  style={{
                    width: `${
                      (partsPerStep[_step]!.completedSteps * 100) /
                      partsPerStep[_step]!.totalSteps
                    }%`,
                  }}
                />
                <div className={"h-full w-0.5 bg-straps-accent-1"} />
              </div>
            </ClickableDiv>

            <CollapsibleContainer open={_step === activeTab}>
              {isGatewayOmitted(_step) ? (
                <span className={classNames("px-5 py-2 text-xs italic")}>
                  Gateway Install Omitted{" "}
                </span>
              ) : (
                <>
                  {(_step === "backpackSiteVisit" && <SiteVisitSteps />) ||
                    (_step === "intervalData" && <IntervalDataSteps />) ||
                    Object.entries(group).map(([key, step]) => (
                      <React.Fragment key={step.pathId}>
                        <OnboardingGuideStep
                          key={step.pathId}
                          step={group[key]!}
                          text={startCase(key)}
                          onClick={() => {
                            let pathId = step.pathId;
                            // calculate different pathId for aggregateStep
                            if (step.stepType === "aggregateStep") {
                              const subSteps = step.subSteps;

                              const firstOpenSubStep = Object.keys(
                                subSteps
                              ).find(
                                (k) =>
                                  OnboardingStatusEnum.Open ===
                                  subSteps[k]!.status
                              );

                              if (firstOpenSubStep) {
                                pathId = subSteps[firstOpenSubStep]!.pathId;
                              }
                            }

                            const showTooltip =
                              !step.notClickableInMenuWhenComplete ||
                              (step.notClickableInMenuWhenComplete &&
                                step.status === OnboardingStatusEnum.Open);

                            return !step.readOnly && showTooltip
                              ? setActiveStep(pathId, {
                                  page: props.page,
                                })
                              : null;
                          }}
                        />
                        {step.stepType === "aggregateStep" &&
                          step.listSubSteps &&
                          Object.keys(step.subSteps).map((subStepKey) => (
                            <OnboardingGuideStep
                              key={subStepKey}
                              step={step.subSteps[subStepKey]!}
                              text={startCase(subStepKey)}
                              onClick={() => {
                                // All existing "listed sub steps" are also read only, but we may need to implement this handler in future if that is no longer the case.
                                return;
                              }}
                            />
                          ))}
                      </React.Fragment>
                    ))}
                </>
              )}
            </CollapsibleContainer>

            <div className="h-px bg-border" />
          </div>
        );
      })}
    </>
  );
}
