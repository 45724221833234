import { useQuery as useReactQuery } from "react-query";
import FetchClients from "../client";
const account = {
  useQuery,
  mutations: {},
} as const;

export default account;

const GLOBAL_INDUSTRIAL_ACCOUNT_KEY = "global-industrial-account";
function useQuery(userId?: number) {
  return useReactQuery([GLOBAL_INDUSTRIAL_ACCOUNT_KEY, userId], async () => {
    const { data, error } = await FetchClients.marketplace.GET(
      "/global_industrial/account_info",
      {}
    );
    if (data) return data;
    throw new Error(error);
  });
}
