import Cookies from "js-cookie";
import {
  camelCase,
  forEach,
  isArray,
  isObject,
  isPlainObject,
  isString,
  snakeCase,
  startCase,
} from "lodash";
import * as R from "ramda";
import * as React from "react";

import { bugsnagClient } from "../../bugsnag";
import {
  inboundUnitCipher,
  outboundUnitEncoder,
} from "../../context/UnitsContext";
import { isDev, isProd, LOGIN_URL } from "../../env";
import request from "../request";
import { arraysAreEqual } from "../util";

import type { UnitConversions, UtilityUnit } from "../../types";
export const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
] as const;

export const monthsFullName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

export const convertISODateString = (date: string): string => {
  let dt = new Date(date);
  // @ts-expect-error - TS2345 - Argument of type 'Date' is not assignable to parameter of type 'number'.
  if (isNaN(dt)) return date;
  // prettier-ignore
  return `${startCase(months[dt.getMonth()])} ${dt.getDate()}, ${dt.getFullYear()}`;
};

export const convertNumericDate = (date: string): string => {
  if (!date.includes("-")) return date;
  const yearMonthArr = date.split("-");
  return (
    monthsFullName[parseInt(yearMonthArr[1]!) - 1] + ", " + yearMonthArr[0]!
  );
};

export const camelCaseCipher = <T>(
  key: string,
  value: T,
  options?: any
): {
  [key: string]: T;
} => ({
  [camelCase(key)]: value,
});

const inboundUnitConversionCipher = {
  initial_unit_id: "initialUnitId",
  target_unit_id: "targetUnitId",
  initial_unit_name: {
    name: "initialUnitName",
    abbreviation: "initialUnitAbbreviation",
    label: "initialUnitLabel",
  },
  target_unit_name: {
    name: "targetUnitName",
    abbreviation: "targetUnitAbbreviation",
    label: "targetUnitLabel",
  },
  multiplication_factor: "multiplicationFactor",
} as const;

export const snakeCaseCipher = <T>(
  key: string,
  value: T
): {
  [key: string]: T;
} => ({
  [snakeCase(key)]: value,
});

export const outboundUnitIdEncoder = (
  unit: UtilityUnit
): Promise<number | null | undefined> =>
  request
    .get("/rest/units")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ name: `eq.${outboundUnitEncoder[unit] || unit}` })
    .then(({ body }) => (body.length ? body[0].id : null))
    .catch(bugsnagPostgrestErrorHandler);

export const getUnitsMap = (): Promise<
  Partial<
    Record<
      number | string,
      {
        id: number;
        key: string;
        label: UtilityUnit;
      }
    >
  >
> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | { [index: string]: unknown; }>' is not assignable to type 'Promise<Partial<Record<string | number, { id: number; key: string; label: UtilityUnit; }>>>'.
  request
    .get("/rest/units")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) =>
      R.fromPairs(
        // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
        body.map((row) => [
          row.id,
          { id: row.id, key: row.name, label: inboundUnitCipher[row.name] },
        ])
      )
    )
    .catch(bugsnagPostgrestErrorHandler);

export const getTimezones = (): Promise<string[]> =>
  request
    .get(`/rest/time_zones`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
    .then(({ body }) => body.map((row) => row.rails));

type Options = {
  allowNulls?: boolean;
  allowUnknownKeys?: boolean;
};

// TODO: add better flow typing
export const formatDataObj = (
  // @ts-expect-error - TS7006 - Parameter 'inputObj' implicitly has an 'any' type.
  inputObj,
  cipher: any,
  options: Options = {}
): any => {
  // This function will flatten objects nested due to foreign key constraints
  if (!cipher) return {};
  const { allowNulls = false, allowUnknownKeys = false } = options;

  let formattedOutput: Record<string, any> = {};
  for (let [key, value] of Object.entries(inputObj)) {
    if (typeof cipher === "function") {
      const result = cipher(key, value, inputObj);
      if (result) Object.assign(formattedOutput, result);
    } else if (typeof cipher[key] === "function") {
      const result = cipher[key](key, value, inputObj);
      if (result) Object.assign(formattedOutput, result);
    } else if (
      value != null &&
      typeof value === "object" &&
      !Array.isArray(value)
    ) {
      Object.assign(
        formattedOutput,
        formatDataObj(value, cipher[key], options)
      );
    } else if (allowNulls || value != null) {
      let formattedKey = cipher[key];

      if (!formattedKey && allowUnknownKeys) formattedKey = key;

      if (formattedKey) {
        formattedOutput[formattedKey] = value;
      }
    }
  }
  return formattedOutput;
};

// @ts-expect-error - TS7006 - Parameter 'inputArr' implicitly has an 'any' type.
export const formatDataArray: any = (inputArr, cipher: any, options: Options) =>
  // @ts-expect-error - TS7006 - Parameter 'curr' implicitly has an 'any' type.
  inputArr.map((curr) => formatDataObj(curr, cipher, options));

// NOTE - this function specifically converts utility VALUES to camelCase as well as keys. The reverse will NOT be true by default when converting back to snake_case.
export const recursiveCamelCaseCipher = (
  inboundObject: Array<Record<any, any>> | Record<any, any>
) => {
  if (inboundObject == null) return null;
  const camelCaseObject: any = isArray(inboundObject) ? [] : {};
  forEach(inboundObject, function (value, key) {
    let newValue;
    if (isPlainObject(value)) {
      // checks that a value is a plain object - for recursive key conversion
      newValue = recursiveCamelCaseCipher(value); // recursively update keys of any values that are also objects
    } else if (isArray(value)) {
      newValue = value.map((val) =>
        isObject(val) ? recursiveCamelCaseCipher(val) : val
      ); // recursively update keys of any values that are also objects
    } else if (
      isString(value) &&
      (key === "utility" || key === "utility_type")
    ) {
      newValue = camelCase(value);
    } else {
      newValue = value;
    }

    let newKey = key;
    const keyWithoutNumbers = key.toString().replace(/[0-9]/g, ""); // remove numbers b.c snakeCase will add _ between letters and numbers (i.e. hs3)
    const snakeCaseKey = snakeCase(keyWithoutNumbers);
    if (
      snakeCaseKey === keyWithoutNumbers ||
      `${snakeCaseKey}_` === keyWithoutNumbers // needed so keys like emissions_scope_3 will get camelCase'd
    ) {
      newKey = camelCase(key); // only change snakeCase keys
    }
    camelCaseObject[newKey] = newValue;
  });
  return camelCaseObject;
};

export const recursiveSnakeCaseCipher = (
  inboundObject: Array<any> | Record<any, any>,
  options?:
    | {
        convertUtilityValues?: boolean;
      }
    | number // allow number for usage inline inside .maps etc
) => {
  if (inboundObject == null) return null;
  const snakeCaseObject: any = isArray(inboundObject) ? [] : {};
  forEach(inboundObject, function (value, key) {
    let newValue;
    if (isPlainObject(value)) {
      // checks that a value is a plain object - for recursive key conversion
      newValue = recursiveSnakeCaseCipher(value); // recursively update keys of any values that are also objects
    } else if (isArray(value)) {
      newValue = value.map((val) =>
        isObject(val) ? recursiveSnakeCaseCipher(val) : val
      ); // recursively update keys of any values that are also objects
    } else {
      newValue = value;
    }

    let newKey = key;
    const keyWithoutNumbers = key.toString().replace(/[0-9]/g, ""); // remove numbers b.c camelCase will capitalize letters after a number
    if (camelCase(keyWithoutNumbers) === keyWithoutNumbers)
      newKey = snakeCase(key); // only change camelCase keys

    if (
      typeof options === "object" &&
      options?.convertUtilityValues &&
      isString(value) &&
      (key === "utility" || key === "utilityType")
    ) {
      snakeCaseObject[newKey] = snakeCase(value);
    } else {
      snakeCaseObject[newKey] = newValue;
    }
  });
  return snakeCaseObject;
};

type UseAPIOptions = {
  preserveData?: boolean;
  returnPromise?: boolean;
  onError?: (arg1?: string | null | undefined) => void;
};

type UseAPIReturnValues<DataType> = {
  loading: boolean;
  data: undefined | DataType;
  error: string | null | undefined;
  triggerRefresh: (
    arg1?: UseAPIOptions | null | undefined
  ) => Promise<undefined> | (() => void);
  setData: (
    arg1:
      | undefined
      | DataType
      | ((arg1: undefined | DataType) => undefined | DataType)
  ) => void;
};

export const useAPI = <DataType>(
  apiFunction?: ((...rest: Array<any>) => Promise<DataType>) | null,
  nextParams: Array<any> = [],
  apiOptions: UseAPIOptions = {}
): UseAPIReturnValues<DataType> => {
  const [loading, setLoading] = React.useState(true);
  const [params, setParams] = React.useState(nextParams);
  const [error, setError] = React.useState<any>(undefined);
  const [data, setData] = React.useState<undefined | DataType>(undefined);

  const optionsRef = React.useRef<UseAPIOptions>(apiOptions);
  optionsRef.current = apiOptions;

  React.useEffect(() => {
    if (!arraysAreEqual(params, nextParams)) {
      setParams(nextParams);
    }
  }, [params, nextParams]);

  const triggerRefresh = React.useCallback(
    (refreshOptions = {}) => {
      let didCancel = false;
      const options = { ...optionsRef.current, ...refreshOptions } as const;
      const { preserveData, returnPromise = true } = options || {};
      const fetchData = async () => {
        setLoading(true);

        if (!preserveData) setData(undefined);

        let nextData;
        let errorMessage;

        try {
          if (apiFunction) {
            nextData = await apiFunction(...params);
          }
        } catch (err: any) {
          console.error(err); // eslint-disable-line
          errorMessage = err.message;
        }

        if (!didCancel) {
          setData(nextData);
          setError(errorMessage);
          setLoading(false);
          if (errorMessage && options.onError) {
            options.onError(errorMessage);
          }
        }
      };

      const promise = fetchData();
      if (returnPromise) return promise;

      return () => {
        didCancel = true;
      };
    },
    [apiFunction, params]
  );

  React.useEffect(
    // @ts-expect-error - TS2322 - Type 'Promise<void> | (() => void)' is not assignable to type 'void | Destructor'.
    () => triggerRefresh({ returnPromise: false }),
    [triggerRefresh]
  );

  // @ts-expect-error - TS2322 - Type '(refreshOptions?: any) => Promise<void> | (() => void)' is not assignable to type '(arg1?: UseAPIOptions | null | undefined) => Promise<undefined> | (() => void)'.
  return { loading, data, error, triggerRefresh, setData };
};

export const redirectOnError = (e: any) => {
  if (
    e?.status === 401 &&
    e?.response?.body?.message === "JWSError JWSInvalidSignature"
  ) {
    // https://github.com/microsoft/TypeScript/issues/48949
    const win: Window = window;
    win.location = `${LOGIN_URL || ""}/logout`;
    return true;
  }
};

export const bugsnagPostgrestErrorHandler = async (e: any): Promise<void> => {
  if (redirectOnError(e)) return;

  if (isDev) {
    console.log(e); // eslint-disable-line no-console
  } else if (isProd) {
    const metaData = e?.response?.req
      ? {
          type: "Postgrest Request Failed",
          method: e.response.req.method,
          url: e.response.req.url,
          message: e.response.body?.message,
          req_data: JSON.stringify(e.response.req._data),
        }
      : {
          type: "Postgrest Request Processing Failed",
        };
    bugsnagClient?.leaveBreadcrumb("Postgrest Request Failed", metaData);
    bugsnagClient?.notify(e, (ev) => {
      ev.severity = "warning";
      ev.addMetadata("error", metaData);
    });
  }
};

export const bugsnagPortfolioManagerErrorHandler = (e: any) => {
  if (isDev) {
    console.log(e); // eslint-disable-line
  } else if (isProd) {
    const metaData = e?.response?.req
      ? {
          type: "Portfolio Manager Request Failed",
          method: e.response.req.method,
          url: e.response.req.url,
          message: e.response.text,
          req_data: JSON.stringify(e.response.req._data),
        }
      : {
          type: "Portfolio Manager Request Processing Failed",
        };
    bugsnagClient?.leaveBreadcrumb(
      "Portfolio Manager Request Failed",
      metaData
    );
    bugsnagClient?.notify(e, (ev) => {
      ev.severity = "warning";
      ev.addMetadata("error", metaData);
    });
  }
};

export const bugsnagGresbErrorHandler = async (e: any): Promise<void> => {
  if (isDev) {
    console.log(e); // eslint-disable-line
  } else if (isProd) {
    const metaData = e?.response?.req
      ? {
          type: "GRESB API Request Failed",
          method: e.response.req.method,
          url: e.response.req.url,
          message: e.response.text,
          req_data: JSON.stringify(e.response.req._data),
        }
      : {
          type: "GRESB API Request Processing Failed",
        };
    bugsnagClient?.leaveBreadcrumb("GRESB API Request Failed", metaData);
    bugsnagClient?.notify(e, (ev) => {
      ev.severity = "warning";
      ev.addMetadata("error", metaData);
    });
  }
};

export const bugsnagGeneralErrorHandler = (e: any) => {
  if (isDev) {
    console.log(e); // eslint-disable-line no-console
  } else if (isProd) {
    bugsnagClient?.notify(e);
  }
};

export const getUnitConversions = (): Promise<UnitConversions> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | object>' is not assignable to type 'Promise<Partial<Record<string | number, Partial<Record<string | number, number>>>>>'.
  request
    .get(`/rest/unit_conversions`)
    .query({
      select:
        "*,initial_unit_name:units!unit_conversions_initial_unit_id_fkey(*),target_unit_name:units!unit_conversions_target_unit_id_fkey(*)",
    })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => {
      const formattedBody = formatDataArray(body, inboundUnitConversionCipher);
      return R.mergeAll(
        // @ts-expect-error - TS2345 - Argument of type 'unknown[]' is not assignable to parameter of type 'readonly object[]'.
        [
          "initialUnitId",
          "initialUnitName",
          "initialUnitLabel",
          "initialUnitAbbreviation",
        ].map((initialPropName) =>
          R.pipe(
            R.filter(R.pipe(R.prop(initialPropName), R.isNil, R.not)),
            // @ts-expect-error - TS2345 - Argument of type '(list: readonly unknown[]) => Record<string, unknown[]>' is not assignable to parameter of type '(a: readonly unknown[]) => readonly unknown[][]'.
            R.groupBy(R.prop(initialPropName)),
            R.map((arr) =>
              R.fromPairs(
                R.unnest(
                  [
                    "targetUnitName",
                    "targetUnitId",
                    "targetUnitAbbreviation",
                    "targetUnitLabel",
                  ].map((targetProp) =>
                    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                    arr
                      // @ts-expect-error - TS7006 - Parameter 'pair' implicitly has an 'any' type.
                      .filter((pair) => R.prop(targetProp, pair) != null)
                      // @ts-expect-error - TS7006 - Parameter 'pair' implicitly has an 'any' type.
                      .map((pair) => [
                        R.prop(targetProp, pair),

                        pair.multiplicationFactor,
                      ])
                  )
                )
              )
            )
          )(formattedBody)
        )
      );
    })
    .catch(bugsnagPostgrestErrorHandler);

export const raise = (errMessage: string): never => {
  throw new Error(errMessage);
};
