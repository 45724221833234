import * as React from "react";
import { BractletForm, Input, Modal } from "../global_components";
// // POST etc needed for login so allow import for this file

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import * as superagent from "superagent";
import Cookies from "js-cookie";

import {
  IdentityContextProvider,
  useIdentityContext,
} from "react-netlify-identity";

import { VITE_SITE } from "../env";
import { Text } from "@src/straps/base";
import AsyncButton from "@src/straps/derived/AsyncButton/AsyncButton";

const urlMap: Partial<Record<typeof VITE_SITE, string>> = {
  console: "https://backpack-console.netlify.app",
  ip: "https://bractlet-ip.netlify.app",
  valinor: "https://bractlet-valinor.netlify.app",
};

const withIdentifyWrapper =
  (
    Component: () => React.ReactElement<
      React.ComponentProps<(allProps: { as?: any }) => any>
    >
  ) =>
  () => {
    const url = urlMap[VITE_SITE];
    if (!url) return null;
    return (
      <IdentityContextProvider url={url}>
        <Component />
      </IdentityContextProvider>
    );
  };

export default withIdentifyWrapper(function StagingLoginModal() {
  // const onSubmit = React.useCallback(async form => {
  //   // Endpoint not implemented yet
  //   const response = await request
  //     .post(`${LOGIN_URL}/api/login`)
  //     .send(form);

  //   response.body.jwt && Cookies.set("jwt", response.body.jwt);
  //   window.location.reload();
  // }, []);

  const user = useIdentityContext();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = async () => {
    const newUser = await user.loginUser(email, password, true);
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    if (!newUser.jwt) return console.error("Couldnt log in: ", newUser); // eslint-disable-line no-console

    const res = await superagent
      .get("/.netlify/functions/staging-login")
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      .set("Authorization", `Bearer ${newUser.token.access_token}`);

    const newJwt = res.body.jwt;

    // eslint-disable-next-line no-console
    if (!newJwt) return console.error("Couldnt get new jwt: ", res.body);

    Cookies.set("jwt", newJwt);
    window.location.reload();
  };

  return (
    <Modal open includeCancelButton={false}>
      <div className="mx-7 my-4 flex flex-col gap-3">
        <Text variant="h2_t-30-700">Staging Environment Login</Text>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          rounded
          gray
          name="email"
          label="Email"
        />
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          rounded
          gray
          type="password"
          name="password"
          label="Password"
        />
        <div className="flex justify-end">
          <AsyncButton
            className="mr-4"
            onClick={async () => {
              await user.signupUser(email, password, {}, true);
            }}
          >
            Register
          </AsyncButton>
          <AsyncButton onClick={login}>Login</AsyncButton>
        </div>
        <BractletForm
          onSubmit={(form: any) => {
            Cookies.set("jwt", form.jwt);
            window.location.reload();
          }}
        >
          <Text variant="h4_t-20-700">
            (Alternative) manually add JWT to localstorage
          </Text>
          <BractletForm.Input
            rounded
            gray
            name="jwt"
            label="Insert JWT as cookie"
          />
          <div className="flex justify-end">
            <BractletForm.Submit>Add JWT</BractletForm.Submit>
          </div>
        </BractletForm>
      </div>
    </Modal>
  );
});
