import { Flow } from "flow-to-typescript-codemod";
import Cookies from "js-cookie";
import { camelCase, kebabCase, snakeCase } from "lodash";
import moment from "moment";
import * as R from "ramda";
import request from "../request";

import type {
  BillToParse,
  BuildingUtilityWithMeters,
  ChildSiteData,
  DataStreamTypes,
  DateString,
  DateTime,
  EnergyUnit,
  EnergyUtility,
  Equipment,
  ESGBillToParse,
  HyperSpaceJob,
  ID,
  MonthString,
  NestedUtilityDeviationAlerts,
  ParsedDateUtilityData,
  ParsedEnergyBills,
  ParsedESGBill,
  RawSimulatedUtilityBillBase,
  RawSummarizedSimulatedUtilityBill,
  RawSummarizedSimulatedUtilityBills,
  RawUtilityBillParsedDate,
  SimulatedUtilityParsingJob,
  System,
  Utility,
  UtilityBill,
  UtilityBillBase,
  UtilityBillManualUpload,
  UtilityBillParsedDates,
  UtilityBillRateStructure,
  UtilityCalibrationAccuracy,
  UtilityCalibrationAccuracyWithDates,
  UtilityDeviationAlertCondition,
  UtilityDeviationAlertTypes,
  UtilityMeterBase,
  UtilityMetersMonthlySpend,
  UtilityUnit,
  UtilityUsageByMonthOfYear,
  YearString,
  IntegrationStatusType,
  DataStreamSummary,
} from "../../types";

import { fileTypes } from "../util";

import { inboundUnitCipher } from "../../context/UnitsContext";
import { cleanUtilityBills, energyUtilities } from "../utilities";

import {
  calcBlendedRate,
  isValidRow,
  looselyEqual,
} from "../../site__dashboard/UtilitiesPage/utilityBillUtils";
import {
  bugsnagGeneralErrorHandler,
  bugsnagPostgrestErrorHandler,
  camelCaseCipher,
  formatDataArray,
  formatDataObj,
  outboundUnitIdEncoder,
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
  snakeCaseCipher,
} from "./common";
import {
  getConfidenceFactors,
  getEngineeringCalculationsInKWh,
} from "./projectsApi";

import { getChangeKey } from "../../site__data_mapping/MappingResources/utils";
import { useQuery } from "react-query";
import { VITE_UPLOAD_BASE_URL } from "../../env";

const inboundUtilityBillCipher = {
  id: "id",
  site_id: "siteId",
  unit_id: "unitId",
  unit: "unit",
  account_id: "accountId",
  demand_unit: "demandUnit",
  filename: "filename",
  file_type: "fileType",
  uploaded_at: "uploadedAt",
  utility: "utility",
  meter_id: "meterId",
  service_period_start: "startDate",
  service_period_end: "endDate",
  peak_time: "peakOccurred",
  power_factor: "powerFactor",
  fixed_cost: "fixedCost",
  taxes: "taxes",
  total: "totalSpend",
  valid: "isValid",
  parsed_date_id: "parsedDateId",
  month: "month",
  start_date: "parsedStartDate",
  end_date: "parsedEndDate",
  manual_parsed_date: "manualParsedMonth",
  total_consumption: "totalConsumption",
  total_demand: "totalDemand",
  num_of_consumption_tiers: "numOfConsumptionTiers",
  num_of_demand_tiers: "numOfDemandTiers",
  energy_star_id: "energyStarId",
} as const;

const inboundRateStructureCipher = {
  id: "id",
  utility_bill_id: "utilityBillId",
  rate_type: "type",
  tier: "tier",
  billed_quantity: "billedQuantity",
  meter_demand: "meterDemand",
  threshold: "threshold",
  total: "total",
} as const;

const inboundSimulatedUtilityBillJobCipher = {
  id: "id",
  // @ts-expect-error - TS7006 - Parameter 'k' implicitly has an 'any' type. | TS7006 - Parameter 'v' implicitly has an 'any' type.
  created_at: (k, v) => ({ createdAt: moment(v) }),
  site_id: "siteId",
  version_id: "versionId",
  hs3_job_id: "hs3JobId",
  parsed_date_ids: "parsedDateIds",
  status: "status",
  errors: "errors",
  update_existing_bills: "updateExistingBills",
} as const;

const inboundVersionUtilityCalibrationAccuracyCipher = {
  site_id: "siteId",
  version_id: "versionId",
  utility: "utility",
  nmbe: "nmbe",
  cvrmse: "cvrmse",
  // @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
  utility_calibration_start_date: (key, value) => ({
    utilityCalStartDate: moment(value),
  }),
  // @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
  utility_calibration_end_date: (key, value) => ({
    utilityCalEndDate: moment(value),
  }),
  record_type: "type",
} as const;

const inboundHyperSpaceJobCipher = {
  id: "id",
  start_date: "startDate",
  end_date: "endDate",
  building_id: "buildingId",
} as const;

const outboundUtilityBillEncoder = {
  ...R.invertObj(inboundUtilityBillCipher),
  // @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
  utility: (key, value) => ({ utility: snakeCase(value) }),
} as const;
// @ts-expect-error - TS2339 - Property 'month' does not exist on type '{ readonly utility: (key: any, value: any) => { utility: string; }; }'.
delete outboundUtilityBillEncoder.month;
// @ts-expect-error - TS2339 - Property 'numOfConsumptionTiers' does not exist on type '{ readonly utility: (key: any, value: any) => { utility: string; }; }'.
delete outboundUtilityBillEncoder.numOfConsumptionTiers;
// @ts-expect-error - TS2339 - Property 'numOfDemandTiers' does not exist on type '{ readonly utility: (key: any, value: any) => { utility: string; }; }'.
delete outboundUtilityBillEncoder.numOfDemandTiers;

const outboundRateStructureEncoder = R.invertObj(inboundRateStructureCipher);

const outboundSimulatedUtilityBillJob = R.invertObj(
  inboundSimulatedUtilityBillJobCipher
);
const outboundVersionUtilityCalibrationAccuracy = R.invertObj(
  inboundVersionUtilityCalibrationAccuracyCipher
);

const hasUtilityBills = (siteId: number): Promise<boolean> =>
  request
    .get(`/rest/utility_bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}`, limit: 1 })
    .catch(bugsnagPostgrestErrorHandler)
    // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
    .then(({ body }) => body.length > 0);

const updateUtilityBill = async (
  billId: string | number,
  props: Partial<UtilityBill>
): Promise<void> => {
  const outboundBillChanges = formatDataObj(props, outboundUtilityBillEncoder, {
    allowNulls: true,
  });

  if (props.unit) {
    const unitId = await outboundUnitIdEncoder(props.unit);
    outboundBillChanges.unit_id = unitId;
    delete outboundBillChanges.unit;
  }

  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .patch(`/rest/utility_bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${billId}` })
    .send(outboundBillChanges)
    .catch(bugsnagPostgrestErrorHandler);
};

const saveUtilityBill = async (
  newBill: Flow.Diff<
    UtilityBillBase,
    {
      id: ID;
      unit: UtilityUnit;
    }
  >
): Promise<UtilityBillBase | null | undefined> => {
  const outboundBill = formatDataObj(newBill, outboundUtilityBillEncoder);

  return request
    .post(`/rest/utility_bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send(outboundBill)
    .then(({ body }) => body[0])
    .catch(bugsnagPostgrestErrorHandler);
};

function getUtilityBillManualUploads(
  siteId: number
): Promise<UtilityBillManualUpload[]> {
  return request
    .get(`/rest/v_utility_bill_manual_uploads`)
    .query({ site_id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body));
}

export function useUtilityBillManualUploadsQuery({
  siteId,
  enabled = true,
  combineWasteWater = true,
}: {
  siteId: number;
  enabled?: boolean;
  // Changes waste water items to water
  combineWasteWater?: boolean;
}) {
  return useQuery({
    queryKey: ["utilityBillManualUploads", siteId],
    queryFn: () => getUtilityBillManualUploads(siteId),
    enabled,
    select: (items) =>
      combineWasteWater
        ? items.map((item) => ({
            ...item,
            utilityType:
              item.utilityType === "wasteWater" ? "water" : item.utilityType,
          }))
        : items,
  });
}

export const downloadManualUtilityBill = (fileId: string | number) =>
  request
    .get(`${VITE_UPLOAD_BASE_URL}/bill/download`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ file_id: fileId })
    .then((res) => res.text)
    .catch((error) => bugsnagGeneralErrorHandler(error));

// Returns a url to a preview image
export const getPreviewManualUtilityBill = (
  fileId: string | number
): Promise<string | undefined> =>
  request
    .get(`${VITE_UPLOAD_BASE_URL}/bill/preview`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ file_id: fileId })
    .then(({ body }) => body?.[fileId])
    .catch((error) => bugsnagGeneralErrorHandler(error));

const deleteUtilityBill = (id: string | number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete(`/rest/utility_bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${id}` })
    .catch(bugsnagPostgrestErrorHandler);

const saveUtilityRateStructures = (
  // prettier-ignore
  rateStructures: Array<Flow.Diff<UtilityBillRateStructure, {
    id: ID | string,
    status: string,
    isValid: boolean
  }>>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .post(`/rest/utility_bill_rate_structures`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(
      formatDataArray(rateStructures, outboundRateStructureEncoder, {
        allowNulls: true,
      })
    )
    .catch(bugsnagPostgrestErrorHandler);

const updateUtilityRateStructure = (
  id: string | number,
  props: Partial<UtilityBillRateStructure>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .patch(`/rest/utility_bill_rate_structures`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${id}` })
    .send(
      formatDataObj(props, outboundRateStructureEncoder, { allowNulls: true })
    )
    .catch(bugsnagPostgrestErrorHandler);

const deleteUtilityRateStructures = (
  ids: Array<string | number>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete(`/rest/utility_bill_rate_structures`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `in.(${ids.join(",")})` })
    .catch(bugsnagPostgrestErrorHandler);

const structureParsedDates = (
  rows: any
): Partial<Record<Utility, Array<UtilityBillParsedDates>>> => {
  // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
  const convertedUtilityCasing = rows.map((row) => ({
    ...row,
    utility: camelCase(row.utility),
  }));
  // @ts-expect-error - TS2349 - This expression is not callable. | TS2345 - Argument of type '<T>(value: T) => Prop<T, "utility">' is not assignable to parameter of type '(a: T) => string'.
  const sortedByUtility = R.groupBy(R.prop("utility"))(
    formatDataArray(convertedUtilityCasing, camelCaseCipher)
  );
  // @ts-expect-error - TS2559 - Type 'unknown[][]' has no properties in common with type 'Partial<Record<Utility, UtilityBillParsedDates[]>>'. | TS2769 - No overload matches this call.
  return R.map(R.sortBy(R.prop("startDate")), sortedByUtility);
};

const getUtilityBillParsedDates = async (
  siteId: string | number,
  options?: {
    utility?: Utility | null;
    startDate?: DateString;
    endDate?: DateString | null;
  } | null
): Promise<Partial<Record<Utility, Array<UtilityBillParsedDates>>>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  if (options?.utility) query.utility = `eq.${snakeCase(options?.utility)}`;
  // @ts-expect-error - TS2322 - Type 'void | Partial<Record<Utility, UtilityBillParsedDates[]>>' is not assignable to type 'Partial<Record<Utility, UtilityBillParsedDates[]>>'.
  return request
    .get(`/rest/utility_bill_parsed_dates`)
    .query(query)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) =>
      structureParsedDates(
        body.filter(
          // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
          (row) =>
            (options?.startDate == null ||
              row.end_date >= options?.startDate) &&
            (options?.endDate == null || row.start_date <= options?.endDate)
        )
      )
    )
    .catch(console.log); // eslint-disable-line
};

const getSiteEarliestParsedDates = async (
  siteId: string | number
): Promise<MonthString | null | undefined> => {
  return request
    .get(`/rest/utility_bill_parsed_dates`)
    .query({ site_id: `eq.${siteId}`, order: "month", limit: "1" })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => body[0].month);
};

const updateUtilityBillParsedDatesById = async (
  parsedDateId: string | number,
  updatedProps: Partial<UtilityBillParsedDates>
): Promise<Partial<Record<Utility, Array<UtilityBillParsedDates>>>> =>
  // @ts-expect-error - TS2322 - Type 'void | Partial<Record<Utility, UtilityBillParsedDates[]>>' is not assignable to type 'Partial<Record<Utility, UtilityBillParsedDates[]>>'.
  request
    .patch(`/rest/utility_bill_parsed_dates`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query({ id: `eq.${parsedDateId}` })
    .send(formatDataObj(updatedProps, snakeCaseCipher))
    .then(({ body }) => structureParsedDates(body))
    .catch(console.log); // eslint-disable-line

const saveUtilityBillParsedDates = async (
  parsedDates: Array<
    Flow.Diff<
      UtilityBillParsedDates,
      {
        id?: ID;
      }
    >
  >
): Promise<Partial<Record<Utility, Array<UtilityBillParsedDates>>>> =>
  request
    .post(`/rest/utility_bill_parsed_dates`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send(formatDataArray(parsedDates, snakeCaseCipher))
    .then(({ body }) => structureParsedDates(body))
    .catch((err) => {
      console.log(JSON.stringify(parsedDates)); // eslint-disable-line
      console.log(err); // eslint-disable-line
      throw err;
    });

const deleteUtilityBillParsedDates = async (
  ids: Array<number>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .delete(`/rest/utility_bill_parsed_dates`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `in.(${ids.join(",")})` })
    .catch(console.log); // eslint-disable-line

const getMostRecentMonthWithUtilities = (
  siteId: number
): Promise<Partial<Record<Utility, MonthString>>> =>
  request
    .get(`/rest/utilities_most_recent_month_with_data`)
    .query({ site_id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .catch(bugsnagPostgrestErrorHandler)
    // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
    .then(({ body }) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      R.fromPairs(body.map((row) => [camelCase(row.utility), row.month]))
    );

export const updateProviderIdByMeterIdBatch = async (
  providerId: number,
  meterIds: number[]
): Promise<void> => {
  try {
    await request
      .patch("/rest/utility_meters")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ id: `in.(${meterIds.join(",")})` })
      .send({ provider_id: providerId });
  } catch (e: any) {
    bugsnagPostgrestErrorHandler(e);
  }
};

type UtilitySummaryForMonth = {
  // @ts-expect-error - TS1337 - An index signature parameter type cannot be a literal type or generic type. Consider using a mapped object type instead.
  [utility: Utility]: {
    totalSpend: number;
    totalConsumption: number;
    totalGhgEmission: number;
  };
  month: MonthString;
  unit: UtilityUnit;
  unitId: number;
};

export const getUtilitiesSummaryByYear = async (
  siteId: number,
  year: number
) => {
  const { body } = await request
    .get(`/rest/utilities_annual_summary`)
    .query({ site_id: `eq.${siteId}`, year: `eq.${year}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`);

  return recursiveCamelCaseCipher(body);
};

const getUtilitiesSummaryByMonth = (
  siteId: number,
  months: Array<MonthString>
): Promise<Array<UtilitySummaryForMonth>> =>
  request
    .get(`/rest/utilities_summary_by_month`)
    .query({ site_id: `eq.${siteId}`, month: `in.(${months.join(",")})` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      body.map((row) => ({
        month: row.month,
        unitId: row.unit_id,
        unit: inboundUnitCipher[row.unit],
        ...R.fromPairs(
          R.toPairs(row.utility_data).map(([utility, values]: [any, any]) => [
            camelCase(utility),
            formatDataObj(values, camelCaseCipher),
          ])
        ),
      }))
    )
    .catch(bugsnagPostgrestErrorHandler);

const getUtilitiesMonthlySummary = (
  siteId: number
): Promise<Array<UtilityMetersMonthlySpend>> =>
  request
    .get(`/rest/utilities_monthly_summary`)
    .query({ site_id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => body.map(recursiveCamelCaseCipher))
    .then((res) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      res.map((row) => ({ ...row, unit: inboundUnitCipher[row.unit] }))
    )
    .catch(bugsnagPostgrestErrorHandler);

const getUtilityUsageByMonthOfYear = (
  siteId: number
): Promise<UtilityUsageByMonthOfYear> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;

  return (
    request
      .get(`/rest/utility_usage_by_month_of_year`)
      .query(query)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
      .then(({ body }) =>
        body.length ? recursiveCamelCaseCipher(body[0].utility_data) : []
      )
  );
};

const getNestedUtilityDeviationAlerts = (
  siteId: number,
  year?: YearString
): Promise<NestedUtilityDeviationAlerts> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  if (year) query.year = `eq.${year}`;

  return (
    request
      .get(`/rest/nested_utility_deviation_alerts`)
      .query(query)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'. | TS7006 - Parameter 'row' implicitly has an 'any' type.
      .then(({ body }) => body.map((row) => recursiveCamelCaseCipher(row)))
  );
};

const updateUtilityDeviationAlert = (
  alertId: number,
  props: {
    resolved?: boolean;
    comment?: string | null;
  }
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .patch(`/rest/utility_deviation_alerts`)
    .query({ id: `eq.${alertId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(props)
    .catch(bugsnagPostgrestErrorHandler);

const getExistingMeters = (
  siteId: number
): Promise<
  Partial<
    Record<
      Utility,
      Array<{
        bractletMeterId: number;
        energyStarMeterId: number | null | undefined;
        meterName: string;
        arcMeterId: number | null | undefined;
        deactivatedAt: string | null;
      }>
    >
  >
> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;

  // @ts-expect-error - TS2322 - Type 'Promise<void | Record<string, any>>' is not assignable to type 'Promise<Partial<Record<Utility, { bractletMeterId: number; energyStarMeterId: number | null | undefined; meterName: string; arcMeterId: number | null | undefined; deactivatedAt: string | null; }[]>>>'.
  return request
    .get(`/rest/existing_utility_meters`)
    .query(query)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => {
      if (body.length === 0) return {};
      const output: Record<string, any> = {};
      R.forEachObjIndexed((value, key) => {
        // @ts-expect-error - TS2345 - Argument of type 'string | number | symbol' is not assignable to parameter of type 'string | undefined'.
        output[camelCase(key)] = formatDataArray(value, camelCaseCipher);
      }, body[0].utilities);
      return output;
    })
    .catch(bugsnagPostgrestErrorHandler);
};

// const getExpectedMeters = (
//   siteId: number
// ): Promise<{
//   [month: MonthString]: {
//     [utility: Utility]: Array<number>,
//   },
// }> =>
//   request
//     .get(`/rest/parsed_date_expected_meters`)
//     .query({ site_id: `eq.${siteId}` })
//     .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
//     .then(({ body }) =>
//       R.pipe(
//         R.groupBy(R.prop("month")),
//         R.map(R.indexBy(props => camelCase(props.utility))),
//         R.map(R.map(R.prop("expected_meter_ids")))
//       )(body)
//     )
//     .catch(bugsnagPostgrestErrorHandler);

const getUtilityProviders = (): Promise<
  Array<{
    id: ID;
    name: string;
  }>
> =>
  request
    .get(`/rest/utility_providers`)
    .query({ order: "name" })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => body)
    .catch(bugsnagPostgrestErrorHandler);

export const useUtilityProvidersQuery = () =>
  useQuery({
    queryKey: "utilityProviders",
    queryFn: getUtilityProviders,
  });

const saveUtilityProvider = (
  name: string
): Promise<{
  id: ID;
  name: string;
}> =>
  request
    .post(`/rest/utility_providers`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ name })
    .set("Prefer", "return=representation")
    .then(({ body }) => body[0])
    .catch(bugsnagPostgrestErrorHandler);

const updateMeterActivationStatus = (meterId: number, active: boolean) =>
  request
    .patch(`/rest/utility_meters`)
    .query({ id: `eq.${meterId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ deactivated_at: active ? null : moment().format() })
    .catch(bugsnagPostgrestErrorHandler);

const getUtilityMetersWithBills = (
  siteId: string | number,
  utility?: Utility | Array<Utility> | null
): Promise<Partial<Record<Utility, BuildingUtilityWithMeters>>> => {
  const query: Record<string, any> = {};
  query.order = "utility";
  query.site_id = `eq.${siteId}`;
  if (utility) {
    query.utility = Array.isArray(utility)
      ? `in.(${utility.map(snakeCase).join(",")})`
      : `eq.${snakeCase(utility)}`;
  }

  // @ts-expect-error - TS2322 - Type 'Promise<void | { [x: string]: unknown; [x: number]: unknown; }>' is not assignable to type 'Promise<Partial<Record<Utility, BuildingUtilityWithMeters>>>'.
  return request
    .get(`/rest/utility_meters_with_bills`)
    .query(query)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => {
      return R.pipe(
        R.map(recursiveCamelCaseCipher),
        R.map((row) => ({
          ...row,
          unit: inboundUnitCipher[row.unit],
          // @ts-expect-error - TS7006 - Parameter 'meter' implicitly has an 'any' type.
          meters: row.meters.map((meter) => ({
            ...meter,
            unit: inboundUnitCipher[meter.unit],
            bills: formatMeterBills(meter),
          })),
        })),
        R.indexBy(R.prop("utility"))
      )(body);
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const formatMeterBills = (meter: any) => {
  // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
  return meter.bills.map((bill) => {
    const output = {
      ...bill,
      meterServiceName: meter.serviceName,
      meterName: meter.meterName,
      meterId: meter.id,
      unit: inboundUnitCipher[bill.unit],
      totalSpend: bill.total,
      startDate: bill.servicePeriodStart,
      endDate: bill.servicePeriodEnd,
      isValid: true,
    } as const;

    const rateTypes = ["consumption", "demand"];
    rateTypes.forEach((rateType) => {
      output[`${rateType}RateStructure`] = R.sortBy(R.prop("tier"))(
        bill.utilityBillRateStructures
          // @ts-expect-error - TS7006 - Parameter 'rateRow' implicitly has an 'any' type.
          .filter((rateRow) => rateRow.rateType === rateType)
          // @ts-expect-error - TS7006 - Parameter 'rateRow' implicitly has an 'any' type.
          .map((rateRow) => {
            const isRowValid = isValidRow(rateRow);
            if (!isRowValid) output.isValid = false;

            return {
              ...rateRow,
              status: "existing",
              isValid: isRowValid,
              hasThreshold: rateRow.threshold != null,
              hasMeterDemand: rateRow.meterDemand != null,
            };
          })
      );
    });
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly ".pdf": ".pdf"; readonly "application/pdf": ".pdf"; readonly ".docx": ".docx"; readonly "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx"; }'.
    output.fileTypes = fileTypes[bill.fileType];
    output.blendedRate = calcBlendedRate(output);

    const totalSpendSummation =
      R.sum(bill.utilityBillRateStructures.map(R.prop("total"))) +
      bill.fixedCost +
      bill.taxes;

    if (!looselyEqual(totalSpendSummation, bill.total)) {
      output.isValid = false;
    }
    delete output.utilityBillRateStructures;

    return output;
  });
};

const saveUtilityMeter = async ({
  providerId,
  siteId,
  meterName,
  utility,
}: {
  providerId?: number | null;
  siteId: number | string;
  meterName: string;
  utility: Utility;
}): Promise<UtilityMeterBase> => {
  return request
    .post(`/rest/utility_meters`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({
      provider_id: providerId,
      site_id: siteId,
      meter_name: meterName,
      utility: snakeCase(utility),
    })
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);
};

const uploadUtilityBillAttachment = async (
  utilityBill: number,
  {
    file,
    filename,
    fileType,
    uploadedAt,
  }: {
    file: File;
    filename: string;
    fileType: string;
    uploadedAt: DateTime;
  }
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .post("/uploads")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ type: "utility_bill", id: utilityBill })
    .then(({ body }) =>
      request
        .put(body.url) // received pre-signed S3 url from backend to upload to
        .set("Content-Type", file.type)
        .send(file)
    )
    .then(() =>
      request
        .patch(`/rest/utility_bills`)
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .query({ id: `eq.${utilityBill}` })
        .send({
          filename,
          file_type: fileType,
          uploaded_at: uploadedAt,
        })
        .catch(bugsnagPostgrestErrorHandler)
    )
    .catch(bugsnagGeneralErrorHandler);

const postSimulatedUtilityParsingJob = async (
  simulatedUtilityParsingJob: SimulatedUtilityParsingJob
): Promise<{
  body: Array<{
    id: number;
    errors: string;
  }>;
  statusCode: number;
}> => {
  const payload = formatDataObj(
    simulatedUtilityParsingJob,
    outboundSimulatedUtilityBillJob,
    {
      allowNulls: true,
    }
  );

  return request
    .post(`/rest/simulated_utility_bill_parsing_jobs`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send(payload)
    .catch((e) => {
      bugsnagPostgrestErrorHandler(e);
      return e;
    });
};

const getSimulatedParsingJobQuery = (query: {
  id?: number;
  siteId?: number | string;
  versiondId?: number | Array<number>;
  status?: string;
}): {
  id?: string;
  site_id?: string;
  version_id?: string;
  status?: string;
} =>
  R.map(
    (v) => (Array.isArray(v) ? `in.(${v.join(",")})` : `eq.${v}`),
    formatDataObj(query, outboundSimulatedUtilityBillJob)
  );

const getSimulatedUtilityParsingJobs = async (query: {
  id?: number;
  siteId?: number | string;
  versiondId?: number | Array<number>;
  status?: string;
}): Promise<Array<SimulatedUtilityParsingJob>> => {
  return request
    .get(`/rest/simulated_utility_bill_parsing_jobs`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query(getSimulatedParsingJobQuery(query))
    .then(({ body }) => {
      if (query.id != null) {
        return formatDataArray(body, inboundSimulatedUtilityBillJobCipher, {
          allowNulls: true,
        });
      } else {
        const jobsForSite = formatDataArray(
          body,
          inboundSimulatedUtilityBillJobCipher
        );
        return jobsForSite.filter(
          // @ts-expect-error - TS7006 - Parameter 'j' implicitly has an 'any' type.
          (j) =>
            (j.status === "queued" || j.status === "started") &&
            moment().diff(j.createdAt, "seconds") < 240
        );
      }
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const getHyperSpaceJob = async (
  jobId: string | number
): Promise<HyperSpaceJob> =>
  request
    .get(`/rest/hyperspace_jobs`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query({ select: "id,start_date,end_date, building_id", id: `eq.${jobId}` })
    .then(({ body }) => formatDataObj(body[0], inboundHyperSpaceJobCipher))
    .catch(bugsnagPostgrestErrorHandler);

const getHyperSpaceJobs = async (
  jobIds: Array<string | number>
): Promise<Array<HyperSpaceJob>> =>
  request
    .get(`/rest/hyperspace_jobs`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query({
      select: "id,start_date,end_date, building_id",
      id: `in.(${jobIds.join(",")})`,
    })
    .then(({ body }) => formatDataArray(body, inboundHyperSpaceJobCipher))
    .catch(bugsnagPostgrestErrorHandler);

const getHyperSpaceOutputs = async (
  jobId: string | number
): Promise<Array<string> | null | undefined> =>
  request
    .get(`/rest/hyperspace_jobs`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query({ select: "outputs", id: `eq.${jobId}` })
    .then(({ body }) => body[0].outputs)
    .catch(bugsnagPostgrestErrorHandler);

const getLinkedHyperspaceJobsForCombinedSite = async (
  childSiteIds: Array<number>,
  versionId: string | number
  // @ts-expect-error - TS1064 - The return type of an async function or method must be the global Promise<T> type. Did you mean to write 'Promise<{ [key: number]: HyperSpaceJob[]; }>'?
): {
  [key: number]: Array<HyperSpaceJob>;
} => {
  const query: Record<string, any> = {};
  query.select =
    "site_id, start_version_hs3_job_id, end_version_hs3_job_id, start_version_id, end_version_id";
  query.site_id = `in.(${childSiteIds.join(",")})`;
  query.published_at = `not.is.null`;
  query.subsystem = `is.null`;
  return (
    request
      .get(`/rest/simulated_utility_bills`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .set("Prefer", "return=representation")
      .query(query)
      .then(({ body }) => [
        ...new Set(
          body
            .filter(
              // @ts-expect-error - TS7031 - Binding element 'start_version_id' implicitly has an 'any' type. | TS7031 - Binding element 'end_version_id' implicitly has an 'any' type.
              ({ start_version_id, end_version_id }) =>
                start_version_id === versionId || end_version_id === versionId
            )
            // @ts-expect-error - TS7031 - Binding element 'start_version_hs3_job_id' implicitly has an 'any' type. | TS7031 - Binding element 'end_version_hs3_job_id' implicitly has an 'any' type.
            .map(({ start_version_hs3_job_id, end_version_hs3_job_id }) =>
              [start_version_hs3_job_id, end_version_hs3_job_id].filter(Boolean)
            )
            .flat()
        ),
      ])
      // @ts-expect-error - TS2345 - Argument of type 'unknown[]' is not assignable to parameter of type '(string | number)[]'.
      .then((jobIds) => getHyperSpaceJobs(jobIds))
      // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
      .then((jobs) => R.groupBy((job) => job.buildingId, jobs))
  );
};

const getRawSimulatedBills = async (options: {
  parsedDateIds: number[];
}): Promise<RawSimulatedUtilityBillBase[]> => {
  return request
    .get(`/rest/simulated_utility_bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ parsed_date_id: `in.(${options.parsedDateIds.join(",")})` })
    .then(({ body }) => recursiveCamelCaseCipher(body));
};

const getBlendedRate = async (
  siteId: string | number,
  utility: Utility
): Promise<
  | {
      blendedRate: number;
      unit: EnergyUnit;
    }
  | null
  | undefined
> =>
  request
    .get("/rest/utility_blended_rate")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      utility: `eq.${snakeCase(utility)}`,
    })
    .then(async ({ body }) => {
      if (body.length === 0) return null;
      const { blended_rate: blendedRate } = body[0];

      return { blendedRate, unit: "kWh" };
    });

const deleteSimulatedUtilityBills = async (
  stateOrVersionId: number,
  deletePublished: boolean
): Promise<Array<void>> => {
  // @ts-expect-error - TS2322 - Type '(void | Response)[]' is not assignable to type 'void[]'.
  return Promise.all([
    request
      .delete(`/rest/simulated_utility_bills`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({
        published_at: deletePublished ? "not.is.null" : "is.null",
        start_version_id: `eq.${stateOrVersionId}`,
        version_state_bridge_month: `is.false`,
      })
      .catch(bugsnagPostgrestErrorHandler),
    request
      .delete(`/rest/simulated_utility_bills`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({
        published_at: deletePublished ? "not.is.null" : "is.null",
        end_version_id: `eq.${stateOrVersionId}`,
      })
      .catch(bugsnagPostgrestErrorHandler),
  ]);
};

const deleteSimulatedUtilityBillsByParsedDateIds = async (
  parsedDateIds: Array<number>
): Promise<Array<void>> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void[]'.
  request
    .delete(`/rest/simulated_utility_bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      parsed_date_id: `in.(${parsedDateIds.join(",")})`,
    })
    .catch(bugsnagPostgrestErrorHandler);

const deleteSimulatedUtilityBillsByUtility = async (
  siteId: string | number,
  utilities: Array<Utility>
): Promise<Array<void>> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void[]'.
  request
    .delete(`/rest/simulated_utility_bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      utility: `in.(${utilities.map(snakeCase).join(",")})`,
    })
    .catch(bugsnagPostgrestErrorHandler);

const deleteSimulatedUtilityBillsByRecordId = async (
  stateOrVersionId: number | string
): Promise<Array<void>> =>
  // @ts-expect-error - TS2322 - Type '(void | Response)[]' is not assignable to type 'void[]'.
  Promise.all([
    request
      .delete(`/rest/simulated_utility_bills`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({
        start_version_id: `eq.${stateOrVersionId}`,
        end_version_id: `eq.${stateOrVersionId}`,
      })
      .catch(bugsnagPostgrestErrorHandler),
    request
      .delete(`/rest/simulated_utility_bills`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({
        start_version_id: `eq.${stateOrVersionId}`,
        end_version_id: `is.null`,
      })
      .catch(bugsnagPostgrestErrorHandler),
    request
      .delete(`/rest/simulated_utility_bills`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({
        start_version_id: `is.null`,
        end_version_id: `eq.${stateOrVersionId}`,
      })
      .catch(bugsnagPostgrestErrorHandler),
  ]);

const publishSimulatedUtilityBills = async (
  stateOrVersionId: number
): Promise<Array<void>> =>
  // @ts-expect-error - TS2322 - Type '(void | Response)[]' is not assignable to type 'void[]'.
  Promise.all([
    request
      .patch(`/rest/simulated_utility_bills`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({
        start_version_id: `eq.${stateOrVersionId}`,
      })
      .send({ published_at: new Date().toISOString() })
      .catch(bugsnagPostgrestErrorHandler),
    request
      .patch(`/rest/simulated_utility_bills`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({
        end_version_id: `eq.${stateOrVersionId}`,
      })
      .send({ published_at: new Date().toISOString() })
      .catch(bugsnagPostgrestErrorHandler),
  ]);

const updateSimulatedUtilityBill = async (
  id: number,
  props: {
    startVersionSimulatedCost?: number | null;
    endVersionSimulatedCost?: number | null;
  }
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Response' is not assignable to type 'void'.
  request
    .patch(`/rest/simulated_utility_bills`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${id}` })
    .send(formatDataObj(props, snakeCaseCipher, { allowNulls: true }));

const setParsedDateOutlier = async (
  parsedDateId: number,
  isOutlier: boolean
): Promise<Array<void>> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void[]'.
  request
    .patch(`/rest/utility_bill_parsed_dates`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${parsedDateId}` })
    .send({ is_outlier: isOutlier })
    .catch(bugsnagPostgrestErrorHandler);

const getParsedUtilityBills = async (
  siteId: string | number,
  energyUtilitiesOnly: boolean = false
): Promise<Array<ParsedEnergyBills>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  if (energyUtilitiesOnly) {
    query.utility = `in.(${energyUtilities.map(snakeCase).join(",")})`;
  }

  // @ts-expect-error - TS2322 - Type 'void | ParsedEnergyBills[]' is not assignable to type 'ParsedEnergyBills[]'.
  return (
    request
      .get(`/rest/building_utility_data`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query(query)
      .then(({ body }) => body.map(recursiveCamelCaseCipher))
      // @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type '(value: any) => any'. | TS2769 - No overload matches this call.
      .then(R.indexBy(R.prop("utility")))
      .then(cleanUtilityBills)
      .catch(bugsnagPostgrestErrorHandler)
  );
};

const getUtilityCalibrationResults = async (
  siteId: string | number,
  versionId: string | null | undefined | number | null | undefined,
  utility?: Utility | null
): Promise<Array<UtilityCalibrationAccuracyWithDates>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  if (versionId != null) query.version_id = `eq.${versionId}`;
  if (utility != null) query.utility = `eq.${snakeCase(utility)}`;

  return request
    .get(`/rest/state_and_version_utility_calibration_accuracy_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query(query)
    .then(({ body }) =>
      formatDataArray(body, inboundVersionUtilityCalibrationAccuracyCipher).map(
        // @ts-expect-error - TS7006 - Parameter 'r' implicitly has an 'any' type.
        (r) => ({ ...r, utility: camelCase(r.utility) })
      )
    )
    .catch((e) => {
      bugsnagPostgrestErrorHandler(e);
      return [];
    });
};

const saveVersionUtilityCalibrationResults = async (
  calibrationAccuracy: UtilityCalibrationAccuracy
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .post(`/rest/state_and_version_utility_calibration_accuracy`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      ...formatDataObj(
        calibrationAccuracy,
        outboundVersionUtilityCalibrationAccuracy
      ),
      utility: snakeCase(calibrationAccuracy.utility),
    })
    .catch(bugsnagPostgrestErrorHandler);

const deleteVersionUtilityCalibrationResults = async (
  versionId: string | number
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .delete(`/rest/state_and_version_utility_calibration_accuracy`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ version_id: `eq.${versionId}` })
    .catch(bugsnagPostgrestErrorHandler);

const updateVersionUtilityCalibrationResults = async (
  calibrationAccuracy: UtilityCalibrationAccuracy
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .patch(`/rest/state_and_version_utility_calibration_accuracy`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      version_id: `eq.${calibrationAccuracy.versionId}`,
      utility: `eq.${snakeCase(calibrationAccuracy.utility)}`,
    })
    .send({
      ...formatDataObj(
        calibrationAccuracy,
        outboundVersionUtilityCalibrationAccuracy,
        { allowNulls: true }
      ),
      utility: snakeCase(calibrationAccuracy.utility),
    })
    .catch(bugsnagPostgrestErrorHandler);

export const patchRequestForManualChange = (
  manualChange: {
    [key: string]: string | null | undefined;
  },
  dataPointId: number,
  parsePatchError: (arg1: {
    equipmentId: number;
    pointType: string;
    changeKey: string;
  }) => void
) =>
  request
    .patch("/rest/bep_data_streams")
    .query({
      bractlet_id: `eq.${dataPointId}`,
    })
    .set("accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(manualChange)
    .catch((e) => {
      if (e.response.statusCode === 409) {
        const reMatch = e.response.body.details.match(/\(([0-9]{1,}),(.*)\)/);
        if (reMatch != null && reMatch.length > 2)
          parsePatchError({
            equipmentId: parseInt(reMatch[1]),
            pointType: reMatch[2],
            // @ts-expect-error - TS2345 - Argument of type 'string | number' is not assignable to parameter of type 'string'.
            changeKey: getChangeKey(dataPointId, R.keys(manualChange)[0]),
          });
      } else {
        bugsnagPostgrestErrorHandler(e);
      }
    });

export const resetParentEquipmentNames = ({
  newEquipment,
  dataStreamIds,
  virtualDataStreamIds,
}: {
  newEquipment: Equipment;
  dataStreamIds: Array<number>;
  virtualDataStreamIds: Array<number>;
}) =>
  unsetParentEquipmentNames({
    dataStreamIds,
    virtualDataStreamIds,
  })
    .then(() =>
      Promise.all([
        request
          .patch("/rest/bep_data_streams")
          .query({
            bractlet_id: `in.(${dataStreamIds.join(",")})`,
          })
          .set("accept", "application/json")
          .set("Content-Type", "application/json")
          .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
          .send({ parent_equipment_id: newEquipment.id }),
        request
          .patch("/rest/virtual_data_streams")
          .query({
            id: `in.(${virtualDataStreamIds.join(",")})`,
          })
          .set("accept", "application/json")
          .set("Content-Type", "application/json")
          .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
          .send({ parent_equipment_id: newEquipment.id })
          .catch((e) => {
            if (e.response.statusCode === 409) {
              return request
                .delete("/rest/virtual_data_streams")
                .query({
                  parent_equipment_id: `eq.${newEquipment.id}`,
                  brick_point_type: `eq.On_Off_Status`,
                })
                .set("accept", "application/json")
                .set("Content-Type", "application/json")
                .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
                .then(() =>
                  request
                    .patch("/rest/virtual_data_streams")
                    .query({
                      id: `in.(${virtualDataStreamIds.join(",")})`,
                    })
                    .set("accept", "application/json")
                    .set("Content-Type", "application/json")
                    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
                    .send({
                      parent_equipment_id: newEquipment.id,
                    })
                );
            } else {
              bugsnagPostgrestErrorHandler(e);
            }
          }),
      ])
    )
    .catch(bugsnagPostgrestErrorHandler);

const unsetParentEquipmentNames = ({
  dataStreamIds,
  virtualDataStreamIds,
}: {
  dataStreamIds: Array<number>;
  virtualDataStreamIds: Array<number>;
}) =>
  Promise.all([
    request
      .patch("/rest/bep_data_streams")
      .query({
        bractlet_id: `in.(${dataStreamIds.join(",")})`,
      })
      .set("accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .send({ parent_equipment_id: null })
      .catch(bugsnagPostgrestErrorHandler),
    request
      .patch("/rest/virtual_data_streams")
      .query({
        id: `in.(${virtualDataStreamIds.join(",")})`,
      })
      .set("accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .send({ parent_equipment_id: null })
      .catch(bugsnagPostgrestErrorHandler),
  ]);

export const clearParentEquipmentNames = ({
  dataStreamIds,
  virtualDataStreamIds,
}: {
  dataStreamIds: Array<number>;
  virtualDataStreamIds: Array<number>;
}) =>
  Promise.all([
    request
      .patch("/rest/bep_data_streams")
      .query({
        bractlet_id: `in.(${dataStreamIds.join(",")})`,
      })
      .set("accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .send({ parent_equipment_id: null })
      .catch(bugsnagPostgrestErrorHandler),
    request
      .delete("/rest/virtual_data_streams")
      .query({
        id: `in.(${virtualDataStreamIds.join(",")})`,
      })
      .set("accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .catch(bugsnagPostgrestErrorHandler),
  ]);

const addEquipment = (
  siteId: number,
  name: string,
  brickEquipmentType: string
): Promise<Equipment | null | undefined> =>
  request
    .post("/rest/equipment")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({
      site_id: siteId,
      name: name,
      brick_equipment_type: brickEquipmentType,
    })
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

const updateEquipment = (
  siteId: number,
  equipmentIds: Array<number | string>,
  brickEquipmentType: string
): Promise<Equipment | null | undefined> =>
  request
    .patch("/rest/equipment")
    .query({
      site_id: `eq.${siteId}`,
      id: `in.(${equipmentIds.join(",")})`,
    })
    .set("accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({
      brick_equipment_type: brickEquipmentType,
    })
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

const deleteEquipment = (
  siteId: number,
  equipmentIds: Array<number | string>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/equipment")
    .query({
      site_id: `eq.${siteId}`,
      id: `in.(${equipmentIds.join(",")})`,
    })
    .set("accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .catch(bugsnagPostgrestErrorHandler);

const getUtilityDeviationAlertConditions = (
  siteId: number
): Promise<Array<UtilityDeviationAlertCondition>> => {
  return (
    request
      .get(`/rest/utility_deviation_alert_conditions_summary`)
      .query({ site_id: `eq.${siteId}`, order: "created_at.desc" })
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      .then(({ body }) => body.map((row) => recursiveCamelCaseCipher(row)))
      .then((alertConditions) =>
        // @ts-expect-error - TS7006 - Parameter 'ac' implicitly has an 'any' type.
        alertConditions.map((ac) => ({
          ...ac,
          utilities: ac.utilities.map(camelCase),
          utilityDeviationTypes: ac.utilityDeviationTypes.map(camelCase),
        }))
      )
      .catch(bugsnagPostgrestErrorHandler)
  );
};

const createAlertSubentries = (
  id: number,
  // @ts-expect-error - TS7031 - Binding element 'utilities' implicitly has an 'any' type. | TS7031 - Binding element 'deviationTypes' implicitly has an 'any' type. | TS7031 - Binding element 'months' implicitly has an 'any' type.
  { utilities, deviationTypes, months }
) =>
  Promise.all([
    // @ts-expect-error - TS7006 - Parameter 'utility' implicitly has an 'any' type.
    ...utilities.map((utility) =>
      request
        .post("/rest/utility_deviation_alert_condition_utilities")
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .send({
          alert_condition_id: id,
          utility: snakeCase(utility),
        })
    ),
    // @ts-expect-error - TS7006 - Parameter 'deviationType' implicitly has an 'any' type.
    ...deviationTypes.map((deviationType) =>
      request
        .post("/rest/utility_deviation_alert_condition_deviation_types")
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .send({
          alert_condition_id: id,
          utility_deviation_type: snakeCase(deviationType),
        })
    ),
    // @ts-expect-error - TS7006 - Parameter 'month' implicitly has an 'any' type.
    ...months.map((month) =>
      request
        .post("/rest/utility_deviation_alert_condition_months")
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .send({ alert_condition_id: id, month })
    ),
  ]);

const createUtilityDeviationAlertCondition = async (
  newAlertCondition: {
    siteId: string | number;
    startMonth: MonthString;
    percent: number;
  },
  utilities: Array<Utility>,
  deviationTypes: Array<UtilityDeviationAlertTypes>,
  months: Array<number>
): Promise<Array<void>> => {
  const newId = await request
    .post("/rest/utility_deviation_alert_conditions")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      site_id: newAlertCondition.siteId,
      start_month: newAlertCondition.startMonth,
      percent: newAlertCondition.percent,
    })
    .set("Prefer", "return=representation")
    .then(({ body }) => body[0].id)
    .catch(bugsnagPostgrestErrorHandler);

  return createAlertSubentries(newId, { utilities, deviationTypes, months });
};

const updateUtilityDeviationAlertCondition = async (
  alertId: number,
  props: {
    percent: number;
    startMonth: MonthString;
  },
  utilities: Array<Utility>,
  deviationTypes: Array<UtilityDeviationAlertTypes>,
  months: Array<number>
): Promise<Array<undefined | Array<undefined>>> => {
  await Promise.all([
    request
      .delete("/rest/utility_deviation_alert_condition_utilities")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ alert_condition_id: `eq.${alertId}` }),
    request
      .delete("/rest/utility_deviation_alert_condition_deviation_types")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ alert_condition_id: `eq.${alertId}` }),
    request
      .delete("/rest/utility_deviation_alert_condition_months")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ alert_condition_id: `eq.${alertId}` }),
  ]);
  // @ts-expect-error - TS2322 - Type '(void | any[] | Response)[]' is not assignable to type '(undefined[] | undefined)[]'.
  return Promise.all([
    request
      .patch("/rest/utility_deviation_alert_conditions")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ id: `eq.${alertId}` })
      .send({ percent: props.percent, start_month: props.startMonth })
      .catch(bugsnagPostgrestErrorHandler),
    createAlertSubentries(alertId, { utilities, deviationTypes, months }),
  ]);
};

const deleteUtilityDeviationAlertCondition = (id: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/utility_deviation_alert_conditions")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${id}` })
    .catch(bugsnagPostgrestErrorHandler);

const getParsedDateUtilityData = (options: {
  siteId?: number | string;
  parsedDatesIds?: number[];
}): Promise<Array<ParsedDateUtilityData>> => {
  const query: Record<string, any> = {};
  if (options.siteId) query.site_id = `eq.${options.siteId}`;
  if (options.parsedDatesIds)
    query.id = `in.(${options.parsedDatesIds.join(",")})`;

  return request
    .get(`/rest/utility_bill_parsed_dates_extended`)
    .query(query)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body))
    .then((rows) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      rows.map((row) => ({ ...row, unit: inboundUnitCipher[row.unit] }))
    )
    .catch(bugsnagPostgrestErrorHandler);
};

type VersionUtilityRate = {
  siteId: number;
  utility: Utility;
  versionId: number;
  averageRate: number | null | undefined;
};
const getVersionUtilityRate = (options: {
  versionId?: number;
  versionIds?: number[];
  utility?: Utility;
}): Promise<VersionUtilityRate[]> => {
  const query: Record<string, any> = {};
  if (options.versionId) query.version_id = `eq.${options.versionId}`;
  if (options.versionIds)
    query.version_id = `in.(${options.versionIds.join(",")})`;
  if (options.utility) query.utility = `eq.${snakeCase(options.utility)}`;

  return request
    .get(`/rest/version_utility_average_rates`)
    .query(query)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body));
};

const getSingleSiteSimulatedUtilityBillsSummary = async (
  siteId: string | number,
  versionId?: string | number,
  recordType?: "version" | "state"
): Promise<{
  published: RawSummarizedSimulatedUtilityBills[];
  unpublished: RawSummarizedSimulatedUtilityBills[];
  queuedNewSet: RawSummarizedSimulatedUtilityBills[];
}> => {
  try {
    const query: Record<string, any> = {};
    query.site_id = `eq.${siteId}`;
    query.order = "month";

    const recordTypeFilter =
      recordType == null || recordType === "state"
        ? // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
          (bill) => true
        : // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
          (bill) => !bill.versionStateBridgeMonth;
    const versionFilter =
      versionId == null
        ? // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
          (bill) => true
        : // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
          (bill) =>
            (bill.startVersionId === versionId && recordTypeFilter(bill)) ||
            bill.endVersionId === versionId;

    const publishedFilter =
      versionId == null
        ? // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
          (bill) =>
            bill.published &&
            bill.startVersionId != null &&
            bill.endVersionId != null
        : // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
          (bill) =>
            bill.published &&
            (bill.startVersionId != null || bill.endVersionId != null);

    const formatBills = (
      allBills: RawSummarizedSimulatedUtilityBill[]
    ): RawSummarizedSimulatedUtilityBills[] =>
      R.toPairs(R.groupBy((bill) => bill.utility, allBills)).map(
        ([utility, bills]: [any, any]) => ({
          utility,
          bills,
        })
      );

    if (typeof versionId === "string") versionId = parseInt(versionId);

    const applyMissingSystemUsage = (bill: any) => {
      // @ts-expect-error - TS7006 - Parameter 's' implicitly has an 'any' type.
      const billTotals = bill.systems.find((s) => s.system == null);
      if (billTotals == null) return bill;
      let startVersionSimulatedConsumption =
        billTotals.startVersionSimulatedConsumption;
      let endVersionSimulatedConsumption =
        billTotals.endVersionSimulatedConsumption;
      let startVersionSimulatedCost = billTotals.startVersionSimulatedCost;
      let endVersionSimulatedCost = billTotals.endVersionSimulatedCost;
      let startVersionSimulatedDemand = billTotals.startVersionSimulatedDemand;
      let endVersionSimulatedDemand = billTotals.endVersionSimulatedDemand;
      bill.systems
        // @ts-expect-error - TS7006 - Parameter 's' implicitly has an 'any' type.
        .filter((s) => s.system != null)
        // @ts-expect-error - TS7006 - Parameter 's' implicitly has an 'any' type.
        .forEach((s) => {
          startVersionSimulatedConsumption -=
            s.startVersionSimulatedConsumption ?? 0;
          endVersionSimulatedConsumption -=
            s.endVersionSimulatedConsumption ?? 0;
          startVersionSimulatedCost -= s.startVersionSimulatedCost ?? 0;
          endVersionSimulatedCost -= s.endVersionSimulatedCost ?? 0;
          startVersionSimulatedDemand -= s.startVersionSimulatedDemand ?? 0;
          endVersionSimulatedDemand -= s.endVersionSimulatedDemand ?? 0;
        });

      const hasMissingUsage =
        startVersionSimulatedConsumption > 1 ||
        endVersionSimulatedConsumption > 1 ||
        startVersionSimulatedCost > 1 ||
        endVersionSimulatedCost > 1 ||
        startVersionSimulatedDemand > 1 ||
        endVersionSimulatedDemand > 1;

      if (!hasMissingUsage) return bill;

      // @ts-expect-error - TS7006 - Parameter 's' implicitly has an 'any' type.
      const otherSystem = bill.systems.find((s) => s.system === "other");
      const systems = otherSystem
        ? // @ts-expect-error - TS7006 - Parameter 's' implicitly has an 'any' type.
          bill.systems.map((s) => {
            if (s.system !== "other") return s;
            return {
              system: s.system,
              startVersionSimulatedConsumption:
                s.startVersionSimulatedConsumption +
                startVersionSimulatedConsumption,
              endVersionSimulatedConsumption:
                s.endVersionSimulatedConsumption +
                endVersionSimulatedConsumption,
              startVersionSimulatedCost:
                s.startVersionSimulatedCost + startVersionSimulatedCost,
              endVersionSimulatedCost:
                s.endVersionSimulatedCost + endVersionSimulatedCost,
              startVersionSimulatedDemand:
                s.startVersionSimulatedDemand + startVersionSimulatedDemand,
              endVersionSimulatedDemand:
                s.endVersionSimulatedDemand + endVersionSimulatedDemand,
            };
          })
        : [
            ...bill.systems,
            {
              system: "other",
              startVersionSimulatedConsumption,
              endVersionSimulatedConsumption,
              startVersionSimulatedCost,
              endVersionSimulatedCost,
              startVersionSimulatedDemand,
              endVersionSimulatedDemand,
            },
          ];

      return { ...bill, systems };
    };

    const rawBills: RawSummarizedSimulatedUtilityBill[] = await request
      .get(`/rest/simulated_utility_bills_summary`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .set("Prefer", "return=representation")
      .query(query)
      .then(({ body }) => recursiveCamelCaseCipher(body))
      .then((rows) => {
        // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
        return rows.map(applyMissingSystemUsage).map((row) => ({
          ...row,
          systems: row.systems
            // will later re-sum subsystems into totals
            // @ts-expect-error - TS7006 - Parameter 's' implicitly has an 'any' type.
            .filter((s) => s.system != null)
            // @ts-expect-error - TS7006 - Parameter 's' implicitly has an 'any' type.
            .map((s) => {
              const rawConsumption =
                (s.startVersionSimulatedConsumption || 0) +
                (s.endVersionSimulatedConsumption || 0);
              const rawCost =
                (s.startVersionSimulatedCost || 0) +
                (s.endVersionSimulatedCost || 0);
              const rawDemand =
                (s.startVersionSimulatedDemand || 0) +
                (s.endVersionSimulatedDemand || 0);
              return {
                system: kebabCase(s.system),
                rawConsumption,
                rawCost,
                rawDemand,
                rawRate: rawConsumption > 0 ? rawCost / rawConsumption : 0,
              };
            }),
        }));
      })
      .catch((e) => {
        bugsnagPostgrestErrorHandler(e);
        return [];
      });

    const filteredBills = rawBills.filter(versionFilter);

    const [engineeringCalcs, confidenceFactors] = await Promise.all([
      getEngineeringCalculationsInKWh(siteId, {
        type: "stateOrVersion",
      }),
      getConfidenceFactors({ siteId, stateOrVersionId: versionId }),
    ]);

    const months = moment.monthsShort().map((month) => month.toLowerCase());
    const engineeringCalcAdjustedBills = filteredBills.map((bill) => {
      const billMonthIdx = parseInt(bill.month.split("-")[1]!) - 1;
      const monthPropName = months[billMonthIdx];
      const engCalcsForBill = engineeringCalcs.filter(
        (calc) =>
          calc.stateOrVersionId === bill.endVersionId &&
          calc.utility === bill.utility
      );
      return {
        ...bill,
        systems: bill.systems.map((s) => {
          const engCalcsForSystem = R.sum(
            engCalcsForBill
              .filter((calc) => calc.system === s.system)
              // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'EngineeringCalculation'.
              .map((calc) => calc[monthPropName])
              .filter(Boolean)
          );
          return {
            ...s,
            rawConsumption: Math.max(0, s.rawConsumption - engCalcsForSystem),
            rawCost: Math.max(s.rawCost - engCalcsForSystem * s.rawRate, 0),
          };
        }),
      };
    });

    const getCF = (
      utility: null | EnergyUtility | "all",
      system: System | null | undefined,
      stateOrVersionId: undefined | number
    ) => {
      const confidenceFactor = confidenceFactors.find(
        (cf) =>
          cf.utility === utility &&
          cf.system === system &&
          cf.stateOrVersionId === stateOrVersionId
      );
      return confidenceFactor ? confidenceFactor.confidenceFactor : 1;
    };

    const confidenceFactorAdjustedBills = engineeringCalcAdjustedBills.map(
      (bill) => {
        if (bill.endVersionRecordType === "state") {
          const baselineBill = rawBills.find(
            (b) =>
              b.parsedDateId === bill.parsedDateId &&
              b.endVersionRecordType === "version"
          );
          if (baselineBill) {
            const totalCF = getCF(null, null, bill.endVersionId);
            const utilityCF = getCF(bill.utility, null, bill.endVersionId);
            return {
              ...bill,
              systems: bill.systems.map((s) => {
                const baselineSystemUsage = baselineBill.systems.find(
                  (baselineSystem) => baselineSystem.system === s.system
                );
                if (!baselineSystemUsage) return s;

                const systemCF = getCF(
                  bill.utility,
                  s.system,
                  bill.endVersionId
                );

                const consumptionSavings = Math.max(
                  0,
                  baselineSystemUsage.rawConsumption - s.rawConsumption
                );

                const costSavings = Math.max(
                  0,
                  baselineSystemUsage.rawCost - s.rawCost
                );

                const adjustedConsumptionSavings =
                  consumptionSavings * systemCF * utilityCF * totalCF;
                const adjustedCostSavings =
                  costSavings * systemCF * utilityCF * totalCF;

                return {
                  ...s,
                  rawCost: baselineSystemUsage.rawCost - adjustedCostSavings,
                  rawConsumption:
                    baselineSystemUsage.rawConsumption -
                    adjustedConsumptionSavings,
                };
              }),
            };
          }
        }

        return bill;
      }
    );

    const publishedBills =
      confidenceFactorAdjustedBills.filter(publishedFilter);
    const unpublishedBills = confidenceFactorAdjustedBills.filter(
      (bill) => !publishedFilter(bill)
    );

    const unpublishedParsedDates = unpublishedBills.map(R.prop("parsedDateId"));
    const isOverwriting = publishedBills.some((bill) =>
      unpublishedParsedDates.includes(bill.parsedDateId)
    );

    // @ts-expect-error - TS7034 - Variable 'wouldBeBills' implicitly has type 'any[]' in some locations where its type cannot be determined.
    let wouldBeBills = [];
    if (
      !confidenceFactorAdjustedBills
        .filter(versionFilter)
        .every(publishedFilter)
    ) {
      wouldBeBills = isOverwriting
        ? unpublishedBills
        : [...publishedBills, ...unpublishedBills];
    }

    return {
      published: formatBills(publishedBills),
      unpublished: formatBills(unpublishedBills),
      // @ts-expect-error - TS7005 - Variable 'wouldBeBills' implicitly has an 'any[]' type.
      queuedNewSet: formatBills(wouldBeBills),
    };
  } catch (e: any) {
    return {
      published: [],
      unpublished: [],
      queuedNewSet: [],
    };
  }
};

const combineChildBillSummaries = (
  firstChildBills: RawSummarizedSimulatedUtilityBills[],
  secondChildBills: RawSummarizedSimulatedUtilityBills[]
): RawSummarizedSimulatedUtilityBills[] => {
  if (firstChildBills.length !== secondChildBills.length) return [];
  // @ts-expect-error - TS2322 - Type '{ utility: EnergyUtility | "all"; bills: ({ systems: { system: System | null | undefined; rawConsumption: number; rawCost: number; rawDemand: number; }[]; parsedDateId: number; ... 15 more ...; versionStateBridgeMonth: boolean; } | null)[]; }[]' is not assignable to type 'RawSummarizedSimulatedUtilityBills[]'.
  return firstChildBills.map((firstParsedBills) => {
    const secondParsedBills = secondChildBills.find(
      ({ utility }) => utility === firstParsedBills.utility
    );
    if (secondParsedBills == null)
      return { utility: firstParsedBills.utility, bills: [] };
    return {
      utility: firstParsedBills.utility,
      bills: firstParsedBills.bills
        .map((firstDataPoint) => {
          const secondDataPoint = secondParsedBills.bills.find(
            (d) =>
              d.parsedDateId === firstDataPoint.parsedDateId &&
              d.startVersionId === firstDataPoint.startVersionId &&
              d.endVersionId === firstDataPoint.endVersionId
          );
          if (secondDataPoint == null) return null;

          const allSystems = R.uniq(
            [...firstDataPoint.systems, ...secondDataPoint.systems].map(
              ({ system }) => system
            )
          );

          return {
            ...firstDataPoint,
            systems: allSystems.map((system) => {
              const firstSystem = firstDataPoint.systems.find(
                (sys) => sys.system === system
              );
              const secondSystem = secondDataPoint.systems.find(
                (sys) => sys.system === system
              );
              return {
                system,
                rawConsumption:
                  firstSystem?.rawConsumption ||
                  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                  0 + secondSystem?.rawConsumption ||
                  0,
                // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                rawCost: firstSystem?.rawCost || 0 + secondSystem?.rawCost || 0,
                rawDemand:
                  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                  firstSystem?.rawDemand || 0 + secondSystem?.rawDemand || 0,
              };
            }),
          };
        })
        .filter(Boolean),
    };
  });
};

const combineChildBillSummaryResults = (
  childJobResults: Array<{
    published: RawSummarizedSimulatedUtilityBills[];
    unpublished: RawSummarizedSimulatedUtilityBills[];
    queuedNewSet: RawSummarizedSimulatedUtilityBills[];
  }>
): {
  published: RawSummarizedSimulatedUtilityBills[];
  unpublished: RawSummarizedSimulatedUtilityBills[];
  queuedNewSet: RawSummarizedSimulatedUtilityBills[];
} => {
  if (childJobResults.length !== 2)
    return { published: [], unpublished: [], queuedNewSet: [] };
  return {
    published: combineChildBillSummaries(
      childJobResults[0]!.published,
      childJobResults[1]!.published
    ),
    unpublished: combineChildBillSummaries(
      childJobResults[0]!.unpublished,
      childJobResults[1]!.unpublished
    ),
    queuedNewSet: combineChildBillSummaries(
      childJobResults[0]!.queuedNewSet,
      childJobResults[1]!.queuedNewSet
    ),
  };
};

const getSimulatedUtilityBillsSummary = async (
  siteId: string | number,
  childSites: Array<ChildSiteData>,
  versionId?: string | number,
  recordType?: "version" | "state"
): Promise<{
  published: RawSummarizedSimulatedUtilityBills[];
  unpublished: RawSummarizedSimulatedUtilityBills[];
  queuedNewSet: RawSummarizedSimulatedUtilityBills[];
}> => {
  if (childSites.length) {
    const childJobResults = await Promise.all(
      childSites.map((childSite) =>
        getSingleSiteSimulatedUtilityBillsSummary(
          childSite.id,
          versionId,
          recordType
        )
      )
    );
    return combineChildBillSummaryResults(childJobResults);
  } else {
    return getSingleSiteSimulatedUtilityBillsSummary(
      siteId,
      versionId,
      recordType
    );
  }
};

const getSiteUsesOldCalErrors = async (
  siteId: string | number
): Promise<boolean> => {
  // @ts-expect-error - TS2322 - Type 'boolean | void' is not assignable to type 'boolean'.
  return request
    .get(`/rest/sites_that_use_old_calibration_errors`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}` })
    .then(({ body }) => body.length > 0)
    .catch(bugsnagPostgrestErrorHandler);
};

const setSiteUsesOldCalErrors = async (
  siteId: string | number,
  bool: boolean
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return bool
    ? request
        .post(`/rest/sites_that_use_old_calibration_errors`)
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .send({ site_id: siteId })
        .query({ site_id: `eq.${siteId}` })
        .catch(bugsnagPostgrestErrorHandler)
    : request
        .delete(`/rest/sites_that_use_old_calibration_errors`)
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .query({ site_id: `eq.${siteId}` })
        .catch(bugsnagPostgrestErrorHandler);
};

const getParsedDatesForBills = (
  siteId: number,
  utility: Utility,
  bills: BillToParse[]
): Promise<{
  parsedDatesToCreate: RawUtilityBillParsedDate[];
  billMapping: BillToParse &
    {
      month: MonthString;
      parsedDateId?: number;
    }[];
}> =>
  request
    .post_safe("/api/parsed_dates/")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      site_id: siteId,
      utility: snakeCase(utility),
      bills: recursiveSnakeCaseCipher(bills),
    })
    .then((res) => recursiveCamelCaseCipher(res.body));

const getIsolatedParsedDates = async (
  bills: BillToParse[],
  utility: Utility
): Promise<{
  parsedDatesToCreate: RawUtilityBillParsedDate[];
  billMapping: ParsedESGBill[];
}> => {
  return await request
    .post_safe("/api/parsed_dates/isolated")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ bills: recursiveSnakeCaseCipher(bills) })
    .then(({ body }) => ({
      parsedDatesToCreate: recursiveCamelCaseCipher(
        body.parsed_dates_to_create
      ),
      // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
      billMapping: recursiveCamelCaseCipher(body.bill_mapping).map((bill) => ({
        ...bill,
        utility,
      })),
    }));
};

const getParsedMonthEdit = (
  siteId: number,
  utility: Utility,
  monthEdits: Array<{
    billId: number;
    month: MonthString | null;
    manualParsedMonth: boolean;
  }>
): Promise<{
  newBillMonthMapping: {
    [billId: number]: string;
  };
  newParsedDates: Array<UtilityBillParsedDates>;
}> =>
  request
    .post("/api/parsed_dates/month_edit")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      site_id: siteId,
      utility: snakeCase(utility),
      monthEdits: recursiveSnakeCaseCipher(monthEdits),
    })
    .then((res) => recursiveCamelCaseCipher(res.body));

const getESGBillsWithParsedMonths = async (
  billsByUtility: Partial<Record<Utility, Array<ESGBillToParse>>>
): Promise<Partial<Record<MonthString, ParsedESGBill[]>>> => {
  const isEsgDemo = localStorage.getItem("esgDemo");
  if (isEsgDemo) return {};

  const output: Record<string, any> = {};

  for (let utilityAndBills of Object.entries(billsByUtility)) {
    // @ts-expect-error - TS2322 - Type '[string, ESGBillToParse[]]' is not assignable to type '[Utility, ESGBillToParse[]]'.
    const [utility, mappedBills]: [Utility, ESGBillToParse[]] = utilityAndBills;

    // totalSpend is used by the parsed dates logic purely as a measure of the relative significance of related bills,
    // therefore its reasonable in this case to use totalConsumption as a direct subsitute since not all ESGBills
    // have a value for spend
    const mapConsumptionToSpend: BillToParse[] = mappedBills.map(
      ({ totalConsumption, ...mb }) => ({
        ...mb,
        totalSpend: totalConsumption,
      })
    );
    const { billMapping } = await getIsolatedParsedDates(
      mapConsumptionToSpend,
      utility
    );

    billMapping.forEach((bill) => {
      if (output[bill.month] == null) output[bill.month] = [];
      output[bill.month].push(bill);
    });
  }

  return output;
};

const getSiteIntegrationStatus = async (
  siteId: string | number
): Promise<IntegrationStatusType[]> => {
  return request
    .get(`/rest/integration_status`)
    .query({ site_id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => body)
    .catch(bugsnagPostgrestErrorHandler);
};

const querySiteDataStreams = async ({
  siteId,
  filter: { query, types, statuses },
  offset,
  limit,
}: {
  siteId: string | number;
  filter: {
    query?: string;
    types: Array<any>;
    statuses: Array<any>;
  };
  offset: number;
  limit: number;
}): Promise<DataStreamSummary[]> => {
  let params: any = {
    site_id: `eq.${siteId}`,
    stream_type: `in.(${types.map((e) => `"${e}"`).join(",")})`,
    offset,
    limit,
  };

  if (!query) {
    params["order"] = "last_heard_from_at.desc.nullslast";
  } else {
    params["bractlet_name"] = `fts.${query}`; // full-text search
  }

  if (statuses.length === 1) {
    if (statuses.includes("online")) {
      params["is_online"] = "eq.true";
    }
    if (statuses.includes("offline")) {
      params["is_online"] = "eq.false";
    }
  }

  return request
    .get(`/rest/bep_data_streams_summary`)
    .query(params)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => body)
    .catch(bugsnagPostgrestErrorHandler);
};

const hasBractletId = (dataStream: any): boolean =>
  dataStream.bractlet_id !== null;

const searchSiteDataStreams = (
  siteId: number,
  types: DataStreamTypes[]
): Promise<Array<DataStreamSummary[]>> => {
  return Promise.all(
    types.map((type) => {
      return request
        .get(`/rest/bep_data_streams_summary`)
        .query({ site_id: `eq.${siteId}`, stream_type: `eq.${type}` })
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .then(({ body }) => R.filter(hasBractletId, body))
        .catch(function (error) {
          bugsnagPostgrestErrorHandler(error);
          return [];
        });
    })
  );
};

export {
  getUtilityMetersWithBills,
  hasUtilityBills,
  updateUtilityBill,
  saveUtilityBill,
  deleteUtilityBill,
  saveUtilityRateStructures,
  updateUtilityRateStructure,
  deleteUtilityRateStructures,
  getUtilityBillParsedDates,
  updateUtilityBillParsedDatesById,
  saveUtilityBillParsedDates,
  deleteUtilityBillParsedDates,
  getUtilityProviders,
  saveUtilityProvider,
  getMostRecentMonthWithUtilities,
  getUtilitiesMonthlySummary,
  getUtilitiesSummaryByMonth,
  getBlendedRate,
  saveUtilityMeter,
  uploadUtilityBillAttachment,
  postSimulatedUtilityParsingJob,
  deleteSimulatedUtilityBills,
  deleteSimulatedUtilityBillsByParsedDateIds,
  deleteSimulatedUtilityBillsByUtility,
  deleteSimulatedUtilityBillsByRecordId,
  publishSimulatedUtilityBills,
  getSimulatedUtilityParsingJobs,
  getHyperSpaceJob,
  getHyperSpaceJobs,
  getHyperSpaceOutputs,
  getUtilityCalibrationResults,
  saveVersionUtilityCalibrationResults,
  updateVersionUtilityCalibrationResults,
  deleteVersionUtilityCalibrationResults,
  getParsedUtilityBills,
  setParsedDateOutlier,
  getLinkedHyperspaceJobsForCombinedSite,
  addEquipment,
  updateEquipment,
  deleteEquipment,
  getNestedUtilityDeviationAlerts,
  getUtilityUsageByMonthOfYear,
  getExistingMeters,
  // getExpectedMeters,
  updateMeterActivationStatus,
  updateUtilityDeviationAlert,
  getUtilityDeviationAlertConditions,
  createUtilityDeviationAlertCondition,
  updateUtilityDeviationAlertCondition,
  deleteUtilityDeviationAlertCondition,
  getParsedDateUtilityData,
  getSimulatedUtilityBillsSummary,
  getSiteUsesOldCalErrors,
  setSiteUsesOldCalErrors,
  getVersionUtilityRate,
  getRawSimulatedBills,
  updateSimulatedUtilityBill,
  getParsedDatesForBills,
  getIsolatedParsedDates,
  getParsedMonthEdit,
  getESGBillsWithParsedMonths,
  getSiteEarliestParsedDates,
  getSiteIntegrationStatus,
  querySiteDataStreams,
  searchSiteDataStreams,
};
