import * as React from "react";
import type { User } from "../types";

export type UserContextType = {
  currentUser?: User | null;
  refreshCurrentUser: () => Promise<void>;
};

const initialContext: UserContextType = {
  refreshCurrentUser: async () => {},
  currentUser: null,
};

const UserContext: React.Context<UserContextType> =
  React.createContext(initialContext);

export default UserContext;

export const useCurrentUser = () => {
  const { currentUser, refreshCurrentUser } = React.useContext(UserContext);
  return { ...currentUser, refreshCurrentUser } as const;
};
