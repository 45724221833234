import { cloneDeep } from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";
import * as R from "ramda";

import {
  inboundEnergyStarUnitCipher,
  inboundUnitCipher,
} from "../context/UnitsContext";
import type {
  AllUtilityCategories,
  AccountSummary,
  BuildingUtility,
  Category,
  EnergySavingsDisplayType,
  EnergyStarMeterType,
  EnergyStarUtilityUnit,
  EnergyStarWasteUnit,
  EnergyUnit,
  EnergyUnitWithDemand,
  EnergyUtility,
  EnergyUtilityCategory,
  ParsedEnergyBills,
  ParsedEnergyUtilityBill,
  RawSummarizedSimulatedUtilityBill,
  RawSummarizedSimulatedUtilityBills,
  SelectOption,
  System,
  SystemCategory,
  TopLevelUtility,
  TopLevelUtilityCategory,
  Utility,
  UtilityCalibrationAccuracyWithDates,
  UtilityUnit,
  WasteUnit,
  WasteUtility,
  WasteUtilityCategory,
  WaterUnit,
  WaterUtility,
  WaterUtilityCategory,
} from "../types";

import { roundToDigit } from "./util.js";
import {
  formatIncompletePhoneNumber,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";

const moment = extendMoment(Moment);

const unitTitles: Partial<Record<EnergyUnitWithDemand, string>> = {
  J: "Joules",
  thm: "Therms",
  MBTU: "MBTU / MMBTU",
  "ton-hour": "Ton Hour",
};

export const energyUnitTitle = (energyUnit: EnergyUnitWithDemand): string =>
  unitTitles[energyUnit] || energyUnit;

export const sortedTopLevelUtilities: Array<TopLevelUtilityCategory> = [
  {
    id: "energy",
    title: "Energy",
    icon: "Energy",
    color: "#00cc8f",
    units: ["kWh", "GJ", "kBTU"],
    defaultUnit: "kWh",
  },
  {
    id: "water",
    title: "Water",
    icon: "WaterFaucet",
    color: "#57d2f2",
    units: ["gal", "CCF", "cGal", "kGal", "m3"],
    defaultUnit: "gal",
  },
  {
    id: "waste",
    title: "Waste",
    icon: "Waste",
    color: "#788ac3",
    units: ["yd3", "ton", "tonnes"],
    defaultUnit: "yd3",
  },
];

export const energyUtilities: Array<EnergyUtility> = [
  "electricity",
  "naturalGas",
  "districtHeating",
  "districtCooling",
];

export const waterUtilities: Array<WaterUtility> = ["water", "wasteWater"];
export const wasteUtilities: Array<WasteUtility> = ["waste" /* "recycling" */];

export const utilities = [
  ...energyUtilities,
  ...waterUtilities,
  ...wasteUtilities,
] as const;

export const getTopLevelUtility = (utility: Utility): TopLevelUtility =>
  // @ts-expect-error - TS2345 - Argument of type 'Utility' is not assignable to parameter of type 'EnergyUtility'.
  energyUtilities.includes(utility)
    ? "energy"
    : // @ts-expect-error - TS2345 - Argument of type 'Utility' is not assignable to parameter of type 'WaterUtility'.
    waterUtilities.includes(utility)
    ? "water"
    : "waste";

export const sortedEnergyUtilities: Array<EnergyUtilityCategory> = [
  {
    id: "electricity",
    title: "Electricity",
    topLevelUtility: "energy",
    icon: "LightBulb",
    color: "#ffd630",
    colorName: "yellow",
    units: ["kWh", "kBTU", "GJ"],
    defaultUnit: "kWh",
  },
  {
    id: "naturalGas",
    title: "Natural Gas",
    topLevelUtility: "energy",
    icon: "Flame",
    color: "#ff7f3d",
    colorName: "orange-gas",
    units: [
      "kWh",
      "thm",
      "MBTU",
      "CCF",
      "GJ",
      "kBTU",
      "KCF",
      "MCF (million cubic feet)",
    ],
    defaultUnit: "kWh",
  },
  {
    id: "districtCooling",
    title: "District Cooling",
    topLevelUtility: "energy",
    icon: "WaterDroplet",
    color: "#416ce9",
    colorName: "blue",
    units: ["kWh", "ton-hour", "GJ", "kBTU"],
    defaultUnit: "kWh",
  },
  {
    id: "districtHeating",
    title: "District Heating",
    topLevelUtility: "energy",
    icon: "WaterDroplet",
    color: "#fb4e77",
    colorName: "red",
    units: ["kWh", "ton-hour", "GJ", "kBTU", "kLb"],
    defaultUnit: "kWh",
  },
];

export const sortedWaterUtilities: Array<WaterUtilityCategory> = [
  {
    id: "water",
    title: "Water",
    icon: "WaterFaucet",
    topLevelUtility: "water",
    color: "#57d2f2",
    colorName: "bright-light-blue",
    units: ["gal", "CCF", "cGal", "kGal"],
    defaultUnit: "gal",
  },
  {
    id: "wasteWater",
    title: "Waste Water",
    topLevelUtility: "water",
    icon: "WaterFaucet",
    color: "#ff7f3d",
    colorName: "orange-gas",
    units: ["gal", "CCF", "cGal", "kGal"],
    defaultUnit: "gal",
  },
];

export const sortedWasteUtilities: Array<WasteUtilityCategory> = [
  {
    id: "waste",
    title: "Waste",
    topLevelUtility: "waste",
    icon: "Waste",
    color: "#57d2f2",
    colorName: "bright-light-blue",
    units: ["yd3", "ton"],
    defaultUnit: "yd3",
  },
  // {
  //   id: "recycling",
  //   title: "Recycling",
  //   topLevelUtility: "waste",
  //   icon: "Waste",
  //   color: "#ff7f3d",
  //   units: ["yd3", "ton"],
  // },
];

export const sortedAllUtilities: Array<AllUtilityCategories> = [
  ...sortedEnergyUtilities,
  ...sortedWaterUtilities,
  ...sortedWasteUtilities,
];

export const energyUtilitiesById: Partial<
  Record<EnergyUtility, EnergyUtilityCategory>
> = R.indexBy(R.prop("id"), sortedEnergyUtilities);

export const topLevelUtilitiesById: Partial<
  Record<TopLevelUtility, TopLevelUtilityCategory>
> = R.indexBy(R.prop("id"), sortedTopLevelUtilities);

export const topLevelUtilitiesDefaultUnits: Partial<
  Record<TopLevelUtility, UtilityUnit>
> = R.map(R.prop("defaultUnit"), topLevelUtilitiesById);

export const allUtilitiesById: Record<Utility, AllUtilityCategories> =
  R.indexBy(R.prop("id"), [
    ...sortedEnergyUtilities,
    ...sortedWaterUtilities,
    ...sortedWasteUtilities,
  ]);

export const unitsForEnergyUtility = (
  utility: "all" | EnergyUtility
): Array<EnergyUnit> =>
  utility === "all"
    ? ["kWh", "GJ", "kBTU"]
    : // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      energyUtilitiesById[utility].units;

export const unitsForUtility = (
  utility: Utility
): Array<EnergyUnit> | Array<WaterUnit> | Array<WasteUnit> =>
  allUtilitiesById[utility].units;

export const unitsForUtilityOrEnergyUnit = (
  utility: Utility
): Array<EnergyUnit> | Array<WaterUnit> | Array<WasteUnit> => {
  if (["energy", "water", "waste", "wasteWater"].includes(utility))
    return unitsForUtility(utility);
  // @ts-expect-error - TS2345 - Argument of type 'Utility' is not assignable to parameter of type 'EnergyUtility | "all"'.
  return unitsForEnergyUtility(utility);
};

export const getEnergyStarUnitsForMeterType = (
  utility: Utility,
  meterType: EnergyStarMeterType
): SelectOption<EnergyStarUtilityUnit | EnergyStarWasteUnit>[] => {
  // ): {
  //   id: EnergyStarUtilityUnit | EnergyStarWasteUnit,
  //   label: UtilityUnit,
  // }[] => {
  const unitsByUtility: Partial<
    Record<Utility, EnergyStarUtilityUnit | EnergyStarWasteUnit[]>
  > = {
    electricity: [
      // @ts-expect-error - TS2322 - Type '"kWh (thousand Watt-hours)"' is not assignable to type 'EnergyStarWasteUnit'.
      "kWh (thousand Watt-hours)",
      // @ts-expect-error - TS2322 - Type '"kBtu (thousand Btu)"' is not assignable to type 'EnergyStarWasteUnit'.
      "kBtu (thousand Btu)",
      /* not implemented yet
      "GJ",
      "MWh (million Watt-hours)",
      */
    ],
    naturalGas: [
      // @ts-expect-error - TS2322 - Type '"ccf (hundred cubic feet)"' is not assignable to type 'EnergyStarWasteUnit'.
      "ccf (hundred cubic feet)",
      // @ts-expect-error - TS2322 - Type '"kBtu (thousand Btu)"' is not assignable to type 'EnergyStarWasteUnit'.
      "kBtu (thousand Btu)",
      // @ts-expect-error - TS2322 - Type '"kcf (thousand cubic feet)"' is not assignable to type 'EnergyStarWasteUnit'.
      "kcf (thousand cubic feet)",
      // @ts-expect-error - TS2322 - Type '"MCF(million cubic feet)"' is not assignable to type 'EnergyStarWasteUnit'.
      "MCF(million cubic feet)",
      // @ts-expect-error - TS2322 - Type '"therms"' is not assignable to type 'EnergyStarWasteUnit'.
      "therms",
      /* not implemented yet
      "cf (cubic feet)",
      "cm (Cubic meters)",
      "GJ",
      "MWh (million Watt-hours)",
      */
    ],
    districtCooling: [
      // @ts-expect-error - TS2322 - Type '"ton hours"' is not assignable to type 'EnergyStarWasteUnit'.
      "ton hours",
      // @ts-expect-error - TS2322 - Type '"kBtu (thousand Btu)"' is not assignable to type 'EnergyStarWasteUnit'.
      "kBtu (thousand Btu)",
      /* not implemented yet
      "GJ",
      */
    ],
    water: [
      // @ts-expect-error - TS2322 - Type '"ccf (hundred cubic feet)"' is not assignable to type 'EnergyStarWasteUnit'.
      "ccf (hundred cubic feet)",
      // @ts-expect-error - TS2322 - Type '"Gallons (US)"' is not assignable to type 'EnergyStarWasteUnit'.
      "Gallons (US)",
      // @ts-expect-error - TS2322 - Type '"cGal (hundred gallons) (US)"' is not assignable to type 'EnergyStarWasteUnit'.
      "cGal (hundred gallons) (US)",
      // @ts-expect-error - TS2322 - Type '"KGal (thousand gallons) (US)"' is not assignable to type 'EnergyStarWasteUnit'.
      "KGal (thousand gallons) (US)",
      // @ts-expect-error - TS2322 - Type '"kcf (thousand cubic feet)"' is not assignable to type 'EnergyStarWasteUnit'.
      "kcf (thousand cubic feet)",
      // @ts-expect-error - TS2322 - Type '"MCF(million cubic feet)"' is not assignable to type 'EnergyStarWasteUnit'.
      "MCF(million cubic feet)",
      /* not implemented yet
      "cf (cubic feet)",
      "Gallons (UK)",
      "cGal (hundred gallons) (UK)",
      "cm (Cubic meters)",
      "Kcm (Thousand Cubic meters)",
      "Liters",
      "KGal (thousand gallons) (UK)",
      "MGal (million gallons) (UK)",
      "MGal (million gallons) (US)",
      */
    ],
    waste: [
      "Cubic yards",
      "tons",
      /* not implemented yet
      "cm (Cubic meters)",
      "pounds"
      "Kilogram"
      "Tonnes (metric)"
      "Gallons (US)"
      "Gallons (UK)"
      "Liters";
      */
    ],
  };

  const unitsByMeterType: Partial<
    Record<EnergyStarMeterType, EnergyStarUtilityUnit[]>
  > = {
    "District Hot Water": [
      "kBtu (thousand Btu)",
      "therms",
      /* not implemented yet
      "GJ",
      */
    ],
    "District Steam": [
      "kBtu (thousand Btu)",
      "KLbs. (thousand pounds)",
      "therms",
      /* not implemented yet
      "GJ",
      "kg (kilograms)"
      "Lbs. (pounds)",
      "MLbs. (million pounds)",
      */
    ],
  };

  const units = unitsByUtility[utility] || unitsByMeterType[meterType] || [];

  return (
    units
      // @ts-expect-error - TS2339 - Property 'map' does not exist on type 'EnergyStarUtilityUnit | EnergyStarWasteUnit[] | EnergyStarUtilityUnit[]'. | TS7006 - Parameter 'unit' implicitly has an 'any' type.
      .map((unit) =>
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<Record<EnergyStarUtilityUnit | EnergyStarWasteUnit, UtilityUnit>>'.
        inboundEnergyStarUnitCipher[unit] != null
          ? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<Record<EnergyStarUtilityUnit | EnergyStarWasteUnit, UtilityUnit>>'.
            { id: unit, label: inboundEnergyStarUnitCipher[unit] }
          : null
      )
      .filter(Boolean)
  );
};

export const sortedSystems: Array<SystemCategory> = [
  {
    id: "lighting",
    system: "lighting",
    title: "Lighting",
    icon: "LightBulb",
    color: "#ffd630",
  },
  {
    id: "equipment",
    system: "equipment",
    title: "Equipment",
    icon: "Computer",
    color: "#00cc8f",
  },
  {
    id: "fans",
    system: "fans",
    title: "Fans",
    icon: "Fan",
    color: "#42a3ff",
  },
  {
    id: "pumps",
    system: "pumps",
    title: "Pumps",
    icon: "Pump",
    color: "#886cff",
  },
  {
    id: "cooling",
    system: "cooling",
    title: "Cooling",
    icon: "WaterDroplet",
    color: "#416ce9",
  },
  {
    id: "heating",
    system: "heating",
    title: "Heating",
    icon: "WaterDroplet",
    color: "#fb4e77",
  },
  {
    id: "heat-rejection",
    system: "heat-rejection",
    title: "Heat Rejection",
    icon: "HeatRejection",
    color: "#ff7f95",
  },
  {
    id: "domestic-hot-water",
    system: "domestic-hot-water",
    title: "Domestic Hot Water",
    icon: "WaterFaucet",
    color: "#fb4e77",
  },
  {
    id: "other",
    system: "other",
    title: "Other",
    icon: "Require",
    color: "#b9c3e1",
  },
];

export const getSortedCategories = (
  utility: "all" | EnergyUtility,
  displayType: EnergySavingsDisplayType
): Array<Category> =>
  // @ts-expect-error - TS2322 - Type '{ id: EnergyUtility; utility: EnergyUtility; title: string; color: string; icon: string; units: EnergyUnit[]; system: "summary"; }[] | { id: System; ... 8 more ...; defaultUnit?: UtilityUnit | undefined; }[]' is not assignable to type 'Category[]'.
  displayType === "utilities"
    ? sortedEnergyUtilities.map(({ id, title, color, icon, units }) => ({
        id,
        utility: id,
        title,
        color,
        icon,
        units,
        system: "summary",
      }))
    : sortedSystems.map((systemCategory) => ({
        ...(utility === "all" ? {} : energyUtilitiesById[utility]),
        utility,
        ...systemCategory,
      }));

// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type '{ system: System; utility: EnergyUtility | "all"; }': system, utility
export const getUtilitySystemCategory = ({
  system,
  utility,
}: {
  system: System;
  utility: "all" | EnergyUtility;
} = {}): Category | null | undefined => {
  const utilityCategory = utility === "all" ? {} : energyUtilitiesById[utility];
  const systemCategory = systemsById[system];
  const category = {
    ...utilityCategory,
    ...systemCategory,
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2339 - Property 'id' does not exist on type '{}'.
    utility: utilityCategory.id,
  } as const;

  // @ts-expect-error - TS2322 - Type '{ readonly utility: any; readonly id: System; readonly system: System; readonly title: string; readonly icon?: string | undefined; readonly color: string; } | null' is not assignable to type 'Category | null | undefined'.
  return category.id ? category : null;
};

export const systemsById = R.indexBy(R.prop("id"), sortedSystems);

export const systemMapping = {
  // electricity
  "interiorlights:electricity (kWh)": "lighting",
  "exteriorlights:electricity (kWh)": "lighting",
  "interiorequipment:electricity (kWh)": "equipment",
  "exteriorequipment:electricity (kWh)": "equipment",
  "fans:electricity (kWh)": "fans",
  "pumps:electricity (kWh)": "pumps",
  "heating:electricity (kWh)": "heating",
  "cooling:electricity (kWh)": "cooling",
  "heatrejection:electricity (kWh)": "heat-rejection",
  "humidifier:electricity (kWh)": "other",
  "heatrecovery:electricity (kWh)": "cooling",
  "watersystems:electricity (kWh)": "domestic-hot-water",
  "cogeneration:electricity (kWh)": "other",

  // naturalGas
  "cooking:interiorequipment:gas (kWh)": "equipment",
  "interiorequipment:gas (kWh)": "equipment",
  "exteriorequipment:gas (kWh)": "equipment",
  "heating:gas (kWh)": "heating",
  "cooling:gas (kWh)": "cooling",
  "watersystems:gas (kWh)": "domestic-hot-water",
  "cogeneration:gas (kWh)": "other",
  "humidifier:gas (kWh)": "other",

  // districtCooling
  "districtcooling:facility (kWh)": "cooling",

  // districtHeating
  "districtheating:facility (kWh)": "heating",
} as const;

export const cleanUtilityBills = (
  buildingUtilities: Partial<Record<EnergyUtility, BuildingUtility>>
): Array<ParsedEnergyBills> =>
  R.toPairs(buildingUtilities)
    .filter(
      ([utility, buildingUtility]: [any, any]) => buildingUtility.bills.length
    )
    .map(([utility, buildingUtility]: [any, any]): any => {
      const data = R.toPairs(
        // @ts-expect-error - TS2345 - Argument of type '<T>(value: T) => Prop<T, "parsedDateId">' is not assignable to parameter of type '(a: unknown) => string'.
        R.groupBy(R.prop("parsedDateId"), buildingUtility.bills)
      )
        .filter(
          ([parsedDateId, bills]: [any, any]) => bills.filter(Boolean).length
        )
        .map(([parsedDateId, bills]: [any, any]) => {
          const totalCost = bills
            // @ts-expect-error - TS7006 - Parameter 'b' implicitly has an 'any' type.
            .map((b) => b.totalSpend)
            // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type. | TS7006 - Parameter 'b' implicitly has an 'any' type.
            .reduce((a, b) => a + (b || 0), 0);
          const totalConsumption = bills
            // @ts-expect-error - TS7006 - Parameter 'b' implicitly has an 'any' type.
            .map((b) => b.totalConsumption)
            // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type. | TS7006 - Parameter 'b' implicitly has an 'any' type.
            .reduce((a, b) => a + (b || 0), 0);
          const totalDemand = bills
            // @ts-expect-error - TS7006 - Parameter 'b' implicitly has an 'any' type.
            .map((b) => b.totalDemand)
            // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type. | TS7006 - Parameter 'b' implicitly has an 'any' type.
            .reduce((a, b) => a + (b || 0), 0);
          return {
            parsedDateId: Number(parsedDateId),
            startDate: bills[0].startDate,
            endDate: bills[0].endDate,
            month: bills[0].month,
            cost: totalCost,
            totalConsumption,
            totalDemand,
            utilityRate: totalCost / totalConsumption,
            unit:
              inboundUnitCipher[buildingUtility.unit] || buildingUtility.unit,
            unitId: buildingUtility.unitId,
          };
        });

      return { utility, data };
    });

const generateAllObsUtilityBills = (
  cleanedBills: Array<ParsedEnergyBills>
): ParsedEnergyBills => {
  // @ts-expect-error - TS2349 - This expression is not callable. | TS2345 - Argument of type '<T>(value: T) => Prop<T, "month">' is not assignable to parameter of type '(a: T) => string'.
  const allBillsByMonth = R.groupBy(R.prop("month"))(
    cleanedBills.flatMap(R.prop("data"))
  );
  const allBills: Array<ParsedEnergyUtilityBill> = [];

  R.toPairs(allBillsByMonth).forEach(([month, billsPerMonth]: [any, any]) => {
    let totalCost = 0;
    let totalConsumption = 0;
    // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
    billsPerMonth.forEach((bill) => {
      totalCost += bill.cost;
      totalConsumption += bill.totalConsumption;
    });

    allBills.push({
      month,
      cost: totalCost,
      totalConsumption,
      utilityRate: totalConsumption / totalCost,
      startDate: null,
      endDate: null,
      parsedDateId: null,
      unit: "kWh",
      unitId: 21,
      published: true,
      subsystem: null,
      // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
      isOutlier: billsPerMonth.some((bill) => bill.isOutlier),
    });
  });
  return {
    utility: "all",
    // @ts-expect-error - TS2322 - Type '{ utility: "all"; unit: string; unitId: number; data: ParsedEnergyUtilityBill[]; }' is not assignable to type 'ParsedEnergyBills'.
    unit: "kWh",
    unitId: 21,
    data: R.sortBy(R.prop("month"), allBills),
  };
};

export const appendAllObsUtilityBills = (
  cleanedBills: Array<ParsedEnergyBills>
): Array<ParsedEnergyBills> => {
  return [...cleanedBills, generateAllObsUtilityBills(cleanedBills)];
};

export const calculateUtilityCalibrationErrors = (
  observedBills: Array<ParsedEnergyBills>,
  simulatedBills: RawSummarizedSimulatedUtilityBills[],
  dateRange?: {
    // @ts-expect-error - TS2709 - Cannot use namespace 'Moment' as a type.
    startDate: Moment;
    // @ts-expect-error - TS2709 - Cannot use namespace 'Moment' as a type.
    endDate: Moment;
  }
): Array<{
  utility: "all" | EnergyUtility;
  nmbe: number | null | undefined;
  cvrmse: number | null | undefined;
}> => {
  // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
  const simulatedBillFilter = (bill) => dateRange == null || !bill.isOutlier;
  const dateFilterFunction =
    dateRange == null
      ? // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
        (bill) => true
      : // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
        (bill) =>
          bill.startDate && bill.endDate
            ? moment(bill.startDate).isSameOrAfter(
                dateRange.startDate,
                "day"
              ) && moment(bill.endDate).isSameOrBefore(dateRange.endDate, "day")
            : moment(bill.month).isSameOrAfter(dateRange.startDate, "month") &&
              moment(bill.month).isSameOrBefore(dateRange.endDate, "month");

  const uncalibratedUtilities =
    dateRange == null
      ? []
      : simulatedBills
          .filter(({ utility }) => utility !== "all")
          .filter(({ bills }) =>
            bills.filter(dateFilterFunction).every((d) => d.isOutlier)
          )
          .map(({ utility }) => utility);

  const observedAll = uncalibratedUtilities.length
    ? generateAllObsUtilityBills(
        observedBills.filter(
          ({ utility }) =>
            !uncalibratedUtilities.includes(utility) && utility !== "all"
        )
      )
    : observedBills.find(({ utility }) => utility === "all");

  const nonAllSimulatedUtilities = simulatedBills
    .filter(
      ({ utility }) =>
        !uncalibratedUtilities.includes(utility) && utility !== "all"
    )
    .map((simUtil) => ({
      ...simUtil,
      bills: simUtil.bills.filter(
        (d) => simulatedBillFilter(d) && dateFilterFunction(d)
      ),
    }));

  const filteredSimulatedBills = uncalibratedUtilities.length
    ? getSimulatedUtilitiesRawSums(nonAllSimulatedUtilities)
    : simulatedBills.map((simUtil) => ({
        ...simUtil,
        bills: simUtil.bills.filter(
          (d) => simulatedBillFilter(d) && dateFilterFunction(d)
        ),
      }));

  const errors = observedBills.map(({ utility, data }) => {
    const simBills = filteredSimulatedBills.find(
      (sb) => sb.utility === utility
    );
    if (simBills == null) return null;

    const simMonths =
      utility !== "all"
        ? simBills.bills
        : simBills.bills.filter((bill) =>
            nonAllSimulatedUtilities.every((util) =>
              util.bills.some((d) => d.month === bill.month)
            )
          );

    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    const observedData = utility === "all" ? observedAll.data : data;

    const obsConsumption: Array<number> = [];
    const simConsumption: Array<number> = [];
    observedData.filter(dateFilterFunction).forEach((o) => {
      const sim = simMonths.find((s) => s.month === o.month);
      if (sim == null) return;
      const simBillConsumption = sim.systems.find(
        (system) => system.system == null
      );

      if (simBillConsumption != null) {
        obsConsumption.push(o.totalConsumption);
        simConsumption.push(simBillConsumption.rawConsumption);
      }
    });

    if (obsConsumption.length < 1 || simConsumption.length < 1)
      return {
        utility,
        nmbe: null,
        cvrmse: null,
      };
    const obsSum = obsConsumption.reduce((a, b) => a + b, 0);
    const simSum = simConsumption.reduce((a, b) => a + b, 0);
    const rmse = Math.sqrt(
      obsConsumption
        .map((val, index) => (val - simConsumption[index]!) ** 2)
        .reduce((a, b) => a + b, 0) / obsConsumption.length
    );
    return {
      utility,
      nmbe: roundToDigit((100 * (obsSum - simSum)) / obsSum, 4),
      cvrmse: roundToDigit(100 * ((rmse * obsConsumption.length) / obsSum), 2),
    };
  });
  // @ts-expect-error - TS2322 - Type '({ utility: EnergyUtility | "all"; nmbe: null; cvrmse: null; } | { utility: EnergyUtility | "all"; nmbe: number; cvrmse: number; } | null)[]' is not assignable to type '{ utility: EnergyUtility | "all"; nmbe: number | null | undefined; cvrmse: number | null | undefined; }[]'.
  return errors.filter(Boolean);
};

export const selectUtilityCalibrationResults = (
  versionId: number,
  endDate: moment.Moment,
  utilityCalibrationResults: Array<UtilityCalibrationAccuracyWithDates>
): Array<UtilityCalibrationAccuracyWithDates> => {
  if (utilityCalibrationResults.length === 0) return [];
  const versionIdMatches = utilityCalibrationResults.filter(
    (cR) => cR.versionId === versionId
  );
  if (versionIdMatches.length) return versionIdMatches;

  const earlierCalResults = utilityCalibrationResults.filter((cR) =>
    cR.utilityCalEndDate.isBefore(endDate)
  );

  const lastEndDate = moment.max(
    earlierCalResults.map((cR) => cR.utilityCalEndDate)
  );
  return earlierCalResults.filter((cR) =>
    cR.utilityCalEndDate.isSame(lastEndDate)
  );
};

export const getSimulatedUtilitiesRawSums = (
  simulatedUtilities: Array<RawSummarizedSimulatedUtilityBills>
): Array<RawSummarizedSimulatedUtilityBills> => {
  if (simulatedUtilities.length === 0) return [];

  const data: RawSummarizedSimulatedUtilityBills[] = simulatedUtilities.map(
    (util) => ({
      utility: util.utility,
      bills: R.sortBy(R.prop("month"))(
        util.bills.map((bill) => {
          const subsystems = bill.systems.filter((system) => system != null);
          const rawConsumption = R.sum(
            subsystems.map((system) => system.rawConsumption)
          );
          const rawCost = R.sum(subsystems.map((system) => system.rawCost));
          const rawDemand = R.sum(subsystems.map((system) => system.rawDemand));
          const rawRate = rawConsumption > 0 ? rawCost / rawConsumption : 0;

          return {
            ...bill,
            systems: [
              ...bill.systems,
              {
                system: null,
                rawConsumption,
                rawCost,
                rawDemand,
                rawRate,
              },
            ],
          };
        })
      ),
    })
  );

  const flattenedBills = data.flatMap(({ bills }) => bills);
  const allMonths = R.uniq(flattenedBills.map(({ month }) => month)).sort();

  const allBills = allMonths.map((month) => {
    const billsForMonth: RawSummarizedSimulatedUtilityBill[] =
      flattenedBills.filter((bill) => bill.month === month);

    const systemsForMonth = billsForMonth.flatMap(({ systems }) => systems);

    const allSystems = R.uniq(
      systemsForMonth.flatMap(({ system }) => system)
    ).filter(Boolean);

    const systems = allSystems.map((systemName) => {
      const billsForSystem = systemsForMonth.filter(
        (system) => system.system === systemName
      );

      const rawConsumption = R.sum(
        billsForSystem.map((bill) => bill.rawConsumption)
      );
      const rawCost = R.sum(billsForSystem.map((bill) => bill.rawCost));
      const rawDemand = R.sum(billsForSystem.map((bill) => bill.rawDemand));

      return {
        system: systemName,
        rawConsumption,
        rawCost,
        rawDemand,
      };
    });

    const sumOfSystems = {
      system: null,
      rawConsumption: 0,
      rawCost: 0,
      rawDemand: 0,
    } as const;
    billsForMonth.forEach((bill) => {
      bill.systems
        .filter((system) => system.system != null)
        .forEach((system) => {
          // @ts-expect-error - TS2540 - Cannot assign to 'rawConsumption' because it is a read-only property.
          sumOfSystems.rawConsumption += system.rawConsumption;
          // @ts-expect-error - TS2540 - Cannot assign to 'rawCost' because it is a read-only property.
          sumOfSystems.rawCost += system.rawCost;
          // @ts-expect-error - TS2540 - Cannot assign to 'rawDemand' because it is a read-only property.
          sumOfSystems.rawDemand += system.rawDemand;
        });
    });

    return {
      month,
      utility: "all",
      unitId: 21,
      unit: "kWh",
      startDate: null,
      endDate: null,
      startVersionId: billsForMonth.map(R.prop("startVersionId")).find(Boolean),
      endVersionId: billsForMonth.map(R.prop("endVersionId")).find(Boolean),
      heatingDegreeDays: billsForMonth[0]!.heatingDegreeDays,
      coolingDegreeDays: billsForMonth[0]!.coolingDegreeDays,
      isOutlier: billsForMonth.some((bill) => bill.isOutlier),
      published: billsForMonth.every((bill) => bill.published),
      systems: [...systems, sumOfSystems],
    };
  });

  // @ts-expect-error - TS2322 - Type 'RawSummarizedSimulatedUtilityBills | { utility: "all"; bills: { month: string; utility: string; unitId: number; unit: string; startDate: null; endDate: null; ... 6 more ...; systems: { ...; }[]; }[]; }' is not assignable to type 'RawSummarizedSimulatedUtilityBills'. | TS2322 - Type '{ month: string; utility: string; unitId: number; unit: string; startDate: null; endDate: null; startVersionId: undefined; endVersionId: undefined; heatingDegreeDays: number; coolingDegreeDays: number; isOutlier: boolean; published: boolean; systems: { ...; }[]; }[]' is not assignable to type 'RawSummarizedSimulatedUtilityBill[]'.
  return [...data, { utility: "all", bills: allBills }];
};

export const utilityIconMap = {
  energy: { icon: "Energy", color: "green" },
  electricity: { icon: "LightBulb", color: "yellow" },
  naturalGas: { icon: "Flame", color: "orange" },
  districtCooling: { icon: "WaterDroplet", color: "blue" },
  districtHeating: { icon: "WaterDroplet", color: "red" },
  water: { icon: "WaterFaucet", color: "blue" },
  wasteWater: { icon: "WaterFaucet", color: "blue-waste" },
  waste: { icon: "Waste", color: "blue-waste" },
} as const;

export const getBuildings = (
  sites: AccountSummary["sites"],
  blackListIDs: any[]
): AccountSummary["sites"] => {
  const buildings = cloneDeep(sites).filter(
    (item) => !blackListIDs.includes(item.id)
  );
  return buildings || [];
};

export const isValidEmail = (val: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    val
  );
};

export const parseRawNumbers = (value: string): number => {
  return parseInt(value.replace(/[^\d]/g, ""));
};

export const formatLocalStringInput = (value: string): string | null => {
  let parsedNum = parseRawNumbers(value);
  if (isNaN(parsedNum)) return null;

  return parsedNum.toLocaleString();
};

export const parseInteger = (value: string) => {
  const parsed = parseRawNumbers(value);
  if (Number.isNaN(parsed)) {
    return "";
  }
  return parsed;
};

export const formatNumberForSubmit = (value?: number | string | null) => {
  if (value) {
    return typeof value === "number" ? value : parseRawNumbers(value);
  }
  return null;
};

export const isValidPhoneNumber = (value?: string): boolean => {
  return Boolean(value) && isPossiblePhoneNumber(value || "", "US");
};

export const getPhoneNumberSubmissionValue = (
  value?: string
): string | null => {
  let submissionValue;
  try {
    submissionValue = parsePhoneNumber(value || "", "US").nationalNumber;
  } catch (e) {
    submissionValue = null;
  }
  return submissionValue;
};

export const formatPhoneNumber = (value?: string): string => {
  // could also use the parsePhoneNumer function from libphonenumber-js
  // but that function throws an error if the value is not a possible phone number
  // the below function handles the event that a bad number is somehow saved in the DB
  return formatIncompletePhoneNumber(value || "", "US");
};
