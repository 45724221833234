import { FIELD_ID_VALUES } from "../../../backpack-console/pages/Overview/types";

export type LedgerEntities = "sources" | "change-log" | "data-history";

export const ledgerKeys = {
  all: (siteId: number) => ["ledger", siteId] as const,
  field: (siteId: number, fieldId?: FIELD_ID_VALUES) =>
    [...ledgerKeys.all(siteId), fieldId] as const,
  entity: (
    siteId: number,
    fieldId?: FIELD_ID_VALUES,
    entity?: LedgerEntities
  ) => [...ledgerKeys.field(siteId, fieldId), entity] as const,
};
