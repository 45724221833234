import { useState } from "react";
import Modal from "@src/straps/base/dialogs/Modal/Modal";
import Radio from "@src/straps/base/inputs/Radio/Radio";
import RadioGroup from "@src/straps/base/inputs/RadioGroup/RadioGroup";
import { Icon, Text } from "@src/straps/base";
import { categorizeDesignDoc } from "./DocumentsFilesApi";
import { DesignDocCompletenessCategory as DocumentCategory } from "@src/types";
import { ExtendedSemanticColors, Include } from "@src/straps/colors";

export type DocumentsCategoryModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: (arg1: ((arg1: boolean) => boolean) | boolean) => void;
  readonly documentIds: number[];
  readonly refreshDesignDocs: (...rest: any) => Promise<any>;
};

type DocumentsCategoryOption = {
  id: DocumentCategory;
  label: Capitalize<DocumentCategory>;
  strapsColor?: Include<
    ExtendedSemanticColors,
    "brand" | "primary" | "warning-hover" | "accent-1"
  >;
  hexColor?: `#${string}`;
};

const documentsCategoryOptions: DocumentsCategoryOption[] = [
  { id: "structural", label: "Structural", strapsColor: "brand" },
  { id: "mechanical", label: "Mechanical", strapsColor: "primary" },
  { id: "electrical", label: "Electrical", hexColor: "#42A3FF" }, // only category without semantic color
  { id: "architectural", label: "Architectural", strapsColor: "warning-hover" },
  { id: "plumbing", label: "Plumbing", strapsColor: "accent-1" },
];

export const EQUIPMENT_CATEGORY_ID = "equipment" as const;
const equipmentOption = {
  id: EQUIPMENT_CATEGORY_ID,
  label: "Equipment",
  strapsColor: "primary",
  hexColor: undefined,
} as const;

export const documentsCategoryColorMap = [
  ...documentsCategoryOptions,
  equipmentOption,
].reduce<
  Partial<
    Record<
      DocumentsCategoryOption["id"] | "equipment",
      | Omit<DocumentsCategoryOption, "id">
      | {
          label: "Equipment";
          strapsColor: "primary";
          hexColor: undefined;
        }
    >
  >
>((acc, val) => {
  const { id, ...rest } = val;
  acc[id] = {
    ...rest,
  };
  return acc;
}, {});

// @todo peter add tests

function DocumentsCategoryModal({
  isOpen,
  setIsOpen,
  documentIds,
  refreshDesignDocs,
}: DocumentsCategoryModalProps) {
  const [value, setValue] = useState<DocumentCategory>();

  return (
    <Modal open={isOpen} setOpen={setIsOpen}>
      <Modal.Header
        title="Add Category"
        iconProps={{ name: "add-category", size: "extraLarge" }}
      />

      <div className="flex w-[360px] flex-col overflow-hidden bg-white">
        <div className="px-10 pb-4">
          <Text
            variant="sb_t-14-500"
            color="secondary"
            className="mb-[18px] mt-[34px]"
          >
            Select document category
          </Text>

          <RadioGroup
            className="flex flex-col"
            onChange={(e) => {
              setValue(e.target.value as DocumentCategory);
            }}
          >
            {documentsCategoryOptions.map((option) => {
              return (
                <div
                  className="flex h-[50px] border-0  border-t-[1px] "
                  key={option.id}
                >
                  <Radio
                    label={option.label}
                    value={option.id}
                    labelChildren={
                      <Icon
                        name="dot-closed"
                        color={option.strapsColor}
                        style={{ color: option.hexColor }}
                        size="small"
                        className="pl-1" // decreases icon width to design specs
                      />
                    }
                    labelVariant="sb_t-16-500"
                  />
                </div>
              );
            })}
          </RadioGroup>
        </div>

        <Modal.Footer
          submitText="Save"
          submitDisabled={!value}
          closeAfterSubmit
          onSubmit={async () => {
            if (value) await categorizeDesignDoc(documentIds, value);
            refreshDesignDocs();
          }}
        />
      </div>
    </Modal>
  );
}

export default DocumentsCategoryModal;
