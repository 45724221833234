import Cookies from "js-cookie";
import { useQuery as useReactQuery, useQueryClient } from "react-query";
import { VITE_UTILITIES_BASE_URL } from "../../../env";
import { Utility } from "../../../types";
import { CamelCasedProperties } from "type-fest";
import { raise, recursiveCamelCaseCipher } from "../../postgrestApi";
import request from "../../request";
import { ClientOutput } from "../client";
import { useUtilitiesArcadiaQAHeader } from "@src/utilitiesArcadiaQA";

const monthlyUsage = {
  useQuery,
};

export default monthlyUsage;

const QUERY_KEY = "backpack-sites-monthly-usage";

export type WeightedUsageSnakeCase = Omit<
  ClientOutput["utilities"]["NormalizedDataWrapper"]["normalized_data"][number],
  "utility_type" | "usage" | "cost" // Option<Decimal> in Rust are represented as strings in typegen but returned from service as numbers
> & { utility_type: Utility; cost?: number | null; usage?: number | null };

export type NormalizedUtilitySummary =
  CamelCasedProperties<WeightedUsageSnakeCase>;

const getMonthlyUsage = (siteId: number, routeToHeader: string) =>
  request
    .get(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/utility_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("X-Route-To", routeToHeader)
    .then(
      (res) =>
        (recursiveCamelCaseCipher(res.body?.normalized_data) as Array<
          CamelCasedProperties<WeightedUsageSnakeCase>
        >) || raise("No data")
    );

function useQuery(siteId: number) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useReactQuery({
    queryKey: [QUERY_KEY, siteId],
    queryFn: async () => getMonthlyUsage(siteId, routeToHeader),
  });
}

export const useInvalidateUtilitySummaryQuery = (siteId: number) => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries([QUERY_KEY, siteId]);
};
