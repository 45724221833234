import * as React from "react";

import { startCase } from "lodash";
import classnames from "classnames";

import { UtilitySystemIcon, ComparisonBarChart } from "../global_components";

import * as Icons from "../icons";

import type { TopLevelUtility, ConsumptionType, Utility } from "../types";

import styles from "./UtilitySummaryCardTemplate.module.scss";
import Loader from "./Loader";
import TooltipWrapper from "./TooltipWrapper";

type UtilityCardPercentageChangeProps = {
  currVal?: number | null;
  delta?: number | null;
};

export const UtilityCardPercentageChange = (
  props: UtilityCardPercentageChangeProps
) => {
  const { currVal, delta } = props;

  return (
    <div
      className={styles["utility-group--delta"]}
      // @ts-expect-error - TS2322 - Type '{ top: number; } | null' is not assignable to type 'CSSProperties | undefined'.
      style={currVal == null ? { top: -5 } : null}
    >
      {delta != null && delta !== 0 && (
        <Icons.SideArrow
          className={`${styles["delta-indicator"]} ${
            delta < 0 ? styles["--negative"] : styles["--positive"]
          }`}
        />
      )}
      {delta ? Math.abs(delta) + "%" : delta === 0 ? "<1%" : "-%"}
    </div>
  );
};

type UtilitySummaryCardTemplateProps = {
  utilitiesSummary:
    | Array<{
        delta?: number | null;
        currVal?: number | null;
        prevVal?: number | null;
        topLevelUtility?: TopLevelUtility;
        utility?: Utility;
        units: string;
        subcategories?: Array<{
          delta?: number | null;
          currVal?: number | null;
          prevVal?: number | null;
          topLevelUtility?: TopLevelUtility;
          utility?: Utility;
          units: string;
        }>;
      }>
    | null
    | undefined;
  goals?: Array<{
    siteId: number;
    topLevelUtility: TopLevelUtility;
    reduction: number;
    utility?: Utility;
  }>;
  header?: React.ReactNode;
  consumptionType: ConsumptionType;
  dark?: boolean;
  loading?: boolean;
  labels?: [string, string];
};

type RowProps = {
  delta?: number | null;
  goal?: number;
  currVal?: number | null;
  prevVal?: number | null;
  topLevelUtility?: TopLevelUtility;
  utility?: Utility;
  units: string;
  consumptionType: ConsumptionType;
  dark?: boolean;
  children?: React.ReactNode;
};

const Row = (props: RowProps) => {
  const {
    delta,
    currVal,
    prevVal,
    topLevelUtility,
    utility,
    units,
    consumptionType,
    dark,
    children,
    goal,
  } = props;

  const utilityType = topLevelUtility || utility;

  const [showChildren, setShowChildren] = React.useState(true);

  const toggleSubcats = React.useCallback(
    () => setShowChildren((prev) => !prev),
    []
  );
  return (
    <>
      {goal &&
        (goal * -1 > (delta || 0) ? (
          <TooltipWrapper
            tooltip={
              <div className={styles.tooltip}>
                You are on track to emit less than your target
              </div>
            }
          >
            <div className={classnames(styles.goal_message, styles.on_track)}>
              <Icons.Valid />
              On Track
              <Icons.Info className="color--green" />
            </div>
          </TooltipWrapper>
        ) : (
          <TooltipWrapper
            tooltip={
              <div className={styles.tooltip}>
                Your emissions are not on track to meet your target
              </div>
            }
          >
            <div className={classnames(styles.goal_message)}>
              <Icons.Invalid className="color--red" />
              Not On Track
              <Icons.Info className="color--red" />
            </div>
          </TooltipWrapper>
        ))}
      <div key={utilityType} className={styles["utility-rates--row-container"]}>
        <div className={styles["utility-rates-row--cell"]}>
          <div className={styles["utility-rates-row--cell-top-row"]}>
            <UtilitySystemIcon
              topLevelUtility={topLevelUtility}
              utility={utility}
            />
            <div className={styles["utility-rates-row--cell-label"]}>
              {startCase(utilityType)}
            </div>
          </div>
          <div className={styles["utility-rates-row--cell-bottom-row"]}>
            <UtilityCardPercentageChange currVal={currVal} delta={delta} />
          </div>
          {children && (
            <div className={styles.show_sub} onClick={toggleSubcats}>
              <Icons.Caret
                style={{ transform: `rotate(${showChildren ? 0 : 180}deg)` }}
              />{" "}
              SUBCATEGORIES
            </div>
          )}
        </div>
        <div className={styles["utility-rates-row--chart-container"]}>
          <ComparisonBarChart
            units={units}
            consumptionType={consumptionType}
            utilityType={utilityType}
            dark={dark}
          >
            {goal && (
              <ComparisonBarChart.Goal
                value={((100 - goal) * (prevVal || 0)) / 100}
              />
            )}
            <ComparisonBarChart.Bar value={currVal} />
            <ComparisonBarChart.Bar value={prevVal} opacity={0.6} />
            {!currVal && !prevVal && <ComparisonBarChart.Null value={50000} />}
          </ComparisonBarChart>
        </div>
      </div>
      {showChildren && children}
    </>
  );
};

const UtilitySummaryCardTemplate = (props: UtilitySummaryCardTemplateProps) => {
  const {
    utilitiesSummary,
    header,
    consumptionType,
    dark,
    labels,
    goals = [],
  } = props;

  return (
    <div
      className={classnames(styles["utilities-overview--summary-card"], {
        [styles["--dark"]!]: props.dark,
      })}
    >
      {header && <div className={styles["summary-card--title"]}>{header}</div>}
      <div className={styles["summary-card--utility-rates-row"]}>
        <Loader show={props.loading} dark={!dark} style={{ zIndex: 2 }} />
        {utilitiesSummary != null &&
          utilitiesSummary.map(({ subcategories, ...topLevelUtility }) => (
            <Row
              key={topLevelUtility.topLevelUtility || topLevelUtility.utility}
              {...topLevelUtility}
              consumptionType={consumptionType}
              dark={dark}
              goal={
                goals.find(
                  (g) =>
                    g.topLevelUtility === topLevelUtility.topLevelUtility &&
                    g.utility === null
                )?.reduction
              }
            >
              {subcategories && subcategories.length > 0
                ? subcategories.map((utility) => (
                    <Row
                      key={utility.utility}
                      {...utility}
                      consumptionType={consumptionType}
                      dark={dark}
                    />
                  ))
                : null}
            </Row>
          ))}
      </div>
      {labels && (
        <div className={styles["summary-card--chart-legend"]}>
          <div className={styles["summary-card--chart-legend--item"]}>
            <div />
            <label>{labels[0]}</label>
          </div>
          <div className={styles["summary-card--chart-legend--item"]}>
            <div style={{ opacity: 0.3 }} />
            <label>{labels[1]}</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default UtilitySummaryCardTemplate;
