export default [
  "#416CE9", // blue
  "#FFD630", // yellow
  "#FF8B9A", // pink
  "#16CF90", // green
  "#A37CFD", // purple
  "#FC4B40", // red
  "#8BA6FE", // light purple
  "#00CC8F", // green
  "#6C45EF", // dark purple
  "#9FD253", // green-yellow
];
