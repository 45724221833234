import { useUtilitiesArcadiaQAHeader } from "@src/utilitiesArcadiaQA";
import { useQuery } from "react-query";

import FetchClients, { ClientOutput } from "../client";

export type UtilityAccount = ClientOutput["utilities"]["UtilityAccount"];

export type UtilityAccountRequest =
  ClientOutput["utilities"]["UtilityAccountRequest"];

const accounts = {
  useQueryAll,
} as const;

export default accounts;

export const UTILITY_ACCOUNTS_KEY = "utilities/accounts";

function useQueryAll(siteId: number) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useQuery([UTILITY_ACCOUNTS_KEY, siteId], async () => {
    const { data, error } = await FetchClients.utilities.GET(
      "/{site_id}/accounts",
      {
        params: {
          path: {
            site_id: siteId,
          },
        },
        headers: {
          "X-Route-To": routeToHeader,
        },
      }
    );
    if (data) return data;
    throw new Error(error);
  });
}
