import FetchClients, { ClientOutput } from "../client";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { CamelCasedPropertiesDeep } from "type-fest";
import { retryIfNot404 } from "@src/global_functions/fortyTwoApi/reactQueryUtils";
import { recursiveCamelCaseCipher } from "@src/global_functions/postgrestApi";
import { NotFoundError } from "./util";
import detachedParkingSdk, {
  DetachedParking,
  PARKING_QUERY_KEYS,
} from "./detachedParking";
import surfaceLotSdk from "./surfaceLot";

export type ParkingSnakeCase =
  ClientOutput["tenantsAndSpaces"]["ParkingDetails"];
export type Parking = CamelCasedPropertiesDeep<ParkingSnakeCase>;
export type LegacyParking = {
  parking?: DetachedParking;
  surfaceLotId?: Parking["surfaceLots"][number]["id"];
};
export type LegacyParkingBody = {
  parking?: DetachedParking;
  hasSurfaceLot: boolean;
  surfaceLotArea?: number;
};

const parking = { useQuery, useQueryAll, mutations: { usePut } } as const;

export default parking;

// return the first item in the parking array and the surface lot ID for back. compatibility. Return a 404 if neither
export const getParking = async (site_id: number) => {
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/parking",
    {
      params: { path: { site_id } },
    }
  );
  const { parking: detachedParking, surface_lots } = data ?? {};
  if (detachedParking?.length || surface_lots?.length) {
    return {
      ...(detachedParking?.[0]
        ? { parking: recursiveCamelCaseCipher(detachedParking[0]) }
        : {}),
      ...(surface_lots?.[0] ? { surfaceLotId: surface_lots[0].id } : {}),
    } as LegacyParking;
  }
  if (data) throw new NotFoundError(`No parking set up for site ${site_id}`);
  throw new Error(error);
};

/**
 * Legacy query for backwards compatibility with office site setup
 */
function useQuery(site_id: number) {
  return useReactQuery(
    [PARKING_QUERY_KEYS.parking, site_id, "legacy"],
    () => getParking(site_id),
    {
      refetchOnWindowFocus: false,
      retry: retryIfNot404,
    }
  );
}

const getParkings = async (site_id: number) => {
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/parking",
    {
      params: { path: { site_id } },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as Parking;
  throw new Error(error);
};

function useQueryAll(site_id: number) {
  return useReactQuery(
    [PARKING_QUERY_KEYS.parking, site_id, "updated"],
    () => getParkings(site_id),
    {
      refetchOnWindowFocus: false,
      retry: retryIfNot404,
    }
  );
}

const {
  mutations: { usePut: usePutDetachedParking },
} = detachedParkingSdk;
const {
  mutations: { usePut: usePutSurfaceLot, useDelete: useDeleteSurfaceLot },
} = surfaceLotSdk;
/**
 * Legacy mutation for backwards compatibility with office site setup
 */
function usePut(site_id: number) {
  const queryClient = useQueryClient();
  const { mutateAsync: putDetachedParking } = usePutDetachedParking(site_id);
  const { mutateAsync: putSurfaceLot } = usePutSurfaceLot(site_id);
  const { mutateAsync: deleteSurfaceLot } = useDeleteSurfaceLot(site_id);
  const { data: prevParking } = useQuery(site_id);
  const prevSurfaceLotId = prevParking?.surfaceLotId;
  return useMutation({
    mutationFn: async ({
      parking: parkingBody,
      hasSurfaceLot,
      surfaceLotArea: surfaceArea,
    }: LegacyParkingBody) => {
      if (parkingBody) await putDetachedParking(parkingBody);
      if (hasSurfaceLot) {
        await putSurfaceLot({ id: prevSurfaceLotId, surfaceArea });
      } else if (prevSurfaceLotId) {
        await deleteSurfaceLot(prevSurfaceLotId);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries([PARKING_QUERY_KEYS.parking, site_id]),
  });
}
