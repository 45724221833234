import moment from "moment";
import { OperatingSchedule } from "./types";

export const timeTo12hmm = (time: string) => {
  return moment(time, ["HH:mm"]).format("h:mm");
};

export const timeToMeridiem = (time: string) => {
  return moment(time, ["h:mm"]).format("A").toLowerCase();
};

export const timeToHourOption = (time: string) => {
  const hmm = timeTo12hmm(time);
  return {
    id: hmm,
    label: hmm,
  };
};

export const timeToMeridiemOption = (time: string) => {
  const Meridiem = timeToMeridiem(time);
  return {
    id: Meridiem,
    label: Meridiem.toUpperCase(),
  };
};

export const meridiemTimeTo24Time = (time: string) => {
  return moment(time, ["HH:mm a"]).format("H:mm");
};

export const violatesSingleDayTimeConstraint = (
  schedule: OperatingSchedule
) => {
  const startTime = moment(schedule.startTime, "HH:mm");
  const endTime = moment(schedule.endTime, "HH:mm");
  return startTime.isSameOrAfter(endTime) && schedule.endTime !== "0:00";
};
