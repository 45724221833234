import Cookies from "js-cookie";
import * as R from "ramda";
import { startCase, snakeCase, camelCase, kebabCase } from "lodash";
import request from "../request";
import colors from "./colors";
import moment from "moment";

import { energyUtilities } from "../utilities";

import {
  formatDataObj,
  formatDataArray,
  bugsnagPostgrestErrorHandler,
  recursiveCamelCaseCipher,
} from "./common";

import { deleteSimulatedUtilityBillsByRecordId } from "./dataMappingApi";

import type {
  Version,
  State,
  AltVersion,
  RecordType,
  UtilityRate,
  Currency,
  EnergyUtility,
  VersionHierarchy,
  VersionHierarchyItem,
  EnergySavingsSummarySchema,
  Utility,
} from "../../types";

const inboundRecordCipher = {
  parent_version_id: "versionId",
  alternative_parent_id: "parentId",
  id: "id",
  record_type: "type",
  site_id: "siteId",
  name: "name",
  description: "description",
  linked: "isLinked",
  end_date: "endDate",
  baseline: "isBaseline",
  utility_calibration_start_date: "utilityCalStartDate",
  utility_calibration_end_date: "utilityCalEndDate",
  equipment_calibration_start_date: "equipmentCalStartDate",
  equipment_calibration_end_date: "equipmentCalEndDate",
  published: "published",
  start_date: "startDate",
} as const;

const outboundRecordEncoder = R.invertObj(inboundRecordCipher);

const altVersionYellow = "#ffd630";
const lighten = (color: any, percentage: number) => {
  let splitColors = [color.slice(1, 3), color.slice(3, 5), color.slice(5)];
  return (
    "#" +
    splitColors
      .map((c) => {
        const offsetFromWhite = 255 - Number(`0x${c}`);
        const colorShift = offsetFromWhite * percentage;
        let lightenedColor = Math.floor(Number(`0x${c}`) + colorShift);
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
        lightenedColor = lightenedColor.toString(16).toUpperCase();
        // @ts-expect-error - TS2339 - Property 'length' does not exist on type 'number'.
        while (lightenedColor.length < 2) {
          // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
          lightenedColor = "0" + lightenedColor;
        }
        return lightenedColor;
      })
      .join("")
  );
};

export const getBaselinesAndStatesForSites = async (
  siteIds: number[]
): Promise<Array<Version>> =>
  await request
    .get(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `in.(${siteIds.join(",")})`,
    })
    .then(({ body }) => formatDataArray(body, inboundRecordCipher))
    .catch(bugsnagPostgrestErrorHandler);

const getSiteBaseline = async (
  siteId: number | string
): Promise<Array<Version>> =>
  await request
    .get(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      baseline: "eq.true",
    })
    .then(({ body }) => formatDataArray(body, inboundRecordCipher))
    .catch(bugsnagPostgrestErrorHandler);

const getRecords = async (
  siteId: number | string,
  recordType: RecordType
): Promise<Array<any>> => {
  return request
    .get(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      order: "start_date.nullsfirst",
      site_id: `eq.${siteId}`,
      record_type: `eq.${recordType}`,
    })
    .then((result) => formatDataArray(result.body, inboundRecordCipher))
    .catch(bugsnagPostgrestErrorHandler);
};

const getVersions = async (siteId: number | string): Promise<Array<Version>> =>
  getRecords(siteId, "version").then((versions) =>
    versions.map((v, i) => ({ ...v, color: colors[i % colors.length] }))
  );

const getStates = async (siteId: number | string): Promise<Array<State>> =>
  getRecords(siteId, "state").then((states) => {
    let colorAddedStates: Array<State> = [];
    let colorIndex = 1;
    states.forEach((s, i) => {
      if (
        i > 0 &&
        s.versionId !== states[i - 1].versionId &&
        s.name === states[i - 1].name
        // represents the first state in a version being the copy of a prior state
      ) {
        colorAddedStates.push({
          ...s,
          color: lighten(
            colors[colors.length - (colorIndex % colors.length)],
            0.3
          ),
        });
      } else {
        colorIndex++;
        colorAddedStates.push({
          ...s,
          color: colors[colors.length - (colorIndex % colors.length)],
        });
      }
    });
    return colorAddedStates;
  });

const getAltVersions = async (
  siteId: number | string,
  parentId?: number | string
): Promise<Array<AltVersion>> => {
  let query = {
    site_id: `eq.${siteId}`,
    record_type: `eq.alternative`,
  };
  // @ts-expect-error - TS2322 - Type '{ alternative_parent_id: string; site_id: string; record_type: string; }' is not assignable to type '{ site_id: string; record_type: string; }'.
  if (parentId) query = { ...query, alternative_parent_id: `eq.${parentId}` };
  return request
    .get(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query(query)
    .then(({ body }) => {
      const formattedData = formatDataArray(body, inboundRecordCipher);
      // @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
      return formattedData.map((v) => ({ ...v, color: altVersionYellow }));
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const getRecord = async (
  siteId: number | string,
  recordId: number | string
): Promise<any> => {
  return request
    .get(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      id: `eq.${recordId}`,
    })
    .then(({ body }) =>
      body.length ? formatDataObj(body[0], inboundRecordCipher) : null
    )
    .catch(bugsnagPostgrestErrorHandler);
};

const getVersion = async (
  siteId: number | string,
  recordId: number | string
): Promise<Version | null | undefined> => {
  const allVersions = await getVersions(siteId);
  // @ts-expect-error - TS2345 - Argument of type 'Partial<Diff<BaseState, { versionId: number; parentId: number; }>>[]' is not assignable to parameter of type 'readonly Record<"id", any>[]'.
  return R.find(R.propEq("id", Number(recordId)))(allVersions);
};

const getState = async (
  siteId: number | string,
  recordId: number | string
): Promise<State | null | undefined> => {
  const allStates = await getStates(siteId);
  // @ts-expect-error - TS2345 - Argument of type 'Partial<Diff<BaseState, { parentId: number; }>>[]' is not assignable to parameter of type 'readonly Record<"id", any>[]'.
  return R.find(R.propEq("id", Number(recordId)))(allStates);
};

const getAltVersion = async (
  siteId: number | string,
  recordId: number | string
): Promise<AltVersion | null | undefined> =>
  getRecord(siteId, recordId).then((res) => {
    if (res) {
      return { ...res, color: altVersionYellow };
    }
  });

const updateRecord = async (
  siteId: string | number,
  recordId: string | number,
  props: Partial<Version> | Partial<State> | Partial<AltVersion>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .patch(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query({
      site_id: `eq.${siteId}`,
      id: `eq.${recordId}`,
    })
    .send(formatDataObj(props, outboundRecordEncoder, { allowNulls: true }))
    .catch(bugsnagPostgrestErrorHandler);

const saveRecord = async (
  siteId: string | number,
  recordType: RecordType,
  props: Partial<Version> | Partial<State> | Partial<AltVersion>
): Promise<Version & State & AltVersion> => {
  return request
    .post(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({
      site_id: siteId,
      record_type: recordType,
      ...formatDataObj(props, outboundRecordEncoder),
    })
    .then(({ body }) => formatDataObj(body[0], inboundRecordCipher))
    .catch(bugsnagPostgrestErrorHandler);
};

const deleteRecord = async (
  siteId: string | number,
  recordId: string | number
): Promise<void> => {
  await deleteSimulatedUtilityBillsByRecordId(recordId);
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .delete(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      id: `eq.${recordId}`,
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const updateStatesParentVersion = async (
  siteId: string | number,
  recordId: string | number,
  newParentId: string | number
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .patch(`/rest/states_and_versions`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      parent_version_id: `eq.${recordId}`,
      record_type: "eq.state",
    })
    .send({ parent_version_id: newParentId })
    .catch(bugsnagPostgrestErrorHandler);
};

const editUtilityRate = async (
  siteId: string | number,
  versionId: string | number,
  utilityId: EnergyUtility,
  {
    avgRate,
    unitId,
  }: {
    avgRate: Currency;
    unitId: number;
  }
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .patch(`/rest/utility_rates`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      version_id: `eq.${versionId}`,
      utility: `eq.${snakeCase(utilityId)}`,
    })
    .send({ average_rate: avgRate, unit_id: unitId })
    .catch(bugsnagPostgrestErrorHandler);
};

const saveUtilityRate = async (
  siteId: string | number,
  versionId: string | number,
  utilityId: string,
  avgRate: Currency,
  unitId: number
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .post(`/rest/utility_rates`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      site_id: siteId,
      version_id: versionId,
      average_rate: avgRate,
      utility: snakeCase(utilityId),
      unit_id: unitId,
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const deleteUtilityRate = async (
  siteId: string | number,
  versionId: string | number,
  utilityId: EnergyUtility
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .delete(`/rest/utility_rates`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      version_id: `eq.${versionId}`,
      utility: `eq.${snakeCase(utilityId)}`,
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const getUtilityRates = async (
  siteId: number | string,
  versionId: number | string
): Promise<Array<UtilityRate>> => {
  return request
    .get(`/rest/utility_rates`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      order: "utility",
      site_id: `eq.${siteId}`,
      version_id: `eq.${versionId}`,
    })
    .then(({ body }) => {
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      return body.map((row) => ({
        versionId: row.version_id,
        id: camelCase(row.utility),
        title: startCase(row.utility),
        avgRate: row.average_rate,
        siteId: row.site_id,
        yearlySpend: row.yearly_spend,
        unitId: row.unit_id,
      }));
    })
    .catch(bugsnagPostgrestErrorHandler);
};

export const getVersionHierarchy = async (
  siteId: string
): Promise<VersionHierarchy> => {
  const versionStates: Record<string, any> = {};
  const altsByParent = {
    version: {},
    state: {},
  } as const;

  (await getStates(siteId)).forEach((state) => {
    // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
    versionStates[state.versionId] = [
      // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
      ...(versionStates[state.versionId] || []),
      state,
    ];
  });

  (await getAltVersions(siteId)).forEach((alt) => {
    let parentType = alt.parentId === alt.versionId ? "version" : "state";
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly version: {}; readonly state: {}; }'.
    altsByParent[parentType][alt.parentId] = [
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly version: {}; readonly state: {}; }'.
      ...(altsByParent[parentType][alt.parentId] || []),
      alt,
    ];
  });

  return (await getVersions(siteId)).map((version) => ({
    version,
    // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
    alternatives: altsByParent.version[version.id] || [],
    // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
    states: (versionStates[version.id] || []).map((state) => ({
      state,
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
      alternatives: altsByParent.state[state.id] || [],
    })),
  }));
};

export const getFlattenedVersionHierarchy = async (
  siteId: string
): Promise<Array<VersionHierarchyItem>> => {
  const hierarchy = (await getVersionHierarchy(siteId)) || [];

  // @ts-expect-error - TS2322 - Type '({ versionTypeLabel: string; id?: number | undefined; siteId?: number | undefined; name?: string | undefined; description?: string | null | undefined; type?: RecordType | undefined; ... 9 more ...; endDate?: string | ... 1 more ... | undefined; } | { ...; } | { ...; })[]' is not assignable to type 'VersionHierarchyItem[]'.
  return hierarchy
    .flatMap(({ version, states, alternatives: altVersions }) => [
      version,
      ...states.flatMap(({ state, alternatives: altStates }) => [
        state,
        ...altStates,
      ]),
      ...altVersions,
    ])
    .filter(Boolean)
    .map((item: Version | State | AltVersion, index) => {
      let versionTypeLabel = "Baseline Version";
      if (item.type === "alternative") {
        versionTypeLabel = "Alternative";
      } else if (item.type === "state") {
        versionTypeLabel = "State";
      }

      return {
        ...item,
        versionTypeLabel,
      };
    });
};

const getEnergyUsage = async (
  siteId: string | number,
  id: string | number
): Promise<EnergySavingsSummarySchema[]> => {
  const baselineUsage = await request
    .get(`/rest/energy_usage_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      state_or_version_id: `eq.${id}`,
      utility: `in.(${energyUtilities.map(snakeCase).join(",")})`,
    })
    .then(({ body }) => recursiveCamelCaseCipher(body))
    .then((rows) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      rows.map((row) => ({ ...row, system: kebabCase(row.system) }))
    )
    .catch((e) => {
      bugsnagPostgrestErrorHandler(e);
      return [];
    });

  return baselineUsage;
};

const createNewBaseline = (siteId: number | string) =>
  saveRecord(siteId, "version", {
    name: "Baseline",
    published: true,
    isLinked: true,
    isBaseline: true,
    startDate: moment().subtract(1, "year").format(),
  } as Partial<Version>);

const getExcludedModeledUtilities = async (
  siteId: string | number
): Promise<Utility[]> =>
  request
    .get(`/rest/excluded_modeled_utilities`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}` })
    // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
    .then(({ body }) => body.map((row) => camelCase(row.utility)));

const getExcludedModeledMeterIds = async (
  siteId: string | number
): Promise<number[]> =>
  request
    .get(`/rest/excluded_modeled_meter_ids`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}` })
    // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
    .then(({ body }) => body.map((row) => row.meter_id));

const saveExcludedModeledUtilities = async (
  siteId: string | number,
  utilities: Utility[]
): Promise<undefined[]> =>
  // @ts-expect-error - TS2322 - Type 'Response[]' is not assignable to type 'undefined[]'.
  Promise.all(
    utilities.map((u) =>
      request
        .post(`/rest/excluded_modeled_utilities`)
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .send({ site_id: siteId, utility: snakeCase(u) })
    )
  );

const saveExcludedModeledMeterIds = async (
  siteId: string | number,
  meterIds: number[]
): Promise<undefined[]> =>
  // @ts-expect-error - TS2322 - Type 'Response[]' is not assignable to type 'undefined[]'.
  Promise.all(
    meterIds.map((meterId) =>
      request
        .post(`/rest/excluded_modeled_meter_ids`)
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .send({ site_id: siteId, meter_id: meterId })
    )
  );

const deleteExcludedModeledUtilities = async (
  siteId: string | number,
  utilities: Utility[]
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Response' is not assignable to type 'void'.
  request
    .delete(`/rest/excluded_modeled_utilities`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      utility: `in.(${utilities.map(snakeCase).join(",")})`,
    });

const deleteExcludedModeledMeterIds = async (
  siteId: string | number,
  meterIds: number[]
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Response' is not assignable to type 'void'.
  request
    .delete(`/rest/excluded_modeled_meter_ids`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      meter_id: `in.(${meterIds.join(",")})`,
    });

export {
  getSiteBaseline,
  getRecords,
  getVersions,
  getStates,
  getAltVersions,
  getVersion,
  getState,
  getAltVersion,
  updateRecord,
  saveRecord,
  deleteRecord,
  updateStatesParentVersion,
  getUtilityRates,
  editUtilityRate,
  deleteUtilityRate,
  saveUtilityRate,
  getEnergyUsage,
  createNewBaseline,
  getExcludedModeledUtilities,
  getExcludedModeledMeterIds,
  saveExcludedModeledUtilities,
  saveExcludedModeledMeterIds,
  deleteExcludedModeledUtilities,
  deleteExcludedModeledMeterIds,
};
