import { atom } from "jotai";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { UseAsyncReturnValues } from "../global_functions/useAsync";

import type { SummaryOverview } from "../types";

export type SiteContext = {
  refreshSite: (siteId: string | number) => Promise<void>;
  activeSites: Array<SummaryOverview>;
  allSites: Array<SummaryOverview>;
  sitesState: UseAsyncReturnValues<SummaryOverview[]>;
  refreshSiteState: UseAsyncReturnValues<void>;
  loading: boolean;
};

// TODO find a way to type this effectively, then can add it into useAsync file
const useAsyncDefaultReturnValues: any = {
  loading: false,
  success: false,
  error: null,
  setData: () => {},
  setState: () => {},
};

const SitesContext = createContext<SiteContext>({
  refreshSite: async () => {},
  sitesState: useAsyncDefaultReturnValues,
  refreshSiteState: useAsyncDefaultReturnValues,
  activeSites: [],
  allSites: [],
  loading: false,
});

export default SitesContext;

export const useSite = (siteId?: string | number | null) => {
  siteId = Number(siteId);
  const { allSites: sites } = useContext(SitesContext);

  return useMemo(
    () => (siteId ? sites.find((site) => site.id === siteId) : undefined),
    [sites, siteId]
  );
};

export const useActiveSites = (accountIdParam?: number) => {
  const { accountId: accountIdQueryParam } = useParams<{ accountId: string }>();
  const accountId = accountIdParam ?? accountIdQueryParam;
  const { activeSites: sites } = useContext(SitesContext);

  return useMemo(
    () => sites.filter((s) => !accountId || s.accountId === Number(accountId)),
    [accountId, sites]
  );
};

export const useSitesLoading = () => {
  const { loading } = useContext(SitesContext);
  return loading;
};

export function useSiteIdParam() {
  const params = useParams<{ siteId: string }>();
  const siteId = Number(params.siteId);

  if (Number.isNaN(siteId)) {
    throw new Error(`Site ID query param is NaN: ${params.siteId}`);
  }

  return siteId;
}

// Similar to `useSiteIdParam` but does not throw an error if siteId param is not a number or undefined
export function useOptionalSiteIdParam() {
  const params = useParams<{ siteId: string }>();
  const siteId = Number(params.siteId);

  if (Number.isNaN(siteId)) {
    return undefined;
  }

  return siteId;
}

export const useCurrentSite = () => {
  const siteId = useSiteIdParam();

  return useSite(siteId);
};

// Provide an atom to get current site since useSiteIdParam only works for components nested under SiteDashboard
export const currentSiteIdAtom = atom<number | null>(null);
