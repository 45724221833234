import * as React from "react";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import moment from "moment";
import { capitalize } from "lodash";
import {
  getTopLevelUtility,
  topLevelUtilitiesDefaultUnits,
} from "../global_functions/utilities";
import {
  getMostRecentMonthWithUtilities,
  getUtilitiesSummaryByMonth,
} from "../global_functions/postgrestApi";
import { useAsync } from "../global_functions/useAsync";

import type {
  MonthString,
  Utility,
  TopLevelUtility,
  ConsumptionType,
  UtilityUnit,
} from "../types";
import UtilitySummaryCardTemplate from "./UtilitySummaryCardTemplate";

type UtilitySummaryInfo = {
  id: TopLevelUtility;
  unit: UtilityUnit;
  currConsumption: number | null;
  prevConsumption: number | null;
  currSpend: number | null;
  prevSpend: number | null;
  currRate: number | null;
  mostRecentYear: number;
  prevRate: number | null;
  currGhgEmission: number | null;
  prevGhgEmission: number | null;
  subcategories?: Array<
    UtilitySummaryInfo & {
      utility: Utility;
    }
  >;
};

const nullFillers = {
  unit: null,
  currConsumption: null,
  prevConsumption: null,
  currSpend: null,
  prevSpend: null,
  currRate: null,
  prevRate: null,
  currGhgEmission: null,
  prevGhgEmission: null,
} as const;

// @ts-expect-error - TS7006 - Parameter 'utility' implicitly has an 'any' type.
const getDelta = (utility, propName: any): number | null | undefined => {
  const [curr, prev] = [utility[`curr${propName}`], utility[`prev${propName}`]];
  if (curr == null || prev == null) return null;
  const delta = Math.round(((curr - prev) / prev) * 100);
  return isNaN(delta) || !isFinite(delta) ? null : delta;
};

const getUtilitiesSummary = async (
  siteId: number
): Promise<Array<UtilitySummaryInfo>> => {
  const output = {
    energy: { ...nullFillers, subcategoriesObj: {} },
    water: { ...nullFillers, subcategoriesObj: {} },
    waste: { ...nullFillers, subcategoriesObj: {} },
  } as const;

  const mostRecentMonthPerUtility: Partial<Record<Utility, MonthString>> =
    await getMostRecentMonthWithUtilities(siteId);

  if (R.isEmpty(mostRecentMonthPerUtility))
    return R.toPairs(output).map(([id, info]: [any, any]) => ({ id, ...info }));

  const mostRecentMonthMoment = moment(
    R.last(R.values(mostRecentMonthPerUtility).sort()),
    "YYYY-MM"
  );

  const mostRecentYear = mostRecentMonthMoment.year();
  const mostRecentMonthIdx = mostRecentMonthMoment.month();

  const currYearMonthStrings = R.range(0, mostRecentMonthIdx + 1).map((idx) =>
    moment().year(mostRecentYear).month(idx).format("YYYY-MM")
  );

  const prevYearMonthStrings = R.range(0, mostRecentMonthIdx + 1).map((idx) =>
    moment()
      .year(mostRecentYear - 1)
      .month(idx)
      .format("YYYY-MM")
  );

  const [currYearData, prevYearData] = await Promise.all([
    getUtilitiesSummaryByMonth(siteId, currYearMonthStrings),
    getUtilitiesSummaryByMonth(siteId, prevYearMonthStrings),
  ]);

  currYearData.forEach(({ month, unit, ...utilities }) => {
    R.toPairs(utilities).forEach(
      ([utility, { totalSpend, totalConsumption, totalGhgEmission }]: [
        any,
        any
      ]) => {
        const monthMoment = moment(month, "YYYY-MM");
        if (
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<Record<Utility, string>>'.
          mostRecentMonthPerUtility[utility] != null &&
          monthMoment.isSameOrBefore(
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<Record<Utility, string>>'.
            mostRecentMonthPerUtility[utility],
            "month"
          )
        ) {
          const topLevelUtility = getTopLevelUtility(utility);

          if (!output[topLevelUtility].unit) {
            // @ts-expect-error - TS2540 - Cannot assign to 'unit' because it is a read-only property.
            output[topLevelUtility].unit = unit;
          }

          // @ts-expect-error - TS2540 - Cannot assign to 'currConsumption' because it is a read-only property.
          output[topLevelUtility].currConsumption =
            (output[topLevelUtility].currConsumption || 0) +
            (totalConsumption || 0);
          // @ts-expect-error - TS2540 - Cannot assign to 'currSpend' because it is a read-only property.
          output[topLevelUtility].currSpend =
            (output[topLevelUtility].currSpend || 0) + (totalSpend || 0);
          // @ts-expect-error - TS2540 - Cannot assign to 'currGhgEmission' because it is a read-only property.
          output[topLevelUtility].currGhgEmission =
            (output[topLevelUtility].currGhgEmission || 0) +
            (totalGhgEmission || 0);

          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
          if (!output[topLevelUtility].subcategoriesObj[utility]) {
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
            output[topLevelUtility].subcategoriesObj[utility] = {
              ...nullFillers,
            };
          }

          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
          output[topLevelUtility].subcategoriesObj[utility].currConsumption =
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
            (output[topLevelUtility].subcategoriesObj[utility]
              .currConsumption || 0) + (totalConsumption || 0);
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
          output[topLevelUtility].subcategoriesObj[utility].currSpend =
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
            (output[topLevelUtility].subcategoriesObj[utility].currSpend || 0) +
            (totalSpend || 0);
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
          output[topLevelUtility].subcategoriesObj[utility].currGhgEmission =
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
            (output[topLevelUtility].subcategoriesObj[utility]
              .currGhgEmission || 0) + (totalGhgEmission || 0);
        }
      }
    );
  });

  prevYearData.forEach(({ month, unit, ...utilities }) => {
    R.toPairs(utilities).forEach(
      ([utility, { totalSpend, totalConsumption, totalGhgEmission }]: [
        any,
        any
      ]) => {
        const monthMoment = moment(month, "YYYY-MM");
        if (
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<Record<Utility, string>>'.
          mostRecentMonthPerUtility[utility] != null &&
          monthMoment
            .add(1, "year")
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<Record<Utility, string>>'.
            .isSameOrBefore(mostRecentMonthPerUtility[utility], "month")
        ) {
          const topLevelUtility = getTopLevelUtility(utility);

          if (!output[topLevelUtility].unit) {
            // @ts-expect-error - TS2540 - Cannot assign to 'unit' because it is a read-only property.
            output[topLevelUtility].unit = unit;
          }

          // @ts-expect-error - TS2540 - Cannot assign to 'prevConsumption' because it is a read-only property.
          output[topLevelUtility].prevConsumption =
            (output[topLevelUtility].prevConsumption || 0) +
            (totalConsumption || 0);
          // @ts-expect-error - TS2540 - Cannot assign to 'prevSpend' because it is a read-only property.
          output[topLevelUtility].prevSpend =
            (output[topLevelUtility].prevSpend || 0) + (totalSpend || 0);
          // @ts-expect-error - TS2540 - Cannot assign to 'prevGhgEmission' because it is a read-only property.
          output[topLevelUtility].prevGhgEmission =
            (output[topLevelUtility].prevGhgEmission || 0) +
            (totalGhgEmission || 0);

          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
          if (!output[topLevelUtility].subcategoriesObj[utility]) {
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
            output[topLevelUtility].subcategoriesObj[utility] = {
              ...nullFillers,
            };
          }

          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
          output[topLevelUtility].subcategoriesObj[utility].prevConsumption =
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
            (output[topLevelUtility].subcategoriesObj[utility]
              .prevConsumption || 0) + (totalConsumption || 0);
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
          output[topLevelUtility].subcategoriesObj[utility].prevSpend =
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
            (output[topLevelUtility].subcategoriesObj[utility].prevSpend || 0) +
            (totalSpend || 0);
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
          output[topLevelUtility].subcategoriesObj[utility].prevGhgEmission =
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{} | {} | {}'.
            (output[topLevelUtility].subcategoriesObj[utility]
              .prevGhgEmission || 0) + (totalGhgEmission || 0);
        }
      }
    );
  });

  return R.toPairs(output).map(
    ([id, { subcategoriesObj, ...info }]: [any, any]) => ({
      id,
      ...info,
      mostRecentYear,
      subcategories: R.toPairs(subcategoriesObj).map(
        ([utility, rest]: [any, any]) => ({
          utility,
          ...rest,
          currRate:
            rest.currSpend && rest.currConsumption
              ? rest.currSpend / rest.currConsumption
              : null,
          prevRate:
            rest.prevSpend && rest.prevConsumption
              ? rest.prevSpend / rest.prevConsumption
              : null,
        })
      ),
      currRate:
        info.currSpend && info.currConsumption
          ? info.currSpend / info.currConsumption
          : null,
      prevRate:
        info.prevSpend && info.prevConsumption
          ? info.prevSpend / info.prevConsumption
          : null,
    })
  );
};
export type UtilityCardContextType = {
  consumptionType: ConsumptionType;
  setConsumptionType: any;
};

export const UtilityCardContext = React.createContext<UtilityCardContextType>({
  consumptionType: "rate",
  setConsumptionType: () => {},
});

const getSummaryValue = (
  utility: any,
  year: "curr" | "prev",
  consumptionType: ConsumptionType
): number | null | undefined => {
  return utility[`${year}${capitalize(consumptionType)}`];
};

type UtilitySummaryCardWrapperProps = {
  defaultConsumptionType?: ConsumptionType;
  header: (ctx: UtilityCardContextType) => React.ReactElement;
  dark?: boolean;
};

const UtilitySummaryCardWrapper = (props: UtilitySummaryCardWrapperProps) => {
  const { defaultConsumptionType } = props;
  const { siteId } = useParams<{
    siteId: string;
  }>();

  const [
    { data: utilitiesSummary, ...utilitiesSummaryState },
    fetchUtilitiesSummary,
  ] = useAsync(async () => await getUtilitiesSummary(Number(siteId)), [siteId]);

  const mostRecentYear =
    utilitiesSummary?.[0]?.mostRecentYear || Number(moment().format("YYYY"));

  React.useEffect(() => {
    fetchUtilitiesSummary();
  }, [fetchUtilitiesSummary, siteId]);

  const [consumptionType, setConsumptionType] = React.useState<ConsumptionType>(
    defaultConsumptionType || "spend"
  );

  const value = { consumptionType, setConsumptionType } as const;

  const templateSummary = React.useMemo(
    () =>
      (utilitiesSummary || []).map((utility) => {
        const summaryValues = {
          currVal: getSummaryValue(utility, "curr", consumptionType),
          prevVal: getSummaryValue(utility, "prev", consumptionType),
        } as const;

        const units =
          utility.unit || topLevelUtilitiesDefaultUnits[utility.id] || "";
        const subcategories = (utility.subcategories || []).map((cat) => ({
          utility: cat.utility,
          currVal: getSummaryValue(cat, "curr", consumptionType),
          prevVal: getSummaryValue(cat, "prev", consumptionType),
          units,
          delta: getDelta(cat, capitalize(consumptionType)),
        }));

        const delta = getDelta(utility, capitalize(consumptionType));
        return {
          ...summaryValues,
          units,
          delta,
          topLevelUtility: utility.id,
          subcategories,
        };
      }),
    [consumptionType, utilitiesSummary]
  );

  return (
    <UtilityCardContext.Provider value={value}>
      <UtilitySummaryCardTemplate
        loading={utilitiesSummaryState.loading}
        utilitiesSummary={templateSummary}
        header={props.header(value)}
        consumptionType={consumptionType}
        dark={props.dark}
        labels={[`${mostRecentYear} YTD`, `${mostRecentYear - 1} YTD`]}
      />
    </UtilityCardContext.Provider>
  );
};

export default UtilitySummaryCardWrapper;
