import { useEffect, useState } from "react";

export const PORTAL_ROOT_ID = "portal-root";
export default function PortalRoot() {
  return <div id={PORTAL_ROOT_ID} />;
}

function queryRoot() {
  return document.getElementById(PORTAL_ROOT_ID);
}
export const usePortalRoot = () => {
  const [root, setRoot] = useState<HTMLElement | null>(queryRoot());

  useEffect(() => {
    // If the root element was rendered for the first time on the same update cycle as this hook, it will be initialize to null
    if (!root) {
      requestAnimationFrame(() => {
        setRoot(queryRoot());
      });
    }
  }, [root]);

  return root;
};
