import * as React from "react";
import { Button, Modal } from "../../../global_components";

import FindALocation from "../../../images/onboardingGuide/FindALocation.svg";
import OpenGatewayRemovePowerCord from "../../../images/onboardingGuide/OpenGatewayRemovePowerCord.svg";
import FeedCordsThroughEnclosure from "../../../images/onboardingGuide/FeedCordsThroughEnclosure.svg";
import MountGateway from "../../../images/onboardingGuide/MountGateway.svg";
import PlugInTheGateway from "../../../images/onboardingGuide/PlugInTheGateway.svg";
import PlugIntoBASNetwork from "../../../images/onboardingGuide/PlugIntoBASNetwork.svg";
import CheckForBlueLight from "../../../images/onboardingGuide/CheckForBlueLight.svg";
import Success from "../../../images/onboardingGuide/Success.svg";

import styles from "./OnSiteModal.module.scss";
import Icons from "../../../icons";
import { useOnboardingGuideContext } from ".";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";

const connectToPowerContent = {
  success: false,
  images: [
    FindALocation,
    OpenGatewayRemovePowerCord,
    FeedCordsThroughEnclosure,
    MountGateway,
    PlugInTheGateway,
  ],
  headers: [
    "Find a Location",
    "Open Gateway & Remove Power Cord",
    "Feed Cords Through the Enclosure",
    "Mount Gateway",
    "Plug in the Gateway",
  ],
  title: "Gateway Install",
  text: [
    <>
      <p>
        In order to maximize power meter and cellular connectivity, the gateway
        should be installed in a location that meets the following criteria:
      </p>
      <ul>
        <li>Within 50 feet of the main BAS network switch.</li>
        <li>Area clear of obstructions, preferably high on a wall.</li>
        <li>Away from electrical panels, control boxes, and metal objects.</li>
        <li>Low-traffic area to avoid tampering/unmounting.</li>
      </ul>
    </>,
    "Open the front of the gateway enclosure by unscrewing the 4 screws and remove the power cord.",
    "Feed the power cord and CAT5 cable through the holes at the bottom of the gatway enclosure.",
    "Mount gateway High on the wall away from any metal objects or obstructions.",
    "Plug the gateway power cord into a standard 120V outlet.",
  ],
} as const;

const BASContent = {
  success: false,
  title: "Connect to BAS Network",
  images: [PlugIntoBASNetwork, CheckForBlueLight],
  headers: ["Plug into the BAS Network", "Check for Blue Light"],
  text: [
    "Plug the CAT5 cable into the BAS Network.",
    "Before closing the gateway up, ensure the the blue light on the gateway is on.",
  ],
} as const;

const success = {
  success: true,
  title: "",
  images: [],
  headers: [],
  text: [],
} as const;

export default function OnboardingGuideOnSiteModal() {
  const ctx = useOnboardingGuideContext();

  const onSiteState = ctx.state.onSite;
  const activeStep = ctx.activeStep;

  const open = React.useMemo(
    () =>
      activeStep
        ? Object.values(onSiteState)
            .flatMap((state) =>
              Object.values(state).flatMap((step) => step.pathId)
            )
            .includes(activeStep)
        : false,
    [activeStep, onSiteState]
  );

  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    open && setPage(0);
  }, [open]);

  const prevContent = React.useRef<
    typeof connectToPowerContent | typeof BASContent | typeof success
  >();
  const content = React.useMemo(() => {
    const { gatewayInstallComplete, BASIntegration } =
      onSiteState.gatewayInstall;

    const returnSuccess =
      (gatewayInstallComplete.pathId === activeStep &&
        gatewayInstallComplete.complete) ||
      (BASIntegration.pathId === activeStep && BASIntegration.complete);

    if (returnSuccess) {
      prevContent.current = success;
      return success;
    }

    if (gatewayInstallComplete.pathId === activeStep) {
      prevContent.current = connectToPowerContent;
      return connectToPowerContent;
    }

    if (BASIntegration.pathId === activeStep) {
      prevContent.current = BASContent;
      return BASContent;
    }

    // When closing the modal, keep existing content for duration of animation
    return (
      prevContent.current || { images: [], headers: [], text: [], title: "" }
    );
  }, [onSiteState, activeStep]);

  return (
    <Modal
      open={open}
      onCancel={() => ctx.setActiveStep()}
      // @ts-expect-error - TS2339 - Property 'success' does not exist on type '{ readonly success: false; readonly images: readonly [string, string, string, string, string, string, string]; readonly headers: readonly ["Find a Location", "Open Gateway & Remove Power Cord", "Feed Cords Through the Enclosure", "Mount Gateway", "Plug in the Gateway", "Plug into the BAS Network", "Check for Blue Li...'.
      title={content.success ? undefined : content.title}
      includeCancelButton={false}
      minWidth={294}
      buttons={
        // @ts-expect-error - TS2339 - Property 'success' does not exist on type '{ readonly success: false; readonly images: readonly [string, string, string, string, string, string, string]; readonly headers: readonly ["Find a Location", "Open Gateway & Remove Power Cord", "Feed Cords Through the Enclosure", "Mount Gateway", "Plug in the Gateway", "Plug into the BAS Network", "Check for Blue Li...'.
        !content.success && (
          <div className="flex flex-1">
            {page > 0 && (
              <ClickableDiv
                onClick={() => setPage((prev) => prev - 1)}
                className="flex cursor-pointer items-center text-sm font-semibold text-gray400"
              >
                <Icons
                  icon="Caret"
                  className="mb-px mr-3 -rotate-90 transform stroke-2"
                />
                Previous
              </ClickableDiv>
            )}
            {page < content.images.length - 1 && (
              <Button
                onClick={() => setPage((prev) => prev + 1)}
                className="ml-auto"
              >
                Next
              </Button>
            )}
          </div>
        )
      }
    >
      {/* @ts-expect-error - TS2339 - Property 'success' does not exist on type '{ readonly success: false; readonly images: readonly [string, string, string, string, string, string, string]; readonly headers: readonly ["Find a Location", "Open Gateway & Remove Power Cord", "Feed Cords Through the Enclosure", "Mount Gateway", "Plug in the Gateway", "Plug into the BAS Network", "Check for Blue Li...'. */}
      {content.success ? (
        <div className="my-8">
          <div className="mb-1 flex items-center justify-center pt-4">
            <img src={Success} alt="illustration" className="object-cover" />
          </div>
          <div className="text-center">
            <h2>Success!</h2>
            <p className="mx-auto w-56 text-sm">
              Congrats you are successfully connected to BAS Network.
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="flex h-40 items-center justify-center">
            <img
              src={content.images[page]}
              alt="illustration"
              className="object-cover"
            />
          </div>
          <div className={styles.content}>
            <h3>
              <span>{page + 1}</span>
              {content.headers[page]}
            </h3>
            <div>{content.text[page]}</div>
          </div>
        </>
      )}
    </Modal>
  );
}
