import * as React from "react";
import { startCase } from "lodash";
import { useLocation, useParams } from "react-router-dom";
import UserContext from "../context/UserContext";
import BuildingAccountSwitcher from "./BuildingAccountSwitcher";
import MonthlyReportMonthSelector from "./MonthlyReportMonthSelector";
import UserHeader from "@src/straps/base/navigation/UserHeader/UserHeader";

import { VITE_SITE } from "../env";

const pageTitle = ((pageType) => {
  switch (pageType) {
    case "ip":
      return "Dashboard";
    case "admin":
      return "Admin";
    default:
      return "All Buildings";
  }
})(VITE_SITE);

const getPageTitle = (pathname: string, page: string | null | undefined) => {
  if (!page) return pageTitle;

  switch (page) {
    case "esg-rollup":
      return "ESG Rollup";
    case "idl":
      return "Investment Decision Lab";
    case "projects":
      return "Project Overview";
    case "data-qa":
      return "Data QA";
    case "dashboard":
      return "Building Dashboard";
    case "tree-diagram":
      return "Overview";
    case "elements":
      return getElementHeader(pathname);
    default:
      return startCase(page);
  }
};

const getElementHeader = (pathName: string): string => {
  const splitPath = pathName.split("/");
  const lastItem = splitPath[splitPath.length - 1];
  return startCase(lastItem);
};

const Header = () => {
  const location = useLocation();
  const { currentUser } = React.useContext(UserContext);

  const { siteId, page } = useParams<{
    siteId: string;
    page?: string;
  }>();

  const headerPageSubtitle = ((pageType) => {
    switch (pageType) {
      case "ip":
        return (
          <div className="header--page-subtitle">
            {siteId == null ? "Portfolio" : "Building"}
          </div>
        );
      default:
        return null;
    }
  })(VITE_SITE);

  const title = getPageTitle(location.pathname, page);

  React.useEffect(() => {
    const subTitle =
      VITE_SITE === "ip"
        ? siteId == null
          ? " | Portfolio"
          : " | Building"
        : "";
    document.title = title + subTitle;
  }, [siteId, title]);

  return (
    <header className="header-container">
      <div className="page-title-container">
        <h1 className="header--page-title">
          {headerPageSubtitle}
          {title}
        </h1>
      </div>
      <BuildingAccountSwitcher />
      <MonthlyReportMonthSelector />
      <div className="header--spacer" />
      <UserHeader
        user={currentUser}
        accountsURL="/accounts"
        settingsURL="/settings"
      />
      <div className="h-full w-px bg-border"></div>
    </header>
  );
};

export default Header;
