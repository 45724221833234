import { useContext, useCallback, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import SitesContext, {
  useOptionalSiteIdParam,
} from "../../context/SitesContext";
import { applySiteMutations } from "./sitesHelpers";

import UserContext from "../../context/UserContext";
import { getSite, getSites } from "../postgrestApi";
import { useFeatureFlag } from "@src/featureFlags";

const useIsInactiveSitesEnabled = () => {
  const isInactiveSitesEnabled =
    useFeatureFlag("inactive-sites", "off") === "on";
  const { currentUser } = useContext(UserContext);
  return currentUser?.superAdmin && isInactiveSitesEnabled;
};

export const useDetailedSiteSummaryQuery = ({
  siteId,
}: { siteId?: string | number | null } = {}) => {
  const _siteId = useOptionalSiteIdParam();
  const includeInactive = useIsInactiveSitesEnabled();
  const id = siteId ?? _siteId;
  const queryResponse = useQuery({
    queryKey: ["site-summary", String(id), (!!includeInactive).toString()],
    queryFn: () => getSite(id!, { includeInactive }),
    enabled: !!id && includeInactive !== undefined,
    refetchOnWindowFocus: false,
  });

  const transformedData = useMemo(() => {
    if (queryResponse.data) {
      return applySiteMutations(queryResponse.data);
    }
  }, [queryResponse.data]);

  return useMemo(
    () => ({
      ...queryResponse,
      data: transformedData,
    }),
    [queryResponse, transformedData]
  );
};

interface DetailedSitesSummaryOptions {
  includeInactive?: boolean;
  accountId?: number;
  // Avoid if possible - this request takes several seconds for superadmins in prod. `accountId` prop takes precedence.
  includeAllAccounts?: boolean;
}
export const useDetailedSitesSummaryQuery = ({
  includeInactive,
  accountId,
  includeAllAccounts,
}: DetailedSitesSummaryOptions) => {
  const isInactiveSitesEnabled = useIsInactiveSitesEnabled();
  const _includeInactive = includeInactive ?? isInactiveSitesEnabled;
  const queryResponse = useQuery({
    queryKey: [
      "site-summary",
      "all",
      (!!_includeInactive).toString(),
      accountId,
    ],
    queryFn: () => getSites({ includeInactive: _includeInactive, accountId }),
    enabled:
      _includeInactive !== undefined &&
      (accountId !== undefined || includeAllAccounts),
    refetchOnWindowFocus: false,
  });
  const transformedData = useMemo(() => {
    if (queryResponse.data) {
      return queryResponse.data?.map(applySiteMutations);
    }
  }, [queryResponse.data]);

  return useMemo(
    () => ({
      ...queryResponse,
      data: transformedData,
    }),
    [queryResponse, transformedData]
  );
};

const noop = () => {
  // intentional noop, expect error to be caught by bugsnag previously
};

export const useInvalidateSiteQueries = ({
  siteId,
}: { siteId?: string | number | null } = {}) => {
  const queryClient = useQueryClient();
  const { refreshSite } = useContext(SitesContext);
  const _siteId = useOptionalSiteIdParam();
  const id = siteId ?? _siteId;
  const refreshSiteQueries = useCallback(() => {
    // errors already caught by bugnsag in api
    // refreshSite/refreshSiteQueries is generally not awaited
    // so adding .catches here to appease sonar
    // https://sonarcloud.io/organizations/backpack/rules?open=typescript%3AS6544&rule_key=typescript%3AS6544
    if (id) {
      queryClient
        .invalidateQueries(
          { queryKey: ["site-summary", String(id)] },
          { throwOnError: true }
        )
        .catch(noop);
      refreshSite(id).catch(noop);
    }
    queryClient
      .invalidateQueries(
        { queryKey: ["site-summary", "all"] },
        { throwOnError: true }
      )
      .catch(noop);
  }, [id, queryClient, refreshSite]);
  const refreshSiteQueriesAsync = useCallback(() => {
    if (id) {
      return Promise.all([
        queryClient.invalidateQueries(
          { queryKey: ["site-summary", String(id)] },
          { throwOnError: true }
        ),
        queryClient.invalidateQueries({ queryKey: ["site-summary", "all"] }),
        refreshSite(id),
      ]);
    }
    return queryClient.invalidateQueries({ queryKey: ["site-summary", "all"] });
  }, [id, queryClient, refreshSite]);
  return {
    refreshSiteQueries,
    refreshSiteQueriesAsync,
  };
};
