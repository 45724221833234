import FetchClients, { ClientOutput } from "../client";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { CamelCasedPropertiesDeep } from "type-fest";
import { retryIfNot404 } from "@src/global_functions/fortyTwoApi/reactQueryUtils";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";
import { NotFoundError } from "./util";
import { FLOORS_QUERY_KEY } from "./floors";

export type BuildingSnakeCase = ClientOutput["tenantsAndSpaces"]["Building"];
export type Building = CamelCasedPropertiesDeep<BuildingSnakeCase>;

const building = {
  useQuery,
  useQueryAll,
  mutations: { usePut, useDelete },
} as const;

export default building;

const BUILDING_QUERY_KEY = "/building";

const getBuildings = async (site_id: number) => {
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/building",
    {
      params: { path: { site_id } },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as Building[];
  throw new Error(error);
};

const _getBuilding = async (site_id: number, building_id: number) => {
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/building/{building_id}",
    {
      params: { path: { site_id, building_id } },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as Building;
  throw new Error(error);
};

const getBuilding = async (site_id: number, building_id?: number) => {
  if (!building_id) {
    const [_building] = await getBuildings(site_id);
    if (_building) return _building;
    throw new NotFoundError(`No building set up for site ${site_id}`);
  }
  return _getBuilding(site_id, building_id);
};

function useQuery(site_id: number, building_id?: number) {
  return useReactQuery(
    [BUILDING_QUERY_KEY, site_id, building_id],
    () => getBuilding(site_id, building_id),
    {
      refetchOnWindowFocus: false,
      retry: retryIfNot404,
    }
  );
}

function useQueryAll(site_id: number) {
  return useReactQuery(
    [BUILDING_QUERY_KEY, site_id],
    () => getBuildings(site_id),
    { refetchOnWindowFocus: false }
  );
}

function usePut(site_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: Building) => {
      const { response, error } = await FetchClients.tenantsAndSpaces.PUT(
        "/{site_id}/building",
        {
          params: { path: { site_id } },
          body: recursiveSnakeCaseCipher(body),
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries([BUILDING_QUERY_KEY, site_id]);
      await queryClient.invalidateQueries([FLOORS_QUERY_KEY, site_id, id]);
    },
  });
}

function useDelete(site_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (building_id: number) => {
      const { response, error } = await FetchClients.tenantsAndSpaces.DELETE(
        "/{site_id}/building/{building_id}",
        {
          params: { path: { site_id, building_id } },
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [BUILDING_QUERY_KEY, site_id],
      });
    },
    onSuccess: async (_, id) => {
      await queryClient.invalidateQueries([BUILDING_QUERY_KEY, site_id]);
      await queryClient.invalidateQueries([FLOORS_QUERY_KEY, site_id, id]);
    },
  });
}
