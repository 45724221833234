import React from "react";
import { useQueryClient } from "react-query";
import { EQUIPMENT_DETAILS_QUERY_KEY } from "./equipment";
import { PORTFOLIO_EQUIPMENT_QUERY_KEY } from "./portfolio";
import { SITE_EQUIPMENT_QUERY_KEY } from "./site";

export function useInvalidateEquipmentQueries() {
  const queryClient = useQueryClient();

  return React.useCallback(
    async ({
      equipmentIds,
      accountId,
      siteId,
    }: {
      equipmentIds?: number[] | null;
      accountId?: number | null;
      siteId?: number | null;
    }) => {
      const allQueries = [];
      if (typeof equipmentIds?.length === "number") {
        equipmentIds.forEach((equipmentId) => {
          allQueries.push(
            queryClient.invalidateQueries([
              EQUIPMENT_DETAILS_QUERY_KEY,
              equipmentId,
            ])
          );
        });
      }
      if (typeof siteId === "number") {
        allQueries.push(
          queryClient.invalidateQueries([SITE_EQUIPMENT_QUERY_KEY, siteId])
        );
      }
      if (typeof accountId === "number") {
        allQueries.push(
          queryClient.invalidateQueries([
            PORTFOLIO_EQUIPMENT_QUERY_KEY,
            accountId,
          ])
        );
      }

      return Promise.all(allQueries);
    },
    [queryClient]
  );
}
