import * as React from "react";
import classnames from "classnames";
import { useControllableState } from "../../../../global_functions/hooks";
import styles from "./Checkbox.module.scss";
import CheckboxLabel, { CheckboxLabelProps } from "./CheckboxLabel";

export type CheckboxProps = {
  defaultChecked?: boolean;
  // If undefined, Checkbox can be used as an uncontrolled component
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  size?: "default" | "small";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dataTestId?: string;
} & Partial<Omit<CheckboxLabelProps, "size" | "disabled">>;

const Checkbox = ({
  defaultChecked = false,
  checked: checkedProp,
  disabled = false,
  indeterminate = false,
  size = "default",
  label,
  labelVariant,
  labelClassName,
  labelIcon,
  labelIconProps,
  onChange,
  dataTestId = "straps-checkbox",
}: CheckboxProps) => {
  const cRef = React.useRef<HTMLInputElement>();
  const [checked, setChecked] = useControllableState(
    checkedProp,
    defaultChecked
  );
  React.useEffect(() => {
    if (cRef.current) {
      cRef.current.indeterminate = false;
    }
  }, [cRef, indeterminate]);

  const handleOnChange = React.useCallback(
    (e) => {
      if (disabled) {
        return;
      }

      setChecked(e.target.checked);
      if (onChange) {
        onChange(e);
      }
    },
    [disabled, onChange, setChecked]
  );

  const checkbox = (
    <div
      className={classnames(
        {
          "opacity-25": disabled,
          "text-straps-primary": checked === true || indeterminate === true,
          "text-pure-white-200": !checked && !indeterminate,
          "hover:text-straps-primary-hover ":
            (checked || indeterminate) && !disabled,
          "hover:text-straps-secondary":
            !checked && !indeterminate && !disabled,
        },
        "relative inline-flex items-center justify-center"
      )}
    >
      <input
        className={classnames(
          {
            "cursor-pointer": !disabled,
          },
          "absolute left-0 top-0 m-0 h-full w-full opacity-0"
        )}
        type="checkbox"
        checked={checked}
        data-testid={dataTestId}
        disabled={disabled}
        onChange={handleOnChange}
      />
      <Square
        size={size}
        status={
          indeterminate ? "indeterminate" : checked ? "checked" : "unchecked"
        }
      />
    </div>
  );

  if (!label) {
    return checkbox;
  }

  return (
    <CheckboxLabel
      size={size}
      disabled={disabled}
      labelClassName={classnames(labelClassName, {
        "hover:text-straps-hyperlink-hover": checked && !disabled,
      })}
      label={label}
      labelIcon={labelIcon}
      labelIconProps={labelIconProps}
      labelVariant={
        labelVariant ?? (size === "small" ? "sb_t-12-500" : "sb_t-14-500")
      }
    >
      {checkbox}
    </CheckboxLabel>
  );
};

function Square({
  status,
  size,
}: {
  status: "checked" | "unchecked" | "indeterminate";
  size: "default" | "small";
}) {
  return (
    <svg
      width={size === "default" ? "16px" : "12px"}
      height={size === "default" ? "16px" : "12px"}
      viewBox="0 0 16 16"
      className={classnames(
        styles.checkbox,
        "pointer-events-none fill-current outline-straps-hyperlink-hover"
      )}
    >
      <g
        data-testid="checkbox-checked"
        className={classnames("transition-opacity duration-150 ease-in-out", {
          "opacity-0": status === "unchecked",
          "opacity-100": status !== "unchecked",
        })}
      >
        {status === "indeterminate" && (
          <>
            <path
              opacity="0.5"
              d="M15 1H1V15H15V1Z"
              stroke="#838383"
              strokeWidth="2"
            />
            <path d="M15.999 0H-0.000976562V16H15.999V0Z" />
            <path
              d="M 10 8 L 6 8"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
          </>
        )}
        {status !== "indeterminate" && (
          <>
            <path
              opacity="0.5"
              d="M15 1H1V15H15V1Z"
              stroke="#838383"
              strokeWidth="2"
            />
            <path d="M15.999 0H-0.000976562V16H15.999V0Z" />
            <path
              d="M5.17603 8.25598L7.54059 10.6205"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
            <path
              d="M11.7613 6.39989L7.54059 10.6206"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
          </>
        )}
      </g>

      <path
        d="M5.68434189e-14,16 L5.68434189e-14,0 L16,1.46957616e-15 L16,16 L5.68434189e-14,16 Z M2,1.999 L2,14 L14,14 L14,2 L2,1.999 Z"
        id="Combined-Shape"
        data-testid="checkbox-unchecked"
      />
    </svg>
  );
}
export default Checkbox;
