import * as R from "ramda";
import Handsontable from "handsontable";
import Fuse from "fuse.js";

import { querySiteDataStreams } from "../../global_functions/queryPostgrest";

import type {
  DataStreamData,
  ManualChanges,
  MappingSummaryDatastream,
} from "../../types";

export const dataEntries = (obj: {
  [id: string]: Array<MappingSummaryDatastream>;
}): Array<[string, Array<MappingSummaryDatastream>]> =>
  Object.keys(obj).map((key) => [key, obj[key]!]);

export const getDataStreamLabelName = (dataStream: DataStreamData): string => {
  if (dataStream.bractlet_name != null) return dataStream.bractlet_name;
  if (dataStream.name != null) return dataStream.name;
  return "unassigned";
};

export const getChangeKey = (row: number, prop: string) => `${row}-${prop}`;

const addRowId = (dataArray: Array<DataStreamData>): Array<DataStreamData> =>
  dataArray.map((data, index) => ({
    ...data,
    rowId: index,
  }));

export const queryMappingData = (siteId: number) => {
  return querySiteDataStreams(siteId)
    .then((res) => {
      return {
        basPoints: addRowId(res[0]!),
        powerMeters: addRowId(res[1]!),
        weather: addRowId(res[2]!),
      };
    })
    .catch((error) => {
      return {
        basPoints: [],
        powerMeters: [],
        weather: [],
      };
    });
};

export const mappingTypeToRowTransform = (mappingType: string) => {
  switch (mappingType) {
    case "basPoints":
      return (dataRow: DataStreamData) => {
        let rowObject: Record<string, any> = {};
        rowObject["name"] = dataRow.bractlet_name;
        rowObject["brick_point_type"] = dataRow.brick_point_type;
        rowObject["units"] = dataRow.unit_type;
        rowObject["parent_equipment_name"] = dataRow.parent_equipment_name;
        rowObject["parent_equipment_brick_type"] =
          dataRow.parent_equipment_brick_type;

        // Add tokens -- maybe later
        // rowObject["tokens"] = dataRow.mapping_tokens || [];
        return rowObject;
      };
    case "powerMeters":
      return (dataRow: DataStreamData) => {
        let rowObject: Record<string, any> = {};
        rowObject["name"] = dataRow.bractlet_name;
        rowObject["unit"] = dataRow.unit_type;
        rowObject["parent_equipment_name"] = dataRow.parent_equipment_name;
        rowObject["brick_point_type"] = dataRow.brick_point_type;
        rowObject["parent_equipment_brick_type"] =
          dataRow.parent_equipment_brick_type;

        return rowObject;
      };
    case "weather":
      return (dataRow: DataStreamData) => {
        let rowObject: Record<string, any> = {};
        rowObject["name"] = dataRow.bractlet_name;
        rowObject["unit"] = dataRow.unit_type;
        return rowObject;
      };
    default:
      return;
  }
};

export const mappingTypeToHeaders = (mappingType: string) => {
  switch (mappingType) {
    case "basPoints":
      return [
        "Name",
        "Units",
        "Brick Point Type",
        "Parent Equipment Name",
        "Parent Equipment Type",
      ];
    case "powerMeters":
      return [
        "Name",
        "Unit",
        "Brick Point Type",
        "Parent Equipment Name",
        "Parent Equipment Type",
      ];
    case "weather":
      return ["Name", "Unit", "Brick Point Type"];
    default:
      return;
  }
};

// prettier-ignore
const getDataStreamPathValues = R.curry(
  (prop: string, value?: string | null): Array<string> | string | null | undefined => {
    if (prop === "data_stream_path") {
      return typeof value === "string" ? value.split("-") : [];
    } else {
      return value;
    }
  }
);

export const pillRenderer = (
  instance: any,
  td: any,
  row: number,
  col: number,
  prop: string,
  values: unknown,
  cellProperties: unknown
) => {
  while (td.firstChild) {
    td.removeChild(td.firstChild);
  }

  Handsontable.renderers
    .getRenderer("base")
    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'CellProperties'.
    .call(null, instance, td, row, col, prop, values, cellProperties);

  const container = document.createElement("div");
  container.className = "cell-pill-container";

  // @ts-ignore ?
  R.flatten(R.map(getDataStreamPathValues(prop), [values]))
    .filter(Boolean)
    .forEach((value) => {
      const cellPill = document.createElement("div");
      cellPill.className = "cell-pill";
      // @ts-expect-error - TS2322 - Type '(x: never) => never' is not assignable to type 'string'.
      cellPill.textContent = value;
      container.appendChild(cellPill);
    });
  td.appendChild(container);
};

export const mappingTypeToFormatting = (
  mappingType: string,
  brickPointTypes: Array<string>,
  equipmentNames: Array<string>,
  brickEquipmentTypes: Array<string>
): Array<unknown> => {
  switch (mappingType) {
    case "basPoints":
      return [
        { data: "name", readOnly: true },
        { data: "units", readOnly: true },
        // { data: "tokens", renderer: pillRenderer, readOnly: true },
        {
          data: "brick_point_type",
          renderer: pillRenderer,
          editor: AutocompleteEditor,
          source: brickPointTypes,
        },
        {
          data: "parent_equipment_name",
          renderer: pillRenderer,
          editor: AutocompleteEditor,
          source: equipmentNames,
        },
        {
          data: "parent_equipment_brick_type",
          renderer: pillRenderer,
          editor: AutocompleteEditor,
          source: brickEquipmentTypes,
        },
      ];
    case "powerMeters":
      return [
        { data: "name", readOnly: true },
        {
          data: "unit",
          readOnly: true,
        },
        {
          data: "brick_point_type",
          renderer: pillRenderer,
          editor: AutocompleteEditor,
          source: brickPointTypes,
        },
        {
          data: "parent_equipment_name",
          renderer: pillRenderer,
          editor: AutocompleteEditor,
          source: equipmentNames,
        },
        {
          data: "parent_equipment_brick_type",
          renderer: pillRenderer,
          editor: AutocompleteEditor,
          source: brickEquipmentTypes,
        },
      ];
    default:
      return [
        { data: "name", readOnly: true },
        {
          data: "unit",
          readOnly: true,
        },
        {
          data: "brick_point_type",
          renderer: pillRenderer,
          editor: AutocompleteEditor,
          source: brickPointTypes,
        },
      ];
  }
};

class AutocompleteEditor extends Handsontable.editors.AutocompleteEditor {
  // @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
  prepare(...args) {
    // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    super.prepare(...args);

    this.cellProperties.filter = false;
    this.cellProperties.sortByRelevance = false;
  }

  getDropdownHeight() {
    const rowHeight = 50;
    const visibleRows = 5;
    // @ts-expect-error - TS2339 - Property 'strippedChoices' does not exist on type 'AutocompleteEditor'.
    return this.strippedChoices.length >= visibleRows
      ? visibleRows * rowHeight
      : // @ts-expect-error - TS2339 - Property 'strippedChoices' does not exist on type 'AutocompleteEditor'.
        this.strippedChoices.length * rowHeight + 8;
  }

  // @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
  open(...args) {
    // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    super.open(...args);

    // @ts-expect-error - TS2339 - Property 'htEditor' does not exist on type 'AutocompleteEditor'.
    const instance = this.htEditor.getInstance();
    const { cellProperties } = this;

    instance.updateSettings({
      // @ts-expect-error - TS7006 - Parameter 'td' implicitly has an 'any' type. | TS7006 - Parameter 'row' implicitly has an 'any' type. | TS7006 - Parameter 'col' implicitly has an 'any' type. | TS7006 - Parameter 'prop' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
      afterRenderer: (td, row, col, prop, value) => {
        pillRenderer(
          instance,
          td,
          row,
          col,
          // @ts-expect-error - TS2345 - Argument of type 'string | number' is not assignable to parameter of type 'string'.
          cellProperties.prop,
          value,
          cellProperties
        );
      },
    });
  }

  updateChoicesList(choices: any) {
    const query = (this.getValue() || "").trim();
    const fuse = new Fuse<any>(choices, {
      shouldSort: true,
      threshold: 0.6,
    });

    super.updateChoicesList(
      query ? fuse.search(query).map(({ item }) => item) : choices
    );
  }
}

const reverseChangeKey = (
  changeKey: string
): {
  bractlet_id: number;
  prop: string;
} => {
  const splitKey = changeKey.split("-");
  return {
    bractlet_id: parseInt(splitKey[0]!, 10),
    prop: splitKey[1]!,
  };
};

export const applyManualChanges = (
  basData: Array<DataStreamData>,
  manualChanges: ManualChanges
): Array<DataStreamData> => {
  for (const entry of manualChanges) {
    const changeObj = reverseChangeKey(entry[0]);
    const setIndex = R.findIndex(
      R.propEq("bractlet_id", changeObj.bractlet_id)
    )(basData);
    if (setIndex !== -1) {
      const setRow = R.view(R.lensIndex(setIndex), basData);
      basData = R.set(
        R.lensIndex(setIndex),
        // @ts-expect-error - TS2345 - Argument of type 'Lens<Data, string | number | number[] | null | undefined>' is not assignable to parameter of type 'Lens<Data, unknown>'. | TS2345 - Argument of type 'string' is not assignable to parameter of type 'keyof Data'.
        R.set(R.lensProp(changeObj.prop), entry[1], setRow),
        basData
      );
    }
  }
  return basData;
};
