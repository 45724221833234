import Cookies from "js-cookie";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { VITE_SITE_SERVICE_BASE_URL } from "../../../env";
import { CamelCasedProperties } from "type-fest";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "../../postgrestApi";
import request from "../../request";
import { ClientOutput } from "../client";

const vendors = {
  useQuery,
  usePost,
  usePatch,
  useDelete,
};

export default vendors;

const QUERY_KEY = "vendors";

export type Vendor = CamelCasedProperties<ClientOutput["vendors"]["Vendor"]>;
export type VendorType = CamelCasedProperties<
  ClientOutput["vendors"]["VendorType"]
>;
type VendorAdd = CamelCasedProperties<ClientOutput["vendors"]["VendorAdd"]>;
export type VendorEdit = CamelCasedProperties<
  ClientOutput["vendors"]["VendorEdit"]
>;

const getVendors = (siteId: number): Promise<Vendor[]> =>
  request
    .get(`${VITE_SITE_SERVICE_BASE_URL}/vendors`)
    .query({
      site_id: siteId,
    })
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .then((res) => recursiveCamelCaseCipher(res.body));

function useQuery(siteId: number) {
  return useReactQuery({
    queryKey: [QUERY_KEY, siteId],
    queryFn: async () => getVendors(siteId),
  });
}

export const useInvalidateVendorsQuery = (siteId: number) => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries([QUERY_KEY, siteId]);
};

const postVendor = (vendor: VendorAdd) =>
  request
    .post(`${VITE_SITE_SERVICE_BASE_URL}/vendors`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .send(recursiveSnakeCaseCipher(vendor));

function usePost(siteId: number) {
  const invalidateVendorsQuery = useInvalidateVendorsQuery(siteId);

  return useMutation({
    mutationFn: (vendor: Omit<VendorAdd, "siteId">) =>
      postVendor({ ...vendor, siteId }),
    onSuccess: async () => await invalidateVendorsQuery(),
  });
}

const patchVendor = (vendorId: number, vendor: VendorEdit) =>
  request
    .patch(`${VITE_SITE_SERVICE_BASE_URL}/vendors/${vendorId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .send(recursiveSnakeCaseCipher(vendor));

function usePatch(siteId: number) {
  const invalidateVendorsQuery = useInvalidateVendorsQuery(siteId);

  return useMutation({
    mutationFn: ({
      vendorId,
      vendor,
    }: {
      vendorId: number;
      vendor: VendorEdit;
    }) => patchVendor(vendorId, vendor),
    onSuccess: async () => await invalidateVendorsQuery(),
  });
}

const deleteVendor = (vendorId: number) => {
  return request
    .delete(`${VITE_SITE_SERVICE_BASE_URL}/vendors/${vendorId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`);
};

function useDelete(siteId: number) {
  const invalidateVendorsQuery = useInvalidateVendorsQuery(siteId);

  return useMutation({
    mutationFn: (vendorId: number) => deleteVendor(vendorId),
    onSuccess: async () => await invalidateVendorsQuery(),
  });
}
