import { useCallback, useRef } from "react";

type Noop = () => void;
type AsyncNoop = () => Promise<void> | void;
export type SubscribeFn = (callback: AsyncNoop) => Noop;
export default function useSubscription() {
  const subscribers = useRef<Array<AsyncNoop>>([]);

  const subscribe = useCallback((callback: AsyncNoop) => {
    subscribers.current.push(callback);

    return () => {
      subscribers.current = subscribers.current.filter((cb) => cb !== callback);
    };
  }, []);

  const event = useCallback(async () => {
    await Promise.all(subscribers.current.map(async (cb) => await cb()));
  }, []);

  return [subscribe, event, subscribers] as const;
}
