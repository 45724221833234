import ls from "@livesession/sdk";
import { VITE_LIVESESSION_ID } from "./env";

const isLiveSessionAllowed = Boolean(VITE_LIVESESSION_ID);
type UserAttributes = {
  email: string;
  name: string;
  userId?: number;
  accountId?: string;
};

export default {
  // Initialization
  initialize: function () {
    if (!isLiveSessionAllowed) {
      return;
    }

    ls.init(VITE_LIVESESSION_ID);
    ls.newPageView();
  },

  // Users identification
  // @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'UserAttributes': email, name
  identifyUser: function (attributes: UserAttributes = {}) {
    if (!isLiveSessionAllowed) {
      return;
    }

    const { email, name, ...params } = attributes;
    ls.identify({
      email,
      name,
      params,
    });
  },
};
