/* @flow */
import classNames from "classnames";
import { ElementType, forwardRef, useMemo } from "react";
import { Loader } from "../../../../../global_components";

import Icon, { IconNames } from "../../../icons/Icon/Icon";
import { ButtonProps } from "../Button";
import styles from "./TextButton.module.scss";
import { PolymorphicRef } from "../../../../../utilTypes";
import Text, { TextVariants } from "../../../type/Text/Text";
import { SemanticColors } from "../../../../colors";

const buttonColorClasses: Record<SemanticColors, string> = {
  primary:
    "text-straps-primary hover:text-straps-primary-hover focus-visible:outline-straps-primary-hover",
  secondary:
    "text-straps-secondary hover:text-straps-secondary-hover focus-visible:outline-straps-secondary-hover",
  positive:
    "text-straps-positive hover:text-straps-positive-hover focus-visible:outline-straps-positive-hover",
  warning:
    "text-straps-warning hover:text-straps-warning-hover focus-visible:outline-straps-warning-hover",
  negative:
    "text-straps-negative hover:text-straps-negative-hover focus-visible:outline-straps-negative-hover",
  brand:
    "text-straps-brand hover:text-straps-brand-hover focus-visible:outline-straps-brand-hover",
  hyperlink:
    "text-straps-hyperlink hover:text-straps-hyperlink-hover focus-visible:outline-straps-hyperlink-hover",
  "accent-1":
    "text-straps-accent-1 hover:text-straps-accent-1-hover focus-visible:outline-straps-accent-1-hover",
  white:
    "text-pure-white hover:text-pure-white focus-visible:outline-pure-white-200",
} as const;

type ButtonComponent = <C extends React.ElementType = "button">(
  props: ButtonProps<C>
) => React.ReactElement | null;

export type TextButtonProps = {
  noFrameVariant?: "combo" | "combo-s" | "text" | "hyperlink" | "icon";
};

const textVariantMap: Record<
  Exclude<TextButtonProps["noFrameVariant"], undefined | "icon">,
  TextVariants
> = {
  combo: "sc_u-14-700",
  "combo-s": "sa_t-12-700",
  text: "sb_t-12-500",
  hyperlink: "sa_t-14-700",
};

const TextButton: ButtonComponent = forwardRef(function TextButton<
  C extends ElementType = "button"
>(
  {
    className,
    noFrameVariant: variant = "combo",
    disabled,
    icon,
    iconRight,
    children,
    onClick,
    // default color to hyperlink if hyperlink
    color = variant === "hyperlink" ? "hyperlink" : "primary",
    dataTestId = "text-button",
    loading,
    component,
    ...otherProps
  }: ButtonProps<C>,
  ref: PolymorphicRef<C>
) {
  const sizingClasses = useMemo(() => {
    return classNames({
      "h-4": variant === "icon",
      "h-3.5": variant === "combo" || variant === "combo-s",
      "h-3": variant === "text",
    });
  }, [variant]);

  const buttonClasses = useMemo(() => {
    return classNames(
      styles.button,
      sizingClasses,
      "inline-flex relative",
      "items-center",
      "justify-center",
      "disabled:opacity-25",
      "gap-x-2",
      "bg-transparent border-0",
      "group",
      "p-0",
      "transition-colors",
      buttonColorClasses[color],
      {
        "focus:outline-straps-hyperlink-hover": variant !== "combo-s",
        "focus:outline-straps-negative-hover": variant === "combo-s",
        "cursor-pointer": !disabled,
        "cursor-not-allowed pointer-events-none": disabled,
      },
      className
    );
  }, [className, disabled, sizingClasses, variant, color]);

  const Component = component || "button";
  return (
    <Component
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      className={buttonClasses}
      data-testid={dataTestId}
      {...otherProps}
    >
      <IconLoaderCombo
        size={variant === "icon" ? "medium" : "small"}
        icon={icon}
        loading={loading}
        iconRight={iconRight}
      />
      {children && variant && variant !== "icon" && (
        <Text
          as="span"
          variant={textVariantMap[variant]}
          className={classNames({
            underline: variant === "hyperlink",
            "opacity-0": loading && !icon,
          })}
          color="inherit"
        >
          {children}
        </Text>
      )}
    </Component>
  );
});

function IconLoaderCombo({
  icon,
  iconRight,
  loading,
  size,
}: {
  icon?: IconNames;
  loading?: boolean;
  iconRight?: boolean;
  size?: "small" | "medium";
}) {
  if (loading) {
    return (
      <Loader
        size={icon ? 10 : 12}
        color="currentColor"
        className={classNames({
          "order-1": icon && iconRight,
        })}
      />
    );
  }

  if (icon) {
    return (
      <Icon
        name={icon}
        size={size}
        className={classNames({
          "order-1": iconRight,
        })}
      />
    );
  }

  return null;
}

export default TextButton;
