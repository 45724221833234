import classnames from "classnames";
import { ComponentProps, FunctionComponent } from "react";
import { ExtendedSemanticColors } from "../../../colors";
import Icon, { IconNames } from "../../icons/Icon/Icon";
import Text, { TextVariants } from "@src/straps/base/type/Text/Text";

export type CheckboxLabelProps = {
  label: string;
  disabled?: boolean;
  labelClassName?: string;
  labelVariant?: TextVariants;
  labelColor?: ExtendedSemanticColors;
  labelIcon?: IconNames;
  labelIconProps?: Omit<ComponentProps<typeof Icon>, "name">;
  size?: "default" | "small";
};

const CheckboxLabel: FunctionComponent<CheckboxLabelProps> = ({
  children,
  disabled,
  label,
  labelVariant,
  labelIcon,
  labelIconProps,
  labelClassName,
  labelColor = "primary",
  size = "default",
}) => {
  return (
    <label
      className={classnames(
        labelClassName,
        {
          "cursor-pointer": !disabled,
        },
        "inline-flex items-center"
      )}
    >
      {children}
      <Text
        variant={labelVariant}
        color={labelColor}
        className={classnames("flex", {
          "opacity-25": disabled,
          "ml-3": size === "default",
          "ml-2": size === "small",
        })}
      >
        {!!labelIcon && (
          <Icon
            {...labelIconProps}
            name={labelIcon}
            className={classnames("mr-1 shrink-0", labelIconProps?.className)}
          />
        )}
        {label}
      </Text>
    </label>
  );
};

export default CheckboxLabel;
