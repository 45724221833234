import * as React from "react";
import { Route } from "react-router-dom";
import classnames from "classnames";
import { startCase } from "lodash";

import { useSite } from "../../context/SitesContext";
import UserContext from "../../context/UserContext";
import UnseenContentContext from "../../context/UnseenContentContext";

import NavItem from "./NavItem";
import * as Icon from "../../icons";
import { isDev } from "../../env";

// @ts-expect-error - TS7031 - Binding element 'match' implicitly has an 'any' type.
const IPNav = ({ match }) => {
  const { siteId, accountSubPage, page = "" } = match.params;
  const currentSite = useSite(siteId);
  const { currentUser } = React.useContext(UserContext);
  const { unseenContent, refreshUnseenContent } =
    React.useContext(UnseenContentContext);
  const baseURL = siteId != null ? `/site/${siteId}` : "";
  const isDashboard = siteId == null;

  const accountId = match.params.accountId || currentSite?.accountId;

  const itemProps = React.useCallback(
    (navPage = "", deactivated = false) => ({
      urlLink: `${baseURL}/${navPage}`,
      selected: page === navPage,
      deactivated: isDashboard || deactivated,
      className: classnames({ "--hidden": isDashboard }),
      title: startCase(navPage),
    }),
    [baseURL, isDashboard, page]
  );

  React.useEffect(() => {
    if (siteId) refreshUnseenContent(siteId);
  }, [refreshUnseenContent, siteId]);

  const tabs = React.useMemo(() => {
    if (currentSite == null || currentUser == null) return [];
    const tabList = [
      {
        tiers: ["benchmark", "analytics", "simulation"],
        id: "dashboard",
        // @ts-expect-error - TS7031 - Binding element 'deactivated' implicitly has an 'any' type.
        NavComponent: ({ deactivated }) => (
          <NavItem
            icon={<Icon.BuildingDashboard />}
            {...itemProps("dashboard", deactivated)}
            urlLink={`${baseURL}/dashboard/home`}
            title="Building Dashboard"
          />
        ),
      },
      {
        tiers: ["simulation"],
        id: "idl",
        // @ts-expect-error - TS7031 - Binding element 'deactivated' implicitly has an 'any' type.
        NavComponent: ({ deactivated }) => (
          <NavItem
            icon={<Icon.InvestmentDecisionLab />}
            {...itemProps("projects", deactivated)}
            selected={["projects", "timeline"].includes(page)}
            title="Investment Decision Lab"
          />
        ),
      },
      {
        tiers: ["benchmark", "analytics", "simulation"],
        id: "benchmark",
        // @ts-expect-error - TS7031 - Binding element 'deactivated' implicitly has an 'any' type.
        NavComponent: ({ deactivated }) => (
          <NavItem
            icon={<Icon.Benchmark />}
            {...itemProps("benchmark", deactivated)}
            urlLink={`${baseURL}/benchmark/dashboard`}
          />
        ),
      },
      {
        tiers: ["analytics", "simulation"],
        id: "operational-notifications",
        // @ts-expect-error - TS7031 - Binding element 'deactivated' implicitly has an 'any' type.
        NavComponent: ({ deactivated }) => (
          <NavItem
            icon={<Icon.Monitoring />}
            {...itemProps("operational-notifications", deactivated)}
            hasNewContent={
              unseenContent != null && unseenContent.hasUnseenNotification
            }
          />
        ),
      },
      {
        tiers: ["analytics", "simulation"],
        id: "data-visualization",
        // @ts-expect-error - TS7031 - Binding element 'deactivated' implicitly has an 'any' type.
        NavComponent: ({ deactivated }) => (
          <NavItem
            icon={<Icon.Analytics />}
            {...itemProps("data-visualization", deactivated)}
          />
        ),
      },
      {
        tiers: ["benchmark", "analytics", "simulation"],
        id: "utilityLinking",
        // @ts-expect-error - TS7031 - Binding element 'deactivated' implicitly has an 'any' type.
        NavComponent: ({ deactivated }) => (
          <NavItem
            icon={<Icon.UtilityConnect />}
            {...itemProps("account-linking", deactivated)}
            urlLink={`${baseURL}/account-linking/utilities`}
          />
        ),
      },
    ];

    if (isDev) {
      tabList.push({
        tiers: ["benchmark", "analytics", "simulation"],
        id: "monthlyReport",
        NavComponent: ({ deactivated }) => (
          <NavItem
            icon={<Icon.CustomerSatisfaction />}
            {...itemProps("monthly-report", deactivated)}
            urlLink={`${baseURL}/monthly-report/`}
          />
        ),
      });
    }
    return tabList;
  }, [baseURL, currentSite, currentUser, itemProps, page, unseenContent]);

  return (
    <>
      <NavItem
        icon={<Icon.Buildings />}
        selected={match.params.accountId && !siteId && !accountSubPage}
        title={isDashboard ? "Dashboard" : "Portfolio"}
        urlLink={`/account/${accountId}`}
      />
      <NavItem
        icon={<Icon.Performance />}
        selected={accountSubPage === "portfolio-rollup"}
        title={"Portfolio Rollup"}
        urlLink={`/account/${accountId}/portfolio-rollup`}
      />
      {(currentUser?.superAdmin || currentUser?.rollupEnabled) && (
        <NavItem
          icon={<Icon.ESGOverview />}
          selected={accountSubPage === "esg-rollup"}
          title={"ESG Rollup"}
          urlLink={`/account/${accountId}/esg-rollup`}
        />
      )}
      <div
        className={classnames("nav-item-separator", isDashboard && "--hidden")}
      />
      {currentSite && (
        <>
          {tabs
            .filter(({ tiers }) => tiers.includes(currentSite.productTier))
            .filter(
              ({ id }) => !currentUser?.readOnly || id !== "utilityLinking"
            )
            .map(({ NavComponent, id }) => (
              <NavComponent key={id} deactivated={false} />
            ))}
          {currentSite.productTier !== "simulation" && (
            <>
              <div className="nav-item-separator" />
              <div className="discover-higher-tier">
                <div>Discover</div>
                {/* <button className="request-demo-button">
                  Request Demo
                  <Icon.Caret />
                </button> */}
              </div>
              {tabs
                .filter(({ tiers }) => !tiers.includes(currentSite.productTier))
                .map(({ NavComponent, id }) => (
                  <NavComponent key={id} deactivated />
                ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default () => (
  <Route
    path={["/site/:siteId/:page?", "/account/:accountId/:accountSubPage?", "/"]}
    component={IPNav}
  />
);
