import * as React from "react";
import * as ReactDOM from "react-dom";
import classNames from "classnames";
import AutosizeInput from "react-input-autosize";
import AnimateHeight from "react-animate-height";
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'react-repeatable'. '/Users/stevenbiersteker/src/valinor/node_modules/react-repeatable/lib/index.js' implicitly has an 'any' type.
import Repeatable from "react-repeatable";
import { lowerCase } from "lodash";

import Icon, { IconsProps } from "../icons";
import { useClientRect } from "../global_functions/hooks";

export type InputStatuses =
  | "Unevaluated"
  | "Valid"
  | "Invalid"
  | "Warning"
  | "BractletSymbol"
  | "Gresb"
  | "Duplicate"
  | "Require";

export interface InputProps
  extends Omit<React.ComponentProps<"input">, "value"> {
  containerClassName?: string;
  className?: string;
  icon?: IconsProps["icon"];
  iconClassName?: string;
  iconStyle?: Record<any, any>;
  rounded?: boolean;
  gray?: boolean;
  autosize?: boolean;
  label?: React.ReactNode;
  labelPosition?: "top" | "right" | "bottom" | "left";
  overlay?: string;
  overlayPosition?: "right" | "left";
  style?: Record<any, any>;
  status?: {
    status: InputStatuses;
    hideIcon?: boolean;
    color?: string;
    message?: string | React.ReactNode;
  };
  type?: string;
  value?: string | number | null;
  step?: string | number;
  min?: string | number;
  max?: string | number;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  disableSpinner?: boolean;
  placeholder?: string;
  readOnly?: boolean;
}

const canSpin = (direction: "up" | "down", props: Partial<InputProps>) => {
  const step = props.step == null ? 1 : Number(props.step);
  const value = props.value == null ? 0 : Number(props.value);

  return direction === "up"
    ? props.max == null || value + step <= Number(props.max)
    : props.min == null || value - step >= Number(props.min);
};

// Call native function for stepping up/down, then trigger a change for react to pick up
const spin = (direction: "up" | "down", input?: HTMLInputElement | null) => {
  const fn = direction === "up" ? "stepUp" : "stepDown";
  if (input && input[fn]) {
    input[fn]();
    input.dispatchEvent(new Event("input", { bubbles: true }));
  }
};

// @ts-expect-error - TS7031 - Binding element 'className' implicitly has an 'any' type. | TS7031 - Binding element 'disabled' implicitly has an 'any' type. | TS7031 - Binding element 'onClick' implicitly has an 'any' type.
const Spinner = ({ className, disabled, onClick }) => (
  <Repeatable
    repeatDelay={500}
    repeatInterval={50}
    onPress={onClick}
    onHold={onClick}
  >
    <Icon
      className={classNames(className, { "--disabled": disabled })}
      icon="SideArrow"
    />
  </Repeatable>
);

const Input = ({
  containerClassName,
  className,
  icon,
  iconClassName,
  iconStyle,
  rounded,
  gray,
  autosize,
  label,
  labelPosition = "top",
  overlay,
  overlayPosition = "left",
  style,
  status,
  onClick,
  disableSpinner,
  readOnly = false,
  value,
  ...props
}: InputProps) => {
  const [hovered, setHovered] = React.useState(false);
  const inputRef = React.useRef();
  const inputRefCallback = (ref: any) => (inputRef.current = ref);

  const [{ width: overlayWidth }, overlayRef] = useClientRect();

  if (overlay || status) {
    if (overlayPosition === "left") {
      style = {
        ...style,
        paddingLeft: overlayWidth ? overlayWidth + 16 : null,
      };
    } else {
      style = {
        ...style,
        paddingRight: overlayWidth ? overlayWidth + 16 : null,
      };
    }
  }

  const messageRef = React.useRef<SVGSVGElement>(null);
  return (
    <div
      onClick={onClick}
      className={classNames(containerClassName, {
        "bractlet-input": true,
        "bractlet-input--rounded": rounded,
        "bractlet-input--has-icon": icon && overlayPosition !== "right",
        "bractlet-input--gray": gray,
        "bractlet-input--autosize": autosize,
        "bractlet-input--number": props.type === "number",
        "bractlet-input--range": props.type === "range",
        [`bractlet-input--${status ? lowerCase(status.status) : ""}`]:
          status && status.status,
      })}
      data-label-position={labelPosition}
    >
      {label && <div className="bractlet-input--label">{label}</div>}
      {React.createElement(autosize ? AutosizeInput : "input", {
        ...(autosize
          ? {
              className,
              style: { display: "contents" },
              inputStyle: style,
              inputRef: inputRefCallback,
            }
          : {
              className,
              style,
              ref: inputRefCallback,
              readOnly: readOnly,
              "aria-label": props.name,
            }),
        ...props,
        value: value === null ? "" : value,
      })}
      {Boolean(props.type === "number") && !disableSpinner && (
        <div className="bractlet-input--number-spinner">
          <Spinner
            className="--spin-up"
            disabled={!canSpin("up", props)}
            onClick={() => spin("up", inputRef.current)}
          />
          <Spinner
            className="--spin-down"
            disabled={!canSpin("down", props)}
            onClick={() => spin("down", inputRef.current)}
          />
        </div>
      )}
      <div
        className={classNames("bractlet-input--overlay-container", {
          "position-right": overlayPosition === "right",
          "position-left": overlayPosition === "left",
        })}
        ref={overlayRef}
      >
        {icon && (
          <Icon
            className={classNames("bractlet-input--icon", iconClassName)}
            style={iconStyle}
            icon={icon}
          />
        )}
        {status && !status.hideIcon && status.status !== "Unevaluated" && (
          <Icon
            className={classNames(`bractlet-input--status-icon`, {
              [`color--${status.status === "Invalid" ? "red" : "yellow"}`]:
                status.status === "Invalid" || status.status === "Warning",
              [`color--${status?.color || ""}`]: status.color,
              overlay: !!overlay,
            })}
            icon={status?.status === "Warning" ? "Invalid" : status?.status}
            onMouseLeave={() => setHovered(false)}
            onMouseOver={() => setHovered(true)}
            innerRef={messageRef}
          />
        )}
        {overlay && (
          <div className={classNames("bractlet-input--overlay")}>{overlay}</div>
        )}
      </div>
      {status && status.message && (
        <ErrorMessage
          hovered={hovered}
          setHovered={setHovered}
          status={status}
          messageRef={messageRef}
        />
      )}
    </div>
  );
};

// @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
const ErrorMessage = (props) => {
  const { status, setHovered, hovered, messageRef } = props;

  // @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value. | TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
  const { right, bottom } = messageRef.current
    ? messageRef.current.getBoundingClientRect()
    : {};

  return (
    <div
      className="bractlet-input--status-message-wrapper"
      onMouseLeave={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
    >
      {ReactDOM.createPortal(
        <AnimateHeight
          duration={500}
          height={hovered ? "auto" : 0}
          animateOpacity
          className="bractlet-input--status-message-wrapper"
          style={{
            bottom: `calc(100vh - ${bottom - 10}px)`,
            right: `calc(100vw - ${right - 10}px)`,
          }}
        >
          <div className="bractlet-input--status-message">{status.message}</div>
        </AnimateHeight>,
        // @ts-expect-error - TS2345 - Argument of type 'HTMLElement | null' is not assignable to parameter of type 'Element | DocumentFragment'.
        document.getElementById("root")
      )}
    </div>
  );
};

export default Input;
