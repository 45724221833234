import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query/devtools";

import "./stylesheets/tailwind.css";
import "./stylesheets/index.scss";

import "./timezones";
import "./register-chart-plugins";

import AppRouter from "./AppRouter";

import BugsnagErrorBoundary from "./bugsnag";

import LiveSession from "./livesession";
import "./pendo";
import { intendedOrigin, isDev } from "./env";

import { Buffer } from "buffer";
import EventEmitter from "events";
import process from "process";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./utils/react-query";
import { isInExpectedEnv } from "./global_functions/request";

// sax.js needs these
// @ts-expect-error - TS2339 - Property 'Buffer' does not exist on type 'Window & typeof globalThis'.
window.Buffer = Buffer;
// @ts-expect-error - TS2339 - Property 'EventEmitter' does not exist on type 'Window & typeof globalThis'.
window.EventEmitter = EventEmitter;
window.process = process;

const { location } = window;

if (location.origin !== intendedOrigin) {
  if (isInExpectedEnv) {
    location.replace(
      [intendedOrigin, location.pathname, location.search, location.hash].join(
        ""
      )
    );
  }
}

// enable/disable demo mode, which really only adds or removes a class from the
// <body> tag:
(function () {
  const URL_PARAMS = new URLSearchParams(window.location.search);
  const getParam = (paramName: string) => URL_PARAMS.get(paramName);

  let msg = "";
  ["demo", "benchmark", "analytics", "esgDemo"].forEach((paramName) => {
    const paramValue = getParam(paramName);
    switch (paramValue) {
      case "true":
        localStorage.setItem(paramName, "true");
        msg += `${paramName} mode has been enabled!\n`;
        break;
      case "false":
        localStorage.removeItem(paramName);
        msg += `${paramName} mode has been disabled!\n`;
        break;
      default:
    }

    if (localStorage.getItem(paramName) === "true") {
      document.body.classList.add(paramName);
    }
  });
  if (msg !== "") alert(msg);
})();

const root = document.getElementById("root");

LiveSession.initialize();

if (root) {
  ReactDOM.render(
    <BugsnagErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
        {isDev && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </BugsnagErrorBoundary>,
    root
  );
}
