import * as React from "react";
import * as R from "ramda";
import Fuse from "fuse.js";
import uuid from "uuid/v4";
import ResizeObserver from "resize-observer-polyfill";
import { useLocation, useHistory } from "react-router-dom";

import type { EquipmentId } from "../types";
import { isTest, isDev } from "../env";
import { PrimitiveAtom, atom, useAtom } from "jotai";
import { useMutation } from "react-query";
import request from "./request";
import Cookies from "js-cookie";
import { downloadFile } from "./util";

type UpdateSet<T> = {
  add: (arg1: T) => void;
  delete: (arg1: T) => void;
  clear: () => void;
  set: (arg1: ((arg1: Set<T>) => Set<T>) | Set<T>) => void;
};

export const useSet = <T>(initial?: T[]): [Set<T>, UpdateSet<T>] => {
  const [value, setValue] = React.useState<Set<T>>(new Set(initial));

  const update = React.useMemo(() => {
    return {
      add: (item: T) =>
        setValue((prev) => {
          prev.add(item);
          return new Set(prev);
        }),
      delete: (item: T) =>
        setValue((prev) => {
          prev.delete(item);
          return new Set(prev);
        }),
      clear: () =>
        setValue((prev) => {
          prev.clear();
          return new Set(prev);
        }),
      set: setValue,
    };
  }, []);

  return [value, update];
};

type UpdateMap<K, V> = {
  set: (key: K, value: V) => void;
  delete: (key: K) => void;
};

export const useMap = <K, V>(
  initial?: Map<K, V>
): [
  Map<K, V>,
  UpdateMap<K, V>,
  (arg1: ((arg1: Map<K, V>) => Map<K, V>) | Map<K, V>) => void
] => {
  const [value, setValue] = React.useState<Map<K, V>>(new Map(initial));

  const update = React.useMemo(() => {
    return {
      set: (key: K, newValue: V) =>
        setValue((prev) => {
          prev.set(key, newValue);
          return new Map(prev);
        }),
      delete: (key: K) =>
        setValue((prev) => {
          prev.delete(key);
          return new Map(prev);
        }),
    };
  }, []);
  return [value, update, setValue];
};

export const useHiddenDatasets = (datasets: any): any => {
  const [hiddenDatasets, setHiddenDatasets] = React.useState({});

  const toggleHidden = (id: any): void => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
    setHiddenDatasets((hidden) => ({ ...hidden, [id]: !hidden[id] }));
  };

  return [
    hiddenDatasets,
    toggleHidden,
    React.useMemo(
      () =>
        // @ts-expect-error - TS7006 - Parameter 'dataset' implicitly has an 'any' type.
        (datasets || []).map((dataset) => ({
          ...dataset,
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
          hidden: hiddenDatasets[dataset.id],
        })),
      [datasets, hiddenDatasets]
    ),
  ];
};

export const useSelected = (initiallySelected: any): [any, any] => {
  const [selected, setSelected] = React.useState(() => {
    if (Array.isArray(initiallySelected)) return initiallySelected;
    if (initiallySelected == null) return [];
    return [initiallySelected];
  });

  const toggleSelected = React.useCallback((option, multiple) => {
    if (!option) {
      setSelected([]);
    } else if (multiple) {
      setSelected((prevSelected) => {
        if (prevSelected.includes(option.id)) {
          const optionId = option.id;
          return prevSelected.filter((id) => id !== optionId);
        } else {
          return [...prevSelected, option.id];
        }
      });
    } else {
      setSelected(option ? [option.id] : []);
    }
  }, []);

  return [selected, toggleSelected];
};

export const useScrollbarDimensions = () => {
  const [dimensions, setDimensions] = React.useState({ height: 0, width: 0 });
  const div = React.useRef<HTMLDivElement>();
  const updateDimensions = React.useRef<() => void>();

  updateDimensions.current = () => {
    if (div.current) {
      const height = div.current.offsetHeight - div.current.clientHeight;
      const width = div.current.offsetWidth - div.current.clientWidth;

      if (height !== dimensions.height || width !== dimensions.width) {
        setDimensions({ height, width });
      }
    }
  };

  React.useEffect(() => {
    div.current = document.createElement("div");
    div.current.style.visibility = "none";
    div.current.style.opacity = "0";
    div.current.style.pointerEvents = "none";
    div.current.style.overflow = "scroll";

    if (document.body) document.body.appendChild(div.current);

    const observer = new ResizeObserver(() => {
      if (updateDimensions.current) updateDimensions.current();
    });
    observer.observe(div.current);

    return () => {
      if (div.current) {
        observer.unobserve(div.current);

        if (document.body) document.body.removeChild(div.current);
      }
    };
  }, []);

  return dimensions;
};

export const useUUID = () => React.useState(uuid)[0];

export const usePropState = <T>(propValue: T): [T, (value: T) => void] => {
  const [value, setValue] = React.useState(propValue);
  React.useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return [value, setValue];
};

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);

  return { debouncedValue, setValue: setDebouncedValue };
};

export const useClientRect = (): [any, any] => {
  const [node, setNode] = React.useState<any>(null);
  const [rect, setRect] = React.useState({});

  const resizeObserver = React.useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        // Since we only observe the one element, we don't need to loop over the array
        const entry = entries[0]!;
        setRect(entry.contentRect);
      }),
    []
  );

  React.useEffect(() => {
    if (node) {
      resizeObserver.observe(node);
      return () => resizeObserver.unobserve(node);
    }
  }, [node, resizeObserver]);

  return [rect, setNode];
};

export const useFileBrowser = (
  acceptedFileTypes: Array<string> | string,
  handleFileSelect: (arg1: Array<File>) => void | Promise<void>
): [() => void] => {
  const ref = React.useRef<HTMLInputElement | null>(null);

  const openFileBrowser = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  React.useEffect(() => {
    const onClickHandler = (e: any) => (e.target.value = null);
    const onChangeHandler = (e: any) => {
      handleFileSelect(e.target.files)?.catch((error) => {
        if (isDev) {
          // eslint-disable-next-line
          console.error("Failed to select file: ", error);
        }
      });
    };

    ref.current = document.createElement("input");
    ref.current.type = "file";

    ref.current.style.display = "none";
    ref.current.accept = Array.isArray(acceptedFileTypes)
      ? acceptedFileTypes.join(",")
      : acceptedFileTypes;
    ref.current.addEventListener("click", onClickHandler);
    ref.current.addEventListener("change", onChangeHandler);

    if (document.body) document.body.appendChild(ref.current);

    return () => {
      ref.current?.removeEventListener("click", onClickHandler);
      ref.current?.removeEventListener("change", onChangeHandler);
      if (document.body && ref.current) document.body.removeChild(ref.current);
    };
  }, [acceptedFileTypes, handleFileSelect]);

  return [openFileBrowser];
};

export const useCursorPositionMaintainer = () => {
  const [cursorPosition, setCursorPosition] = React.useState<{
    target: EventTarget | null;
    cursorStart: number | null;
    cursorEnd: number | null;
    newValue: any;
    oldValue: any;
  }>({
    target: null,
    cursorStart: null,
    cursorEnd: null,
    newValue: null,
    oldValue: null,
  });

  React.useEffect(() => {
    const { target, cursorStart, cursorEnd, newValue, oldValue } =
      cursorPosition;
    if (target) {
      const cursorShift =
        newValue && oldValue ? newValue.length - oldValue.length : 0;
      // @ts-expect-error - TS2339 - Property 'setSelectionRange' does not exist on type 'never'.
      target.setSelectionRange(
        // @ts-expect-error Object is possibly 'null'.
        cursorStart + cursorShift,
        // @ts-expect-error Object is possibly 'null'.
        cursorEnd + cursorShift
      );
    }
  }, [cursorPosition]);

  return setCursorPosition;
};

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number): void => {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useDidUpdateEffect = (func: () => void): void => {
  const hasMounted = React.useRef(false);

  React.useEffect(() => {
    if (hasMounted.current) func();
    else hasMounted.current = true;
  }, [func]);
};

// TODO: write an eslint rule to prevent us from committing uses of this
// Usage: useDebugRerender("MySlowComponent", { prop1, prop2, state1, state2 });
export const useDebugRerender = (name: string, values: any): void => {
  const ref = React.useRef(values);

  React.useEffect(() => {
    const diffs: Array<{
      key: string;
      next: any;
      prev: unknown;
    }> = [];

    Object.entries(ref.current).forEach(([key, prev]: [any, any]) => {
      const next = values[key];
      if (!Object.is(prev, next)) {
        diffs.push({ key, prev, next });
      }
    });

    if (diffs.length) {
      console.groupCollapsed(`Rerendering ${name}`); // eslint-disable-line
      console.table(diffs, ["key", "prev", "next"]); // eslint-disable-line
      console.groupEnd(); // eslint-disable-line
    } else {
      console.debug(`Rerendering ${name} (props identical)`); // eslint-disable-line
    }

    ref.current = values;
  });
};
/**
 * Prior to React 17, event listeners registered at the document received ALL click
 * events, regardless of `e.stopPropagation` called on elements in the React tree. At {@link https://legacy.reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation React 17}, `e.stopPropagation`
 * stops the propagation of react events to listeners registered at the document, as is expected.
 *
 * At the upgrade to React 17, the default options of the event listeners in this hook were updated to receive the event
 * during the capture phase, to continue the prior behavior of receiving all events.
 *
 * @param options - set { capture: false } for the event listener to recieve the event during the
 * bubble phase and enable `e.stopPropagation` to stop the event from reaching the listener.
 */

export const useDidClickOutsideElement = (
  cb: (arg1: boolean) => void,
  condition: boolean = true,
  options = { capture: true }
): any => {
  const ref = React.useRef<HTMLElement>();
  const { capture } = options;
  React.useEffect(() => {
    if (condition) {
      const onClickHandler = (e: MouseEvent) => {
        // @ts-expect-error - Argument of type 'EventTarget | null' is not assignable to parameter of type 'Node | null'.
        if (ref.current && !ref.current.contains(e.target)) {
          cb(false);
        }
      };
      document.addEventListener("click", onClickHandler, { capture });

      return () => {
        document.removeEventListener("click", onClickHandler, {
          capture,
        });
      };
    }
  }, [cb, condition, capture]);

  return ref;
};

export const useSearchParams = () => {
  const history = useHistory();
  const location = useLocation();

  const getAllParams = React.useCallback((): {
    [key: string]: string | null | undefined;
  } => {
    const output: Record<string, any> = {};
    const searchParams = new URLSearchParams(location.search);
    for (let [key, val] of searchParams.entries()) {
      // not supporting recurring search keys currently b/c no current need
      output[key] = val;
    }
    return output;
  }, [location.search]);

  const getParsedParam = React.useCallback(
    <T>(
      key: string,
      parser: (arg1: string) => T | null | undefined
    ): T | null | undefined => {
      const searchParams = new URLSearchParams(location.search);
      const incomingParam = searchParams.get(key);
      if (incomingParam == null) return null;
      const parsedParam = parser(incomingParam);
      if (incomingParam != null && parsedParam == null) {
        searchParams.delete(key);
        history.replace({
          pathname: location.pathname,
          search: "?" + searchParams.toString(),
        });
      }
      return parsedParam;
    },
    [history, location.pathname, location.search]
  );

  const getAllParsedParams = React.useCallback(
    (keyParserPairs: {
      [key: string]: (arg1: string) => any | null | undefined;
    }): {
      [key: string]: any | null | undefined;
    } => {
      let hasInvalidParam = false;
      const searchParams = new URLSearchParams(location.search);
      for (let [key, val] of searchParams.entries()) {
        if (keyParserPairs[key] == null || keyParserPairs[key]!(val) == null) {
          hasInvalidParam = true;
          searchParams.delete(key);
        }
      }
      if (hasInvalidParam) {
        history.replace({
          pathname: location.pathname,
          search: "?" + searchParams.toString(),
        });
      }
      return R.mapObjIndexed((parser, key) => {
        const paramVal = searchParams.get(key);
        if (paramVal == null) return null;
        return parser(paramVal);
      }, keyParserPairs);
    },
    [history, location.pathname, location.search]
  );

  const upsertSearchParam = React.useCallback(
    (key: string, value: string | false): void => {
      const searchParams = new URLSearchParams(window.location.search);
      if (!key) return;
      if (!value) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      history.replace({
        search: "?" + searchParams.toString(),
      });
    },
    [history]
  );

  const removeSearchParams = React.useCallback(
    (keys: string | Array<string>): void => {
      const searchParams = new URLSearchParams(window.location.search);
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          searchParams.delete(key);
        });
      } else {
        searchParams.delete(keys);
      }

      history.replace({
        search: "?" + searchParams.toString(),
      });
    },
    [history]
  );

  const clearSearchParams = React.useCallback((): void => {
    history.replace({
      pathname: location.pathname,
      search: "",
    });
  }, [history, location.pathname]);

  return {
    getAllParams,
    getParsedParam,
    getAllParsedParams,
    upsertSearchParam,
    removeSearchParams,
    clearSearchParams,
  };
};

export function useSearchParamState<T>(
  key: string,
  parser: (arg1: string) => T | null | undefined
) {
  const { getParsedParam, upsertSearchParam } = useSearchParams();
  const [state, _setState] = React.useState<T | undefined | null>(
    getParsedParam(key, parser)
  );
  const setState = React.useCallback(
    (newState) => {
      if (isFunction(newState)) {
        return _setState((prevState) => {
          const updatedState = newState(prevState);
          return updatedState;
        });
      }

      upsertSearchParam(key, !!newState && newState.toString());
      return _setState(newState);
    },
    [_setState, upsertSearchParam, key]
  );

  return [state, setState] as const;
}

const SEARCH_PARAM_ATOMS: { [key: string]: PrimitiveAtom<any> } = {};

// Allows multiple components to use this hook and share state based on key
export function useSearchParamAtom<T>(
  key: string,
  parser: (arg1: string) => T | null | undefined
) {
  const { getParsedParam, upsertSearchParam } = useSearchParams();

  const parsedParam = React.useMemo(
    () => getParsedParam(key, parser),
    [key, parser, getParsedParam]
  );

  if (!SEARCH_PARAM_ATOMS[key]) {
    SEARCH_PARAM_ATOMS[key] = atom(parsedParam ?? undefined);
  }

  const [state, _setState] = useAtom(
    SEARCH_PARAM_ATOMS[key] as PrimitiveAtom<T | null | undefined>
  );

  React.useEffect(() => {
    _setState(parsedParam);
  }, [parsedParam, _setState]);

  const setState = React.useCallback(
    (newState) => {
      if (isFunction(newState)) {
        return _setState((prevState) => {
          const updatedState = newState(prevState);
          return updatedState;
        });
      }

      upsertSearchParam(key, !!newState && newState.toString());
      return _setState(newState);
    },
    [_setState, upsertSearchParam, key]
  );

  return [state as T | null | undefined, setState] as const;
}

// Todo - figure out how to merge this with above function with decent Types...
export function useSearchParamStateWithDefault<T>(
  key: string,
  parser: (arg1: string) => T,
  defaultValue: T
) {
  const { getParsedParam, upsertSearchParam } = useSearchParams();
  const [state, _setState] = React.useState(
    getParsedParam(key, parser) || defaultValue
  );

  const upsertSearchParamState = React.useCallback(
    (value: T) => {
      if (value === defaultValue) {
        upsertSearchParam(key, false);
      } else {
        upsertSearchParam(key, !!value && value.toString());
      }
      return value;
    },
    [upsertSearchParam, key, defaultValue]
  );

  const setState: React.Dispatch<React.SetStateAction<T>> = React.useCallback(
    (newState) => {
      if (isFunction(newState)) {
        return _setState((prevState) => {
          const updatedState = newState(prevState);
          return upsertSearchParamState(updatedState);
        });
      }

      upsertSearchParamState(newState);
      return _setState(newState);
    },
    [_setState, upsertSearchParamState]
  );

  return [state, setState] as const;
}

function isFunction<T>(
  value: T | ((prevState: T) => T)
): value is (prevState: T) => T {
  return typeof value === "function";
}

type EquipmentSearchProps = {
  searchTerm: string;
  setSearchTerm: (arg1: ((arg1: string) => string) | string) => void;
  foundEquipmentIds: Array<EquipmentId>;
};

export const useEquipmentSearch = <T>(
  equipment: Array<T>
): EquipmentSearchProps => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const foundEquipment: Array<T> = React.useMemo(() => {
    if (equipment == null) return [];
    const trimmedSearch = searchTerm.trim();
    if (trimmedSearch === "") return equipment;

    const fuse = new Fuse(equipment, {
      shouldSort: true,
      threshold: 0.4,
      keys: ["name", "brickEquipmentType"],
    });

    return fuse.search(trimmedSearch).map(({ item }) => item);
  }, [equipment, searchTerm]);

  return {
    searchTerm,
    setSearchTerm,
    // @ts-expect-error - TS2322 - Type 'undefined[]' is not assignable to type 'number[]'.
    foundEquipmentIds: foundEquipment.map(R.prop("id")),
  };
};

export function useStorage<T>(
  key: string,
  initialValue: T,
  storage = window.localStorage
) {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error: any) {
      console.log(error); // eslint-disable-line
      return initialValue;
    }
  });

  const setValue = React.useCallback(
    (value: T) => {
      try {
        setStoredValue(value);
        if (value === undefined) {
          storage.removeItem(key);
        } else {
          storage.setItem(key, JSON.stringify(value));
        }
      } catch (error: any) {
        console.log(error); // eslint-disable-line
      }
    },
    [key, storage]
  );

  return [storedValue, setValue] as const;
}

type MaybeObject = null | File | Blob | MediaSource;
type UseObjectState = {
  object: MaybeObject;
  objectURL: null | string;
};

export const useObjectURL = (initialObject: MaybeObject) => {
  const createObjState = (
    obj: null | MaybeObject | Blob | File | MediaSource
  ) => ({
    object: obj,
    // URL.createObjectURL throws errors on blobs in Node
    objectURL: obj && !isTest ? URL.createObjectURL(obj) : null,
  });

  // @ts-expect-error - TS2554 - Expected 3 arguments, but got 2.
  const [{ object, objectURL }, setObject] = React.useReducer<
    UseObjectState,
    MaybeObject
    // @ts-expect-error - TS7006 - Parameter 'prevState' implicitly has an 'any' type. | TS7006 - Parameter 'newObject' implicitly has an 'any' type.
  >((prevState, newObject) => {
    if (prevState.objectURL) URL.revokeObjectURL(prevState.objectURL);
    return createObjState(newObject);
  }, createObjState(initialObject));

  React.useEffect(() => {
    return () => {
      if (objectURL != null) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [objectURL]);

  return { objectURL, object, setObject };
};

export const useCopyToClipboard = (resetInterval?: number | null) => {
  const [isCopied, setIsCopied] = React.useState<boolean>(false);
  const input = React.useRef(document.createElement("textarea"));

  input.current.style.opacity = "0";
  input.current.style.pointerEvents = "none";

  const handleCopy = React.useCallback((text: string | number) => {
    input.current.value = text.toString();
    if (document.body != null) document.body.appendChild(input.current);
    input.current.select();
    document.execCommand("copy");
    setIsCopied(true);
  }, []);

  React.useEffect(() => {
    // @ts-expect-error - TS7034 - Variable 'timeout' implicitly has type 'any' in some locations where its type cannot be determined.
    let timeout;
    if (isCopied && resetInterval) {
      timeout = setTimeout(() => setIsCopied(false), resetInterval);
    }
    return () => {
      // @ts-expect-error - TS7005 - Variable 'timeout' implicitly has an 'any' type.
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return { isCopied, handleCopy, setIsCopied };
};

export function useWhyDidYouUpdate(name: string, props: any) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = React.useRef<any>();

  React.useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj: Record<string, any> = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // eslint-disable-next-line
        props.debug && console.log("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

/*
  NOTE: there is an existing issue with table sorting - occasionally some rows will
  fail to register as "on screen" when they are sorted into view (scrolling by comparison
  is very reliable). In this situation you can change the Row to have the array index
  as the key to force it to re render on sort. This is of course not optimal, however
  the performance gain from using this hook will far outweight the loss from using index keys.
*/
export function useOnScreen(): [
  {
    current: HTMLElement | null | undefined;
  },
  boolean
] {
  const ref = React.useRef<HTMLElement | null | undefined>(null);
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    // @ts-expect-error - TS2345 - Argument of type '([entry]: [any]) => void' is not assignable to parameter of type 'IntersectionObserverCallback'.
    const observer = new IntersectionObserver(([entry]: [any]) =>
      setIntersecting(entry.isVisible || entry.isIntersecting)
    );
    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return [ref, isIntersecting];
}

/*
 * If value is defined, state is considered controlled and value is passed through
 * If value is undefined, state is considered uncontrolled and is identical to useState
 */
export function useControllableState<T>(value: T, defaultValue: T) {
  const [storedValue, setStoredValue] = React.useState(value || defaultValue);
  const isControlled = value !== undefined;
  const wasControlledRef = React.useRef(isControlled);
  const derivedValue = isControlled ? value : storedValue;
  const setValue: React.Dispatch<React.SetStateAction<T>> = React.useCallback(
    (newValue) => {
      setStoredValue(newValue);
    },
    []
  );

  if (wasControlledRef.current !== isControlled) {
    // eslint-disable-next-line no-console
    console.warn(
      "Warning: A component is changing a controlled input to be uncontrolled. This is likely caused by the value changing from a defined to undefined, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component."
    );
    wasControlledRef.current = isControlled;
  }

  return [derivedValue, setValue] as const;
}

export function usePrevious<T>(state: T): T | undefined {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = state;
  }, [state]);

  return ref.current;
}

export function useMergedRef<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return React.useCallback(
    (element: T) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(element);
        } else if (ref && typeof ref === "object") {
          (ref as React.MutableRefObject<T>).current = element;
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs
  );
}

export const useDownloadFile = (
  url: string,
  {
    filename,
    type,
    token,
    routeToHeader,
  }: {
    filename: string;
    type: string;
    token?: string;
    routeToHeader: string;
  }
) =>
  useMutation({
    mutationFn: async (params: { [key: string]: any } = {}) => {
      const response = await request
        .get(url)
        .query(params)
        .set("X-Route-To", routeToHeader)
        .set("Authorization", token ?? `Bearer ${Cookies.get("jwt") ?? ""}`);

      // response.text probably doesnt cover all bases. May have to update to handle response.body / response.buffer etc to make it more robust
      const blob = new Blob([response.text], { type });
      return await downloadFile({
        blob,
        filename,
      });
    },
  });

export function useEffectOnce(effect: React.EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(effect, []);
}
