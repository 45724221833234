import { ComponentProps, createContext } from "react";
import type Radio from "../Radio/Radio";

type RadioGroupContextType = Pick<
  ComponentProps<typeof Radio>,
  "value" | "onChange" | "disabled"
>;

export const RadioGroupContext = createContext<
  undefined | RadioGroupContextType
>(undefined);
