import Cookies from "js-cookie";
import request from "../request";
import { snakeCase } from "lodash";
import * as R from "ramda";
import { groupBy } from "../util";

import {
  bugsnagPostgrestErrorHandler,
  recursiveCamelCaseCipher,
  camelCaseCipher,
  formatDataObj,
  snakeCaseCipher,
} from "./common";

import type {
  UtilityAccountBase,
  UtilityAccount,
  UtilityAccountLink,
  ISODateString,
  Utility,
  UtilityOptOut,
} from "../../types";

import { VITE_IP_URL } from "../../env";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addBackpackUtilityManual,
  addBackpackUtilityOptOut,
  updateBackpackUtilityManual,
  updateBackpackUtilityOptOut,
} from "./sites";
import { useInvalidateUtilityTypesQuery } from "../fortyTwoApi/site/hooks/useGetUtilityTypes";
import { UtilityAccountsOptedOutBySite } from "@src/backpack-console/pages/EnvironmentalImpact/EmissionsSummaryDownloadButton";

export const decryptPassword = (password: string) =>
  request
    .get(`${VITE_IP_URL}/api/decrypt_utility_account_password`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ value: password })
    .then(({ body }) => body.value);

export const encryptPassword = (password: string | null | undefined) =>
  request
    .get(`/api/encrypt_utility_account_password`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ value: password })
    .then(({ body }) => body.value);

export const getRandomToken = (): Promise<string | null | undefined> =>
  request
    .get(`/api/random_token`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then((res) => res.body.token)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    });

export const getUtilityAccounts = (
  siteId: string | number
): Promise<Array<UtilityAccount>> =>
  request
    .get(`/rest/utility_accounts_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}` })
    .then(({ body }) => body.map(recursiveCamelCaseCipher))
    .then((rows) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      rows.map((row) => ({
        ...row,
        encryptedPassword: row.password,
        password: null,
      }))
    )
    .catch(bugsnagPostgrestErrorHandler);

const getUtilityAccountsQueryKey = (siteId: number) => [
  "utilityAccounts",
  siteId,
];

// Returns utility accounts for all utility types
export const useAllUtilityAccountsQuery = (siteId: number) =>
  useQuery({
    queryKey: getUtilityAccountsQueryKey(siteId),
    queryFn: () => getUtilityAccounts(siteId),
  });

// Returns the utility accounts for a specific Utility type
export const useUtilityAccountsQuery = (siteId?: number, utility?: Utility) =>
  useQuery({
    queryKey: getUtilityAccountsQueryKey(siteId!),
    queryFn: () => getUtilityAccounts(siteId!),
    select: (utilityAccounts) =>
      (utilityAccounts || []).filter((utilityAccount) => {
        // combine water and wastwater accounts in the water/wastewater section
        if (utility === "water") {
          return (
            utilityAccount.utility === "water" ||
            utilityAccount.utility === "wasteWater"
          );
        }
        return utilityAccount.utility === utility;
      }),
    enabled: Boolean(siteId !== undefined && utility),
  });

export const useInvalidateUtilityAccountsQuery = () => {
  const queryClient = useQueryClient();
  return (siteId: number) =>
    queryClient.invalidateQueries(getUtilityAccountsQueryKey(siteId));
};

// Adds utility account with or updates utility account to status "optout"
export const useBackpackUtilityOptOutMutation = (
  siteId: number,
  utility: Utility
) => {
  const { data: utilityAccounts, isSuccess } = useUtilityAccountsQuery(
    siteId,
    utility
  );
  const invalidateUtilityAccountsQuery = useInvalidateUtilityAccountsQuery();
  const invalidateUtilityTypesQuery = useInvalidateUtilityTypesQuery();

  return useMutation(async () => {
    if (!isSuccess) {
      return;
    }
    if (utilityAccounts && utilityAccounts.length) {
      await updateBackpackUtilityOptOut(
        siteId,
        snakeCase(utility) as UtilityOptOut
      );
    } else {
      await addBackpackUtilityOptOut(
        siteId,
        snakeCase(utility) as UtilityOptOut
      );
    }

    invalidateUtilityAccountsQuery(siteId);
    invalidateUtilityTypesQuery(siteId);
  });
};

// Adds utility account with or updates utility account to status "manual"
export const useBackpackUtilityManualMutation = (
  siteId?: number,
  utility?: Utility
) => {
  const { data: utilityAccounts, isSuccess } = useUtilityAccountsQuery(
    siteId,
    utility
  );
  const invalidateUtilityAccountsQuery = useInvalidateUtilityAccountsQuery();
  const invalidateUtilityTypesQuery = useInvalidateUtilityTypesQuery();

  return useMutation(
    async (
      variables?: { providerName?: string; providerId?: number | null } | void
    ) => {
      const { providerId, providerName } = variables ?? {};
      if (!isSuccess || siteId === undefined || !utility) {
        return;
      }
      if (utilityAccounts && utilityAccounts.length) {
        await updateBackpackUtilityManual(
          siteId,
          utility,
          providerName,
          providerId
        );
      } else {
        await addBackpackUtilityManual(
          siteId,
          utility,
          providerName,
          providerId
        );
      }
      invalidateUtilityAccountsQuery(siteId);
      invalidateUtilityTypesQuery(siteId);
    }
  );
};
// query for multiple sites utility opt-out statuses
const getUtilityAccountsOptOutStatus = async (
  siteIds: number[]
): Promise<Pick<UtilityAccount, "siteId" | "status" | "utility">[]> => {
  const cols: (keyof UtilityAccount)[] = ["status", "siteId", "utility"];
  const selectParam = cols.map(snakeCase);
  return request
    .get(`/rest/utility_accounts_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `in.(${siteIds.join()})`, select: selectParam.join() })
    .then(({ body }) => body.map(recursiveCamelCaseCipher));
};

export const useGetUtilityAccountsOptOutStatus = (siteIds: number[]) =>
  useQuery({
    queryKey: [
      "multiple-site-optout-utility-status",
      siteIds
        .slice()
        .sort((a, b) => a - b)
        .join(),
    ],
    queryFn: () => getUtilityAccountsOptOutStatus(siteIds),
    select: (data): UtilityAccountsOptedOutBySite =>
      R.mapObjIndexed(
        (utilitiesForSite) =>
          R.mapObjIndexed(
            (utilities) => utilities.some(({ status }) => status === "optout"),
            groupBy(utilitiesForSite, "utility") as Partial<
              Record<UtilityAccount["utility"], typeof utilitiesForSite>
            >
          ),
        groupBy(data, "siteId")
      ),
    enabled: !!siteIds.length,
  });

export const getUtilityAccountStatus = () =>
  request
    .get(`/rest/utility_account_status`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => body.map(recursiveCamelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

export const deleteUtilityAccount = (id: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete(`/rest/utility_accounts`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${id}` })
    .catch(bugsnagPostgrestErrorHandler);

export const createUtilityAccount = async ({
  account,
  meters,
  locations,
  fileObj,
}: {
  account: Partial<UtilityAccountBase>;
  meters?: Array<string>;
  locations?: Array<string>;
  fileObj?: {
    file: File | MediaSource | Blob;
    billFileName: string;
  };
}): Promise<void> => {
  const encryptedPassword = account.password
    ? await encryptPassword(account.password)
    : null;

  const accountBase = {
    ...formatDataObj(account, snakeCaseCipher),
    utility: snakeCase(account.utility),
    status: account.status || "submitted",
    password: encryptedPassword,
  } as const;

  const newAccount = await request
    .post(`/rest/utility_accounts`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send(accountBase)
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

  if (newAccount?.id) {
    await Promise.all([
      ...(meters || []).map((meter_name) =>
        request
          .post(`/rest/utility_account_suggested_meters`)
          .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
          .send({ meter_name, utility_account_id: newAccount.id })
          .catch(bugsnagPostgrestErrorHandler)
      ),
      ...(locations || []).map((location) =>
        request
          .post(`/rest/utility_account_suggested_locations`)
          .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
          .send({ location, utility_account_id: newAccount.id })
          .catch(bugsnagPostgrestErrorHandler)
      ),
    ]);
  }
};

export const editUtilityAccountBase = async (
  accountId: number,
  updatedProps: Partial<UtilityAccountBase>
) => {
  const formattedProps = formatDataObj(updatedProps, snakeCaseCipher, {
    allowNulls: true,
  });
  if (formattedProps.password) {
    const encryptedPassword = await encryptPassword(updatedProps.password);
    formattedProps.password = encryptedPassword;
  }
  if (updatedProps.utility) {
    formattedProps.utility = snakeCase(updatedProps.utility);
  }
  return request
    .patch(`/rest/utility_accounts`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${accountId}` })
    .send(formattedProps)
    .catch(bugsnagPostgrestErrorHandler);
};

export const editUtilityAccountLocations = (
  accountId: number,
  locations: Array<string>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response[]>' is not assignable to type 'Promise<void>'.
  request
    .delete(`/rest/utility_account_suggested_locations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ utility_account_id: `eq.${accountId}` })
    .then(() =>
      Promise.all(
        locations.map((location) =>
          request
            .post(`/rest/utility_account_suggested_locations`)
            .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
            .send({ location, utility_account_id: accountId })
        )
      )
    )
    .catch(bugsnagPostgrestErrorHandler);

export const editUtilityAccountMeters = (
  accountId: number,
  meters: Array<string>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response[]>' is not assignable to type 'Promise<void>'.
  request
    .delete(`/rest/utility_account_suggested_meters`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ utility_account_id: `eq.${accountId}` })
    .then(() =>
      Promise.all(
        meters.map((meter_name) =>
          request
            .post(`/rest/utility_account_suggested_meters`)
            .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
            .send({ meter_name, utility_account_id: accountId })
        )
      )
    )
    .catch(bugsnagPostgrestErrorHandler);

export const getUtilityAccountsLastUpdateAt = (
  siteId: number
): Promise<ISODateString | null | undefined> =>
  request
    .get(`/rest/utility_accounts`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      updated_at: "not.is.null",
      select: "updated_at",
      order: "updated_at.desc",
      limit: 1,
    })
    .then(({ body }) => (body.length > 0 ? body[0].updated_at : null))
    .catch(bugsnagPostgrestErrorHandler);

export const getSiteUtilityAccountLinks = (
  siteId: number
): Promise<Array<UtilityAccountLink>> =>
  request
    .get(`/rest/v_site_utility_link_tokens`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}` })
    .then(({ body }) => body.map(recursiveCamelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

export const createNewUtilityAccountLink = async (
  siteId: number,
  utility: string
): Promise<UtilityAccountLink> => {
  const linkToken = await getRandomToken();
  return request
    .post("/rest/v_site_utility_link_tokens")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send(formatDataObj({ siteId, utility, linkToken }, snakeCaseCipher))
    .then(({ body }) => body.map(recursiveCamelCaseCipher));
};
