import request from "../../../global_functions/request";
import Cookies from "js-cookie";
import {
  recursiveCamelCaseCipher,
  bugsnagPostgrestErrorHandler,
} from "../../../global_functions/postgrestApi/common";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import type {
  SourceAsyncReturnValues,
  FIELD_ID_VALUES,
  SourceId,
} from "../../../backpack-console/pages/Overview/types";
import { ledgerKeys } from "./queryKeyFactory";
import { VITE_LEDGER_BASE_URL } from "../../../env";

const sources = {
  //   useQuery,
  useQueryAll,
  mutations: {
    usePutSelectedSource,
  },
} as const;

export default sources;

const getSources = async (
  siteId: string | number,
  fieldId?: FIELD_ID_VALUES
): Promise<Array<SourceAsyncReturnValues>> => {
  if (!fieldId) throw new Error("No field Id");
  return request
    .get(`${VITE_LEDGER_BASE_URL}/sites/${siteId}/fields/${fieldId}/sources`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body))
    .catch((e) => bugsnagPostgrestErrorHandler(e));
};

function useQueryAll(siteId: number, fieldId?: FIELD_ID_VALUES) {
  const queryKey = ledgerKeys.entity(siteId, fieldId, "sources");
  return useReactQuery({
    queryKey,
    queryFn: () => getSources(siteId, fieldId),
    enabled: !!fieldId,
  });
}

const putSelectedSource = (
  siteId: string | number,
  sourceId: SourceId,
  fieldId?: FIELD_ID_VALUES
) => {
  if (!fieldId) throw new Error("No field Id!");
  return request
    .put(
      `${VITE_LEDGER_BASE_URL}/sites/${siteId}/fields/${fieldId}/sources/${sourceId}`
    )
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`);
};

function usePutSelectedSource(siteId: number, fieldId?: FIELD_ID_VALUES) {
  const queryKey = ledgerKeys.field(siteId, fieldId);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (sourceId: SourceId) =>
      putSelectedSource(siteId, sourceId, fieldId),
    onSuccess: async () => await queryClient.invalidateQueries(queryKey),
  });
}
