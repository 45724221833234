import * as R from "ramda";
import { orderBy } from "lodash";

import type { Project, ProjectGrouping } from "../types";

export const getSortedProjects = (
  projects: Array<Project>,
  buildingStateId?: string | null
): Array<Project> => {
  const filteredProjects = projects.filter(
    ({ isGroup, parentStateId }) =>
      !isGroup && parentStateId.toString() === buildingStateId
  );

  if (!filteredProjects) return [];

  const children: Record<string, any> = {};
  const optionGroupings: Record<string, any> = {};
  const parentProjectIds: Array<any | Project> = [];

  filteredProjects.forEach((project) => {
    const { parentProjectId, optionGrouping } = project;

    if (parentProjectId != null) {
      children[parentProjectId] = [
        ...(children[parentProjectId] || []),
        project,
      ];
    } else if (optionGrouping) {
      optionGroupings[optionGrouping] = [
        ...(optionGroupings[optionGrouping] || []),
        project,
      ];
    } else {
      parentProjectIds.push(project);
    }
  });

  const sortProjects = R.sortBy(R.prop("name"));

  // @ts-expect-error - TS7006 - Parameter 'parent' implicitly has an 'any' type.
  const addChildren = (parent) => [
    parent,
    ...sortProjects(children[parent.id] || []),
  ];

  return [
    ...sortProjects(parentProjectIds).flatMap(addChildren),
    ...Object.keys(optionGroupings)
      .sort()
      .map((optionGrouping) =>
        optionGroupings[optionGrouping].flatMap(addChildren)
      ),
  ].flat(1);
};

export const calcInteractiveEffect = (
  key: "netYearlySavings" | "netCost" | "energyReduction",
  project: Project,
  selectedProjects: Array<Project>
): number | null | undefined => {
  const current = project[key];
  if (current == null) return null;

  const prev = R.sum(selectedProjects.map((p) => p[key] || 0));

  return prev ? Math.round(((current - prev) / prev) * 1000) / 1000 : null;
};

export const calcInteractiveEffectCoefficient = (
  key: "netYearlySavings" | "netCost" | "energyReduction",
  project: Project,
  selectedProjects: Array<Project>
): number | null | undefined =>
  1 + (calcInteractiveEffect(key, project, selectedProjects) || 0);

export const getInteractiveEffectForSelectedProjects = (
  selectedProjects: Array<Project>,
  projectGroupings: Array<ProjectGrouping>
): number => {
  if (selectedProjects.length < 2) return 1;

  const selectedIds = R.indexBy(R.prop("id"), selectedProjects);

  const [bestMatch] = orderBy(
    projectGroupings.map((grouping) => {
      const numMatched = grouping.selectedProjects.filter(
        (project) => selectedIds[project.id]
      ).length;
      return {
        numMatched,
        numDiff: grouping.selectedProjects.length - numMatched,
        effect: calcInteractiveEffectCoefficient(
          "energyReduction",
          grouping.project,
          grouping.selectedProjects
        ),
      };
    }),
    [
      (stats: any) =>
        stats.numDiff === 0 && stats.numMatched === selectedProjects.length,
      "numMatched",
      "numDiff",
      "effect",
    ],
    ["desc", "desc", "asc", "asc"]
  );

  // @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'number'.
  return bestMatch ? bestMatch.effect : 1;
};
