import classNames from "classnames";
import * as React from "react";

// import * as Icons from "../../../icons";
import styles from "./BuildingImage.module.scss";

export type BuildingImageProps = {
  name: string;
  imageURL?: string | null;
  size?: string;
  className?: string;
};

export default function BuildingImage(props: BuildingImageProps) {
  return (
    <div
      className={classNames(styles.image, props.className)}
      style={props.size ? { width: props.size, height: props.size } : {}}
    >
      {props.imageURL && import.meta.env.MODE !== "development" ? (
        <img
          data-testid="building-image"
          src={props.imageURL}
          alt={props.name}
          loading="lazy"
        />
      ) : (
        <svg
          data-testid="building-image-placeholder"
          className="h-6 fill-none stroke-straps-brand stroke-[2px]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27 35"
          preserveAspectRatio="xMidYMid meet"
        >
          <g strokeLinecap="square">
            <path
              className="stroke"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M11 14.025V5.006L26 1v33h-9.5"
            ></path>
            <path className="stroke" d="M16 8v6"></path>
            <path className="stroke" strokeMiterlimit="10" d="M21 8v22"></path>
            <path
              className="stroke"
              strokeLinejoin="round"
              d="M1 18h15v16H1z"
            ></path>
            <path className="stroke" strokeMiterlimit="10" d="M7 26h3"></path>
            <path className="stroke" strokeMiterlimit="10" d="M7 22h3"></path>
            <path className="stroke" strokeMiterlimit="10" d="M7 30h3"></path>
          </g>
        </svg>
      )}
    </div>
  );
}
