import { QueryClient } from "react-query";
import { bugsnagGeneralErrorHandler } from "../global_functions/postgrestApi";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: bugsnagGeneralErrorHandler,
    },
    mutations: {
      onError: bugsnagGeneralErrorHandler,
    },
  },
});
