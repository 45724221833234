import * as React from "react";
import { OnboardingStatusEnum } from "./completenessSchema";
import Icons from "../../../icons";

import { useSiteIdParam } from "../../../context/SitesContext";

import moment from "moment";
import { useOnboardingGuideContext } from ".";
import {
  SiteEvent,
  useGetSiteEvents,
} from "@src/global_functions/postgrestApi";

export default function SiteVisitSteps() {
  const { scheduleFollowUpVisit, scheduleInitialSiteVisit } =
    useOnboardingGuideContext().state.onSite.backpackSiteVisit;

  const siteId = useSiteIdParam();
  const { data: siteEvents, refetch: fetchSiteEvents } = useGetSiteEvents(
    siteId,
    false
  );

  React.useEffect(() => {
    // Only need to fetch site events if either of the events is "processing" i.e. there is a scheduled visit.
    if (
      scheduleFollowUpVisit.status === OnboardingStatusEnum.Processing ||
      scheduleInitialSiteVisit.status === OnboardingStatusEnum.Processing
    ) {
      fetchSiteEvents();
    }
  }, [scheduleInitialSiteVisit, scheduleFollowUpVisit, fetchSiteEvents]);

  return (
    <div className="mx-4">
      {scheduleInitialSiteVisit.status !== 20 && (
        <SiteVisitStepsRow
          title="Gateway installation"
          eventType="gateway-installation"
          status={scheduleInitialSiteVisit.status}
          siteEvents={siteEvents ?? []}
        />
      )}
      <SiteVisitStepsRow
        title="Site walk-through"
        eventType="site-walk-through"
        status={scheduleFollowUpVisit.status}
        siteEvents={siteEvents ?? []}
      />
    </div>
  );
}

function SiteVisitStepsRow({
  status,
  title,
  eventType,
  siteEvents,
}: {
  status: number;
  title: string;
  eventType: string;
  siteEvents: SiteEvent[];
}) {
  return (
    <div className="my-2 flex flex-col text-xs">
      <label className="my-1 flex items-center font-semibold text-blue">
        {title}
        {status === OnboardingStatusEnum.Done && (
          <Icons icon="Check" className="color--blue ml-auto h-2.5 w-2.5" />
        )}
      </label>
      {status === OnboardingStatusEnum.Open && (
        <span>Schedule with your Backpack Onboarding Coordinator</span>
      )}
      {status === OnboardingStatusEnum.Processing && (
        <>
          <span className="flex items-center">
            {getFormattedDate(
              (siteEvents || []).find((event) => event.eventType === eventType)
                ?.plannedAt
            )}
            <Icons
              icon="Calendar"
              className="color--charcoal ml-auto h-4 w-4"
            />
          </span>
        </>
      )}
    </div>
  );
}

function getFormattedDate(input?: string | null) {
  if (!input) return undefined;

  return moment.utc(input).format("dddd, MMMM Do");
}
