import { createContext, useContext } from "react";
import OnboardingGuide from "./OnboardingGuide";

import {
  getOfficeSchema,
  OnboardingGuideSchemaUnionType,
  OnboardingStepType,
  OnboardingSubStep,
} from "./completenessSchema";

import OnboardingGuideTooltip from "./Tooltip";
import YesNoOnboardingQuestions from "./YesNoOnboardingQuestions";
import { Utility } from "../../../types";

const OnboardingGuideContext = createContext<{
  state: OnboardingGuideSchemaUnionType;
  activeStep: string | null;
  setActiveStep: (
    pathId?: string,
    options?: {
      page?: "completeness" | "onSite";
      value?: boolean;
      skipCompleted?: boolean;
    }
  ) => void;
  resetState: () => void;
  activeTab: string | null | undefined;
  setActiveTab: any;
  completedSteps: number;
  ref?: HTMLDivElement | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshGuideData: () => Promise<void>;
  isRefreshing?: boolean;
  isLoading?: boolean;
}>({
  state: getOfficeSchema(),
  activeStep: null,
  setActiveStep: () => {},
  activeTab: null,
  setActiveTab: () => {},
  completedSteps: 0,
  resetState: () => {},
  setOpen: () => {},
  refreshGuideData: async () => {},
});

const useOnboardingGuideContext = () => useContext(OnboardingGuideContext);
export const useOnboardingGuide = () =>
  useContext(OnboardingGuideContext).state;

const useOnboardingGuideStep = <Selected>(
  func: (arg1: OnboardingGuideSchemaUnionType["completeness"]) => Selected
) => {
  const ctx = useOnboardingGuideContext();

  const { state, activeStep } = ctx;

  const selected = func(state.completeness);

  // Hard to narrow down Selected here. Cleaner to just ignore the error - sometimes pathId exists and sometimes it doesn't.
  // @ts-expect-error
  if (selected?.pathId) {
    // @ts-expect-error
    selected.active = selected.pathId.includes(activeStep);
  }

  return selected as Selected extends OnboardingStepType | OnboardingSubStep
    ? Selected & { active: boolean }
    : Selected;
};

const utilityOnboardingStepsMap: Record<
  Utility,
  keyof OnboardingGuideSchemaUnionType["completeness"]["utilities"]
> = {
  electricity: "enterElectricityUtility",
  waste: "enterWasteUtility",
  water: "enterWaterUtility",
  wasteWater: "enterWaterUtility",
  naturalGas: "enterNaturalGasUtility",
  districtHeating: "enterDistrictHeatingUtility",
  districtCooling: "enterDistrictCoolingUtility",
} as const;

export const useUtilityOnboardingStep = (utility: Utility) =>
  useOnboardingGuideStep(
    (guide) => guide.utilities[utilityOnboardingStepsMap[utility]]
  );

/**
 * Returns an OnboardingGuide Step using a "pathId"
 * @param pathId - e.g. "overview.buildingName"
 */
const useOnboardingGuideStepFromPathId = (
  pathId: string
): OnboardingStepType => {
  const [group, step, subStep] = pathId.split(".");

  const { state } = useOnboardingGuideContext();

  // Note - would be very hard to correct group the group / step/ substep strings here. I think we just have to ignore the errors.
  const result = subStep
    ? // @ts-expect-error
      state.completeness?.[group]?.[step]?.subSteps?.[subStep]
    : // @ts-expect-error
      state.completeness?.[group]?.[step];

  if (!result)
    throw new Error(
      "Could not locate step from pathId - likely misuse of hook"
    );

  return result;
};

export default OnboardingGuide;

export {
  OnboardingGuideTooltip,
  OnboardingGuideContext,
  useOnboardingGuideContext,
  useOnboardingGuideStepFromPathId,
  useOnboardingGuideStep,
  YesNoOnboardingQuestions,
};
