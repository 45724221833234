import * as React from "react";
import ReactDOM from "react-dom";

import classnames from "classnames";

import Icon from "../icons";

import styles from "./Dropdown.module.scss";
import { Loader, DropdownWrapper } from "./index";
import { get, startCase } from "lodash";

type DropdownOption<T> = {
  label?: string;
  id: T;
  nodeLabel?: React.ReactNode;
};

export type DropdownProps<T> = {
  rounded?: boolean;
  initialValue?: DropdownOption<T>;
  onChange?: (arg1: { option: DropdownOption<T> }) => void;
  disabled?: boolean;
  loading?: boolean;
  placeholder: string;
  options: Array<DropdownOption<T>>;
  optionsBefore?: React.ReactNode;
  optionsAfter?: React.ReactNode;
  className?: string;
  groupBy?: {
    key: string;
    headerKey: string;
  } | null;
  renderGroupHeader?: (id: string | number, name: string) => React.ReactElement;
  dataCy?: string;
  dataTestId?: string;
};

const Dropdown = <T extends number | string>(props: DropdownProps<T>) => {
  const { onChange, rounded = false } = props;
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(props.initialValue);
  React.useEffect(() => {
    setSelected(props.initialValue);
  }, [props.initialValue]);
  const {
    renderGroupHeader = (id: any, name: any) => (
      <div className="bractlet-select--option-group-header">{name}</div>
    ),
  } = props;

  const targetRef = React.useRef<any>();

  const select = React.useCallback(
    (option) => () => {
      setSelected(option);
      setOpen(false);
      onChange && onChange({ option });
    },
    [onChange]
  );

  const toggleOpen = React.useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!props.disabled && !props.loading) {
        setOpen((prev) => !prev);
      }
    },
    [props.disabled, props.loading]
  );

  const dropdownRef = React.useRef();

  const closeDropdown = React.useCallback(
    (e) => {
      const dropdownDOM = ReactDOM.findDOMNode(dropdownRef.current);
      if (dropdownDOM && !dropdownDOM.contains(e.target)) {
        toggleOpen(e);
      }
    },
    [toggleOpen]
  );

  const keyDown = React.useCallback(
    (e) => {
      if (e.keyCode === 27) {
        toggleOpen(e);
      }
    },
    [toggleOpen]
  );

  React.useEffect(() => {
    if (open) {
      window.addEventListener("click", closeDropdown);
      window.addEventListener("keydown", keyDown);

      return () => {
        window.removeEventListener("click", closeDropdown);
        window.removeEventListener("keydown", keyDown);
      };
    }
  }, [open, closeDropdown, keyDown]);

  // @ts-expect-error - TS7034 - Variable 'prevGroup' implicitly has type 'any' in some locations where its type cannot be determined.
  let prevGroup = null;

  const groupedOptions = props.groupBy
    ? props.options.flatMap((option, index) => {
        let header = null;
        if (props.groupBy) {
          const group = get(option, props.groupBy.key);
          // @ts-expect-error - TS7005 - Variable 'prevGroup' implicitly has an 'any' type.
          if (group !== prevGroup) {
            prevGroup = group;
            header = (
              <div key={`grouping-${group}`}>
                {renderGroupHeader(
                  group,
                  get(option, props.groupBy?.headerKey)
                )}
              </div>
            );
          }
        }
        return [
          header,
          <div
            key={`${option.id}-${index}`}
            className={classnames(
              "dropdown-option transition-colors hover:bg-gray150",
              {
                [styles.grouped_option!]: true,
              }
            )}
            onClick={select(option)}
          >
            {option.nodeLabel || option.label}
          </div>,
        ];
      })
    : props.options.map((option) => (
        <div
          key={option.id}
          onClick={select(option)}
          className={classnames("dropdown-option", {
            [styles.dropdown_option!]: !rounded,
            [styles.dropdown_option_rounded!]: rounded,
          })}
        >
          {/* @ts-expect-error - TS2345 - Argument of type 'string | number' is not assignable to parameter of type 'string | undefined'. */}
          {option.nodeLabel || option.label || startCase(option.id)}
        </div>
      ));

  return (
    <>
      <div
        className={classnames(props.className, "truncate", {
          [styles.dropdown!]: !rounded,
          [styles.dropdown_rounded!]: rounded,
          [styles.dropdown_loading!]: props.loading,
        })}
        // @ts-expect-error - TS2322 - Type '(e: MouseEvent) => void' is not assignable to type 'MouseEventHandler<HTMLDivElement>'.
        onClick={toggleOpen}
        ref={targetRef}
        data-cy={props.dataCy ?? "dropdown-field"}
        data-testid={props.dataTestId ?? "dropdown-field"}
      >
        {/* @ts-expect-error - TS2345 - Argument of type 'string | number | undefined' is not assignable to parameter of type 'string | undefined'. */}
        {selected?.label || startCase(selected?.id) || (
          <span
            className={classnames(styles.placeholder, {
              [styles.placeholder_loading!]: props.loading,
            })}
          >
            {props.placeholder}
          </span>
        )}
        {props.loading ? (
          <Loader size={20} align="right" show />
        ) : (
          !props.disabled && (
            <Icon
              icon="Caret"
              style={{
                transform: `rotate(${!open ? 180 : 0}deg)`,
                transitionDuration: "0.25s",
              }}
              className="color--charcoal"
            />
          )
        )}
      </div>

      <DropdownWrapper open={open} target={targetRef} minHeight={400}>
        {/* @ts-expect-error - TS2322 - Type 'MutableRefObject<undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <div className={styles.dropdown_options} ref={dropdownRef}>
          {props.optionsBefore}
          {groupedOptions}
          {props.optionsAfter}
        </div>
      </DropdownWrapper>
    </>
  );
};

export default Dropdown;
