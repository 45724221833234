import classnames from "classnames";
import { capitalize } from "lodash";
import moment from "moment";
import numeral from "numeral";
import * as R from "ramda";
import { allUtilitiesById } from "../../global_functions/utilities";
import * as Icons from "../../icons";

import styles from "./utilityBillColumns.module.scss";

import type {
  EnergyUnit,
  ID,
  OptionalUtilityBillInputTypes,
  TableColumn,
  Utility,
  UtilityBill,
  UtilityBillRateStructure,
  UtilityUnit,
  WasteUnit,
  WaterUnit,
} from "../../types";

import { isBackpack, isIP } from "../../env";
import {
  calcTotalSpend,
  generateRateStructureProp,
  getTierFromPropName,
  looselyEqual,
} from "./utilityBillUtils";

const dateFormats = [
  "MM/DD/YY",
  "M/D/YY",
  "MM/DD/YYYY",
  "M/D/YYYY",
  "YYYY-MM-DD",
];
const dateTimeFormats = [
  "hh:mm A MM/DD/YY",
  "MM/DD/YY hh:mm A",
  "hh:mm A M/D/YY",
  "M/D/YY hh:mm A",
];

const zeros = (numZeros: number) => R.repeat("0", numZeros).join("");

const csvStringParser = (s: string) => (s !== "" ? s : undefined);
const csvRemovePrefixParser = (s: string) =>
  s !== "" ? s.replace(/^#:/, "") : undefined;
const csvNumberParser = (s: string) =>
  s !== "" ? numeral(s).value() : undefined;
const csvDateParser = (s: string) =>
  s !== "" ? moment(s, dateFormats, true).format("YYYY-MM-DD") : undefined;

const allListDataNoSummary = (propName: string, bills: Array<UtilityBill>) => [
  "",
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
  bills.map((bill) => ({ value: bill[propName], id: bill.id })),
];
const allMetersDataSummation = (
  propName: string,
  bills: Array<UtilityBill>
) => [
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
  bills.some((bill) => bill[propName] != null)
    ? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
      R.sum(bills.map((bill) => bill[propName] || 0))
    : null,
  bills.map((bill) => ({
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    value: bill[propName],
    id: bill.id,
  })),
];

const allListDataAverage = (propName: string, bills: Array<UtilityBill>) => {
  const values = bills
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    .filter((bill) => bill[propName])
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    .map((bill) => bill[propName]);
  return [
    R.sum(values) / values.length,
    bills.map((bill) => ({
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
      value: bill[propName],
      id: bill.id,
    })),
  ];
};

const isValidNum = (value?: string | number | null): boolean =>
  value != null && !isNaN(Number(value));
const isValidDate = (value?: string | number | null): boolean =>
  // @ts-expect-error - TS2551 - Property '_isValid' does not exist on type 'Moment'. Did you mean 'isValid'?
  moment(value, dateFormats, true)._isValid;
const isValidDateTime = (value?: string | number | null): boolean =>
  // @ts-expect-error - TS2551 - Property '_isValid' does not exist on type 'Moment'. Did you mean 'isValid'?
  moment(value, dateTimeFormats, true)._isValid;

const getNumeralFormat = (
  minDecimals: number,
  maxDecimals: number,
  excludeCommas: boolean
) =>
  `0${excludeCommas ? "" : ","}0[.]${zeros(minDecimals)}${
    maxDecimals > minDecimals ? `[${zeros(maxDecimals - minDecimals)}]` : ""
  }`;

const formatNum = (
  num: number | string | null | undefined,
  {
    prefix,
    postfix,
    minDecimals = 0,
    maxDecimals,
    excludeCommas = false,
  }: {
    postfix?: string | null;
    prefix?: string | null;
    maxDecimals: number;
    minDecimals?: number;
    excludeCommas?: boolean;
  }
) => {
  const format = getNumeralFormat(minDecimals, maxDecimals, excludeCommas);
  return num != null &&
    numeral(num).value() != null &&
    numeral(num).format(format) !== "NaN"
    ? `${prefix || ""} ${numeral(num).format(format)} ${postfix || ""}`
    : "-";
};

const csvStringRenderer = (string: number | string) =>
  string == null ? "" : String(string);

const dateRenderer = (value: number | string) =>
  // @ts-expect-error - TS2551 - Property '_isValid' does not exist on type 'Moment'. Did you mean 'isValid'?
  value && moment(value, dateFormats)._isValid
    ? moment(value, dateFormats).format(dateFormats[0])
    : "";

const serviceDateRenderer = (dates: number | string) => {
  if (typeof dates === "string") {
    const [startDate, endDate] = dates.split("&");
    return `${moment(startDate, dateFormats).format(dateFormats[0])} - ${moment(
      endDate,
      dateFormats
    ).format(dateFormats[0])}`;
  } else {
    return "";
  }
};

const dateTimeRenderer = (
  // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
  value,
  unit: UtilityUnit | null | undefined | string | "kVa" | "kW"
) => (value ? moment(value).format(dateTimeFormats[0]) : "");

const dollarPrefixRenderer = (
  value: number | string | null | undefined,
  unit: UtilityUnit | null | undefined | string | "kVa" | "kW"
): string => formatNum(value, { prefix: "$ ", minDecimals: 2, maxDecimals: 2 });

const percentPostfixRenderer = (
  // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
  value,
  unit: UtilityUnit | null | undefined | string | "kVa" | "kW"
) => (value != null ? formatNum(value, { postfix: " %", maxDecimals: 2 }) : "");

const allMetersColumnRenderer = (
  // @ts-expect-error - TS7006 - Parameter 'main' implicitly has an 'any' type.
  main,
  subs: Array<{
    id: ID;
    value: any;
  }>,
  // @ts-expect-error - TS7006 - Parameter 'renderer' implicitly has an 'any' type.
  renderer,
  unit: UtilityUnit | null | undefined | "kVa" | "kW"
) => (
  <div className={styles.list_table__all_view_cell}>
    <div className={styles.list_table__all_view_main}>
      {renderer(main, unit)}
    </div>
    {subs.map((row) => (
      <div key={row.id} className={styles.list_table__all_view_sub}>
        {renderer(row.value, unit)}
      </div>
    ))}
  </div>
);

const allMetersDemandColumnRenderer =
  // @ts-expect-error - TS7006 - Parameter 'renderer' implicitly has an 'any' type.


    (renderer) =>
    // @ts-expect-error - TS7031 - Binding element 'bills' implicitly has an 'any' type.
    (propName: string, { bills }) => {
      const summaryValuesByDemandUnit = R.pipe(
        // @ts-expect-error - TS2345 - Argument of type '(list: readonly unknown[]) => Record<string, unknown[]>' is not assignable to parameter of type '(list: readonly unknown[]) => readonly unknown[][]'.
        R.groupBy(R.prop("demandUnit")),
        R.map((billsForDemandUnit) => {
          if (propName.includes("demandRate")) {
            const tier = propName.slice(-1);

            const totalDemandCharges = R.sum(
              bills.map(R.prop(`demandTotal${tier}`))
            );

            const highestDemandValue = Math.max(
              // @ts-expect-error - TS2345 - Argument of type 'undefined' is not assignable to parameter of type 'number'.
              ...billsForDemandUnit.map(R.prop(`demandBilledQuantity${tier}`))
            );

            return totalDemandCharges != null && highestDemandValue > 0
              ? totalDemandCharges / highestDemandValue
              : 0;
          }
          // @ts-expect-error - TS2345 - Argument of type 'undefined' is not assignable to parameter of type 'number'.
          return Math.max(...billsForDemandUnit.map(R.prop(propName)));
        }),
        R.toPairs,
        R.filter(([demandUnit, sum]: [any, any]) => sum != null && sum !== 0),
        R.map(([demandUnit, sum]: [any, any]) => renderer(sum, demandUnit))
      )(bills);

      return (
        <div className={styles.list_table__all_view_cell}>
          <div className={styles.list_table__all_view_main}>
            {/* @ts-expect-error - TS2571 - Object is of type 'unknown'. */}
            {summaryValuesByDemandUnit.length > 0
              ? // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                summaryValuesByDemandUnit.join(" | ")
              : "-"}
          </div>
          {/* @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type. */}
          {bills.map((bill) => (
            <div key={bill.id} className={styles.list_table__all_view_sub}>
              {renderer(bill[propName], bill.demandUnit)}
            </div>
          ))}
        </div>
      );
    };

const allMetersRendererWrapper =
  // @ts-expect-error - TS7006 - Parameter 'summationFn' implicitly has an 'any' type. | TS7006 - Parameter 'prefixRenderer' implicitly has an 'any' type.


    (summationFn, prefixRenderer) =>
    (
      propName: string,
      // @ts-expect-error - TS7031 - Binding element 'bills' implicitly has an 'any' type.
      { bills },
      unit: UtilityUnit | null | undefined | "kVa" | "kW"
    ) =>
      allMetersColumnRenderer(
        // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
        ...summationFn(propName, bills),
        prefixRenderer,
        unit
      );

const getRateInfo = (
  propName: string,
  bill: UtilityBill
): {
  tier: number | null | undefined;
  rateType: "consumption" | "demand";
  currRow: UtilityBillRateStructure | null | undefined;
  prevRow: UtilityBillRateStructure | null | undefined;
} => {
  const tier = getTierFromPropName(propName);
  const rateType = propName.startsWith("consumption")
    ? "consumption"
    : "demand";
  const currRow = R.find(R.propEq("tier", tier))(
    bill[`${rateType}RateStructure`]
  );
  const prevRow =
    tier && tier > 0
      ? R.find(R.propEq("tier", tier - 1))(bill[`${rateType}RateStructure`])
      : null;
  // @ts-expect-error - TS2322 - Type 'Record<"tier", any> | undefined' is not assignable to type 'UtilityBillRateStructure | null | undefined'. | TS2322 - Type 'Record<"tier", any> | null | undefined' is not assignable to type 'UtilityBillRateStructure | null | undefined'.
  return { tier, rateType, currRow, prevRow };
};

const composeArray = (
  ...props: Array<TableColumn | Array<TableColumn> | null | undefined>
): // @ts-expect-error - TS2322 - Type '(TableColumn | null | undefined)[]' is not assignable to type 'TableColumn[]'.
Array<TableColumn> => props.filter(Boolean).flat(1);

const meterIdColumn = {
  data: "meterName",
  title: "Meter Id",
  csvParser: csvRemovePrefixParser,
  csvRenderer: csvStringRenderer,
  isRequired: true,
  singleMeterRenderer: (value) => (value ? `${value}` : ""),
} as TableColumn;

const accountNameColumn = {
  data: "accountNumber",
  title: "Account",
  csvRenderer: csvStringRenderer,
  csvParser: csvRemovePrefixParser,
  singleMeterRenderer: (value) => (value ? `${value}` : ""),
  validator: () => true,
  secondaryValidator: (propName, bill, { accounts }) =>
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    accounts.some((account) => account.accountNumber === bill[propName])
      ? undefined
      : "Account could not be matched to any existing account",
} as TableColumn;

const accountNoColumn = {
  data: "accountNumber",
  title: "Account No.",
  csvRenderer: csvStringRenderer,
  csvParser: csvRemovePrefixParser,
  singleMeterRenderer: (value) => (value ? `${value}` : ""),
  validator: () => true,
  secondaryValidator: (propName, bill, { accounts }) =>
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    accounts.some((account) => account.accountNumber === bill[propName])
      ? undefined
      : "Account could not be matched to any existing account",
} as TableColumn;

const serviceNameColumn = {
  data: "serviceName",
  title: "Service Name",
  csvRenderer: csvStringRenderer,
} as TableColumn;

const unitColumn = (utility: Utility): TableColumn => ({
  data: "unit",
  title: "Unit",
  csvRenderer: csvStringRenderer,
  csvParser: csvStringParser,
  singleMeterRenderer: (value) => (value ? `${value}` : ""),
  isRequired: true,
  validator: () => true,
  secondaryValidator: (propName, bill) => {
    const possibleUnits = allUtilitiesById[utility].units;
    // @ts-expect-error - TS2345 - Argument of type 'any' is not assignable to parameter of type 'never'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    if (!possibleUnits.includes(bill[propName])) {
      return `Unit must be one of (${possibleUnits.join(", ")})`;
    }
  },
});

const demandUnitColumn = (utility: string): TableColumn => ({
  data: "demandUnit",
  title: "Demand Unit",
  csvRenderer: csvStringRenderer,
  csvParser: csvStringParser,
  singleMeterRenderer: (value) => (value ? `${value}` : ""),
  isRequired: (propName, bill) => {
    return (
      utility === "electricity" &&
      // @ts-expect-error - TS2339 - Property 'demandBilledQuantity1' does not exist on type 'UtilityBill'. | TS2339 - Property 'demandMeterDemand1' does not exist on type 'UtilityBill'.
      (bill.demandBilledQuantity1 != null || bill.demandMeterDemand1 != null)
    );
  },
  validator: () => true,
  secondaryValidator: (propName, bill) => {
    const possibleUnits = ["kW", "kVa"];
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    if (!possibleUnits.includes(bill[propName])) {
      return `Unit must be one of (${possibleUnits.join(", ")})`;
    }
  },
});

const monthColumn: TableColumn = {
  data: "month",
  title: "Month",
  singleMeterRenderer: (val) => moment(val).format("MMM, YY").toUpperCase(),
  allMetersRenderer: (propName, parsedDates, unit) => {
    const { month, startDate, endDate } = parsedDates;

    return (
      <div className={styles.list_table__parsed_month}>
        <span className={styles.parsed_month_text}>
          {moment(month).format("MMM, YYYY")}
        </span>
        {!isIP && (
          <div className={styles.list_table__parsed_dates}>
            <div className={styles.list_table__parsed_date_container}>
              <span className={styles.list_table__parsed_date_text}>
                {moment(startDate).format("MM/DD/YY")}
              </span>
            </div>
            -
            <div className={styles.list_table__parsed_date_container}>
              <span className={styles.list_table__parsed_date_text}>
                {moment(endDate).format("MM/DD/YY")}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  },
};

const seperateDateColumns = [
  {
    data: "startDate",
    title: "Start Date",
    csvParser: csvDateParser,
    csvRenderer: csvStringRenderer,
    isRequired: true,
    validator: isValidDate,
    singleMeterRenderer: dateRenderer,
  },
  {
    data: "endDate",
    title: "End Date",
    csvParser: csvDateParser,
    csvRenderer: csvStringRenderer,
    isRequired: true,
    validator: isValidDate,
    singleMeterRenderer: dateRenderer,
    secondaryValidator: (propName, bill) => {
      if (bill.startDate && moment(bill.endDate).isBefore(bill.startDate)) {
        return "End date must be after start";
      }
    },
  },
] as Array<TableColumn>;

const meterInformationColumn = (): TableColumn =>
  ({
    data: "meterInfo",
    title: "Meter Information",
    allMetersRenderer: (propName, { bills }, unit, { history, siteId }) => (
      <div className={styles.list_table__all_view_cell}>
        <div className={styles.list_table__all_view_main}>
          {`${bills.length} Meter${bills.length !== 1 ? "s" : ""}`}
        </div>
        {bills.map((bill) => (
          <div className={styles.list_table__all_view_meters} key={bill.id}>
            <div className={styles.all_view_meters__title}>ID</div>
            <div
              className={styles.all_view_meters__value_link}
              onClick={() => {
                if (isBackpack) {
                  history.replace(`/site/${siteId}/utilities/bills/${bill.id}`);
                } else {
                  history.replace(
                    `/site/${siteId}/dashboard/utilities/bills/${bill.id}`
                  );
                }
              }}
            >
              {String(bill.meterName).slice(-4)}
            </div>
            {/* <div className={styles.all_view_meters__meter_name}>
            {bill.meterServiceName || "Meter Name"}
          </div> */}
            {!bill.isValid && (
              <Icons.Invalid className={styles.all_view_meters__status} />
            )}
          </div>
        ))}
      </div>
    ),
    parsedDatesTableRenderer: (propName, bills, unit) => {
      return (
        <div className={styles.list_table__all_view_cell}>
          <div className={styles.list_table__all_view_main}>
            {`${bills.length} Meter${bills.length !== 1 ? "s" : ""}`}
          </div>
          {bills.map((bill) => (
            <div className={styles.list_table__all_view_meters} key={bill.id}>
              <div className={styles.all_view_meters__title}>ID</div>
              <div className={styles.all_view_meters__value}>
                {String(bill.meterName).slice(-4)}
              </div>
              {/* <div className={styles.all_view_meters__meter_name}>
            {bill.meterServiceName || "Meter Name"}
          </div> */}
              {!bill.isValid && (
                <Icons.Invalid className={styles.all_view_meters__status} />
              )}
            </div>
          ))}
        </div>
      );
    },
  } as TableColumn);

const serviceDatesColumn = {
  data: "serviceDates",
  title: "Service Dates",
  singleMeterRenderer: serviceDateRenderer,
  csvRenderer: serviceDateRenderer,
  allMetersRenderer: (propName, { bills }, unit) => {
    const earliestStart = bills.map((bill) => bill.startDate).sort()[0];
    const latestEnd = bills
      .map((bill) => bill.endDate)
      .sort()
      .reverse()[0];
    return (
      <div className={styles.list_table__all_view_cell}>
        <div className={styles.list_table__all_view_main}>
          {bills.length > 0
            ? serviceDateRenderer(earliestStart + "&" + latestEnd)
            : "-"}
        </div>
        {bills.map((bill) => (
          <div className={styles.list_table__all_view_meters} key={bill.id}>
            {serviceDateRenderer(bill.startDate + "&" + bill.endDate)}
          </div>
        ))}
      </div>
    );
  },
  parsedDatesTableRenderer: (propName, bills, unit) => {
    const earliestStart = bills.map((bill) => bill.startDate).sort()[0];
    const latestEnd = bills
      .map((bill) => bill.endDate)
      .sort()
      .reverse()[0];
    return (
      <div className={styles.list_table__all_view_cell}>
        <div className={styles.list_table__all_view_main}>
          {bills.length > 0
            ? serviceDateRenderer(earliestStart + "&" + latestEnd)
            : "-"}
        </div>
        {bills.map((bill) => (
          <div className={styles.list_table__all_view_meters} key={bill.id}>
            {serviceDateRenderer(bill.startDate + "&" + bill.endDate)}
          </div>
        ))}
      </div>
    );
  },
} as TableColumn;

const statusColumn = {
  data: "status",
  title: "Status",
  allMetersRenderer: (propName, { bills }, unit) => {
    const numActive = bills.filter((bill) => bill.deactivatedAt == null).length;
    const numInactive = bills.filter(
      (bill) => bill.deactivatedAt != null
    ).length;
    return (
      <div className={styles.list_table__all_view_cell}>
        <div
          className={classnames(
            styles.list_table__all_view_main,
            styles.status_cell
          )}
        >
          {bills.length === 0 && "-"}
          {numActive > 0 && (
            <div className={styles.indicator_block}>
              <div className={styles.status_indicator} />
              {numActive}
            </div>
          )}
          {numInactive > 0 && (
            <div className={styles.indicator_block}>
              <div
                className={classnames(styles.status_indicator, styles.inactive)}
              />
              {numInactive}
            </div>
          )}
        </div>
        {bills.map((bill) => (
          <div className={styles.list_table__all_view_meters} key={bill.id}>
            <div
              className={classnames(
                styles.status_indicator,
                bill.deactivatedAt ? styles.inactive : ""
              )}
            />
            {bill.deactivatedAt ? "Inactive" : "Active"}
          </div>
        ))}
      </div>
    );
  },
} as TableColumn;

const totalCostColumn = {
  data: "totalSpend",
  title: "Total",
  csvParser: csvNumberParser,
  csvRenderer: (value) =>
    formatNum(value, { maxDecimals: 2, minDecimals: 2, excludeCommas: true }),
  isRequired: true,
  validator: isValidNum,
  singleMeterRenderer: dollarPrefixRenderer,
  allMetersRenderer: allMetersRendererWrapper(
    allMetersDataSummation,
    dollarPrefixRenderer
  ),
  parsedDatesTableRenderer: (propName, bills, unit) =>
    allMetersColumnRenderer(
      // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
      ...allMetersDataSummation(propName, bills),
      dollarPrefixRenderer,
      unit
    ),
  secondaryValidator: (propName, bill) => {
    const calcedTotalSpend = calcTotalSpend(bill);
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    if (!looselyEqual(calcedTotalSpend, bill[propName])) {
      return `Calculated Total is ${calcedTotalSpend.toFixed(2)}`;
    }
  },
} as TableColumn;

const costsColumns = [
  {
    data: "fixedCost",
    title: "Fixed Cost",
    csvParser: csvNumberParser,
    csvRenderer: (value) =>
      formatNum(value, { maxDecimals: 2, minDecimals: 2, excludeCommas: true }),
    validator: isValidNum,
    singleMeterRenderer: dollarPrefixRenderer,
    allMetersRenderer: allMetersRendererWrapper(
      allMetersDataSummation,
      dollarPrefixRenderer
    ),
  },
  {
    data: "taxes",
    title: "Taxes and Other",
    csvParser: csvNumberParser,
    csvRenderer: (value) =>
      formatNum(value, { maxDecimals: 2, minDecimals: 2, excludeCommas: true }),
    validator: isValidNum,
    singleMeterRenderer: dollarPrefixRenderer,
    allMetersRenderer: allMetersRendererWrapper(
      allMetersDataSummation,
      dollarPrefixRenderer
    ),
  },
  totalCostColumn,
] as Array<TableColumn>;

const rateTierColumns = (
  type: "consumption" | "demand",
  // unit: UtilityUnit | "kW",
  rateStructure: Array<{
    threshold: boolean;
    meterDemand: boolean;
  }>,
  includeUtilizationAndBilled?: boolean
): Array<TableColumn> => {
  const titleType = type === "consumption" ? "Cons." : "Demand";
  const rateRenderer = (
    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    value,
    unit:
      | any
      | null
      | undefined
      | "kVa"
      | "kW"
      | string
      | EnergyUnit
      | WaterUnit
      | WasteUnit
      | UtilityUnit
  ) =>
    formatNum(value, {
      postfix: unit ? ` $/${unit}` : "",
      maxDecimals: 2,
      minDecimals: 2,
    });
  const quantityRenderer = (
    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    value,
    unit:
      | any
      | null
      | undefined
      | "kVa"
      | "kW"
      | string
      | EnergyUnit
      | WaterUnit
      | WasteUnit
      | UtilityUnit
  ) => formatNum(value, { postfix: unit ? ` ${unit}` : "", maxDecimals: 6 });

  const isRowPropRequired = (propName: string, bill: UtilityBill) => {
    const { tier, rateType, currRow } = getRateInfo(propName, bill);
    const hasHigherTier = bill[`${rateType}RateStructure`]
      // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
      .filter((row) => row.tier > tier)
      .some(
        (row) =>
          row.billedQuantity != null ||
          row.total != null ||
          row.meterDemand != null
      );
    const isActiveTier =
      currRow &&
      (currRow.billedQuantity != null ||
        currRow.total != null ||
        currRow.meterDemand != null);
    return hasHigherTier || isActiveTier;
  };

  const hasPrevThreshold = (
    prevRow: UtilityBillRateStructure | null | undefined
  ) => {
    if (prevRow && prevRow.threshold == null) {
      return "Previous tier requires threshold";
    }
  };

  const rateCol = (titleTier: string, tier: number) => ({
    data: generateRateStructureProp(type, tier, "rate"),
    title: `${titleTier} ${titleType} Rate`,
    singleMeterRenderer: rateRenderer,
    allMetersRenderer:
      type === "consumption"
        ? // @ts-expect-error - TS7006 - Parameter 'propName' implicitly has an 'any' type. | TS7006 - Parameter 'bills' implicitly has an 'any' type.
          allMetersRendererWrapper((propName, bills) => {
            const consumption = R.sum(
              bills.map(
                // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
                (bill) =>
                  bill.consumptionRateStructure[tier]?.billedQuantity || 0
              )
            );
            const spend = R.sum(
              bills.map(
                // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
                (bill) => bill.consumptionRateStructure[tier]?.total || 0
              )
            );
            return [
              spend != null && consumption != null && spend !== 0
                ? spend / consumption
                : null,
              // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
              bills.map((bill) => ({
                value: bill[propName],
                id: bill.id,
              })),
            ];
          }, rateRenderer)
        : allMetersDemandColumnRenderer(rateRenderer),
  });

  const billedQuantityCol = (
    titleTier: string,
    tier: number,
    usageTitle: string
  ) => ({
    data: generateRateStructureProp(type, tier, "billedQuantity"),
    title: `${titleTier} ${titleType} ${usageTitle}`,
    csvParser: csvNumberParser,
    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    csvRenderer: (value) =>
      formatNum(value, { maxDecimals: 6, excludeCommas: true }),
    isRequired: isRowPropRequired,
    validator: isValidNum,
    singleMeterRenderer: quantityRenderer,
    allMetersRenderer:
      type === "consumption"
        ? allMetersRendererWrapper(allMetersDataSummation, quantityRenderer)
        : allMetersDemandColumnRenderer(quantityRenderer),
    // @ts-expect-error - TS7006 - Parameter 'propName' implicitly has an 'any' type. | TS7006 - Parameter 'bill' implicitly has an 'any' type.
    secondaryValidator: (propName, bill) => {
      const { currRow, prevRow } = getRateInfo(propName, bill);
      if (hasPrevThreshold(prevRow)) return hasPrevThreshold(prevRow);
      if (
        currRow &&
        currRow.threshold != null &&
        currRow.billedQuantity != null &&
        currRow.billedQuantity > currRow.threshold
      ) {
        return "Usage exceeds threshold";
      }
    },
  });

  const meterDemandCol = (titleTier: string, tier: number) => ({
    data: generateRateStructureProp(type, tier, "meterDemand"),
    title: `${titleTier} ${titleType} Meter Demand`,
    csvParser: csvNumberParser,
    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    csvRenderer: (value) =>
      formatNum(value, { maxDecimals: 6, excludeCommas: true }),
    isRequired: false,
    validator: isValidNum,
    singleMeterRenderer: quantityRenderer,
    allMetersRenderer:
      type === "consumption"
        ? allMetersRendererWrapper(allMetersDataSummation, quantityRenderer)
        : allMetersDemandColumnRenderer(quantityRenderer),
    // @ts-expect-error - TS7006 - Parameter 'propName' implicitly has an 'any' type. | TS7006 - Parameter 'bill' implicitly has an 'any' type.
    secondaryValidator: (propName, bill) => {
      const { prevRow } = getRateInfo(propName, bill);
      return hasPrevThreshold(prevRow);
    },
  });

  const thresholdCol = (titleTier: string, tier: number) => ({
    data: generateRateStructureProp(type, tier, "threshold"),
    title: `${titleTier} ${titleType} Threshold`,
    csvParser: csvNumberParser,
    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    csvRenderer: (value) =>
      formatNum(value, { maxDecimals: 6, excludeCommas: true }),
    validator: isValidNum,
    singleMeterRenderer: quantityRenderer,
    allMetersRenderer:
      type === "consumption"
        ? allMetersRendererWrapper(allListDataAverage, quantityRenderer)
        : allMetersDemandColumnRenderer(quantityRenderer),
    // @ts-expect-error - TS7006 - Parameter 'propName' implicitly has an 'any' type. | TS7006 - Parameter 'bill' implicitly has an 'any' type.
    isRequired: (propName, bill) => {
      const { tier: currTier, rateType } = getRateInfo(propName, bill);
      return (
        Boolean(currTier) &&
        // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
        bill[`numOf${capitalize(rateType)}Tiers`] > currTier + 1
      );
    },
    // @ts-expect-error - TS7006 - Parameter 'propName' implicitly has an 'any' type. | TS7006 - Parameter 'bill' implicitly has an 'any' type.
    secondaryValidator: (propName, bill) => {
      const { prevRow } = getRateInfo(propName, bill);
      return hasPrevThreshold(prevRow);
    },
  });

  const totalCol = (titleTier: string, tier: number) => ({
    data: generateRateStructureProp(type, tier, "total"),
    title: `${titleTier} ${titleType} Total`,
    csvParser: csvNumberParser,
    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    csvRenderer: (value) =>
      formatNum(value, { maxDecimals: 2, minDecimals: 2, excludeCommas: true }),
    isRequired: isRowPropRequired,
    validator: isValidNum,
    singleMeterRenderer: dollarPrefixRenderer,
    allMetersRenderer: allMetersRendererWrapper(
      allMetersDataSummation,
      dollarPrefixRenderer
    ),
  });

  return rateStructure
    .map((row, i) => {
      const titleTier = `Tier-${i + 1}`;
      const usageTitle = row.meterDemand ? "Billed Demand" : "Utilization";

      return composeArray(
        rateCol(titleTier, i),
        // @ts-expect-error - TS2345 - Argument of type '{ data: string; title: string; csvParser: (s: string) => number | null | undefined; csvRenderer: (value: any) => string; isRequired: (propName: string, bill: UtilityBill) => boolean | ... 1 more ... | undefined; validator: (value?: string | ... 2 more ... | undefined) => boolean; singleMeterRenderer: (value: any, un...' is not assignable to parameter of type 'TableColumn | TableColumn[] | null | undefined'.
        includeUtilizationAndBilled && type === "demand"
          ? [
              billedQuantityCol(titleTier, i, "Utilization"),
              billedQuantityCol(titleTier, i, "Billed Demand"),
            ]
          : billedQuantityCol(titleTier, i, usageTitle),
        row.meterDemand && type === "demand"
          ? meterDemandCol(titleTier, i)
          : null,
        row.threshold ? thresholdCol(titleTier, i) : null,
        totalCol(titleTier, i)
      );
    })
    .flat();
};

const peakOccurred = {
  data: "peakOccurred",
  title: "Peak Occurred",
  csvParser: csvStringParser,
  csvRenderer: csvStringRenderer,
  isRequired: false,
  validator: isValidDateTime,
  singleMeterRenderer: dateTimeRenderer,
  allMetersRenderer: allMetersRendererWrapper(
    allListDataNoSummary,
    dateTimeRenderer
  ),
  secondaryValidator: (propName, bill) => {
    const [startDate, endDate, peak] = [
      moment(bill["startDate"]),
      moment(bill["endDate"]),
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
      moment(bill[propName]),
    ];
    if (!peak.isBetween(startDate, endDate)) {
      return "Peak must be within billing period";
    }
  },
} as TableColumn;

const powerFactor = {
  data: "powerFactor",
  title: "Power Factor",
  csvParser: csvNumberParser,
  csvRenderer: (value) =>
    formatNum(value, { maxDecimals: 3, excludeCommas: true }),
  isRequired: false,
  validator: isValidNum,
  singleMeterRenderer: percentPostfixRenderer,
  allMetersRenderer: allMetersRendererWrapper(
    allListDataAverage,
    percentPostfixRenderer
  ),
  secondaryValidator: (propName, bill) => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
    const value = bill[propName];
    if (value < 0 || value > 100) return "Value must in range 0 - 100";
  },
} as TableColumn;

const summaryColumns = (utility: Utility): Array<TableColumn> => {
  const quantityRenderer = (
    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    value,
    unit:
      | any
      | null
      | undefined
      | "kVa"
      | "kW"
      | string
      | EnergyUnit
      | WaterUnit
      | WasteUnit
      | UtilityUnit
  ) => formatNum(value, { postfix: unit ? ` ${unit}` : "", maxDecimals: 2 });
  const demandQuantityRenderer = (
    value: number | string,
    unit: string | null | undefined
  ) => formatNum(value, { postfix: " kW", maxDecimals: 2 });
  const rateRenderer = (
    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
    value,
    unit:
      | "kVa"
      | null
      | undefined
      | "kW"
      | string
      | EnergyUnit
      | WaterUnit
      | WasteUnit
      | UtilityUnit
  ) =>
    formatNum(value, {
      postfix: unit ? ` $/${unit}` : "",
      maxDecimals: 2,
      minDecimals: 2,
    });

  return composeArray(
    {
      data: "totalConsumption",
      title: "Total Cons.",
      csvRenderer: (value) =>
        formatNum(value, { maxDecimals: 3, excludeCommas: true }),
      singleMeterRenderer: quantityRenderer,
      allMetersRenderer: allMetersRendererWrapper(
        allMetersDataSummation,
        quantityRenderer
      ),
      parsedDatesTableRenderer: (propName, bills, unit) =>
        allMetersColumnRenderer(
          // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
          ...allMetersDataSummation(propName, bills),
          quantityRenderer,
          unit
        ),
    },
    utility === "electricity"
      ? {
          data: "totalDemand",
          title: "Peak Demand",
          csvRenderer: (value) =>
            formatNum(value, { maxDecimals: 3, excludeCommas: true }),
          singleMeterRenderer: demandQuantityRenderer,
          allMetersRenderer: allMetersDemandColumnRenderer(quantityRenderer),
        }
      : null,
    {
      data: "blendedRate",
      title: "Blended Rate",
      csvRenderer: (value) =>
        formatNum(value, {
          maxDecimals: 2,
          minDecimals: 2,
          excludeCommas: true,
        }),
      singleMeterRenderer: rateRenderer,
      allMetersRenderer: (propName, { bills }, unit) => {
        const [totalSpend, totalConsumption] = [
          // @ts-expect-error - TS2345 - Argument of type '(number | null | undefined)[]' is not assignable to parameter of type 'readonly number[]'.
          R.sum(bills.map((bill) => bill["totalSpend"])),
          R.sum(bills.map((bill) => bill["totalConsumption"])),
        ];
        const [main, subs] = [
          totalConsumption !== 0 &&
          !isNaN(totalConsumption) &&
          !isNaN(totalSpend)
            ? formatNum(totalSpend / totalConsumption, {
                minDecimals: 2,
                maxDecimals: 2,
              })
            : "",
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'UtilityBill'.
          bills.map((bill) => ({ value: bill[propName], id: bill.id })),
        ];
        return allMetersColumnRenderer(main, subs, rateRenderer, unit);
      },
    }
  );
};

export default ({
  format,
  utility,

  rateFormats: {
    consumptionRateFormat,
    demandRateFormat,
    includeUtilizationAndBilled,
  },

  optionalColumns = {},
}: {
  // prettier-ignore
  format: 'ALL_METERS_VIEW' | 'SINGLE_METER_VIEW' | 'CSV_DOWNLOAD' | 'CSV_UPLOAD' | 'PARSED_DATE_VIEW',
  utility: Utility;
  rateFormats: {
    consumptionRateFormat: Array<{
      threshold: boolean;
      meterDemand: boolean;
    }>;
    demandRateFormat: Array<{
      threshold: boolean;
      meterDemand: boolean;
    }>;
    includeUtilizationAndBilled?: boolean;
  };
  optionalColumns?: Partial<Record<OptionalUtilityBillInputTypes, boolean>>;
}): Array<TableColumn> => {
  const formatFilters = {
    // @ts-expect-error - TS7006 - Parameter 'col' implicitly has an 'any' type.
    ALL_METERS_VIEW: (col) => col.allMetersRenderer != null,
    // @ts-expect-error - TS7006 - Parameter 'col' implicitly has an 'any' type.
    SINGLE_METER_VIEW: (col) =>
      col.singleMeterRenderer != null &&
      col.data !== "meterName" &&
      col.data !== "accountName" &&
      col.data !== "unit" &&
      col.data !== "startDate" &&
      col.data !== "endDate",
    // @ts-expect-error - TS7006 - Parameter 'col' implicitly has an 'any' type.
    CSV_DOWNLOAD: (col) => col.csvRenderer != null && col.data !== "month",
    // @ts-expect-error - TS7006 - Parameter 'col' implicitly has an 'any' type.
    CSV_UPLOAD: (col) => col.csvParser != null,
    // @ts-expect-error - TS7006 - Parameter 'col' implicitly has an 'any' type.
    PARSED_DATE_VIEW: (col) => col.parsedDatesTableRenderer != null,
  } as const;

  if (isBackpack) {
    return composeArray(
      monthColumn,
      accountNoColumn,
      meterInformationColumn(),
      serviceDatesColumn,
      statusColumn,
      totalCostColumn,
      summaryColumns(utility)
    ).filter(formatFilters[format]);
  }

  return composeArray(
    monthColumn,
    meterIdColumn,
    accountNameColumn,
    serviceNameColumn,
    unitColumn(utility),
    utility === "electricity" ? demandUnitColumn(utility) : null,
    meterInformationColumn(),
    serviceDatesColumn,
    statusColumn,
    seperateDateColumns,
    rateTierColumns("consumption", consumptionRateFormat),
    utility === "electricity"
      ? rateTierColumns("demand", demandRateFormat, includeUtilizationAndBilled)
      : null,
    optionalColumns.peakOccurred ? peakOccurred : null,
    optionalColumns.powerFactor ? powerFactor : null,
    costsColumns,
    summaryColumns(utility)
  ).filter(formatFilters[format]);
};
