import Cookies from "js-cookie";
import * as R from "ramda";
import request from "./request";
import {
  bugsnagPostgrestErrorHandler,
  bugsnagGeneralErrorHandler,
} from "./postgrestApi/common";

import { getEquipmentWithRelatedDatastreams } from "./postgrestApi";

import type { DropDownOptions, DataStreamData } from "../types";

export const queryDropDownOptions = (
  siteId: number
): Promise<DropDownOptions> =>
  Promise.all([
    request
      .get(`/rest/brick_point_types`)
      .query({ select: "name" })
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .then((res) => R.map((x) => x.name, res.body))
      .catch(bugsnagPostgrestErrorHandler),
    request
      .get(`/rest/brick_equipment_types`)
      .query({ select: "name" })
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .then((res) => R.map((x) => x.name, res.body))
      .catch(bugsnagPostgrestErrorHandler),
    getEquipmentWithRelatedDatastreams(siteId),
  ])
    .then(
      ([brickPointTypes, brickEquipmentTypes, equipment]: [any, any, any]) => ({
        brickPointTypes,
        brickEquipmentTypes,
        equipment,
      })
    )
    .catch(function (error) {
      bugsnagGeneralErrorHandler(error);
      return {
        brickPointTypes: [],
        brickEquipmentTypes: [],
        equipment: [],
      };
    });

const hasBractletId = (dataStream: DataStreamData): boolean =>
  dataStream.bractlet_id !== null;

const queryDataPoints = (
  endpoint: string,
  options: {
    [key: string]: string;
  }
): Promise<Array<DataStreamData>> =>
  request
    .get(`/rest/${endpoint}`)
    .query(options)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then((res) => R.filter(hasBractletId, res.body))
    .catch(function (error) {
      bugsnagPostgrestErrorHandler(error);
      return [];
    });

export const querySiteDataStreams = (
  siteId: number
): Promise<Array<Array<DataStreamData>>> => {
  const queryOptions = { select: "*", site_id: `eq.${siteId}` } as const;
  // @ts-expect-error - TS2322 - Type 'Promise<{ stream_type: string; bractlet_created_at: string; bractlet_id: number; bractlet_name?: string | undefined; data_stream_id: number | null | undefined; description?: string | null | undefined; ... 16 more ...; referencing_data_stream_ids: number[]; }[][]>' is not assignable to type 'Promise<Data[][]>'.
  return Promise.all([
    queryDataPoints("bep_data_streams_summary", {
      ...queryOptions,
      stream_type: "eq.bas",
    }),
    queryDataPoints("bep_data_streams_summary", {
      ...queryOptions,
      stream_type: "eq.equipment",
    }).then((res) => res.map((d) => ({ ...d, stream_type: "power_meter" }))),
    queryDataPoints("bep_data_streams_summary", {
      ...queryOptions,
      stream_type: "eq.weather",
    }),
    queryDataPoints("bep_data_streams_summary", {
      ...queryOptions,
      stream_type: "eq.pulse",
    }),
  ]);
};

type TimeseriesApiResponse = {
  data_stream_id: number;
  end_time: number;
  start_time: number;
  interval: number;
  data: Array<{
    time: number;
    value: number;
  }>;
};

export const getTimeseriesData = (
  dataToFetch: Array<{
    dataStreamId: number;
    startTime: number;
    endTime: number;
    interval: number;
    endInclusive?: boolean;
  }>
): Promise<Array<TimeseriesApiResponse>> =>
  request
    .post("/api/data_streams")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(
      dataToFetch.map((requestPayload) => {
        const { dataStreamId, startTime, endTime, interval, endInclusive } =
          requestPayload;
        return {
          data_stream_id: dataStreamId,
          start_time: startTime,
          end_time: endTime,
          interval,
          end_inclusive: endInclusive != null ? endInclusive : true,
          fill: null,
        };
      })
    )
    .then((r) => r.body);
