import Chart from "chart.js";
import { defaultMemoize } from "reselect";
import moment from "moment";

// register plugins
import "chartjs-plugin-zoom";

import { rgba } from "./global_functions/util";

// Fixes bug with zooming in on charts
// @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
Chart.helpers.isObject = (value) =>
  value !== null &&
  !(value instanceof moment) &&
  Object.prototype.toString.call(value) === "[object Object]";

Chart.plugins.register({
  id: "backgroundColor",
  // @ts-expect-error - TS2339 - Property 'chart' does not exist on type 'Chart'. | TS2339 - Property 'backgroundColor' does not exist on type 'ChartOptions'.
  beforeDraw({ chart: { width, height, ctx }, options: { backgroundColor } }) {
    if (!backgroundColor) return;

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, width, height);
  },
});

const setBackgroundColors = defaultMemoize(
  // @ts-expect-error - TS7006 - Parameter 'datasets' implicitly has an 'any' type. | TS7006 - Parameter 'width' implicitly has an 'any' type. | TS7006 - Parameter 'height' implicitly has an 'any' type. | TS7006 - Parameter 'yScale' implicitly has an 'any' type. | TS7006 - Parameter 'ctx' implicitly has an 'any' type. | TS7006 - Parameter 'options' implicitly has an 'any' type.
  (datasets, width, height, yScale, ctx, options) => {
    if (!datasets) return;

    const zero = yScale.getPixelForValue(0);

    const runningTotals = {};

    // @ts-expect-error - TS7006 - Parameter 'dataset' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type.
    datasets.forEach((dataset, index) => {
      if (dataset.hidden) return;

      const meta = Object.values(dataset._meta)[0];
      // @ts-expect-error - TS2571 - Object is of type 'unknown'.
      if (meta.type !== "line") return;

      // @ts-expect-error - TS2571 - Object is of type 'unknown'.
      const model = meta.$filler.el._model;

      if (options.gradient) {
        let min = Infinity;
        let max = -Infinity;

        // @ts-expect-error - TS7031 - Binding element 'x' implicitly has an 'any' type. | TS7031 - Binding element 'y' implicitly has an 'any' type.
        dataset.data.forEach(({ x, y }) => {
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
          min = Math.min(min, y, runningTotals[x] || 0);
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'. | TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
          runningTotals[x] = (runningTotals[x] || 0) + y;
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
          max = Math.max(max, runningTotals[x]);
        });

        const bottom = yScale.getPixelForValue(min);
        const top = yScale.getPixelForValue(max);

        const gradient = ctx.createLinearGradient(0, top, 0, bottom);

        const maxColor = rgba(dataset.backgroundColor, options.opacity || 0.4);
        const minColor = "rgba(255,255,255,0)";

        gradient.addColorStop(0, maxColor);

        if (zero > top && zero < bottom) {
          const middle = (bottom - zero) / (bottom - top);

          gradient.addColorStop(middle, minColor);
          gradient.addColorStop(1, maxColor);
        } else {
          gradient.addColorStop(1, minColor);
        }

        model.backgroundColor = gradient;
      } else if (options.opacity != null) {
        model.backgroundColor = rgba(dataset.backgroundColor, options.opacity);
      }
    });
  }
);

Chart.plugins.register({
  id: "backgroundFill",
  // @ts-expect-error - TS2339 - Property 'chart' does not exist on type 'Chart'. | TS2339 - Property 'backgroundFill' does not exist on type 'ChartOptions'.
  beforeRender({ chart, options: { backgroundFill: options = {} } }) {
    if (!options.enabled) return;

    const scales = options.scales || Object.keys(chart.scales);

    for (const scale of scales) {
      setBackgroundColors(
        chart.config.data.datasets,
        chart.width,
        chart.height,
        chart.scales[scale],
        chart.ctx,
        options
      );
    }
  },
});
