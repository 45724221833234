import * as React from "react";
import classnames from "classnames";
import styles from "./Switch.module.scss";
import { useControllableState } from "../../../../global_functions/hooks";
import Text, { TextVariants } from "../../type/Text/Text";
import Icon, { IconNames } from "../../icons/Icon/Icon";
import { Include, SemanticColors } from "@src/straps/colors";

export interface SwitchProps extends SwitchIconProps {
  defaultChecked?: boolean;
  // If undefined, Switch can be used as an uncontrolled component
  label?: string;
  labelVariant?: TextVariants;
  labelColor?: React.ComponentProps<typeof Text>["color"];
  labelContainerClassName?: string;
  labelIcon?: IconNames;
  labelIconProps?: Omit<React.ComponentProps<typeof Icon>, "name">;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dataTestId?: string;
}

const Switch = ({
  defaultChecked = false,
  checked: checkedProp,
  color,
  disabled = false,
  size = "medium",
  label,
  labelVariant, // add default label variants for each size
  labelColor,
  labelContainerClassName,
  labelIcon,
  labelIconProps,
  onChange,
  dataTestId,
}: Readonly<SwitchProps>) => {
  const [checked, setChecked] = useControllableState(
    checkedProp,
    defaultChecked
  );
  const handleOnChange = React.useCallback(
    (e) => {
      if (disabled) {
        return;
      }

      setChecked(e.target.checked);
      if (onChange) {
        onChange(e);
      }
    },
    [disabled, onChange, setChecked]
  );

  const switchEl = (
    <div
      className={classnames(
        { "opacity-25": disabled },
        "relative inline-flex items-center justify-center p-1"
      )}
    >
      <input
        className={classnames(
          {
            "cursor-pointer": !disabled,
          },
          "absolute left-0 top-0 m-0 h-full w-full opacity-0"
        )}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleOnChange}
        data-testid={dataTestId ?? "switch-input"}
      />
      <SwitchIcon
        checked={checked}
        disabled={disabled}
        size={size}
        color={color}
      />
    </div>
  );

  if (!label) {
    return switchEl;
  }

  return (
    <label
      className={classnames(
        labelContainerClassName,
        {
          "cursor-pointer": !disabled,
        },
        "inline-flex items-center"
      )}
    >
      <Text
        variant={labelVariant}
        color={labelColor}
        className={classnames("flex", {
          "opacity-25": disabled,
          "mr-2": size === "small",
          "mr-3": size === "medium",
          "mr-[14px]": size === "large",
        })}
      >
        {!!labelIcon && (
          <Icon
            {...labelIconProps}
            name={labelIcon}
            className={classnames("mr-1 shrink-0", labelIconProps?.className)}
          />
        )}
        {label}
      </Text>
      {switchEl}
    </label>
  );
};

interface SwitchIconProps {
  checked?: boolean;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  color?: Include<SemanticColors, "positive" | "negative">;
}
function SwitchIcon({
  checked,
  disabled,
  size,
  color = "positive",
}: Readonly<SwitchIconProps>) {
  const small = size === "small";
  return (
    <span
      className={classnames(
        "group pointer-events-none relative rounded-full outline-straps-hyperlink-hover transition-colors hover:shadow-[0px_3px_6px_rgba(0,29,35,0.18)]",
        styles.switch,
        {
          "h-[16px] w-[30px] ": small,
          "h-[18px] w-[33px] ": !small,
          "cursor-pointer": !disabled,
          "bg-straps-tertiary": !checked,
          "bg-straps-positive-bg": checked && color === "positive",
          "bg-straps-negative-bg": checked && color === "negative",
        }
      )}
    >
      <span
        className={classnames(
          "absolute top-0 rounded-full transition-all ease-in-out",
          {
            "h-[16px] w-[16px] ": small,
            "h-[18px] w-[18px] ": !small,
            "left-0 bg-straps-secondary": !checked,
            "bg-straps-positive": checked && color === "positive",
            "bg-straps-negative": checked && color === "negative",
            "left-[14px] ": checked && small, // 30 - 16
            "left-[15px] ": checked && !small, // 33 - 18
            "group-hover:bg-straps-primary-hover": !disabled && !checked,
          }
        )}
      ></span>
    </span>
  );
}
export default Switch;
