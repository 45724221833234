import * as React from "react";
import * as ReactDOM from "react-dom";
import { bugsnagClient } from "../bugsnag";

type TooltipContainerProps = {
  children: React.ReactNode;
  position: {
    x?: number;
    y?: number;
    width?: number;
  };
  renderOnTarget?: boolean;
  dropdown: boolean;
  align?: "right" | "bottom" | "topRight" | "left" | "topLeft";
};

const TooltipContainer = (props: TooltipContainerProps) => {
  const tooltipRef = React.useRef<any>(null);

  const tooltipRoot = document.getElementById("root");

  const [position, setPosition] = React.useState<any>(props.position);
  const [size, setSize] = React.useState({ width: 0, height: 0 });

  const handler = React.useCallback(
    ({ x, y }: MouseEvent) => {
      if (tooltipRef.current) {
        const { width, height } = tooltipRef.current.getBoundingClientRect();
        setSize({ width, height });
      }
      if (props.renderOnTarget) {
        return;
      }
      setPosition({ x, y });
    },
    [props.renderOnTarget]
  );

  React.useEffect(() => {
    document.addEventListener("mousemove", handler);

    return () => document.removeEventListener("mousemove", handler);
  }, [handler]);

  const { children } = props;

  const style = React.useMemo(() => {
    if (props.dropdown) {
      return {
        left: position.x,
        top: position.y,
        width: position.width,
      };
    }
    switch (props.align) {
      case "right":
        return {
          left: `${position.x + 8}px`,
          bottom: `${window.innerHeight - position.y - size.height / 2}px`,
        };

      case "topRight":
        return {
          left: `${position.x - 12}px`,
          bottom: `${window.innerHeight - position.y + 20}px`,
        };

      case "bottom":
        return {
          left: `${position.x - 8}px`,
          top: `${position.y + size.height / 2 + 4}px`,
        };

      case "left":
        return {
          left: `${position.x - size.width - 8}px`,
          top: `${position.y + 8}px`,
        };

      case "topLeft":
        return {
          left: `${position.x - size.width + 18}px`,
          bottom: `${window.innerHeight - position.y + 20}px`,
        };

      default:
        return {
          left: `${position.x - size.width / 2}px`,
          bottom: `${window.innerHeight - position.y + 20}px`,
        };
    }
  }, [props.dropdown, position, size, props.align]);

  if (!tooltipRoot) {
    bugsnagClient?.notify("Could not find tooltip root: #root");
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={tooltipRef} className="tooltip--inner-container" style={style}>
      {children}
    </div>,
    tooltipRoot
  );
};

type TooltipWrapperProps = {
  children: React.ReactElement;
  tooltip?: React.ReactNode;
  onMouseEnter?: any;
  onMouseLeave?: any;
  renderOnTarget?: boolean;
  debug?: boolean;
  dropdown?: boolean;
  align?: "right" | "bottom" | "topRight" | "left" | "topLeft";
};

const TooltipWrapper = (props: TooltipWrapperProps) => {
  const target = React.useRef<HTMLElement>();
  const [hovered, setHovered] = React.useState<boolean>(false);
  const [position, setPosition] = React.useState<{
    x: number;
    y: number;
    width?: number;
  } | null>(null);

  const getPosition = (e: any) => {
    props.onMouseEnter?.(e);
    setHovered(true);

    if (!props.renderOnTarget) {
      return setPosition({
        x: e.pageX - e.view?.scrollX,
        y: e.pageY - e.view?.scrollY,
      });
    }
    if (target?.current) {
      const { x, y, width, height, bottom } =
        target.current.getBoundingClientRect();

      if (props.dropdown) {
        return setPosition({ x, y: bottom, width });
      }
      switch (props.align) {
        case "right":
          return setPosition({
            x: x + width,
            y: y + height / 2,
          });

        default:
          return setPosition({
            x: x + width / 2,
            y: y + 18,
          });
      }
    }
  };

  const onMouseLeave = (e: any) => {
    props.onMouseLeave?.(e);
    // onMouseLeave is being overwritten below, so any pre-existing onMouseLeave calls need to be re-added here
    props.children.props.onMouseLeave?.(e);
    if (!props.debug) setHovered(false);
  };

  return (
    <>
      {React.cloneElement(props.children, {
        onMouseOver: getPosition,
        onMouseLeave,
        ...(props.renderOnTarget && {
          ref: target,
        }),
      })}
      {hovered && (
        <TooltipContainer
          renderOnTarget={props.renderOnTarget}
          position={position || {}}
          dropdown={props.dropdown || false}
          align={props.align}
        >
          <div style={{ display: "flex" }}>{props.tooltip}</div>
        </TooltipContainer>
      )}
    </>
  );
};

export default TooltipWrapper;
