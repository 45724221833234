import classnames from "classnames";
import { ElementType, forwardRef, useMemo } from "react";

import { Loader } from "../../../../global_components";
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from "../../../../utilTypes";
import { SemanticColors } from "../../../colors";
import Icon, { IconNames } from "../../icons/Icon/Icon";
import styles from "./Button.module.scss";
import TextButton, { TextButtonProps } from "./TextButton/TextButton";

interface BaseButtonProps {
  color?: SemanticColors;
  disabled?: boolean;
  icon?: IconNames;
  iconRight?: boolean;
  loading?: boolean;
  dataTestId?: string;
}

type MainButtonProps = {
  noFrameVariant: undefined;
  outline?: boolean;
  size?: "large" | "medium" | "small";
  width?: string;
  iconSize?: "large" | "medium" | "small";
  rounded?: boolean;
};

type Props = BaseButtonProps & (MainButtonProps | TextButtonProps);

export type ButtonProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, Props>;

const allColorClasses: Record<
  SemanticColors,
  { FILL: string; STROKE: string; TEXT_COLOR: string }
> = {
  primary: {
    FILL: "bg-straps-primary hover:bg-straps-primary-hover focus-visible:outline-straps-primary-hover",
    STROKE:
      "border-straps-primary hover:border-straps-primary-hover hover:bg-straps-primary-hover focus-visible:outline-straps-primary-hover",
    TEXT_COLOR: "text-straps-primary hover:text-pure-white",
  },
  secondary: {
    FILL: "bg-straps-secondary hover:bg-straps-secondary-hover focus-visible:outline-straps-secondary-hover",
    STROKE:
      "border-straps-secondary hover:border-straps-secondary-hover hover:bg-straps-secondary-hover focus-visible:outline-straps-secondary-hover",
    TEXT_COLOR: "text-straps-secondary hover:text-pure-white",
  },
  positive: {
    FILL: "bg-straps-positive hover:bg-straps-positive-hover focus-visible:outline-straps-positive-hover",
    STROKE:
      "border-straps-positive hover:border-straps-positive-hover hover:bg-straps-positive-hover focus-visible:outline-straps-positive-hover",
    TEXT_COLOR: "text-straps-positive hover:text-pure-white",
  },
  warning: {
    FILL: "bg-straps-warning hover:bg-straps-warning-hover focus-visible:outline-straps-warning-hover",
    STROKE:
      "border-straps-warning hover:border-straps-warning-hover hover:bg-straps-warning-hover focus-visible:outline-straps-warning-hover",
    TEXT_COLOR: "text-straps-warning hover:text-pure-white",
  },
  negative: {
    FILL: "bg-straps-negative hover:bg-straps-negative-hover focus-visible:outline-straps-negative-hover",
    STROKE:
      "border-straps-negative hover:border-straps-negative-hover hover:bg-straps-negative-hover focus-visible:outline-straps-negative-hover",
    TEXT_COLOR: "text-straps-negative hover:text-pure-white",
  },
  brand: {
    FILL: "bg-straps-brand hover:bg-straps-brand-hover focus-visible:outline-straps-brand-hover",
    STROKE:
      "border-straps-brand hover:border-straps-brand-hover hover:bg-straps-brand-hover focus-visible:outline-straps-brand-hover",
    TEXT_COLOR: "text-straps-brand hover:text-pure-white",
  },
  hyperlink: {
    FILL: "bg-straps-hyperlink hover:bg-straps-hyperlink-hover focus-visible:outline-straps-hyperlink-hover",
    STROKE:
      "border-straps-hyperlink hover:border-straps-hyperlink-hover hover:bg-straps-hyperlink-hover focus-visible:outline-straps-hyperlink-hover",
    TEXT_COLOR: "text-straps-hyperlink hover:text-pure-white",
  },
  "accent-1": {
    FILL: "bg-straps-accent-1 hover:bg-straps-accent-1-hover focus-visible:outline-straps-accent-1-hover",
    STROKE:
      "border-straps-accent-1 hover:border-straps-accent-1-hover hover:bg-straps-accent-1-hover focus-visible:outline-straps-accent-1-hover",
    TEXT_COLOR: "text-straps-accent-1 hover:text-pure-white",
  },
  white: {
    FILL: "bg-pure-white focus-visible:outline-pure-white-200",
    STROKE:
      "border-pure-white hover:border-pure-white-200 hover:bg-pure-white-200 focus-visible:outline-pure-white-200",
    TEXT_COLOR: "text-pure-white hover:text-primary",
  },
} as const;

export const BUTTON_COLORS = Object.keys(allColorClasses) as Array<
  keyof typeof allColorClasses
>;

type ButtonComponent = <C extends React.ElementType = "button">(
  props: ButtonProps<C>
) => React.ReactElement | null;

const MainButton: ButtonComponent = forwardRef(function MainButton<
  C extends ElementType = "button"
>(
  {
    component,
    className,
    color = "primary",
    outline = false,
    size = "medium",
    width,
    rounded,
    disabled,
    icon,
    children,
    loading,
    iconRight = false,
    iconSize = "small",
    dataTestId,
    noFrameVariant,
    ...otherProps
  }: ButtonProps<C>,
  ref: PolymorphicRef<C>
) {
  const colorClasses = useMemo(() => {
    if (outline) {
      return classnames(
        "bg-transparent",
        "border-solid",
        "border-2",
        allColorClasses[color].STROKE
      );
    }
    return classnames("border-0", allColorClasses[color].FILL);
  }, [color, outline]);

  const sizingClasses = useMemo(() => {
    return classnames({
      "h-11.5": size === "large",
      "w-11.5": size === "large" && !children,
      "h-8.5": size === "medium",
      "w-8.5": size === "medium" && !children,
      "w-7 h-7": size === "small" && !children,
      "h-6": size === "small" && children,
    });
  }, [children, size]);

  const spacingClasses = useMemo(() => {
    if (width || !children) return "";
    return classnames({
      "px-6": size === "large",
      "px-4": size === "medium",
      "px-2": size === "small",
    });
  }, [size, width, children]);

  const textClasses = useMemo(() => {
    const classes = classnames("font-bold", {
      "text-bn uppercase": !rounded,
      "text-bs leading-[14px] capitalize": rounded,
    });
    if (outline) {
      return classnames(allColorClasses[color].TEXT_COLOR, classes);
    }
    return classnames(
      {
        "text-pure-white": color !== "warning" && color !== "white",
        "text-straps-primary": color === "warning",
        "text-straps-secondary hover:text-straps-primary-hover":
          color === "white",
      },
      classes
    );
  }, [color, rounded, outline]);

  const buttonClasses = useMemo(() => {
    return classnames(
      styles.button,
      colorClasses,
      textClasses,
      sizingClasses,
      spacingClasses,
      "relative inline-flex items-center justify-center",
      "gap-x-2",
      "hover:shadow-[0_10px_20px_rgba(0,29,35,0.12)]",
      "transition",
      {
        "cursor-pointer": !disabled && !loading,
        "cursor-wait": loading,
        "cursor-not-allowed opacity-25": disabled,
        "rounded-full": rounded,
      },
      className
    );
  }, [
    loading,
    colorClasses,
    textClasses,
    sizingClasses,
    spacingClasses,
    disabled,
    rounded,
    className,
  ]);

  const Component = component || "button";
  return (
    <Component
      ref={ref}
      {...otherProps}
      style={{ width: width }}
      disabled={disabled || loading}
      className={buttonClasses}
      data-testid={dataTestId}
    >
      <>
        {icon ? (
          loading ? (
            <Loader
              size={12}
              color="currentColor"
              className={classnames("static", {
                "order-1": iconRight,
              })}
            />
          ) : (
            <Icon
              name={icon}
              size={iconSize}
              className={classnames({
                "order-1": iconRight,
              })}
            />
          )
        ) : (
          // TODO implement outline colors for loader when we have a Straps loader component
          loading && <Loader size={16} color="currentColor" />
        )}
        {children && (
          <span
            className={classnames({
              "mb-[2px] h-3.5": !rounded,
              "h-3": rounded,
              "opacity-0": loading && !icon,
            })}
          >
            {children}
          </span>
        )}
      </>
    </Component>
  );
});

const Button: ButtonComponent = forwardRef(function Button<
  C extends ElementType = "button"
>(props: ButtonProps<C>, ref: PolymorphicRef<C>) {
  if (props.noFrameVariant) {
    return <TextButton ref={ref} {...props} />;
  }

  return <MainButton ref={ref} {...props} />;
});

export default Button;
