import classNames from "classnames";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { ExtendedSemanticColors, Include } from "../../../colors";
import Icon from "../../icons/Icon/Icon";
import { IconNames } from "../../icons/Icon/types";

type PillColor =
  | Include<
      ExtendedSemanticColors,
      "primary" | "warning" | "accent-3" | "positive" | "negative"
    >
  | "white"
  | "transparent";
type PillSize = "s" | "m";

interface PillProps extends ComponentPropsWithoutRef<"div"> {
  size?: PillSize;
  color?: PillColor;
  width?: `${number}px`;
  iconRight?: IconNames;
  iconLeft?: IconNames;
  iconColor?: ExtendedSemanticColors;
}

const colorClasses: Record<PillColor, string> = {
  primary: "bg-straps-primary text-white",
  warning: "bg-straps-warning text-white",
  positive: "bg-straps-positive text-white",
  negative: "bg-straps-negative text-white",
  white: "bg-white text-straps-primary",
  "accent-3": "bg-straps-accent-3 text-straps-secondary",
  transparent:
    "bg-transparent text-straps-secondary border-2 border-straps-primary",
};

const sizeClasses: Record<PillSize, string> = {
  s: "h-[20px] px-2",
  m: "h-[34px] px-3",
};

const Pill = forwardRef<HTMLDivElement, PillProps>(
  (
    {
      className,
      color = "primary",
      size = "s",
      iconColor,
      iconLeft,
      iconRight,
      children,
      width,
      ...divProps
    },
    ref
  ) => (
    <div
      style={{ width }}
      className={classNames(
        className,
        colorClasses[color],
        sizeClasses[size],
        "inline-flex items-center justify-between rounded-full text-xs font-bold shadow"
      )}
      {...divProps}
      ref={ref}
    >
      {iconLeft && (
        <Icon
          color={iconColor}
          name={iconLeft}
          className="mr-1"
          size={size === "s" ? "small" : "medium"}
        />
      )}
      {children}
      {iconRight && (
        <Icon
          color={iconColor}
          name={iconRight}
          className="ml-1"
          size={size === "s" ? "small" : "medium"}
        />
      )}
    </div>
  )
);

export default Pill;
