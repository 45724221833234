import * as React from "react";
import classnames from "classnames";

type Props = {
  url: string;
  icon: any;
  title: string;
  className: string;
  disabled?: boolean;
};
export default ({ url, icon, title, className, disabled }: Props) => {
  const admin = className === "admin";
  return (
    <a
      href={url}
      className={classnames("site-link", { disabled: disabled, admin: admin })}
    >
      <div className={classnames("bractlet-symbol-container", className)}>
        {icon}
      </div>
      <h3 className={"software-title"}>{title}</h3>
    </a>
  );
};
