import * as React from "react";
import classnames from "classnames";
import { Check } from "../icons";
import { useAsync } from "../global_functions/useAsync";
import Loader from "./Loader";
export type CheckboxProps = {
  className?: string;
  value?: string;
  checked: boolean;
  onChange?: (e: React.MouseEvent<HTMLInputElement>) => void | Promise<void>;
  label?: React.ReactNode;
  labelClassName?: string;
  labelStyle?: Record<any, any>;
  labelPosition?: "top" | "right" | "bottom" | "left";
  checkboxClassName?: string;
  disabled?: boolean;
  dataTestId?: string;
};

const noop = () => {};

const Checkbox = ({
  className,
  value,
  checked,
  onChange,
  label,
  labelClassName,
  labelStyle,
  labelPosition = "right",
  checkboxClassName,
  disabled,
  dataTestId,
  ...props
}: CheckboxProps) => {
  const [updateState, update] = useAsync(
    async (e) => (onChange ? await onChange(e) : noop),
    [onChange]
  );

  return (
    <label
      className={classnames("bractlet-checkbox", className, {
        "--disabled": Boolean(disabled),
      })}
      data-label-position={labelPosition}
    >
      {Boolean(label) && (
        <div
          className={classnames("bractlet-checkbox--label", labelClassName)}
          style={labelStyle}
        >
          {label}
        </div>
      )}
      <div
        style={{ width: 16, position: "relative" }}
        className={checkboxClassName}
      >
        {updateState.loading && <Loader size={16} />}
        <input
          type="checkbox"
          value={value}
          checked={checked}
          onChange={update}
          disabled={Boolean(disabled) || updateState.loading}
          data-testid={dataTestId}
        />
        <div className="bractlet-checkbox--checkbox">
          <Check />
        </div>
      </div>
    </label>
  );
};

export default Checkbox;
