import { Flow } from "flow-to-typescript-codemod";
import Cookies from "js-cookie";
import { camelCase, snakeCase } from "lodash";
import * as R from "ramda";

import { inboundUnitCipher } from "../../context/UnitsContext";
import { isDev, VITE_LEDGER_BASE_URL } from "../../env";
import { demoSites } from "../../esgDemoData";
import request from "../request";
import {
  bugsnagGeneralErrorHandler,
  bugsnagPostgrestErrorHandler,
  formatDataArray,
  formatDataObj,
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
  snakeCaseCipher,
} from "./common";

import type {
  Account,
  AccountSummary,
  Building,
  BuildingTag,
  MonthString,
  SiteStakeholder,
  SummaryOverview,
  SummaryOverviewQueryParams,
  Utility,
  UtilityOptOut,
  UtilityUnit,
} from "../../types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useOnboardingGuideContext } from "@src/backpack-console/components/OnboardingGuide";

// const climateZones = [
//   "Hot-Humid",
//   "Mixed-Humid",
//   "Hot-Dry",
//   "Mixed-Dry",
//   "Cold",
//   "Very-Cold",
//   "Subarctic",
//   "Marine",
// ];

// const randomlyNull = (result: any) => (Math.random() < 0.33 ? null : result);

// const siteFields = [
//   "id",
//   "name",
//   "streetAddress:street_address",
//   "squareFeet:square_feet",
//   "timeZone:time_zone",
//   "zipcode",
//   "city",
//   "state",
//   "latitude",
//   "longitude",
//   "weatherLatitude:weather_latitude",
//   "weatherLongitude:weather_longitude",
//   "account:account_name",
//   "accountId:account_id",
//   "tags",
//   "numberOfFloors:number_of_floors",
//   "numberOfBasements:number_of_basements",
//   "imageKey:image_key",
//   "childSites:child_sites",
//   "energyStarPropertyId:energy_star_property_id",
//   "energyStarPropertyPermissions:energy_star_property_permissions",
//   "energyStarLinkedAt:energy_star_linked_at",
//   "cbecsClimateRegion:cbecs_climate_region",
//   "cbecsPrincipleBuildingActivity:cbecs_principle_building_activity",
//   "cbecsMoreSpecificPrincipleBuildingActivity:cbecs_more_specific_principle_building_activity",
//   "yearConstructed:year_constructed",
//   "productTier:product_tier",
//   "gresbEntityId:gresb_entity_id",
//   "gresbAssetId:gresb_asset_id",
//   "gresbAssetLinkedAt:gresb_asset_linked_at",
//   "arcAssetId:arc_asset_id",
//   "arcAssetLinkedAt:arc_asset_linked_at",
//   "users",
// ];

export const outBoundSiteEncoder = {
  accountId: "account_id",
  cbecsPrincipleBuildingActivity: "cbecs_principle_building_activity",
  cbecsMoreSpecificPrincipleBuildingActivity:
    "cbecs_more_specific_principle_building_activity",
  name: "name",
  legalName: "legal_name",
  squareFeet: "square_feet",
  squareFeetRentable: "square_feet_rentable",
  numberOfFloors: "number_of_floors",
  numberOfBasements: "number_of_basements",
  numberOfUnits: "number_of_units",
  streetAddress: "street_address",
  productTier: "product_tier",
  zipcode: "zipcode",
  city: "city",
  state: "state",
  yearConstructed: "year_constructed",
  timeZone: "time_zone",
  weatherLatitude: "weather_latitude",
  weatherLongitude: "weather_longitude",
  energyStarPropertyType: "energy_star_property_type",
  // stakeholders: "stakeholders",
  siteOwnership: "site_ownership",
  yearCommissioned: "year_commissioned",
  expectedSaleDate: "expected_sale_date",
  utilityOptOut: "utility_opt_out",
  gresbOptOut: "gresb_opt_out",
  leedOptOut: "leed_opt_out",
  needsEnergystarAccount: "needs_energystar_account",
  market: "market",
  fund: "fund",
  onboardingCompletedAt: "onboarding_completed_at",
} as const;

const formatInboundSite = (inboundSite: any): Building => {
  return {
    ...inboundSite,
    account: inboundSite?.accountName, // TODO update references to deprecated "account" field
    imageURL: inboundSite?.imageKey
      ? `https://bractlet-public${
          isDev ? "-dev" : ""
        }.s3.amazonaws.com/valinor/building_images/${inboundSite.imageKey}.jpeg`
      : null,
  };
};

export async function getSites<
  T extends SummaryOverviewQueryParams | void = void
>({
  includeInactive = false,
  accountId,
  fields,
}: {
  includeInactive?: boolean;
  accountId?: number;
  fields?: T;
} = {}): Promise<Array<T extends void ? Building : SummaryOverview>> {
  return await request
    .get(`${VITE_LEDGER_BASE_URL}/sites/summary`)
    .query({
      order: "name",
      ...(!includeInactive && { status: "active" }),
      ...(accountId && { account_id: accountId }),
      ...(fields?.length && { fields: fields.map(snakeCase).join(",") }),
    })
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .catch((e) => {
      bugsnagPostgrestErrorHandler(e);
      return { body: [] };
    })
    .then(({ body }) => recursiveCamelCaseCipher(body))
    .then((r) => {
      const isEsgDemo = localStorage.getItem("esgDemo");
      const sites = r.map(formatInboundSite);
      return isEsgDemo
        ? sites
            // @ts-expect-error - TS7006 - Parameter 'site' implicitly has an 'any' type.
            .filter((site) => Object.keys(demoSites).includes(String(site.id)))
            // @ts-expect-error - TS7006 - Parameter 'site' implicitly has an 'any' type.
            .map((site) => ({
              ...site,
              // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly "77": { readonly name: "10 Downing Street"; readonly arcAssetId: 9000000073; readonly arcAsset: DetailedArcAsset; readonly voc: readonly [ArcMeterConsumption, ArcMeterConsumption, ArcMeterConsumption]; ... 4 more ...; readonly operationalDays: readonly [...]; }; readonly "132": { ...; }; readonly "147": {...'.
              name: demoSites[site.id].name,
              // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly "77": { readonly name: "10 Downing Street"; readonly arcAssetId: 9000000073; readonly arcAsset: DetailedArcAsset; readonly voc: readonly [ArcMeterConsumption, ArcMeterConsumption, ArcMeterConsumption]; ... 4 more ...; readonly operationalDays: readonly [...]; }; readonly "132": { ...; }; readonly "147": {...'.
              streetAddress: demoSites[site.id].name,
              account: "Acme Asset Management",
              tags: [],
              // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly "77": { readonly name: "10 Downing Street"; readonly arcAssetId: 9000000073; readonly arcAsset: DetailedArcAsset; readonly voc: readonly [ArcMeterConsumption, ArcMeterConsumption, ArcMeterConsumption]; ... 4 more ...; readonly operationalDays: readonly [...]; }; readonly "132": { ...; }; readonly "147": {...'.
              arcAssetId: demoSites[site.id].arcAssetId,
            }))
        : sites;
    });
}

export async function getSite<
  T extends SummaryOverviewQueryParams | void = void
>(
  siteId: string | number,
  {
    includeInactive = false,
    fields,
  }: {
    includeInactive?: boolean;
    fields?: T;
  } = {}
): Promise<(T extends void ? Building : SummaryOverview) | void> {
  try {
    const { body } = await request
      .get(`${VITE_LEDGER_BASE_URL}/sites/summary`)
      .query({
        site_id: siteId,
        ...(!includeInactive && { status: "active" }),
        ...(fields?.length && { fields: fields.map(snakeCase).join(",") }),
      })
      .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`);
    const r = recursiveCamelCaseCipher(body[0]);
    if (!r) {
      throw new Error(`Site id ${siteId} not found`);
    }
    return formatInboundSite(r);
  } catch (e) {
    return bugsnagPostgrestErrorHandler(e);
  }
}

export const updateUtilityOptOutPropertyToBepSite = async (
  siteId: number | string,
  utilityOptOut: Array<UtilityOptOut>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .patch("/rest/sites")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${siteId}` })
    .send({
      utility_opt_out: utilityOptOut,
    })
    .catch(bugsnagPostgrestErrorHandler);

export const updateBackpackUtilityOptOut = async (
  siteId: number | string,
  utilityOptOut: UtilityOptOut
) =>
  request
    .patch("/rest/utility_accounts")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}`, utility: `eq.${utilityOptOut}` })
    .send({
      status: "optout",
    })
    .catch(bugsnagPostgrestErrorHandler);

export const addBackpackUtilityOptOut = async (
  siteId: number | string,
  utilityOptOut: UtilityOptOut
) =>
  request
    .post("/rest/utility_accounts")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      site_id: siteId,
      utility: utilityOptOut,
      status: "optout",
    })
    .catch(bugsnagPostgrestErrorHandler);

export const updateBackpackUtilityManual = async (
  siteId: number | string,
  utility: Utility,
  providerName?: string,
  providerId?: number | null
) =>
  request
    .patch("/rest/utility_accounts")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}`, utility: `eq.${snakeCase(utility)}` })
    .send({
      status: "manual",
      provider_name: providerName,
      provider_id: providerId,
    })
    .catch(bugsnagPostgrestErrorHandler);

export const addBackpackUtilityManual = async (
  siteId: number | string,
  utility: Utility,
  providerName?: string,
  providerId?: number | null
) =>
  request
    .post("/rest/utility_accounts")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      site_id: siteId,
      utility: snakeCase(utility),
      provider_name: providerName,
      provider_id: providerId,
      status: "manual",
    })
    .catch(bugsnagPostgrestErrorHandler);

export const updateSite = (
  siteId: string | number,
  props: Partial<Building>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .patch(`/api/buildings`)
    .query({ id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(
      formatDataObj(props, outBoundSiteEncoder, {
        allowNulls: true,
        allowUnknownKeys: false,
      })
    )
    .catch(bugsnagPostgrestErrorHandler);

export const updateSiteIntel = (
  siteId: string | number,
  props: Partial<Building>
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  return request
    .patch(`/rest/sites`)
    .query({ id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(
      formatDataObj(props, outBoundSiteEncoder, {
        allowNulls: true,
        allowUnknownKeys: false,
      })
    )
    .catch(bugsnagPostgrestErrorHandler);
};

export const getSitePartners = async (
  siteId: number
): Promise<Array<SiteStakeholder>> => {
  const response = await request
    .get(`/rest/site_stakeholders`)
    .query({ site_id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then((res) => ({
      ...res,
      body: recursiveCamelCaseCipher(res.body),
    }));

  if (response.ok) return response.body;

  throw response.error;
};

export const updateSitePartner = (stakeholder: SiteStakeholder) =>
  request
    .patch(`/rest/site_stakeholders`)
    .query({ id: `eq.${stakeholder.id}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    // .set("Prefer", "return=representation")
    .set("Prefer", "resolution=merge-duplicates")
    .send(formatDataObj({ ...stakeholder }, snakeCaseCipher))
    .then(R.prop("body"))
    .catch(bugsnagPostgrestErrorHandler);

export const createSitePartner = (stakeholder: Partial<SiteStakeholder>) =>
  request
    .post(`/rest/site_stakeholders`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    // .set("Prefer", "resolution=merge-duplicates")
    .send(formatDataObj({ ...stakeholder }, snakeCaseCipher))
    .then(R.prop("body"))
    .catch(bugsnagPostgrestErrorHandler);

export const removeSitePartner = (stakeholderId: string | number) =>
  request
    .delete(`/rest/site_stakeholders`)
    .query({ id: `eq.${stakeholderId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .catch(bugsnagPostgrestErrorHandler);

export const saveSiteTag = (
  site_id: string | number,
  tag: string
): Promise<BuildingTag> =>
  request
    .post(`/rest/site_tags`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({ site_id, tag })
    .then((r) => r.body[0])
    .catch(bugsnagPostgrestErrorHandler);

export const deleteSiteTag = (id: string | number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete(`/rest/site_tags`)
    .query({ id: `eq.${id}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .catch(bugsnagPostgrestErrorHandler);

export const uploadBuildingImage = async (
  siteId: number,
  imageBlob: Blob
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .post("/uploads")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ type: "building_image", id: siteId })
    .then(({ body }) =>
      request
        .put(body.url) // received pre-signed S3 url from backend to upload to
        .set("Content-Type", imageBlob.type)
        .send(imageBlob)
    )
    .then(() =>
      request
        .post("/upload_completions")
        .send({ type: "building_image", id: siteId })
    )
    .catch(bugsnagGeneralErrorHandler);

export const getDefaultUtilityUnit = (
  siteId: string | number,
  utility: Utility
): Promise<
  | {
      unit: UtilityUnit;
      unitId: number;
    }
  | null
  | undefined
> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | { unit: UtilityUnit; unitId: any; } | null>' is not assignable to type 'Promise<{ unit: UtilityUnit; unitId: number; } | null | undefined>'.
  request
    .get(`/rest/default_utility_units`)
    .query({ site_id: `eq.${siteId}`, utility: `eq.${snakeCase(utility)}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) =>
      body.length
        ? { unit: inboundUnitCipher[body[0].unit], unitId: body[0].unitId }
        : null
    )
    .catch(bugsnagPostgrestErrorHandler);

export const getSiteDefaultUtilityUnits = (
  siteId: string | number
): Promise<
  {
    utility: Utility;
    unit: UtilityUnit;
    unitId: number;
  }[]
> =>
  request
    .get(`/rest/site_default_utility_units`)
    .query({ site_id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      body.map((row) => ({
        utility: camelCase(row.utility),
        unit: inboundUnitCipher[row.site_unit],
        unitId: row.site_unit_id,
      }))
    );

const addImageUrlToSite = (site: any) => {
  const { imageKey, ...rest } = site;
  const devSlug = isDev ? "-dev" : "";
  return {
    ...rest,
    imageURL: site?.imageKey
      ? `https://bractlet-public${devSlug}.s3.amazonaws.com/valinor/building_images/${site.imageKey}.jpeg`
      : null,
  };
};

export const getAccountSummary = (accountId: number): Promise<AccountSummary> =>
  request
    .get(`/rest/accounts_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .query({ id: `eq.${accountId}` })
    .then(({ body }) => recursiveCamelCaseCipher(body))
    .then((account) => ({
      ...account,
      sites: account.sites.map(addImageUrlToSite),
    }));

export const updateTargetBackpacks = (
  accountId: number,
  target: number
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/accounts")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${accountId}` })
    .send({ num_target_backpacks: target });

export const getAccountsSummaries = (): Promise<AccountSummary[]> =>
  request
    .get(`/rest/accounts_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body))
    .then((accounts) =>
      accounts.map((account: any) => ({
        ...account,
        sites: account.sites.map(addImageUrlToSite),
      }))
    );

export const resetWeatherData = (siteId: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .post("/api/weather_data_resets")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ building_id: siteId });

export const getAccount = (id: string | number): Promise<Account> =>
  request
    .get(`/rest/accounts`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .query({ id: `eq.${id}` })
    .then(({ body }) => recursiveCamelCaseCipher(body));

export const getAccounts = (): Promise<Account[]> =>
  request
    .get(`/rest/accounts`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body));

export const createNewAccount = (
  newAccount: Partial<
    Flow.Diff<
      Account,
      {
        id: number;
      }
    >
  >
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .post("/rest/accounts")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(
      formatDataObj(newAccount, snakeCaseCipher, {
        allowNulls: true,
        allowUnknownKeys: false,
      })
    );

export const editAccount = (
  accountId: number,
  accountEdits: Partial<
    Flow.Diff<
      Account,
      {
        id: number;
      }
    >
  >
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/accounts")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${accountId}` })
    .send(
      formatDataObj(accountEdits, snakeCaseCipher, {
        allowNulls: true,
        allowUnknownKeys: false,
      })
    );

export const createNewSite = (newSite: Partial<Building>): Promise<Building> =>
  request
    .post("/api/buildings")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(
      formatDataObj(newSite, outBoundSiteEncoder, {
        allowNulls: true,
        allowUnknownKeys: false,
      })
    )
    .then(({ body }) => formatInboundSite(body[0]));

export const deleteSite = (siteId: string | number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/sites")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${siteId}` })
    .send({ deleted_at: new Date() });

export const addSiteUserBlacklist = ({
  siteId,
  userId,
}: {
  siteId: number;
  userId: number;
}): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .post("/rest/site_user_blacklists")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ site_id: siteId, user_id: userId });

export const addSiteUserBlacklists = (
  blacklists: {
    siteId: number;
    userId: number;
  }[]
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .post("/rest/site_user_blacklists")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(formatDataArray(blacklists, snakeCaseCipher));

export const deleteSiteUserBlacklist = ({
  siteId,
  userId,
}: {
  siteId: number;
  userId: number;
}): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/site_user_blacklists")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}`, user_id: `eq.${userId}` });

export const deleteSiteBlacklistsForUser = (userId: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/site_user_blacklists")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ user_id: `eq.${userId}` });

export const bpgDataExport = (): Promise<Record<any, any>[]> =>
  request
    .get("/rest/bpg_savings_data")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then((res) => res.body);

export const getSavingsDataForSite = (
  siteId: string | number,
  month: MonthString
): Promise<
  | {
      baselineAdjustedSimulatedTotalCost: number | null | undefined;
      baselineAdjustedSimulatedTotalConsumption: number | null | undefined;
      projectAdjustedSimulatedTotalCost: number | null | undefined;
      projectAdjustedSimulatedTotalConsumption: number | null | undefined;
      totalCost: number | null | undefined;
      totalConsumption: number | null | undefined;
    }
  | null
  | undefined
> =>
  request
    .get("/rest/bpg_savings_data")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}`, month: `eq.${month}` })
    .then((res) =>
      res.body[0] ? recursiveCamelCaseCipher(res.body[0]) : null
    );

export const userDataExport = (): Promise<Record<any, any>[]> =>
  request
    .get("/rest/user_data_export")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then((res) => res.body);

export const getPersonas = (): Promise<Record<any, any>[]> =>
  request
    .get(`/rest/v_user_persona_roles`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body));

export type SiteEventStatus = "todo" | "planned" | "completed" | "cancelled";
export type SiteEvent = {
  id: number;
  siteId: number;
  eventType: "gateway-installation" | "site-walk-through";
  subject: string;
  plannedAt: string | null; // date,
  realizedAt: string; //date,
  visitorUserId: number;
  hostUserId: number;
  notes: string;
  createdAt: string; //date,
  status: SiteEventStatus;
};
const getSiteEvents = (siteId: number): Promise<SiteEvent[]> =>
  request
    .get("/rest/v_site_events")
    .query({ site_id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body));

export const useGetSiteEvents = (siteId: number, enabled: boolean = true) =>
  useQuery(["site-events", siteId], () => getSiteEvents(siteId), {
    enabled,
  });

const patchSiteEvent = ({
  eventId,
  event,
}: {
  eventId: number;
  event: Partial<SiteEvent>;
}) =>
  request
    .patch("/rest/v_site_events")
    .query({ id: `eq.${eventId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ ...recursiveSnakeCaseCipher(event) });

export const usePatchSiteEvent = (siteId: number) => {
  const queryClient = useQueryClient();
  const { refreshGuideData } = useOnboardingGuideContext();
  return useMutation({
    mutationFn: patchSiteEvent,
    onSettled: async () => {
      await refreshGuideData();
      return queryClient.invalidateQueries(["site-events", siteId]);
    },
  });
};

const patchSiteIntegrationStatus = async (
  siteId: string | number,
  optOutStatus: boolean
) => {
  return request
    .patch(`/rest/integration_status_for_update`)
    .query({ site_id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ opt_out: optOutStatus });
};

export const usePatchGatewayInstallOptOut = (siteId: number) => {
  const { refreshGuideData } = useOnboardingGuideContext();
  return useMutation({
    mutationFn: (optOutStatus: boolean) =>
      patchSiteIntegrationStatus(siteId, optOutStatus),
    onSettled: () => refreshGuideData(),
  });
};
