import { Ref, ChangeEvent, forwardRef } from "react";
import classNames from "classnames";
import type { ExtendedSemanticColors, Include } from "@src/straps/colors";
import { BaseInputProps } from "./Input";
import styles from "./Input.module.scss";

export const backgroundColorMap: Record<
  Include<ExtendedSemanticColors, "body" | "white">,
  `bg-${string}`
> = { body: "bg-straps-body", white: "bg-pure-white" };

export type BackgroundColor = keyof typeof backgroundColorMap;

type InnerProps = {
  handleInputFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (evt: ChangeEvent<HTMLInputElement>) => void;
};

export type RoundedVariantProps = {
  backgroundColor?: BackgroundColor;
};

export const Rounded = forwardRef(function Rounded(
  props: BaseInputProps & RoundedVariantProps & InnerProps,
  ref: Ref<HTMLInputElement>
) {
  const {
    icon,
    backgroundColor = "white",
    status,
    disabled,
    name,
    placeholder = "",
    value,
    handleChange,
    handleInputFocus,
    autoCompleteOptions,
    dataTestId,
    ...rest
  } = props;
  return (
    <input
      className={classNames(
        styles.input,
        "text-sm",
        "font-[500]",
        "p-1",
        "w-full",
        "h-8",
        "outline-0",
        "rounded-full",
        "transition-all",
        "hover:text-straps-primary",
        "hover:drop-shadow-xl",
        "active:text-straps-primary",
        "active:drop-shadow-xl",
        "placeholder:text-sm placeholder:text-straps-tertiary placeholder:transition-colors placeholder:hover:text-straps-primary placeholder:focus:text-straps-tertiary",
        "leading-5",
        {
          "px-[18px]": !icon,
          "pl-[38px]": typeof icon !== "undefined",
          [backgroundColorMap[backgroundColor]]: status === "default",
          "bg-straps-positive-bg": status === "positive",
          "bg-straps-negative-bg": status === "negative",
          "bg-straps-warning-bg": status === "warning",
          "text-straps-tertiary": disabled,
          "pointer-events-none cursor-not-allowed": disabled,
        }
      )}
      {...rest}
      ref={ref}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onFocus={handleInputFocus}
      disabled={disabled}
      autoComplete={autoCompleteOptions ? "off" : "on"}
      data-testid={dataTestId ?? "input"}
    />
  );
});

// futureproof adding props specific to line variant
export type LineVariantProps = {};

export const Line = forwardRef(function Line(
  props: BaseInputProps & LineVariantProps & InnerProps,
  ref: Ref<HTMLInputElement>
) {
  const {
    icon,
    status,
    disabled,
    name,
    placeholder = "",
    value,
    handleChange,
    handleInputFocus,
    autoCompleteOptions,
    dataTestId,
    ...rest
  } = props;
  return (
    <input
      className={classNames(
        "bg-transparent",
        "w-full",
        "border-b",
        "outline-0",
        "transition-all",
        "text-straps-primary",
        "placeholder:focus:text-straps-tertiary",
        "py-[5px]",
        "font-bold",
        "text-base",
        "leading-5",
        {
          "pl-6": typeof icon !== "undefined",
          "border-straps-tertiary": status === "default",
          "border-straps-positive": status === "positive",
          "border-straps-negative": status === "negative",
          "hover:border-straps-negative": status === "negative",
          "border-straps-warning": status === "warning",
          "hover:border-straps-primary": !disabled && status !== "negative",
          "text-straps-tertiary": disabled,
          "hover:text-straps-tertiary": disabled,
          "hover:text-straps-primary": !disabled,
          "pointer-events-none cursor-not-allowed": disabled,
          "placeholder:text-straps-tertiary": disabled,
          "placeholder:hover:text-straps-primary": !disabled,
          "placeholder:hover:text-straps-tertiary": disabled,
        }
      )}
      {...rest}
      ref={ref}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onFocus={handleInputFocus}
      disabled={disabled}
      autoComplete={autoCompleteOptions ? "off" : "on"}
      data-testid={dataTestId ?? "input"}
    />
  );
});
