import Cookies from "js-cookie";
import request from "../../../../global_functions/request";

import { bugsnagGeneralErrorHandler } from "../../../../global_functions/postgrestApi/common";
import { VITE_UPLOAD_BASE_URL } from "../../../../env";

const baseUrl = `${VITE_UPLOAD_BASE_URL}/project-attachment`;

export const getProjectAttachmentPreviews = async (
  projectId: number
): Promise<{
  [key: string]: string;
}> =>
  request
    .get(`${baseUrl}/preview?project_id=${projectId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .then((res) => res.body)
    .catch((error) => bugsnagGeneralErrorHandler(error));

export const uploadProjectAttachment = async (
  projectId: number,
  file: File
): Promise<void> =>
  await request
    .post(`${baseUrl}/upload?project_id=${projectId}&filename=${file.name}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Content-Type", "text/plain")
    .send(file)
    .then((res) => res.body)
    .catch((error) => bugsnagGeneralErrorHandler(error));

export const downloadProjectAttachment = async (
  attachmentId: string | number
): Promise<string> =>
  // @ts-expect-error - TS2322 - Type 'string | void' is not assignable to type 'string'.
  request
    .get(`${baseUrl}/download?attachment_id=${attachmentId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .then((res) => res.text)
    .catch((error) => bugsnagGeneralErrorHandler(error));

// rewards - @todo peter move to FETCH clients once https://bractlet.atlassian.net/browse/INNO-786 is resolved
const rewardsBaseUrl = `${VITE_UPLOAD_BASE_URL}/reward-receipt`;
export const getRewardReceiptPreviews = (
  reward_id: number
): Promise<{
  [key: string]: string;
}> =>
  request
    .get(`${rewardsBaseUrl}/preview`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .query({ reward_id })
    .then((res) => res.body)
    .catch((error) => bugsnagGeneralErrorHandler(error));

export const uploadRewardReceipt = (
  project_id: number,
  file: File
): Promise<void> =>
  request
    .post(`${rewardsBaseUrl}/upload`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("Content-Type", "text/plain")
    .query({ project_id, filename: file.name })
    .send(file)
    .then((res) => res.body)
    .catch((error) => bugsnagGeneralErrorHandler(error));

export const downloadRewardReceipt = (
  receipt_id: number
): Promise<string | void> =>
  request
    .get(`${rewardsBaseUrl}/download`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .query({ receipt_id })
    .then((res) => res.text)
    .catch((error) => bugsnagGeneralErrorHandler(error));
