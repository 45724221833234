import React, { ComponentProps, FunctionComponent, LegacyRef } from "react";
import classnames from "classnames";
import * as AllIcons from "./allIcons";

export * from "./allIcons";

export type BractletIconNames = keyof typeof AllIcons;
export interface IconsProps extends ComponentProps<"svg"> {
  icon: BractletIconNames;
  color?: string;
  innerRef?: LegacyRef<SVGSVGElement>;
}
const Icons: FunctionComponent<IconsProps> = ({ icon, color, ...props }) => {
  if (!AllIcons[icon]) {
    console.log(`Cannot find icon ${icon}`); // eslint-disable-line
    return null;
  }

  return React.createElement(AllIcons[icon], {
    ...props,
    className: classnames(props.className, icon, {
      [`color--${color}`]: color != null,
    }),
  });
};

export default Icons;
