import moment from "moment";
import { useField } from "react-final-form";

export const dateAtHourZero = (date?: null | string | Date): Date =>
  moment(date).startOf("day").toDate();

export const useFieldValue = (name: string, config?: any) => {
  return useField(name, config).input.value;
};

export const useFieldWithErrors = (name: string, config?: any) => {
  const { input, meta } = useField(name, config);

  return {
    value: input.value,
    meta,
  };
};
