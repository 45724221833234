import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

export default function LinkWithQuery({
  to,
  children,
  queries,
  search: initialSearch,
  ...props
}: React.ComponentProps<typeof Link> & {
  queries?: string[];
  to: string;
  // Link will merge current search params with this prop
  search?: string;
}) {
  const { search } = useLocation();

  const value = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const keys = [...searchParams.keys()];
    keys.forEach((key) => {
      // If queries undefined, default to passing all search params
      if (queries && !queries.includes(key)) {
        searchParams.delete(key);
      }
    });

    const initialSearchParams = new URLSearchParams(initialSearch);
    const allSearchParams = new URLSearchParams({
      ...Object.fromEntries(searchParams),
      ...Object.fromEntries(initialSearchParams),
    });

    return allSearchParams.toString();
  }, [search, initialSearch, queries]);

  return (
    <Link
      {...props}
      to={{
        pathname: to,
        search: value,
      }}
    >
      {children}
    </Link>
  );
}
