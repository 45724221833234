import * as React from "react";
import classnames from "classnames";

import UserContext from "../context/UserContext";
import { AsyncButton } from "../global_components";
import { updateUser } from "../global_functions/postgrestApi";
import termsOfService from "./termsOfService";
import styles from "./CookieConsentBanner.module.scss";

const terms = termsOfService[0];

const CookieConsentBanner = () => {
  const { currentUser, refreshCurrentUser } = React.useContext(UserContext);

  const acceptTerms = React.useCallback(async () => {
    if (currentUser) {
      await updateUser(currentUser.id, {
        acceptedTermsOfServiceVersion: terms!.version,
      });
      await refreshCurrentUser();
    }
  }, [refreshCurrentUser, currentUser]);

  if (!currentUser) return null;
  return (
    <footer
      className={classnames(styles.cookie_banner, {
        [styles.open!]:
          currentUser.acceptedTermsOfServiceVersion !== terms!.version,
      })}
    >
      <div className={styles.left_col}>
        <div className={styles.terms_wrapper}>{terms!.terms(true)}</div>
        <a
          className={styles.read_more}
          href="https://bractlet.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          READ MORE
        </a>
      </div>
      <AsyncButton rounded onClick={acceptTerms}>
        Accept
      </AsyncButton>
    </footer>
  );
};

export default CookieConsentBanner;
