import * as React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

export default ({
  icon,
  title,
  subtitle,
  selected,
  deactivated,
  urlLink = "/",
  className,
  hasNewContent,
}: {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  selected?: boolean;
  deactivated?: boolean;
  urlLink?: string;
  className?: string;
  hasNewContent?: boolean;
}) => (
  <Link
    to={urlLink}
    className={classnames(className, {
      "nav-item": true,
      deactivated,
      selected,
    })}
  >
    <div className="nav-icon">
      {icon}
      <div
        className={classnames("nav-icon--new-content-indicator", {
          "--active": hasNewContent,
        })}
      />
    </div>
    <h3
      className={classnames({
        "nav-title": true,
        "nav-title--has-subtitle": subtitle,
      })}
    >
      {title}
      <small>{subtitle && <span>{subtitle}</span>}</small>
    </h3>
  </Link>
);
