import * as React from "react";
import * as R from "ramda";

import classnames from "classnames";
import numeral from "numeral";

import styles from "./ComparisonBarChart.module.scss";
import { useSpring, animated } from "@react-spring/web";
import TooltipWrapper from "./TooltipWrapper";
import type { TopLevelUtility, Utility } from "../types";

import { hexToRGB } from "../global_functions/util";

type PlotTypes = "spend" | "rate" | "consumption" | "dailyConsumption" | "ghg";

const getValueWithUnits = (
  value: number | null | undefined,
  units: string,
  plotType: PlotTypes,
  format: string
): [string, string, string] => {
  let pre = "";
  let post = "";

  if (plotType === "spend") {
    pre = "$";
  } else if (plotType === "rate") {
    post = ` $/${units}`;
  } else if (plotType === "consumption" || plotType === "ghg") {
    post = ` ${units}`;
  } else if (plotType === "dailyConsumption") {
    post = ` ${units}/day`;
  }

  return [
    pre,
    value == null
      ? "-"
      : Math.abs(value) > 1
      ? numeral(value).format(format)
      : `${value.toPrecision(2)}`,
    post,
  ];
};

const getNodeValueWithUnits = (
  value: number | null | undefined,
  units: string,
  plotType: PlotTypes,
  format: string,
  hideUnit: boolean = false
) => {
  const [pre, val, post] = getValueWithUnits(value, units, plotType, format);
  return (
    <>
      <small>{pre}</small>
      <span>{val}</span>
      {!hideUnit && <small style={{ paddingLeft: 2 }}>{post}</small>}
    </>
  );
};

type ConsumptionType = "spend" | "consumption" | "rate";

type BarChartContextType = {
  max: number;
  consumptionType: ConsumptionType;
  units?: string;
  barWidth?: string;
};

const BarChartContext = React.createContext<BarChartContextType>({
  max: 0,
  consumptionType: "rate",
});

type BarProps = {
  value?: number | null;
  opacity?: number;
  label?: string;
  color?: string;
  textColor?: string;
  barWidth?: string;
  tooltip?: (value: number) => React.ReactElement;
};

const Bar = (props: BarProps) => {
  const {
    tooltip = (args: any) => {},
    value,
    opacity = 1,
    color,
    label,
    textColor,
    ...rest
  } = props;

  const {
    max,
    units = "",
    consumptionType,
    barWidth: _barWidth,
  } = React.useContext(BarChartContext);
  const barWidth = props.barWidth || _barWidth || "20px";

  const [barProps, setBarProps] = useSpring(() => ({
    width: `${((value || 0) / max) * 100}%`,
  }));

  React.useEffect(() => {
    setBarProps({ width: `${((value || 0) / max) * 100}%` });
  }, [value, max, setBarProps]);

  if (!value) return null;

  return (
    <div style={{ position: "relative" }} {...rest}>
      <div
        className={styles["utility-group--text"]}
        style={{
          height: barWidth,
          lineHeight: barWidth,
          color: textColor,
        }}
      >
        {label}
      </div>
      <TooltipWrapper tooltip={tooltip(value)} renderOnTarget>
        <animated.div
          className={styles["utility-group--value"]}
          style={{
            background: color ? hexToRGB(color, opacity) : color,
            opacity: color ? 1 : opacity, // If a "color" prop is provided then opacity is handled by the previous line
            height: barWidth,
            lineHeight: barWidth,
            color: textColor,
            ...barProps,
          }}
        >
          {label}
        </animated.div>
      </TooltipWrapper>

      <label
        className={styles.bar_label_right}
        style={{ lineHeight: barWidth }}
      >
        {getNodeValueWithUnits(value, units, consumptionType, "0[.][0]a")}
      </label>
    </div>
  );
};

const Null = (props: any) => {
  return (
    <div style={{ position: "relative" }}>
      <div className={styles.null}>-</div>
    </div>
  );
};

type BarPartProps = {
  value: number;
  label: Utility;
  tooltip: any;
  barWidth?: string;
};

const BarPart = (props: BarPartProps) => {
  const { value, label, barWidth: barWidthProp, ...rest } = props;

  const { max, barWidth = barWidthProp || "20px" } =
    React.useContext(BarChartContext);

  const [barProps, setBarProps] = useSpring(() => ({
    width: `${((value || 0) / max) * 100}%`,
  }));

  React.useEffect(() => {
    setBarProps({ width: `${((value || 0) / max) * 100}%` });
  }, [value, max, setBarProps]);

  if (!value) return null;

  return (
    <TooltipWrapper tooltip={props.tooltip} renderOnTarget>
      <animated.div
        {...rest}
        className={classnames(styles.multi_bar, styles[label])}
        style={{
          height: barWidth,
          lineHeight: barWidth,
          ...barProps,
        }}
      />
    </TooltipWrapper>
  );
};

type MultiBarProps = {
  value?: number | null;
  values: Array<[Utility, number]>;
  opacity?: number;
  label?: string;
  color?: string;
  barWidth?: string;
  tooltip?: (value: [Utility, number]) => React.ReactElement;
};

const MultiBar = (props: MultiBarProps) => {
  const {
    tooltip = (args: any) => {},
    value,
    color,
    label,
    values,
    ...rest
  } = props;

  const {
    barWidth = "20px",
    consumptionType,
    units,
  } = React.useContext(BarChartContext);

  return (
    <div className={styles.multi_bar_container} {...rest}>
      {values.map((v) => (
        <BarPart
          label={v[0]}
          value={v[1]}
          tooltip={tooltip(v)}
          key={v[0]}
          barWidth={barWidth}
        />
      ))}
      {values.length > 0 && (
        <>
          <label
            className={styles.bar_label_left}
            style={{ lineHeight: barWidth }}
          >
            {label}
          </label>
          <label
            className={styles.bar_label_right}
            style={{ lineHeight: barWidth }}
          >
            {getNodeValueWithUnits(
              value,
              units || "",
              consumptionType,
              "0[.][0]a"
            )}
          </label>
        </>
      )}
    </div>
  );
};

type ComparisonBarChartProps = {
  consumptionType: ConsumptionType;
  children: React.ReactNode;
  utilityType?: Utility | TopLevelUtility;
  units?: any;
  dark?: boolean;
  hideTicks?: boolean;
  hideLastBar?: boolean;
  margin?: string;
  barWidth?: string;
  axisLabel?: string;
  max?: number;
  dataCy?: string;
  dataTestId?: string;
};

export const calculateMax = (values: Array<number>): number => {
  let higher = Math.max(...values);
  if (values.every((value) => value === null)) return 1;
  if (higher <= 0) return 1;

  let mult = 1;
  while (higher < 1 && higher > 0) {
    higher *= 10;
    mult *= 10;
  }
  return Math.ceil(Number((higher * 1.6).toPrecision(1))) / mult;
};

const ComparisonBarChart = (props: ComparisonBarChartProps) => {
  const {
    utilityType,
    consumptionType,
    units,
    dark,
    barWidth,
    max: maxProp,
    axisLabel,
    hideTicks = false,
    hideLastBar = false,
    dataCy,
    dataTestId,
  } = props;

  // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
  const values = React.Children.map(
    props.children,
    // @ts-expect-error - TS2339 - Property 'props' does not exist on type 'string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactPortal'.
    (element) => element?.props?.value
  ).filter((v) => !isNaN(v));

  const max = React.useMemo(
    () => maxProp || calculateMax(values),
    [values, maxProp]
  );

  const increment = max / 4;

  return (
    <>
      <div
        data-cy={dataCy ?? "comparison-bar-chart"}
        data-testid={dataTestId ?? "comparison-bar-chart"}
        className={classnames(styles["summary-card--bar-chart"], {
          [styles["--dark"]!]: dark,
        })}
      >
        <div className={styles["bar-chart--x-axis"]}>
          {R.range(0, 5).map((idx) => {
            if (hideLastBar === true && idx === 4) {
              return <div key={idx} />;
            }
            return (
              <div key={idx} className={styles["x-axis--tick"]}>
                {!hideTicks &&
                  getNodeValueWithUnits(
                    idx * increment,
                    units || "",
                    consumptionType,
                    "0[.][00]a",
                    true
                  )}
              </div>
            );
          })}
        </div>
        <div
          className={classnames(styles["bar-chart--utility-group"], {
            [styles[`--${utilityType!}`]!]: !!utilityType,
          })}
        >
          <div style={{ margin: `${props.margin || "10px"} 0` }}>
            <BarChartContext.Provider
              value={{ max, units, consumptionType, barWidth }}
            >
              {props.children}
            </BarChartContext.Provider>
          </div>
        </div>
      </div>
      {axisLabel && <div className={styles.axis_label}>{axisLabel}</div>}
    </>
  );
};

type GoalProps = {
  value?: number | null;
  opacity?: number;
  label?: string;
  color?: string;
  tooltip?: (value: number) => React.ReactElement;
};

const Goal = (props: GoalProps) => {
  const {
    tooltip = (args: any) => {},
    value,
    opacity = 1,
    color,
    label,
  } = props;

  const { max } = React.useContext(BarChartContext);

  const [barProps, setBarProps] = useSpring(() => ({
    left: `${((value || 0) / max) * 100}%`,
  }));

  React.useEffect(() => {
    setBarProps({ left: `${((value || 0) / max) * 100}%` });
  }, [value, max, setBarProps]);

  if (!value) return null;

  return (
    <TooltipWrapper tooltip={tooltip(value)} renderOnTarget>
      <animated.div
        className={styles.goal}
        style={{
          opacity,
          background: color,
          ...barProps,
        }}
      >
        {label || ""}
      </animated.div>
    </TooltipWrapper>
  );
};

ComparisonBarChart.Bar = Bar;
ComparisonBarChart.MultiBar = MultiBar;
ComparisonBarChart.Null = Null;
ComparisonBarChart.Goal = Goal;

export default ComparisonBarChart;
