import * as React from "react";
import { bugsnagClient } from "../../../bugsnag";
import * as ReactDOM from "react-dom";

import classNames from "classnames";
import { Transition } from "react-transition-group";
import { usePortalRoot } from "@src/PortalRoot";

interface SharedTooltipProps {
  children: React.ReactNode;
  align?: "topRight" | "top" | "topLeft" | "bottom";
  fixed?: boolean;
  margin?: number;
  enableInteraction?: boolean;
}
interface TooltipContainerProps extends SharedTooltipProps {
  childRef: React.RefObject<HTMLElement>;
  hovered?: boolean;
}

const TooltipContainer = ({
  margin = 4,
  align = "top",
  fixed = false,
  ...props
}: TooltipContainerProps) => {
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const portalRoot = usePortalRoot();
  const [childPosition, setChildPosition] = React.useState<{
    x: number;
    y: number;
  }>({
    x: props.childRef.current?.getBoundingClientRect().x || 0,
    y: props.childRef.current?.getBoundingClientRect().y || 0,
  });
  const [mousePosition, setMousePosition] = React.useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });
  const [tooltipSize, setTooltipSize] = React.useState({ width: 0, height: 0 });
  const [childSize, setChildSize] = React.useState({
    width: props.childRef.current?.getBoundingClientRect().width || 0,
    height: props.childRef.current?.getBoundingClientRect().height || 0,
  });

  const handler = React.useCallback(() => {
    if (tooltipRef.current) {
      const { width, height } = tooltipRef.current.getBoundingClientRect();
      setTooltipSize({ width, height });
    }

    if (props.childRef.current) {
      const { x, y, width, height } =
        props.childRef.current.getBoundingClientRect();
      setChildPosition({ x, y });
      setChildSize({ width, height });
    }
  }, [props.childRef]);

  const onMouseMove = React.useCallback(({ x, y }: MouseEvent) => {
    setMousePosition({ x, y });
  }, []);

  React.useEffect(handler, [handler, props.hovered]);

  React.useEffect(() => {
    if (!fixed) {
      document.addEventListener("mousemove", onMouseMove);
      return () => document.removeEventListener("mousemove", onMouseMove);
    }
  }, [onMouseMove, fixed]);

  const { children } = props;

  const style = React.useMemo(() => {
    if (fixed) {
      const offsetX = 18;
      const centerX = childPosition.x + childSize.width / 2;
      switch (align) {
        case "topRight":
          return {
            left: `${centerX - offsetX}px`,
            bottom: `${window.innerHeight - childPosition.y + margin}px`,
          };
        case "topLeft":
          return {
            left: `${centerX - tooltipSize.width + offsetX}px`,
            bottom: `${window.innerHeight - childPosition.y + margin}px`,
          };
        case "top":
          return {
            left: `${centerX - tooltipSize.width / 2}px`,
            bottom: `${window.innerHeight - childPosition.y + margin}px`,
          };
        case "bottom":
          return {
            left: `${centerX - tooltipSize.width / 2}px`,
            top: `${childPosition.y + childSize.height + margin}px`,
          };
      }
    }
    switch (align) {
      case "topRight":
        return {
          left: `${mousePosition.x - 12}px`,
          bottom: `${window.innerHeight - mousePosition.y + 20}px`,
        };
      case "topLeft":
        return {
          left: `${mousePosition.x - tooltipSize.width + 18}px`,
          bottom: `${window.innerHeight - mousePosition.y + 20}px`,
        };
      case "top":
        return {
          left: `${mousePosition.x - tooltipSize.width / 2}px`,
          bottom: `${window.innerHeight - mousePosition.y + 20}px`,
        };
      case "bottom":
        return {
          left: `${mousePosition.x - tooltipSize.width / 2}px`,
          top: `${mousePosition.y + 20}px`,
        };
    }
  }, [
    childPosition,
    mousePosition,
    childSize,
    tooltipSize,
    align,
    fixed,
    margin,
  ]);

  if (!portalRoot) {
    bugsnagClient?.notify("Could not find portal root to render tooltip");
    return null;
  }

  return ReactDOM.createPortal(
    <Transition
      in={props.hovered}
      timeout={{
        enter: 0,
        exit: 250,
      }}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <div
          ref={tooltipRef}
          className={classNames("fixed z-[999999] transition-opacity", {
            "pointer-events-none": !props.enableInteraction,
            "pointer-events-auto": props.enableInteraction,
            "opacity-100": state === "entered" || state === "entering",
            "opacity-0": state === "exiting" || state === "exited",
          })}
          style={{ ...style }}
        >
          {children}
        </div>
      )}
    </Transition>,
    portalRoot
  );
};

interface TooltipProps extends SharedTooltipProps {
  children: React.ReactElement;
  tooltip: React.ReactNode;
  debug?: boolean;
  dataTestId?: string;
}

const TooltipWrapper = ({
  children,
  debug,
  dataTestId = "text-button",
  tooltip,
  ...sharedProps
}: TooltipProps) => {
  const [hovered, setHovered] = React.useState<boolean>(false);

  const onMouseOver = () => {
    setHovered(true);
    children.props.onMouseOver?.();
  };

  const onMouseLeave = () => {
    if (!debug) setHovered(false);
    children.props.onMouseLeave?.();
  };

  const childRef = React.useRef<HTMLElement>(null);

  return (
    <>
      {React.cloneElement(children, {
        onMouseOver,
        onMouseLeave,
        ref: childRef,
      })}

      <TooltipContainer
        hovered={hovered || debug}
        childRef={childRef}
        data-testid={dataTestId}
        {...sharedProps}
      >
        {tooltip}
      </TooltipContainer>
    </>
  );
};

export default TooltipWrapper;
