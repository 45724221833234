import { useCallback, useEffect, useRef, useState } from "react";

import { sortBy, startCase } from "lodash";
import classNames from "classnames";

import BuildingImage from "@src/straps/atoms/BuildingImage/BuildingImage";
import Button from "@src/straps/base/buttons/Button/Button";
import Icon from "@src/straps/base/icons/Icon/Icon";
import Text from "@src/straps/base/type/Text/Text";
import Checkbox from "../../Checkbox/Checkbox";
import { DropdownProps, Option } from "../Dropdown";
import { useDropdownSearch } from "./DropdownSearch";

export default function DropdownMultipleSearch<
  OptionIDType extends string | number = string,
  OptionMetadata extends {} | void = void
>({
  name,
  options,
  selectOnCheck,
  applyText,
  setValue,
  value,
  closeEvent,
  placeholder,
  onApply,
  onCancel,
}: Pick<
  DropdownProps<OptionIDType, OptionMetadata>,
  | "name"
  | "options"
  | "selectOnCheck"
  | "applyText"
  | "placeholder"
  | "onCancel"
  | "onApply"
> & {
  setValue: (options: Array<Option<OptionIDType, OptionMetadata>>) => void;
  value: Array<Option<OptionIDType, OptionMetadata>>;
  closeEvent: () => void;
}) {
  // Indepedent selected value for selectOnCheck functionality
  const [valueTemp, setValueTemp] = useState<typeof value>(value);
  useEffect(() => {
    setValueTemp(value);
  }, [value]);

  const isChecked = useCallback(
    (option: Option<OptionIDType, OptionMetadata>) => {
      if (valueTemp) {
        if (Array.isArray(value)) {
          const selectedIDs = valueTemp.map((o) => o.id);
          return selectedIDs.includes(option.id);
        }
      }
      return false;
    },
    [valueTemp, value]
  );

  const handleSelect = useCallback(
    (option: Option<OptionIDType, OptionMetadata>) => {
      const updated = valueTemp?.slice(0) || [];
      const selectedIDs = valueTemp?.map((o) => o.id) || [];
      const index = selectedIDs.indexOf(option.id);
      if (index > -1) {
        updated.splice(index, 1);
      } else {
        updated.push(option);
      }
      const newValue = sortBy(updated, "label");
      setValueTemp(newValue);

      if (selectOnCheck) {
        setValue?.(updated);
      }
    },
    [valueTemp, setValueTemp, selectOnCheck, setValue]
  );

  const { search, setSearch, filteredOptions } = useDropdownSearch(options);

  const [prevValue, setPrevValue] =
    useState<Array<Option<OptionIDType, OptionMetadata>>>();

  useEffect(() => {
    setPrevValue(value);
  }, [value]);

  const handleClickCancel = useCallback(() => {
    prevValue && setValue(prevValue);
    onCancel?.(prevValue);
    closeEvent();
  }, [prevValue, closeEvent, setValue, onCancel]);

  const handleClickApply = useCallback(() => {
    setPrevValue(valueTemp);
    setValue(valueTemp);
    closeEvent();
    onApply?.(valueTemp);
  }, [closeEvent, setPrevValue, setValue, valueTemp, onApply]);

  const handleClearAll = useCallback(() => {
    setValueTemp([]);
  }, [setValueTemp]);

  const handleSelectAll = useCallback(() => {
    if (filteredOptions) {
      const updated = filteredOptions
        .filter((option) => !option.disabled)
        .slice(0);
      setValueTemp(updated);
    }
  }, [filteredOptions, setValueTemp]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);
  return (
    <>
      <div
        data-testid="dropdown-search-container"
        className={classNames("flex w-full flex-row pl-[18px] pr-8 pt-[18px]", {
          "pb-[18px]": selectOnCheck,
        })}
      >
        <div className="flex h-7 min-h-[28px] w-full items-center gap-2 rounded-full bg-straps-body px-[18px]">
          <Icon
            name="search"
            className="h-3 w-3 shrink-0 text-straps-secondary"
            size="small"
          />
          <input
            data-testid="dropdown-search-input"
            name={name}
            ref={inputRef}
            className="flex-1 border-0 bg-straps-body text-bs font-medium text-straps-tertiary outline-none placeholder:text-straps-tertiary"
            placeholder={placeholder}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      {!selectOnCheck ? (
        <div className="flex w-full flex-row items-center justify-evenly p-2.5">
          <button
            className="cursor-pointer bg-transparent p-0 text-bs text-straps-primary hover:text-straps-hyperlink-hover"
            onClick={handleSelectAll}
          >
            Select All
          </button>
          <div className="h-6 w-px min-w-[1px] max-w-[1px] bg-straps-accent-3" />
          <button
            className="cursor-pointer bg-transparent p-0 text-bs text-straps-primary hover:text-straps-hyperlink-hover"
            onClick={handleClearAll}
          >
            Clear All
          </button>
        </div>
      ) : null}
      <div className="h-px w-full bg-straps-accent-3" />
      <div className="flex max-h-[455px] w-full flex-col gap-y-0.5 overflow-y-auto">
        {filteredOptions?.map((option) => (
          <button
            key={`option-${option.id}`}
            data-testid={`dropdown-option-${option.id}`}
            className={classNames(
              "group flex w-full cursor-pointer flex-row items-center justify-between bg-pure-white py-0 pl-4 pr-4 transition-all hover:bg-straps-body hover:text-straps-hyperlink-hover",
              {
                "cursor-default opacity-25": option.disabled,
              }
            )}
            onClick={(e) => {
              e.preventDefault();
              if (!option.disabled) handleSelect(option);
            }}
          >
            <div className="flex w-[calc(100%-40px)] flex-row items-center">
              <BuildingImage
                name={option.label ?? startCase(option.id.toString())}
                imageURL={option.imageURL}
                className="mr-3 h-10 min-h-[40px] w-10 min-w-[40px]"
              />
              <div className="flex flex-col items-start overflow-hidden">
                <Text
                  as="span"
                  variant="sb_t-14-500"
                  className="pointer-events-none w-full truncate text-left text-straps-primary transition-all group-hover:text-straps-hyperlink-hover"
                >
                  {option.label ?? startCase(option.id.toString())}
                </Text>
                <Text
                  variant="sb_t-12-500"
                  className=" w-full truncate text-left text-straps-tertiary"
                  as="span"
                >
                  {option.supportText}
                </Text>
              </div>
            </div>
            <Checkbox
              size="small"
              checked={!option.disabled ? isChecked(option) : false}
              onChange={() => {
                if (!option.disabled) handleSelect(option);
              }}
            />
          </button>
        ))}
      </div>
      <div className="h-px w-full bg-straps-accent-3" />
      <div className="flex w-full flex-row items-center justify-end gap-5 py-2.5 pr-[26px]">
        <Button noFrameVariant="text" onClick={handleClickCancel}>
          Cancel
        </Button>
        <Button onClick={handleClickApply} dataTestId="dropdown-apply">
          {applyText ?? "Apply"}
        </Button>
      </div>
    </>
  );
}
