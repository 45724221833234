import * as R from "ramda";
import { randomInt } from "mathjs";
import moment from "moment";

import type {
  DateString,
  Utility,
  ArcMeterConsumption,
  DetailedArcAsset,
  MonthString,
} from "./types";

const arcScore = (props: {
  energy: number | null | undefined;
  water: number | null | undefined;
  waste: number | null | undefined;
  transport: number | null | undefined;
  humanExperience: number | null | undefined;
  month?: MonthString | null;
}) => {
  const energy = props.energy || randomInt(8, 27);
  const water = props.water || randomInt(2, 13);
  const waste = props.waste || randomInt(2, 7);
  const transport = props.transport || randomInt(3, 10);
  const humanExperience = props.humanExperience || randomInt(4, 15);
  return {
    id: -1,
    month: props.month,
    scores: {
      energy,
      water,
      waste,
      transport,
      humanExperience,
      base: 10,
      subscoresOccupant: null,
      subscoresCo2: null,
      subscoresVoc: null,
    },
    maxima: {
      base: 10,
      energy: 33,
      water: 15,
      waste: 8,
      transport: 14,
      humanExperience: 20,
      subscoresOccupant: 6.67,
      subscoresCo2: 6.67,
      subscoresVoc: 6.67,
    },
    effectiveAt: "2021-03-01T00:00:00Z",
    totalPoints: energy + water + waste + transport + humanExperience,
  };
};

const fakeTransitSurveyResponse = (responses: any) => ({
  responses: responses || randomInt(60, 170),
  modes: {
    bus: 4.97,
    car: 63.66,
    car23: 12.64,
    cars4: 11.51,
    motorcycle: 2.26,
    walk: 3.16,
    heavyRail: 1.81,
    lightRail: 0,
  },
});

const fakeHumanExperienceSurveyResponse = (responses: any) => {
  let remainingScore = 100;

  const satisfaction = R.range(0, 11).map((idx) => {
    if (idx === 10 || remainingScore === 0) return remainingScore;
    const score =
      remainingScore < 3
        ? remainingScore
        : randomInt(2, Math.min(10, remainingScore));
    remainingScore -= score;
    return score;
  });

  let averageSatisfactionScore = 0;
  satisfaction.forEach((val, idx) => {
    averageSatisfactionScore += (val * idx) / 100;
  });

  return {
    responses: responses || randomInt(60, 170),
    satisfaction,
    averageSatisfactionScore: Math.round(averageSatisfactionScore),
  };
};

export const getFakeLeedMeterReading = (
  props?: {
    startDate?: DateString;
    endDate?: DateString;
    utility?: Utility;
    reading?: number;
  } | null
): ArcMeterConsumption => {
  const { startDate, endDate, utility, reading } = props || {};
  return {
    id: -1,
    reading: reading || randomInt(10, 50),
    startDate: startDate || "2020-01-03",
    endDate:
      endDate || startDate
        ? moment(startDate).add(1, "month").format("YYYY-MM-DD")
        : "2020-02-04",
    utility: utility || "unknown",
    // @ts-expect-error - TS2740 - Type '{}' is missing the following properties from type 'ArcMeter': id, name, utility, valinorUnit, and 2 more.
    meter: {},
  };
};

const getFakeArcAsset: (arg1?: any) => DetailedArcAsset = ({
  name,
  leedId,
  certification,
  points,
  dateCertified,
  utilitiesWithBillsToSubmit,
  currentScore,
  responses,
  leedBills,
}) => ({
  leedId,
  name,
  certification,
  points,
  key: "sVVlAJ1UvW8QJV6gPAVuALz4",
  occupancy: 230,
  certifications: [
    {
      id: 1,
      dateCertified,
      certificationPoints: points,
      certificationLevel: certification,
    },
  ],
  currentScore: arcScore(
    currentScore || {
      energy: 22,
      water: 10,
      waste: 4,
      transport: 11,
      humanExperience: 18,
    }
  ),
  transitSurveySummary: fakeTransitSurveyResponse(responses),
  humanExperienceSurveySummary: fakeHumanExperienceSurveyResponse(responses),
  // @ts-expect-error - TS2739 - Type '{ id: number; }' is missing the following properties from type 'ArcMeter': name, utility, valinorUnit, nativeUnit, fuelType
  occupancyMeter: { id: -1 },
  // @ts-expect-error - TS2739 - Type '{ id: number; }' is missing the following properties from type 'ArcMeter': name, utility, valinorUnit, nativeUnit, fuelType
  grossAreaMeter: { id: -1 },
  // @ts-expect-error - TS2739 - Type '{ id: number; }' is missing the following properties from type 'ArcMeter': name, utility, valinorUnit, nativeUnit, fuelType
  operatingHoursMeter: { id: -1 },
  // @ts-expect-error - TS2739 - Type '{ id: number; }' is missing the following properties from type 'ArcMeter': name, utility, valinorUnit, nativeUnit, fuelType
  operationalDaysMeter: { id: -1 },
  // @ts-expect-error - TS2739 - Type '{ id: number; }' is missing the following properties from type 'ArcMeter': name, utility, valinorUnit, nativeUnit, fuelType
  co2Meter: { id: -1 },
  // @ts-expect-error - TS2739 - Type '{ id: number; }' is missing the following properties from type 'ArcMeter': name, utility, valinorUnit, nativeUnit, fuelType
  vocMeter: { id: -1 },
  valinorUtilities: {},
  leedBills: leedBills || { "2020-10": [] },
  utilitiesWithBillsToSubmit: utilitiesWithBillsToSubmit || [],
});

export const demoSites = {
  "77": {
    name: "10 Downing Street",
    arcAssetId: 9000000073,
    arcAsset: getFakeArcAsset({
      name: "10 Downing Street",
      certification: "platinum",
      points: 84,
      dateCertified: "2019-10-02",
      currentScore: {
        energy: 27,
        water: 12,
        waste: 5,
        transport: 11,
        humanExperience: 16,
      },
    }),
    voc: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    co2: [],
    occupancy: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    grossArea: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    operatingHours: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    operationalDays: [
      getFakeLeedMeterReading({ startDate: "2020-08-12", reading: 313 }),
    ],
  },
  "132": {
    name: "510 S. Congress",
    arcAssetId: 9000000073,
    arcAsset: getFakeArcAsset({
      name: "510 S. Congress",
      certification: "silver",
      points: 54,
      dateCertified: "2020-12-13",
      currentScore: {
        energy: 17,
        water: 9,
        waste: 3,
        transport: 7,
        humanExperience: 10,
      },
    }),
    voc: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    co2: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    occupancy: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    grossArea: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    operatingHours: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    operationalDays: [
      getFakeLeedMeterReading({ startDate: "2020-08-12", reading: 313 }),
    ],
  },
  "147": {
    name: "2 Macquaire Street",
    arcAssetId: 9000000073,
    arcAsset: getFakeArcAsset({
      name: "2 Macquaire Street",
      certification: "platinum",
      points: 91,
      dateCertified: "2018-07-22",
      currentScore: {
        energy: 30,
        water: 13,
        waste: 7,
        transport: 13,
        humanExperience: 18,
      },
    }),
    voc: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    co2: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    occupancy: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    grossArea: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    operatingHours: [
      getFakeLeedMeterReading({ startDate: "2020-08-12" }),
      getFakeLeedMeterReading({ startDate: "2020-06-04" }),
      getFakeLeedMeterReading({ startDate: "2020-03-02" }),
    ],
    operationalDays: [
      getFakeLeedMeterReading({ startDate: "2020-08-12", reading: 313 }),
    ],
  },
  "184": {
    name: "3709 Promontory Point",
    arcAsset: null,
  },
  "188": {
    name: "1600 Pennsylvania Avenue",
    arcAssetId: 9000000073,
    arcAsset: getFakeArcAsset({
      name: "1600 Pennsylvania Avenue",
      certification: "gold",
      points: 75,
      dateCertified: "2019-02-09",
      utilitiesWithBillsToSubmit: [],
      currentScore: {
        energy: 25,
        water: 10,
        waste: 4,
        transport: 10,
        humanExperience: 14,
      },
      responses: 70,
      leedBills: {
        "2019-07": [
          { utility: "electricity", startDate: "2019-07-10" },
          { utility: "water", startDate: "2019-07-06" },
          { utility: "waste", startDate: "2019-07-14" },
        ],
        "2021-02": [
          { utility: "electricity", endDate: "2021-03-16" },
          { utility: "water", endDate: "2021-01-05" },
        ],
        "2020-02": [{ utility: "waste", endDate: "2020-03-13" }],
      },
    }),
    voc: [
      getFakeLeedMeterReading({ startDate: "2020-08-17", reading: 0.3 }),
      getFakeLeedMeterReading({ startDate: "2019-06-14", reading: 0.4 }),
      getFakeLeedMeterReading({ startDate: "2018-09-05", reading: 0.45 }),
    ],
    co2: [
      getFakeLeedMeterReading({ startDate: "2020-06-12", reading: 10157 }),
      getFakeLeedMeterReading({ startDate: "2019-05-04", reading: 11121 }),
      getFakeLeedMeterReading({ startDate: "2018-07-02", reading: 9736 }),
    ],
    occupancy: [
      getFakeLeedMeterReading({ startDate: "2021-02-01", reading: 230 }),
      getFakeLeedMeterReading({ startDate: "2020-11-13", reading: 220 }),
      getFakeLeedMeterReading({ startDate: "2020-08-08", reading: 190 }),
      getFakeLeedMeterReading({ startDate: "2020-04-02", reading: 170 }),
      getFakeLeedMeterReading({ startDate: "2020-01-04", reading: 250 }),
    ],
    grossArea: [
      getFakeLeedMeterReading({ startDate: "2018-03-02", reading: 120000 }),
    ],
    operatingHours: [
      getFakeLeedMeterReading({ startDate: "2020-08-12", reading: 55 }),
      getFakeLeedMeterReading({ startDate: "2020-06-04", reading: 50 }),
      getFakeLeedMeterReading({ startDate: "2020-03-02", reading: 49 }),
    ],
    operationalDays: [
      getFakeLeedMeterReading({ startDate: "2020-08-12", reading: 313 }),
    ],
  },
} as const;
