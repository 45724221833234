import FetchClients from "../client";
import { useMutation } from "react-query";

const spaces = { mutations: { useDelete } } as const;

export default spaces;

const deleteSpace = async (
  site_id: number,
  building_id?: number | null,
  space_id?: number
) => {
  if (typeof building_id !== "number" || typeof space_id !== "number")
    throw new Error("Invalid building or space id.");
  const { response, error } = await FetchClients.tenantsAndSpaces.DELETE(
    "/{site_id}/buildings/{building_id}/spaces/{space_id}",
    {
      params: {
        path: {
          site_id,
          building_id,
          space_id,
        },
      },
    }
  );
  if (response.ok) return;
  throw new Error(error);
};

function useDelete(site_id: number, building_id?: number | null) {
  return useMutation((space_id: number) =>
    deleteSpace(site_id, building_id, space_id)
  );
}
