import React, { ChangeEvent, forwardRef, Ref, useRef } from "react";
import classnames from "classnames";
import { IconNames } from "@src/straps/base/icons/Icon/types";
import Icon from "@src/straps/base/icons/Icon/Icon";
import Text from "@src/straps/base/type/Text/Text";
import Fuse from "fuse.js";
import { useDidClickOutsideElement } from "@src/global_functions/hooks";
import Tooltip from "@src/straps/base/dialogs/Tooltip/Tooltip";
import {
  Line,
  Rounded,
  LineVariantProps,
  RoundedVariantProps,
} from "./inputVariants";

export type InputVariant = "rounded" | "line";
export type InputStatus = "default" | "negative" | "positive" | "warning";

type ContainerProps = {
  label?: string;
  tooltip?: string;
  iconSecondary?: IconNames;
  unit?: string;
  supportText?: string;
  validation?: string;
  containerClassName?: string;
};

export type BaseInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "ref"
> & {
  name: string;
  dataTestId?: string;
  status?: InputStatus;
  icon?: IconNames;
  autoCompleteOptions?: string[];
  autoCompleteOptionLimit?: number;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
};

export type InputVariantUnionProps =
  | ({
      variant?: "line";
    } & LineVariantProps)
  | ({
      variant: "rounded";
    } & RoundedVariantProps);

export type InputProps = BaseInputProps &
  ContainerProps &
  InputVariantUnionProps;

const Input = forwardRef(function Input(
  props: InputProps,
  ref: Ref<HTMLInputElement>
) {
  const {
    label,
    tooltip,
    iconSecondary,
    unit,
    supportText,
    validation,
    className,
    containerClassName,
    variant,
    onChange,
    autoCompleteOptions,
    autoCompleteOptionLimit,
    name,
    status = "default",
    icon,
    value,
    disabled,
    ...rest
  } = props;

  const isLineVariant = variant === "line" || !variant;

  const [showAutoCompleteMenu, setShowAutoCompleteMenu] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => inputRef.current
  );

  const containerRef = useDidClickOutsideElement(() => {
    setShowAutoCompleteMenu(false);
  });

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(evt);
  };

  function handleAutocompleteSelect(option: string) {
    // recommendation from Dan Abramov on how to manually trigger a change event in the React Dom
    // https://github.com/facebook/react/issues/11600#issuecomment-345813130
    // prettier-ignore
    const  nativeInputValueSetter = Object.getOwnPropertyDescriptor( window.HTMLInputElement.prototype, "value")?.set;

    if (inputRef.current && nativeInputValueSetter) {
      nativeInputValueSetter.call(inputRef.current, option);
      const ev2 = new Event("input", { bubbles: true });
      inputRef.current.dispatchEvent(ev2);
      setShowAutoCompleteMenu(false);
    }
  }

  function handleInputFocus(e: React.FocusEvent<HTMLInputElement>) {
    if ((autoCompleteOptions || []).length > 0) {
      setShowAutoCompleteMenu(true);
    }
    rest.onFocus && rest.onFocus(e);
  }

  React.useEffect(() => {
    if (document.activeElement === inputRef.current) {
      if ((autoCompleteOptions || []).length > 0) {
        setShowAutoCompleteMenu(true);
      }
    }
  }, [autoCompleteOptions]);

  return (
    <div className="w-full">
      {label && (
        <InputLabel
          label={label}
          name={name}
          variant={variant ?? "line"}
          tooltip={tooltip}
          disabled={disabled}
        />
      )}
      <div
        className={classnames(
          "relative",
          "flex",
          "items-center",
          "justify-center",
          "bg-transparent",
          "rounded-full",
          "group/icon",
          containerClassName
        )}
        ref={containerRef}
      >
        {icon && (
          <Icon
            name={icon}
            size={isLineVariant ? "medium" : "small"}
            className={classnames("absolute z-10 text-straps-secondary", {
              "text-straps-tertiary": disabled,
              "text-straps-positive":
                status === "positive" && variant === "rounded",
              "text-straps-negative":
                status === "negative" && variant === "rounded",
              "text-straps-warning":
                status === "warning" && variant === "rounded",
              "group-hover/icon:text-straps-positive-hover":
                status === "positive" && variant === "rounded",
              "group-hover/icon:text-straps-negative-hover":
                status === "negative" && variant === "rounded",
              "group-hover/icon:text-straps-warning-hover":
                status === "warning" && variant === "rounded",
              "left-[18px]": variant === "rounded",
              "left-0": isLineVariant,
            })}
          />
        )}
        {props.variant === "rounded" && (
          <Rounded
            {...rest}
            ref={inputRef}
            name={name}
            disabled={disabled}
            icon={icon}
            backgroundColor={props.backgroundColor}
            status={status}
            value={value}
            handleChange={handleChange}
            handleInputFocus={handleInputFocus}
            autoCompleteOptions={autoCompleteOptions}
          />
        )}
        {isLineVariant && (
          <Line
            {...rest}
            ref={inputRef}
            name={name}
            disabled={disabled}
            icon={icon}
            status={status}
            value={value}
            handleChange={handleChange}
            handleInputFocus={handleInputFocus}
            autoCompleteOptions={autoCompleteOptions}
          />
        )}
        {autoCompleteOptions && autoCompleteOptions.length > 0 && (
          <AutoCompleteOptionsMenu
            options={autoCompleteOptions}
            inputValue={value}
            onSelect={handleAutocompleteSelect}
            open={showAutoCompleteMenu}
            optionLimit={autoCompleteOptionLimit}
          />
        )}
        <div
          className={classnames("absolute right-0 flex", {
            "pr-[18px]": variant === "rounded",
          })}
        >
          {iconSecondary && (
            <Icon
              className={classnames({
                "text-pure-white-300": !disabled,
                "text-straps-tertiary": disabled,
              })}
              name={iconSecondary}
            />
          )}

          {unit && (
            <div
              className={classnames(
                "ml-[8px] h-[16px] border-l-[1px] pl-[8px]"
              )}
            >
              <Text
                variant={isLineVariant ? "sa_t-16-700" : "sb_t-12-500"}
                color="tertiary"
              >
                {unit}
              </Text>
            </div>
          )}
        </div>
      </div>
      <div
        className={classnames("relative", "flex", {
          "text-straps-tertiary": disabled,
        })}
      >
        {supportText && (
          <Text
            variant={isLineVariant ? "sb_t-14-500" : "sb_t-12-500"}
            className="pt-2"
            color={getColor(status, disabled)}
          >
            {supportText}
          </Text>
        )}
        {validation && isLineVariant && (
          <div
            className={classnames("absolute right-0 p-1", {
              "text-white": status !== "default",
              "bg-straps-positive": status === "positive",
              "bg-straps-negative": status === "negative",
              "bg-straps-warning": status === "warning",
            })}
          >
            <Text variant="sb_t-12-500" color="white">
              {validation}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
});

export function getColor(status?: InputStatus, disabled?: boolean) {
  if (disabled) return "tertiary";

  if (status === "negative") return "negative";

  if (
    (status === "default" && !disabled) ||
    status === "positive" ||
    status === "warning"
  )
    return "secondary";
}

export default Input;

function getLabelTextColor(variant: InputVariant, disabled?: boolean) {
  if (disabled) {
    return "tertiary";
  }

  return variant === "line" ? "primary" : "secondary";
}

// Note on label styling - there is not currently a single source of truth for label styling.
// For now we are keeping TextInputs separate while grouping other inputs to use CheckboxLabel.
// FormLabel is a distinct component used only for the Form in case different styling is needed there.
export function InputLabel(props: {
  label: string;
  name: string;
  tooltip?: string;
  variant: InputVariant;
  disabled?: boolean;
}) {
  const { label, name, tooltip, variant, disabled } = props;
  return (
    <div
      className={classnames("flex", {
        "mb-[3px]": variant === "line",
        "pb-1": variant === "rounded",
      })}
    >
      <Text
        variant={variant === "line" ? "h5_t-18-700" : "sb_t-12-500"}
        color={getLabelTextColor(variant, disabled)}
        className="pt-2"
      >
        <label htmlFor={name}>{label}</label>
      </Text>
      {tooltip && (
        <Tooltip fixed content={tooltip}>
          <Icon
            name="info"
            size="medium"
            className="ml-1 mt-2 text-straps-tertiary"
          />
        </Tooltip>
      )}
    </div>
  );
}

function AutoCompleteOptionsMenu({
  options,
  inputValue,
  onSelect,
  open,
  optionLimit,
}: {
  options: string[];
  inputValue: string | undefined;
  onSelect: (option: string) => void;
  open: boolean;
  optionLimit?: number;
}) {
  const lastInputValue = useRef(inputValue);
  const [filteredOptions, setFilteredOptions] = React.useState<string[]>([]);
  const fuse = React.useMemo(
    () =>
      new Fuse(options, {
        shouldSort: true,
        threshold: 0.4,
      }),
    [options]
  );
  React.useEffect(() => {
    async function filter() {
      lastInputValue.current = inputValue;
      const result = await new Promise<string[]>((resolve) => {
        if (inputValue) {
          resolve(fuse.search(inputValue).map(({ item }) => item));
        } else if (!optionLimit) {
          resolve(options);
        } else {
          resolve([]);
        }
      });

      if (lastInputValue.current === inputValue) {
        setFilteredOptions(result.slice(0, optionLimit));
      }
    }
    filter();
  }, [options, inputValue, fuse, optionLimit]);

  if (!open || filteredOptions.length === 0) {
    return null;
  }

  return (
    <div className="absolute top-9 z-10 flex max-h-32 w-full flex-col overflow-y-auto bg-pure-white shadow-lg">
      {filteredOptions.map((option) => (
        <button
          key={option}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSelect(option);
          }}
          className={classnames(
            "group/option flex w-full shrink-0 cursor-pointer flex-row items-center bg-pure-white py-2 pl-5 transition-all hover:bg-straps-body"
          )}
        >
          <Text
            as="span"
            variant="sb_t-12-500"
            className="pointer-events-none block truncate text-left text-straps-primary transition-all group-hover/option:text-straps-hyperlink-hover"
          >
            {option}
          </Text>
        </button>
      ))}
    </div>
  );
}
