import { useCallback } from "react";

import { sortBy } from "lodash";

import { DropdownProps, Option } from "../Dropdown";
import { DropdownOptionButton } from "../DropdownOptionButton";

export default function DropdownMultiple<
  OptionIDType extends string | number = string,
  OptionMetadata extends {} | void = void
>({
  options,
  setValue,
  value,
}: Pick<
  DropdownProps<OptionIDType, OptionMetadata>,
  "options" | "selectOnCheck"
> & {
  setValue: (options: Array<Option<OptionIDType, OptionMetadata>>) => void;
  value: Array<Option<OptionIDType, OptionMetadata>>;
}) {
  const isChecked = useCallback(
    (option: Option<OptionIDType, OptionMetadata>) => {
      if (value) {
        if (Array.isArray(value)) {
          const selectedIDs = value.map((o) => o.id);
          return selectedIDs.includes(option.id);
        }
      }
      return false;
    },
    [value]
  );

  const handleSelect = useCallback(
    (option: Option<OptionIDType, OptionMetadata>) => {
      const updated = value?.slice(0) || [];
      const selectedIDs = value?.map((o) => o.id) || [];
      const index = selectedIDs.indexOf(option.id);
      if (index > -1) {
        updated.splice(index, 1);
      } else {
        updated.push(option);
      }
      const newValue = sortBy(updated, "label");

      setValue?.(newValue);
    },
    [setValue, value]
  );

  return (
    <>
      {options.map((option) => (
        <DropdownOptionButton
          key={`${option.id}`}
          option={option}
          variant="multiple"
          handleSelect={handleSelect}
          isChecked={isChecked}
        />
      ))}
    </>
  );
}
