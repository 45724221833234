import request from "../../../global_functions/request";
import Cookies from "js-cookie";
import type { Building } from "../../../types";
import { outBoundSiteEncoder } from "../../../global_functions/postgrestApi";
import { useInvalidateSiteQueries } from "../../../global_functions/sites/sitesQueries";
import { formatDataObj } from "../../../global_functions/postgrestApi/common";
import { useMutation } from "react-query";

const sites = {
  mutations: {
    usePatch,
  },
} as const;

export default sites;

const patchSite = (siteId: string | number, props: Partial<Building>) => {
  return request
    .patch(`/rest/sites`)
    .query({ id: `eq.${siteId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(
      formatDataObj(props, outBoundSiteEncoder, {
        allowNulls: true,
        allowUnknownKeys: false,
      })
    );
};

function usePatch(siteId: number) {
  const { refreshSiteQueries } = useInvalidateSiteQueries({ siteId });
  return useMutation({
    mutationFn: (site: Partial<Building>) => patchSite(siteId, site),
    onSuccess: () => refreshSiteQueries(),
  });
}
