import request from "../../request";
import Cookies from "js-cookie";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
  bugsnagPostgrestErrorHandler,
} from "../../postgrestApi/common";
import { CamelCasedProperties } from "type-fest";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { tenantsAndSpacesKeys } from "../../fortyTwoApi/tenantsAndSpaces/queryKeyFactory";
import { VITE_TENANTS_AND_SPACES_BASE_URL } from "../../../env";
import { ClientOutput } from "@src/global_functions/backpackSdk/client";

export type EffectiveDate = CamelCasedProperties<
  ClientOutput["tenantsAndSpaces"]["EffectiveDate"]
>;

const effectiveDate = {
  useQuery,
  mutations: {
    usePut,
  },
} as const;

export default effectiveDate;

const getEffectiveDate = async (
  siteId: string | number
): Promise<EffectiveDate> =>
  request
    .get(`${VITE_TENANTS_AND_SPACES_BASE_URL}/sites/${siteId}/effective_date`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body))
    .catch((e) => bugsnagPostgrestErrorHandler(e));

function useQuery(siteId: number) {
  const queryKey = tenantsAndSpacesKeys.entity(siteId, "effectiveDate");
  return useReactQuery({
    queryKey,
    queryFn: () => getEffectiveDate(siteId),
  });
}

// input needs to match format YYYY-(M|MM)-(D|DD), but can type as string to match DatePickerInput component
const putEffectiveDate = (
  siteId: number,
  input: EffectiveDate["effectiveDate"]
) => {
  const effectiveDatePayload = {
    effectiveDate: input,
  };
  return request
    .put(`${VITE_TENANTS_AND_SPACES_BASE_URL}/sites/${siteId}/effective_date`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .send(recursiveSnakeCaseCipher(effectiveDatePayload));
};

function usePut(siteId: number) {
  const queryKey = tenantsAndSpacesKeys.entity(siteId, "effectiveDate");
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (input: EffectiveDate["effectiveDate"]) =>
      putEffectiveDate(siteId, input),
    onSuccess: () => queryClient.invalidateQueries(queryKey),
  });
}
