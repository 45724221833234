import * as React from "react";

import classNames from "classnames";

import styles from "./AsyncButton.module.scss";
import Loader from "./Loader";
import { useAsync } from "../global_functions/useAsync";
import Icon from "../icons";

export interface AsyncButtonProps extends React.ComponentProps<"button"> {
  onClick: (arg1?: any) => Promise<void>;
  deps?: any[];
  loading?: boolean;
  children: React.ReactNode;
  // prettier-ignore
  color?: 'blue' | 'green' | 'gray' | 'red' | 'yellow' | 'charcoal' | 'white' | 'orange',
  outline?: boolean;
  className?: string;
  icon?: boolean;
  rounded?: boolean;
  showTick?: boolean;
  noStyle?: boolean;
  size?: "small" | "large" | "default";
  dataCy?: string;
  dataTestId?: string;
}

const AsyncButton = (props: AsyncButtonProps) => {
  const {
    onClick,
    deps,
    children,
    icon,
    color = "",
    outline,
    rounded,
    showTick,
    loading: loadingProp,
    className,
    size = "default",
    dataTestId,
    dataCy,
    ...rest
  } = props;
  const [{ loading, error, success, setState }, buttonOnClick] = useAsync(
    onClick,
    [onClick]
  );

  const clearError = React.useCallback(
    (e) => {
      e.stopPropagation();
      setState((prev) => ({ ...prev, error: null }));
    },
    [setState]
  );

  React.useEffect(() => {
    if (success && showTick) {
      const x = setTimeout(() => {
        setState((prev) => ({ ...prev, success: false }));
      }, 3000);
      return () => clearTimeout(x);
    }
  }, [success, showTick, setState]);
  return (
    <>
      <button
        onClick={buttonOnClick}
        data-cy={dataCy ?? "bractlet-async-button"}
        data-testid={dataTestId ?? "bractlet-async-button"}
        // @ts-expect-error - TS2322 - Type 'string | false' is not assignable to type 'string | undefined'.
        className={
          !props.noStyle &&
          classNames(
            {
              "bractlet-button": true,
              [`bractlet-button--${color}`]: [
                "blue",
                "green",
                "gray",
                "red",
                "yellow",
                "charcoal",
                "white",
                "orange",
              ].includes(color),
              [`bractlet-button--${size}`]: ["small", "large"].includes(size),

              "bractlet-button--icon": icon,
              "bractlet-button--outline": outline,
              "bractlet-button--rounded": rounded,
            },
            props.className
          )
        }
        {...rest}
      >
        <div className="relative">
          <Loader size={18} show={Boolean(loading || loadingProp)} />
          <span
            style={{
              opacity:
                !(success && showTick) && !(loading || props.loading) ? 1 : 0,
            }}
          >
            {children}
          </span>
          {success && showTick && !(loading || props.loading) && (
            <Icon className={styles.valid} icon="Valid" />
          )}
          {!success && error && (
            <span className={styles.error_message} onClick={clearError}>
              {error.message}
            </span>
          )}
        </div>
      </button>
    </>
  );
};

export default AsyncButton;
