import classNames from "classnames";
import { ComponentPropsWithRef, forwardRef } from "react";
import { CardHeader } from "./CardHeader";
import { ClickableDiv } from "../../../utils/ClickableDiv";

interface CardProps extends ComponentPropsWithRef<"div"> {
  corners?: "base" | "radius" | "asymmetrical";
  shadow?: "s" | "m";
}
export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ corners = "base", shadow, onClick, className, ...divProps }, ref) => {
    const props: ComponentPropsWithRef<"div"> = {
      ref,
      className: classNames(className, "bg-pure-white overflow-hidden", {
        "rounded-lg": corners === "radius",
        "rounded-2xl rounded-tl-none": corners === "asymmetrical",
        shadow: shadow === "s",
        "shadow-md": shadow === "m",
        "hover:shadow-md": shadow === "s" && onClick !== undefined,
        "hover:shadow-lg": shadow === "m" && onClick !== undefined,
        "cursor-pointer focus:outline-straps-hyperlink-hover outline-2":
          onClick !== undefined,
      }),
      ...divProps,
    };

    if (onClick !== undefined) {
      return <ClickableDiv onClick={onClick} {...props} />;
    }

    return <div {...props} />;
  }
);

export { CardHeader };
