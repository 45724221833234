import * as R from "ramda";
import { sortBy } from "lodash";
import type {
  SummaryOverview,
  SummaryOverviewQueryParams,
  ProductTier,
} from "../../types";
import { isDev } from "../../env";
import { getSites, getSite } from "../../global_functions/postgrestApi";

function applySiteDemoMode<T extends SummaryOverview>(site: T) {
  const isDemo = localStorage.getItem("demo");
  let demoTier: ProductTier = site.productTier;
  if (localStorage.getItem("benchmark")) demoTier = "benchmark";
  else if (localStorage.getItem("analytics")) demoTier = "analytics";

  return isDemo && demoTier ? { ...site, productTier: demoTier } : site;
}

function applySiteDevOverrides<T extends SummaryOverview>(site: T) {
  if (isDev) {
    if (site.id === 15) {
      return { ...site, arcAssetId: 9000000073 };
    }
  }
  return site;
}

export const applySiteMutations = R.pipe(
  applySiteDemoMode,
  applySiteDevOverrides
);

const applySiteMutationsToAllSites = (sites: SummaryOverview[]) =>
  sortBy(sites.filter(Boolean).map(applySiteMutations), "name");

export const SITE_SUMMARY_FIELDS: SummaryOverviewQueryParams = [
  "id",
  "accountId",
  "accountName",
  "gresbAssetId",
  "arcAssetId",
  "energyStarPropertyId",
  "productTier",
  "name",
  "imageKey",
  "status",
  "latitude",
  "longitude",
];

export const handleGetSites = async (
  includeInactive = false,
  fields?: SummaryOverviewQueryParams
) =>
  applySiteMutationsToAllSites(
    await getSites({
      includeInactive,
      fields,
    })
  );

export async function handleRefreshSite(
  siteId: string | number,
  setSiteData: (
    arg1:
      | SummaryOverview[]
      | ((
          arg1?: SummaryOverview[] | null | undefined
        ) => SummaryOverview[] | null | undefined)
      | null
      | undefined
  ) => void,
  includeInactive = false,
  fields?: SummaryOverviewQueryParams
) {
  // Retrieve individual site
  const updatedSite = await getSite(siteId, {
    includeInactive,
    fields,
  });
  if (!updatedSite) {
    throw new Error(`Error refreshing Site (siteId: ${siteId})`);
  }

  // Update main sites array with new data
  setSiteData((allSites) => {
    let found = false;
    const updatedSites = (allSites ?? []).map((site) => {
      if (Number(site.id) === Number(siteId)) {
        found = true;
        return updatedSite;
      }
      return site;
    });

    if (updatedSite && !found) updatedSites.push(updatedSite);
    return applySiteMutationsToAllSites(updatedSites);
  });
}
