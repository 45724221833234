import request from "../request";
import Cookies from "js-cookie";

import {
  camelCaseCipher,
  snakeCaseCipher,
  recursiveCamelCaseCipher,
  formatDataObj,
  bugsnagPostgrestErrorHandler,
  bugsnagGeneralErrorHandler,
} from "./common";

import type { User } from "../../types";

import type {
  UnseenContentType,
  PagesToTrack,
} from "../../context/UnseenContentContext";

import { isDev } from "../../env";

export const getUser = (userId: number): Promise<User | null | undefined> =>
  request
    .get("/rest/users_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${userId}` })
    .then(({ body }) => recursiveCamelCaseCipher(body[0]))
    .catch(bugsnagPostgrestErrorHandler);

export const getUsers = (accountId: number): Promise<User[]> =>
  request
    .get("/rest/users_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ account_id: `eq.${accountId}` })
    .then(({ body }) => recursiveCamelCaseCipher(body));

export const updateUser = (
  userId: number,
  updatedProps: Partial<User>,
  sendInvite?: boolean
): Promise<void> =>
  request
    .patch("/rest/users")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${userId}` })
    .send(formatDataObj(updatedProps, snakeCaseCipher, { allowNulls: true }))
    .then(async () => {
      if (sendInvite) {
        await sendUserInvitation(userId);
      }
    })
    .catch(bugsnagPostgrestErrorHandler);

export const createUser = (
  user: {
    accountId: number;
    email: string;
    firstName: string | null;
    lastName: string | null;
  },
  subscriptions: Partial<User>,
  sendInvite?: boolean
): Promise<number> =>
  request
    .post("/api/users")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(formatDataObj(user, snakeCaseCipher, { allowNulls: true }))
    .then((res) => res.body[0])
    .then(async ({ id }) => {
      await updateUser(id, subscriptions, sendInvite);
      return id;
    });

export const deleteUser = (userId: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/users")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${userId}` })
    .send({ deleted_at: new Date() })
    .catch(bugsnagPostgrestErrorHandler);

export const sendUserInvitation = (userId: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .post("/api/user_invites")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ user_id: userId });

export const sendUserPasswordReset = (userId: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .post("/api/password_resets")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ user_id: userId });

export const queryCurrentUser = (): Promise<User | null | undefined> =>
  request
    .get(`/rest/current_user`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .then(({ body }) => ({
      ...formatDataObj(body, camelCaseCipher),
      imageURL: body.image_key
        ? `https://bractlet-public${
            isDev ? "-dev" : ""
          }.s3.amazonaws.com/intel/user_images/${body.image_key}.jpeg`
        : null,
      readOnly: body.user_permissions === "read_only",
      siteUserBlacklists: recursiveCamelCaseCipher(body.site_user_blacklists),
    }))
    .catch(bugsnagPostgrestErrorHandler);

export const uploadUserImage = async (
  userId: string | number,
  imageBlob: Blob
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .post("/uploads")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ type: "user_image", id: userId })
    .then(({ body }) =>
      request
        .put(body.url) // received pre-signed S3 url from backend to upload to
        .set("Content-Type", imageBlob.type)
        .send(imageBlob)
    )
    .then(() =>
      request
        .post("/upload_completions")
        .send({ type: "user_image", id: userId })
    )
    .catch(bugsnagGeneralErrorHandler);

export const updatePassword = async (
  userId: string | number,
  password: string,
  passwordConfirmation: string
): Promise<[number, Array<string> | null | undefined]> =>
  // @ts-expect-error - TS2322 - Type '[any, any] | (number | null)[]' is not assignable to type '[number, string[] | null | undefined]'.
  request
    .patch(`/api/users/${userId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      user: { password, password_confirmation: passwordConfirmation },
    })
    .then((res) => [res.status, null])
    .catch((e) => [e.status, e.response.body.errors]);

export const getUnseenContentSummary = (
  siteId: number,
  userId: number
): Promise<UnseenContentType | null | undefined> =>
  request
    .get("/rest/users_unseen_content_summary")
    .query({ site_id: `eq.${siteId}`, user_id: `eq.${userId}` })
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .catch(bugsnagPostgrestErrorHandler)
    .then((res) =>
      res && res.body && res.body.length === 1
        ? { hasUnseenNotification: res.body[0].has_unseen_notification }
        : null
    );

export const createUserLastedViewedEntry = (
  site_id: number,
  user_id: number
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .post("/rest/users_last_viewed_pages")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ user_id, site_id })
    .catch(bugsnagPostgrestErrorHandler);

export const updateUserLastedViewedEntry = (
  siteId: number,
  userId: number,
  page: PagesToTrack
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/users_last_viewed_pages")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}`, user_id: `eq.${userId}` })
    .send({ schedule_notifications: new Date() })
    .catch(bugsnagPostgrestErrorHandler);
