import { useQuery } from "react-query";

import FetchClients, { ClientOutput } from "../client";

export type UtilityProvider = ClientOutput["arcadia"]["Provider"];

const providers = {
  useQueryAll,
} as const;

export default providers;

export const UTILITY_PROVIDERS_KEY = "arcadia/providers";

function useQueryAll() {
  return useQuery<UtilityProvider[]>([UTILITY_PROVIDERS_KEY], async () => {
    const { data, error } = await FetchClients.arcadia.GET("/providers", {});
    if (data) return data;
    throw new Error(error);
  });
}
