import { useQuery as useReactQuery, useQueryClient } from "react-query";
import { noop } from "lodash";
import { getAccountSummary } from "@src/global_functions/postgrestApi";

const accounts = {
  useQuery,
} as const;

export default accounts;

const ACCOUNT_SUMMARY_KEY = "account-summary";
function useQuery(accountId?: number) {
  return useReactQuery(
    [ACCOUNT_SUMMARY_KEY, accountId],
    () => getAccountSummary(accountId!),
    { enabled: !!accountId }
  );
}

export const useInvalidateAccountSummaryQuery = (accountId?: number) => {
  const queryClient = useQueryClient();
  return accountId
    ? () => queryClient.invalidateQueries([ACCOUNT_SUMMARY_KEY, accountId])
    : noop;
};
