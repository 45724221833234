import FetchClients, { ClientOutput } from "../client";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { CamelCasedPropertiesDeep } from "type-fest";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";

type SnakeCaseDetachedParking = ClientOutput["tenantsAndSpaces"]["Parking"];
export type DetachedParking =
  CamelCasedPropertiesDeep<SnakeCaseDetachedParking>;

const detachedParking = { useQuery, mutations: { usePut, useDelete } } as const;

export default detachedParking;

// define here to avoid circular import issue
export const PARKING_QUERY_KEYS = {
  parking: "/parking",
  detachedParking: "/detached_parking",
} as const;

const getDetachedParking = async (site_id: number, parking_id: number) => {
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/detached_parking/{parking_id}",
    {
      params: { path: { site_id, parking_id } },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as DetachedParking;
  throw new Error(error);
};

function useQuery(site_id: number, parking_id: number) {
  return useReactQuery(
    [PARKING_QUERY_KEYS.detachedParking, site_id, parking_id],
    () => getDetachedParking(site_id, parking_id),
    {
      refetchOnWindowFocus: false,
    }
  );
}

function usePut(
  site_id: number,
  { invalidateParkingQueries }: { invalidateParkingQueries?: boolean } = {}
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: DetachedParking) => {
      const { response, error } = await FetchClients.tenantsAndSpaces.PUT(
        "/{site_id}/detached_parking",
        {
          params: { path: { site_id } },
          body: recursiveSnakeCaseCipher(body),
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries([
        PARKING_QUERY_KEYS.detachedParking,
        site_id,
        id,
      ]);
      if (invalidateParkingQueries)
        await queryClient.invalidateQueries([
          PARKING_QUERY_KEYS.parking,
          site_id,
        ]);
    },
  });
}

function useDelete(
  site_id: number,
  { invalidateParkingQueries }: { invalidateParkingQueries?: boolean } = {}
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (parking_id: number) => {
      const { response, error } = await FetchClients.tenantsAndSpaces.DELETE(
        "/{site_id}/detached_parking/{parking_id}",
        {
          params: { path: { site_id, parking_id } },
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onMutate: async () => {
      // cancel queries
      await queryClient.cancelQueries({
        queryKey: [PARKING_QUERY_KEYS.detachedParking, site_id],
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        PARKING_QUERY_KEYS.detachedParking,
        site_id,
      ]);
      if (invalidateParkingQueries)
        await queryClient.invalidateQueries([
          PARKING_QUERY_KEYS.parking,
          site_id,
        ]);
    },
  });
}
