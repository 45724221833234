import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { startCase } from "lodash";
import classnames from "classnames";
import { last } from "ramda";
// @ts-expect-error - TS2305 - Module '"react-router-dom"' has no exported member 'Location'.
import type { Location } from "react-router-dom";

type Props = {
  url: string;
  location: Location;
  deactivated: boolean;
  title?: string;
};
// @ts-expect-error - TS2345 - Argument of type '({ url, location, deactivated, title }: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<RouteComponentProps<any, StaticContext, unknown>>'.
export default withRouter(({ url, location, deactivated, title }: Props) => {
  title = title || startCase(last(url.split("/")));
  return (
    <Link
      to={url}
      className={classnames(
        "nav-item sub-page",
        { deactivated: deactivated },
        {
          selected: location.pathname.includes(url),
        }
      )}
    >
      <h4 className={classnames("nav-item sub-page nav-title")}>
        <span className={classnames("nav-item sub-page nav-title dot")} />
        {title}
      </h4>
    </Link>
  );
});
