import * as React from "react";
import * as R from "ramda";
import classnames from "classnames";
import { Route, Link, useParams, Redirect } from "react-router-dom";
import moment from "moment";

import Select from "./Select";
import * as Icons from "../icons";
import { useAsync } from "../global_functions/useAsync";
import { getSiteEarliestParsedDates } from "../global_functions/postgrestApi";
import styles from "./MonthlyReportMonthSelector.module.scss";

import { isIP } from "../env";

const MonthlyReportMonthSelector = () => {
  const params = useParams();
  // @ts-expect-error - TS2339 - Property 'siteId' does not exist on type '{}'. | TS2339 - Property 'month' does not exist on type '{}'.
  const { siteId, month } = params;
  const [{ data: earliestMonth }, fetchEarliestMonth] = useAsync(
    () => getSiteEarliestParsedDates(siteId),
    [siteId]
  );

  React.useEffect(() => {
    fetchEarliestMonth();
  }, [fetchEarliestMonth]);

  const monthOptions = React.useMemo(() => {
    let sortedMonths = [moment().format("YYYY-MM")];
    if (earliestMonth != null) {
      const diff = moment().diff(earliestMonth, "month");
      sortedMonths = R.range(0, diff + 1).map((idx) =>
        moment().subtract(idx, "month").format("YYYY-MM")
      );
    }
    return sortedMonths.map((m) => ({
      id: m,
      label: m,
      className: styles.option_container,
      nodeLabel: (
        <Link
          className={classnames(styles.dropdown_row, {
            [styles.selected_row!]: m === month,
          })}
          to={`/site/${siteId}/monthly-report/${m}`}
          onClick={(e) => e.stopPropagation()}
        >
          <span>{moment(m).format("MMMM, YYYY")}</span>
        </Link>
      ),
    }));
  }, [month, earliestMonth, siteId]);

  const monthOutOfRange =
    earliestMonth != null &&
    month != null &&
    !moment(month).isBetween(earliestMonth, moment(), "month", "[]");

  if (month != null && earliestMonth == null) return null;

  if (month == null || earliestMonth == null || monthOutOfRange) {
    return (
      <Redirect
        to={`/site/${siteId}/monthly-report/${moment().format("YYYY-MM")}`}
      />
    );
  }

  return (
    <Select
      // @ts-expect-error - TS2769 - No overload matches this call.
      exrtra="month"
      label={
        <React.Fragment>
          {moment(month).format("MMMM, YYYY")}
          <Icons.Caret className={styles.label_caret} />
        </React.Fragment>
      }
      selected={month}
      openOnLabelClick
      className={styles.month_selector}
      onChange={() => {}}
      options={monthOptions}
    />
  );
};

export default () =>
  isIP ? (
    <Route
      path={"/site/:siteId/monthly-report/:month?"}
      component={MonthlyReportMonthSelector}
    />
  ) : null;
