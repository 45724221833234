import { Flow } from "flow-to-typescript-codemod";

import * as React from "react";
import Cookies from "js-cookie";
import * as R from "ramda";
import request from "../request";

import { snakeCase, kebabCase, startCase, camelCase } from "lodash";
import {
  formatDataObj,
  formatDataArray,
  camelCaseCipher,
  snakeCaseCipher,
  bugsnagPostgrestErrorHandler,
  bugsnagGeneralErrorHandler,
  recursiveCamelCaseCipher,
} from "./common";

import { getNameCopy, removeExtensionFromFilename, fileTypes } from "../util";
import type {
  Project,
  ProjectGrouping,
  NewProject,
  VersionHierarchyItem,
  Saving,
  ProjectType,
  System,
  NewEngineeringCalculation,
  EngineeringCalculation,
  ProjectAccountingType,
  ProjectAttachment,
  ProjectPlan,
  DateTime,
  ID,
  OptionGrouping,
  RawProjectConfidenceFactor,
  EnergyUtility,
  ProjectContractorCost,
  ProjectContractorCostCard,
  ProjectContractorCostSummary,
  CapexProjectStatus,
} from "../../types";

import { isIP } from "../../env";

const inboundProjectCipher = {
  id: "id",
  site_id: "siteId",
  name: "name",
  description: "description",
  project_type: "type",
  savings_category: "savingCategory",
  state_or_version_id: "parentStateId",
  parent_project_id: "parentProjectId",
  option_grouping: "optionGrouping",
  option_grouping_id: "optionGroupingId",
  accounting_om_savings_total: "omSavings",
  accounting_costs_total: "costs",
  accounting_rebates_total: "rebates",
  // @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type. | TS7006 - Parameter 'val' implicitly has an 'any' type.
  published_at: (key, val) => ({ published: val != null ? true : false }),
  implemented: "implemented",
  implemented_date: "implementedDate",
  is_group: "isGroup",
} as const;

const inboundProjectViewCipher = {
  ...inboundProjectCipher,
  energy_savings: "energySavings",
  net_yearly_savings: "netYearlySavings",
  net_yearly_ghg_savings: "netYearlyGhgSavings",
  net_cost: "netCost",
  payback_in_years: "payback",
  energy_reduction: "energyReduction",
  baseline_published: "baselinePublished",
  baseline_name: "baselineName",
  timelined: "timelined",
  selected_projects: "selectedProjectIds",
  budgeted_cost: "budgetedCost",
  final_cost: "finalCost",
  estimated_cost: "estimatedCost",
  source: "source",
  status: "status",
  planned_date: "plannedDate",
  completed_date: "completedDate",
  attachment_ids: "attachmentIds",
  budget_status: "budgetStatus",
  budget_notes: "budgetNotes",
  timeline_status: "timelineStatus",
  timeline_notes: "timelineNotes",
  owned_by: "ownedBy",
  planned_completion_month: "plannedCompletionMonth",
  is_energy_saver: "isEnergySaver",
  timeline_status_updated_at: "timelineStatusUpdatedAt",
  timeline_status_updated_by: "timelineStatusUpdatedBy",
  budget_status_updated_at: "budgetStatusUpdatedAt",
  budget_status_updated_by: "budgetStatusUpdatedBy",
  updated_by: "updatedBy",
  created_by: "createdBy",
} as const;

const inboundProjectInputCipher = {
  id: "id",
  project_id: "projectId",
  project_accounting_type: "type",
  title: "title",
  description: "description",
  cost_type: "costType",
  current_operations: "subDescription",
  utility_name: "utilityProvider",
  expiration: "rebateExpiration",
  number_of_rebates: "rebateAmount",
  rebate_rate: "rebateRate",
  analysis: "analysis",
  total: "total",
  sub_project_id: "subProjectId",
  parent_accounting_id: "parentInputId",
  selected: "isSelected",
} as const;

const inboundProjectAttachmentCipher = {
  id: "id",
  project_id: "projectId",
  accounting_type: "type",
  filename: "filename",
  created_at: "uploadedDate",
  hyperlink: "hyperlink",
  file_type: "fileType",
  has_file: "hasFile",
} as const;

const outboundProjectEncoder = {
  ...R.invertObj(inboundProjectCipher),
  // @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type. | TS7006 - Parameter 'val' implicitly has an 'any' type.
  published: (key, val) => ({
    published_at: val ? new Date().toISOString() : null,
  }),
  optionGroupingId: "option_grouping_id",
  estimatedCost: "estimated_cost",
  budgetedCost: "budgeted_cost",
  plannedDate: "planned_date",
  completedDate: "completed_date",
  finalCost: "final_cost",
  source: "source",
  status: "status",
  budgetStatus: "budget_status",
  budgetNotes: "budget_notes",
  ownedBy: "owned_by",
  plannedCompletionMonth: "planned_completion_month",
  timelineStatus: "timeline_status",
  timelineNotes: "timeline_notes",
  isEnergySaver: "is_energy_saver",
  timelineStatusUpdatedAt: "timeline_status_updated_at",
  timelineStatusUpdatedBy: "timeline_status_updated_by",
  budgetStatusUpdatedAt: "budget_status_updated_at",
  budgetStatusUpdatedBy: "budget_status_updated_by",
  updatedBy: "updated_by",
  createdBy: "created_by",
} as const;

const outboundProjectInputEncoder = R.invertObj(inboundProjectInputCipher);
delete outboundProjectInputEncoder.total;

const outboundProjectAttachmentEncoder = R.invertObj(
  inboundProjectAttachmentCipher
);

const getProjects = async (
  {
    siteId,
    siteIds,
    projectIds,
  }: {
    siteId?: string | number;
    siteIds?: Array<number>;
    projectIds?: Array<number>;
  },
  options: {
    includeGroupings?: boolean;
    recordId?: string | number;
    allowUnpublished?: boolean;
    implemented?: boolean;
    onlyGroupings?: boolean;
  } = {}
): Promise<Array<Project>> => {
  let queryParams: Record<string, any> = {};
  if (siteId) queryParams.site_id = `eq.${siteId}`;
  if (siteIds) queryParams.site_id = `in.(${siteIds.join(",")})`;

  if (projectIds) queryParams.id = `in.(${projectIds.join(",")})`;
  if (options.recordId)
    queryParams.state_or_version_id = `eq.${options.recordId}`;
  if (!options.includeGroupings) queryParams.is_group = `eq.false`;
  if (options.onlyGroupings) queryParams.is_group = `eq.true`;
  if (isIP && !options.allowUnpublished)
    queryParams.published_at = `not.is.null`;
  if (options.implemented === true) queryParams.implemented = `eq.true`;
  if (options.implemented === false) queryParams.implemented = `eq.false`;

  return request
    .get(`/rest/projects_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)

    .query(queryParams)
    .then(({ body }) => formatDataArray(body, inboundProjectViewCipher))
    .catch((e) => {
      bugsnagPostgrestErrorHandler(e);
      return [];
    });
};

const getProjectGroupingWithProjects = async ({
  siteId,
  siteIds,
}: {
  siteId?: string | number;
  siteIds?: Array<number>;
}): Promise<Array<ProjectGrouping>> => {
  let query: Record<string, any> = {};
  query.is_group = `eq.true`;
  if (siteId) query.site_id = `eq.${siteId}`;
  if (siteIds) query.site_id = `in.(${siteIds.join(",")})`;

  const groupings = await request
    .get(`/rest/projects_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query(query)
    .then(({ body }) => formatDataArray(body, inboundProjectViewCipher));

  // @ts-expect-error - TS7006 - Parameter 'g' implicitly has an 'any' type.
  const allSelectedProjectIds = groupings.flatMap((g) => g.selectedProjectIds);

  const allSelectedProjects = await getProjects({
    projectIds: allSelectedProjectIds,
  });

  // @ts-expect-error - TS7006 - Parameter 'g' implicitly has an 'any' type.
  return groupings.map((g) => ({
    project: g,
    selectedProjects: allSelectedProjects.filter((p) =>
      g.selectedProjectIds.includes(p.id)
    ),
  }));
};

const getProject = async (
  siteId: string | number,
  id: string | number,
  options: {
    allowUnpublished?: boolean;
  } = {}
): Promise<Project> => {
  const query: Record<string, any> = {};
  query.id = `eq.${id}`;
  if (isIP && !options.allowUnpublished) query.published_at = `not.is.null`;
  return request
    .get(`/rest/projects_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .query(query)
    .then(({ body }) => formatDataObj(body, inboundProjectViewCipher));
};

const saveProject = async (
  siteId: string | number,
  recordId: string | number,
  project: NewProject
): Promise<void> => {
  const projects = await getProjects({ siteId }, { recordId });
  if (project.name) {
    project.name = getNameCopy(
      project.name,
      projects.map((p) => p.name)
    );
  }
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .post(`/rest/projects`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      site_id: siteId,
      state_or_version_id: recordId,
      ...formatDataObj(project, outboundProjectEncoder, { allowNulls: true }),
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const updateProject = async (
  projectId: string | number,
  props: Partial<Project>
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .patch(`/rest/projects`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${projectId}` })
    .send(formatDataObj(props, outboundProjectEncoder, { allowNulls: true }))
    .catch(bugsnagPostgrestErrorHandler);
};

const postAdditionalProjectOptOut = async (siteId: number, optOut: boolean) =>
  request
    .post(`/rest/additional_project_opt_out`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      site_id: siteId,
      opt_out: optOut,
    });

export const getCapexProjectStatus = (
  siteId: string | number
): Promise<CapexProjectStatus> =>
  request
    .get(`/rest/project_capital_plan_complete`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}` })
    .then(({ body }) => formatDataArray(body, camelCaseCipher))
    .then((data) => data[0]);

export const updateCapexProjectStatus = (
  status: Partial<CapexProjectStatus>
): Promise<CapexProjectStatus> =>
  request
    .post(`/rest/project_capital_plan_complete`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation,resolution=merge-duplicates")
    .query({ on_conflict: "site_id" })
    .send(formatDataObj(status, snakeCaseCipher))
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher));

const formatInboundCF = (row: any) => ({
  ...formatDataObj(row, camelCaseCipher, { allowNulls: true }),
  utility: row.utility ? camelCase(row.utility) : null,
  system: row.system ? kebabCase(row.system) : null,
});

// @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
const formatOutboundCF = (row) => ({
  ...formatDataObj(row, snakeCaseCipher, { allowNulls: true }),
  utility: row.utility ? snakeCase(row.utility) : null,
  system: row.system ? snakeCase(row.system) : null,
});

export const getConfidenceFactors = async ({
  siteId,
  projectId,
  stateOrVersionId,
}: {
  siteId: string | number;
  projectId?: number;
  stateOrVersionId?: number;
}): Promise<RawProjectConfidenceFactor[]> => {
  try {
    const query: Record<string, any> = {};
    query.site_id = `eq.${siteId}`;
    if (projectId) query.project_id = `eq.${projectId}`;
    if (stateOrVersionId) query.state_or_version_id = `eq.${stateOrVersionId}`;

    const { body = [] } = await request
      .get(`/rest/projects_confidence_factors`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .set("Prefer", "resolution=merge-duplicates,return=representation")
      .query(query);

    return body.map(formatInboundCF);
  } catch (e: any) {
    bugsnagPostgrestErrorHandler(e);
    throw e;
  }
};

export const updateConfidenceFactor = async (cf: {
  siteId: number;
  projectId: number | undefined;
  stateOrVersionId: number | undefined;
  confidenceFactor: number;
  utility: EnergyUtility | null | undefined;
  system: System | null | undefined;
}) => {
  try {
    const query: Record<string, any> = {};
    query.system = "is.null";
    query.utility = "is.null";

    if (cf.utility) query.utility = `eq.${snakeCase(cf.utility)}`;
    if (cf.system) query.system = `eq.${snakeCase(cf.system)}`;

    if (cf.projectId) query.project_id = `eq.${cf.projectId}`;

    if (cf.stateOrVersionId)
      query.state_or_version_id = `eq.${cf.stateOrVersionId}`;

    const send = formatOutboundCF(cf);

    const { body } = await request
      .patch(`/rest/projects_confidence_factors`)
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .set("Prefer", "return=representation")
      .query(query)
      .send(send);

    if (body.length === 0) {
      await request
        .post(`/rest/projects_confidence_factors`)
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .send(send);
    }

    return;
  } catch (e: any) {
    bugsnagPostgrestErrorHandler(e);
    throw e;
  }
};

const deleteProject = async (projectId: string | number): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .delete(`/rest/projects`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${projectId}` })
    .catch(bugsnagPostgrestErrorHandler);
};

const deleteProjectsByStateOrVersionId = async (
  state_or_version_id: string | number
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .delete(`/rest/projects`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ state_or_version_id: `eq.${state_or_version_id}` })
    .catch(bugsnagPostgrestErrorHandler);
};

const getProjectInputs = async (
  {
    projectIds,
    projectInputIds,
  }: {
    projectIds?: Array<number> | string | number;
    projectInputIds?: Array<number> | string | number;
  },
  types: {
    omSavings?: boolean;
    costs?: boolean;
    rebates?: boolean;
  } = {
    omSavings: true,
    costs: true,
    rebates: true,
  }
): Promise<Array<Saving>> => {
  const query: Record<string, any> = {};
  query.order = "id";
  if (Array.isArray(projectIds)) {
    query.project_id = `in.(${projectIds.join(",")})`;
  } else if (projectIds && ["string", "number"].includes(typeof projectIds)) {
    query.project_id = `eq.${projectIds}`;
  }
  if (Array.isArray(projectInputIds)) {
    query.id = `in.(${projectInputIds.join(",")})`;
  } else if (
    projectInputIds &&
    ["string", "number"].includes(typeof projectInputIds)
  ) {
    query.id = `eq.${projectInputIds}`;
  }
  query.project_accounting_type = `in.(${R.keys(types)
    .filter((type) => types[type])
    .map(snakeCase)
    .join(",")})`;
  return request
    .get(`/rest/project_accounting_summary`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query(query)
    .then(({ body }) => {
      const formattedRows = formatDataArray(body, inboundProjectInputCipher, {
        allowNulls: true,
      });
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      return formattedRows.map((row) => {
        return {
          ...row,
          type: camelCase(row.type),
          costType: row.costType
            ? row.costType.toLowerCase().replace(/\s/g, "") //remove spaces
            : null,
        };
      });
    });
};

const saveProjectInput = async (
  projectId: string | number,
  projectInputType: ProjectAccountingType
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | { id: number; savings_id: number; amount: number; rate: string; }' is not assignable to type 'void'.
  return request
    .post(`/rest/project_accounting`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .set("Accept", "application/vnd.pgrst.object+json")
    .send({
      project_id: projectId,
      project_accounting_type: snakeCase(projectInputType),
    })
    .then(({ body }) => saveProjectInputInformations(body.id))
    .catch(bugsnagPostgrestErrorHandler);
};

const copyProjectInputToGroup = async (
  { analysis, id, ...input }: Saving,
  projectGroupId: number
): Promise<void> => {
  const newInput = {
    ...input,
    costType: input.costType
      ? startCase(input.costType).split(" ").join(" + ")
      : null,
    subProjectId: input.projectId,
    projectId: projectGroupId,
    type: snakeCase(input.type),
    parentInputId: id,
    isSelected: true,
  } as const;

  const savings_id = await request
    .post(`/rest/project_accounting`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .set("Accept", "application/vnd.pgrst.object+json")
    .send(
      formatDataObj(newInput, outboundProjectInputEncoder, { allowNulls: true })
    )
    .then(({ body }) => body.id)
    .catch(bugsnagPostgrestErrorHandler);

  const inputInformations = R.sortBy(R.prop("id"))(analysis).map((row) => ({
    savings_id,

    amount: row.amount,

    rate: row.rate,
  }));

  await request
    .post(`/rest/project_accounting_informations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(inputInformations)
    .catch(bugsnagPostgrestErrorHandler);
};

const updateProjectInput = async (
  inputId: string | number,
  props: Partial<
    Flow.Diff<
      Saving,
      {
        id: number;
      }
    >
  >
): Promise<void> => {
  if (props.costType) {
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'ProjectCostType | null | undefined'.
    props.costType = startCase(props.costType).split(" ").join(" + ");
  }
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .patch(`/rest/project_accounting`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${inputId}` })
    .send(
      formatDataObj(props, outboundProjectInputEncoder, { allowNulls: true })
    )
    .catch(bugsnagPostgrestErrorHandler);
};

const deleteProjectInput = async (inputId: string | number): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .delete(`/rest/project_accounting`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${inputId}` })
    .catch(bugsnagPostgrestErrorHandler);
};

const formatInboundEnergyCalculation = (data: any) => ({
  ...formatDataObj(data, camelCaseCipher, {
    allowNulls: true,
    allowUnknownKeys: true,
  }),
  utility: camelCase(data.utility),
  system: kebabCase(data.system),
});

const getEngineeringCalculations = async ({
  propName,
  id,
}: {
  propName: "projectId" | "stateOrVersionId";
  id: string | number;
}): Promise<Array<EngineeringCalculation>> =>
  request
    .get(`/rest/engineering_calculations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      [snakeCase(propName)]: `eq.${id}`,
      order: "id",
    })
    .then((result) => result.body.map(formatInboundEnergyCalculation));

const getEngineeringCalculationsInKWh = async (
  siteId: string | number,
  options?: {
    type: "stateOrVersion" | "project";
  } | null
): Promise<Array<EngineeringCalculation>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  query.order = "id";
  if (options?.type === "stateOrVersion") {
    query.state_or_version_id = "not.is.null";
  } else if (options?.type === "project") {
    query.project_id = "not.is.null";
  }
  return request
    .get(`/rest/engineering_calculations_in_kwh`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query(query)
    .then((result) => result.body.map(formatInboundEnergyCalculation));
};

const saveEngineeringCalculation = async (
  {
    propName,
    id,
  }: {
    propName: "projectId" | "stateOrVersionId";
    id: string | number;
  },
  calculation: NewEngineeringCalculation
): Promise<EngineeringCalculation> => {
  return request
    .post(`/rest/engineering_calculations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({
      ...formatDataObj(
        {
          ...calculation,
          [propName]: id,
        },
        snakeCaseCipher,
        { allowUnknownKeys: true }
      ),
      utility: snakeCase(calculation.utility),
      system: snakeCase(calculation.system),
    })
    .then((result) => formatInboundEnergyCalculation(result.body[0]))
    .catch(bugsnagPostgrestErrorHandler);
};

const updateEngineeringCalculation = async (
  id: number,
  updates: Partial<EngineeringCalculation>
): Promise<EngineeringCalculation> => {
  return request
    .patch(`/rest/engineering_calculations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .set("Accept", "application/vnd.pgrst.object+json")
    .query({ id: `eq.${id}` })
    .send({
      ...formatDataObj(updates, snakeCaseCipher, {
        allowNulls: true,
        allowUnknownKeys: true,
      }),
      utility: updates.utility ? snakeCase(updates.utility) : undefined,
      system: updates.system ? snakeCase(updates.system) : undefined,
    })
    .then(({ body }) => formatInboundEnergyCalculation(body))
    .catch(bugsnagPostgrestErrorHandler);
};

const deleteEngineeringCalculation = async (id: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .delete(`/rest/engineering_calculations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${id}` })
    .catch(bugsnagPostgrestErrorHandler);

const getProjectTypes = async (): Promise<Array<ProjectType>> =>
  request
    .get(`/rest/project_types`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    // @ts-expect-error - TS7006 - Parameter 'r' implicitly has an 'any' type.
    .then(({ body }) => body.map((r) => r.name))
    .catch(bugsnagPostgrestErrorHandler);

const getSystems = async (): Promise<Array<System>> =>
  request
    .get(`/rest/systems`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    // @ts-expect-error - TS7006 - Parameter 'r' implicitly has an 'any' type.
    .then(({ body }) => body.map((r) => r.name))
    .catch(bugsnagPostgrestErrorHandler);

const saveProjectOptionGroup = async (
  siteId: string | number,
  stateOrVersionId: string | number,
  name: string
): Promise<OptionGrouping> => {
  return request
    .post(`/rest/project_option_groupings`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({ site_id: siteId, state_or_version_id: stateOrVersionId, name })
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);
};

const getOptionGroups = async (
  siteId: string | number,
  stateOrVersionId: string
): Promise<Array<OptionGrouping>> =>
  request
    .get(`/rest/project_option_groupings`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      state_or_version_id: `eq.${stateOrVersionId}`,
    })
    .then(({ body }) => formatDataArray(body, camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

const updateOptionGroup = async (
  optionId: string | number,
  name: string
): Promise<OptionGrouping> =>
  request
    .patch(`/rest/project_option_groupings`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query({ id: `eq.${optionId}` })
    .send({ name })
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

const deleteOptionGroup = async (optionId: string | number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .delete(`/rest/project_option_groupings`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${optionId}` })
    .catch(bugsnagPostgrestErrorHandler);

const saveProjectInputInformations = async (
  inputId: string | number
): Promise<{
  id: number;
  savings_id: number;
  amount: number;
  rate: string;
}> => {
  return request
    .post(`/rest/project_accounting_informations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({ savings_id: inputId })
    .then(({ body }) => body[0])
    .catch(bugsnagPostgrestErrorHandler);
};

const updateProjectInputInformations = async (
  inputId: string | number,
  props: {
    amount?: number | null;
    rate?: string | null;
  }
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .patch(`/rest/project_accounting_informations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${inputId}` })
    .send(props)
    .catch(bugsnagPostgrestErrorHandler);
};

const deleteProjectInputInformations = async (
  inputId: string | number
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .delete(`/rest/project_accounting_informations`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${inputId}` })
    .catch(bugsnagPostgrestErrorHandler);
};

// TODO: this probably doesnt belong here, need to find somewhere more appropriate to put it
export const getVersionOption = (item: VersionHierarchyItem) => ({
  id: item.id,
  className: "building-state-hierarchy-option",
  style: {
    "--version-color": item.color,
  },
  label: item.name,
  nodeLabel: (
    <React.Fragment>
      {item.name}
      <span className="building-state-hierarchy-option--version-type">
        {item.versionTypeLabel}
      </span>
    </React.Fragment>
  ),
});

const getProjectAttachments = async (
  projectId: string | number
): Promise<Array<ProjectAttachment>> => {
  return request
    .get(`/rest/project_attachments`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ project_id: `eq.${projectId}`, order: "created_at" })
    .then((result) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      result.body.map((row) => ({
        ...formatDataObj(row, inboundProjectAttachmentCipher),
        type: camelCase(row.accounting_type),
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly ".pdf": ".pdf"; readonly "application/pdf": ".pdf"; readonly ".docx": ".docx"; readonly "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx"; }'.
        fileType: fileTypes[row.file_type],
      }))
    )
    .catch(bugsnagPostgrestErrorHandler);
};

const updateProjectAttachment = async (
  attachmentId: string | number,
  props: Partial<ProjectAttachment>
): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'ProjectAccountingType | "projectScope" | null | undefined'.
  if (props.type) props.type = snakeCase(props.type);
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .patch(`/rest/project_attachments`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${attachmentId}` })
    .send(
      formatDataObj(props, outboundProjectAttachmentEncoder, {
        allowNulls: true,
      })
    )
    .catch(bugsnagPostgrestErrorHandler);
};

const saveProjectAttachment = async (
  // prettier-ignore
  props: Flow.Diff<ProjectAttachment, {
    id: ID,
    uploadedDate: DateTime,
    hasFile: boolean
  }>
): Promise<ProjectAttachment> => {
  return request
    .post(`/rest/project_attachments`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send(formatDataObj(props, outboundProjectAttachmentEncoder))
    .then(({ body }) => body[0]) // response id used for subsequent file upload
    .catch(bugsnagPostgrestErrorHandler);
};

const uploadProjectAttachment = async (
  attachmentId: number,
  file: File,
  newEntry?: boolean
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response | undefined' is not assignable to type 'void'.
  request
    .post("/uploads")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ type: "project_attachment", id: attachmentId })
    .then(({ body }) =>
      request
        .put(body.url) // received pre-signed S3 url from backend to upload to
        .set("Content-Type", file.type)
        .send(file)
    )
    .then(() =>
      request
        .patch(`/rest/project_attachments`)
        .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
        .query({ id: `eq.${attachmentId}` })
        .send({
          filename: removeExtensionFromFilename(file.name),
          file_type: file.type,
          has_file: true,
        })
        .catch(bugsnagPostgrestErrorHandler)
    )
    .catch((e) => {
      bugsnagGeneralErrorHandler(e);
      if (newEntry) return deleteProjectAttachment(attachmentId);
    });

const deleteProjectAttachment = async (id: string | number): Promise<void> => {
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  return request
    .delete(`/rest/project_attachments`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${id}` })
    .catch(bugsnagPostgrestErrorHandler);
};

const formatProjectPlan = ({
  selected_projects = [],
  ...plan
}): ProjectPlan => {
  const selectedProjectYears: Record<string, any> = {};

  for (const { project_id, year } of selected_projects) {
    selectedProjectYears[project_id] = year;
  }

  return {
    ...formatDataObj(plan, camelCaseCipher),
    selectedProjects: recursiveCamelCaseCipher(selected_projects),
    selectedProjectYears,
  };
};

const createNewProjectPlan = async (
  stateOrVersionId: string | number
): Promise<ProjectPlan> =>
  // @ts-expect-error - TS2322 - Type 'void | ProjectPlan' is not assignable to type 'ProjectPlan'.
  request
    .post("/rest/project_plans")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .set("Accept", "application/vnd.pgrst.object+json")
    .send({ state_or_version_id: stateOrVersionId })
    .then((response) => formatProjectPlan(response.body))
    .catch(bugsnagPostgrestErrorHandler);

const getProjectPlans = async (
  stateOrVersionId: string | number | Array<string | number>
): Promise<Array<ProjectPlan>> => {
  const plans = await request
    .get("/rest/project_plans_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      state_or_version_id: Array.isArray(stateOrVersionId)
        ? `in.(${stateOrVersionId.join(",")})`
        : `eq.${stateOrVersionId}`,
    })
    .then((response) => response.body.map(formatProjectPlan))
    .catch(bugsnagPostgrestErrorHandler);

  if (
    // @ts-expect-error - TS7006 - Parameter 'plan' implicitly has an 'any' type.
    !plans.find((plan) => !plan.launched) &&
    !Array.isArray(stateOrVersionId)
  ) {
    plans.push(await createNewProjectPlan(stateOrVersionId));
  }

  // @ts-expect-error - TS2322 - Type 'unknown[]' is not assignable to type 'ProjectPlan[]'. | TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
  return R.sortBy((plan) => plan.launchedAt || plan.updatedAt, plans).reverse();
};

const getProjectPlansForSite = async (
  siteId: string | number
): Promise<Array<ProjectPlan>> => {
  const plans = await request
    .get("/rest/project_plans_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}` })
    .then((response) => response.body.map(formatProjectPlan))
    .catch(bugsnagPostgrestErrorHandler);

  // @ts-expect-error - TS2322 - Type 'unknown[]' is not assignable to type 'ProjectPlan[]'. | TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
  return R.sortBy((plan) => plan.launchedAt || plan.updatedAt, plans).reverse();
};

const getProjectPlan = async (planId: string | number): Promise<ProjectPlan> =>
  // @ts-expect-error - TS2322 - Type 'void | ProjectPlan' is not assignable to type 'ProjectPlan'.
  request
    .get("/rest/project_plans_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .query({ id: `eq.${planId}` })
    .then((response) => formatProjectPlan(response.body))
    .catch(bugsnagPostgrestErrorHandler);

const getIsProjectTimelined = (projectId: number): Promise<boolean> =>
  // @ts-expect-error - TS2322 - Type 'Promise<boolean | void>' is not assignable to type 'Promise<boolean>'.
  request
    .get("/rest/project_plan_selected_projects")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ project_id: `eq.${projectId}` })
    .then(({ body }) => body.length > 0)
    .catch(bugsnagPostgrestErrorHandler);

const resetPlan = async (plan: ProjectPlan): Promise<void> => {
  const [oldPlan] = await request
    .get("/rest/project_plans")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      state_or_version_id: `eq.${plan.stateOrVersionId}`,
      limit: 1,
      order: "launched_at.desc.nullslast",
      launched: "is.true",
    })
    .then((response) => response.body.map(formatProjectPlan))
    .catch(bugsnagPostgrestErrorHandler);

  await request
    .delete("/rest/project_plan_selected_projects")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ project_plan_id: `eq.${plan.id}` })
    .catch(bugsnagPostgrestErrorHandler);

  if (oldPlan) {
    const oldProjects = await request
      .get("/rest/project_plan_selected_projects")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ project_plan_id: `eq.${oldPlan.id}` })
      .then((response) => response.body)
      .catch(bugsnagPostgrestErrorHandler);

    await request
      .post("/rest/project_plan_selected_projects")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      // @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
      .send(oldProjects.map((data) => ({ ...data, project_plan_id: plan.id })))
      .catch(bugsnagPostgrestErrorHandler);
  }
};

const nameProjectPlan = async (
  plan: ProjectPlan,
  name: string
): Promise<void> => {
  await request
    .patch(`/rest/project_plans`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${plan.id}` })
    .send({ name, launched: true })
    .catch(bugsnagPostgrestErrorHandler);

  if (!plan.launched) {
    const newPlan = await createNewProjectPlan(plan.stateOrVersionId);
    await resetPlan(newPlan);
  }
};

const deleteProjectPlan = async (plan: ProjectPlan): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .delete(`/rest/project_plans`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${plan.id}` })
    .catch(bugsnagPostgrestErrorHandler);

const saveProjectPlanSelectedProject = async (
  planId: string | number,
  stateOrVersionId: string | number,
  projectId: string | number,
  year: number
): Promise<void> => {
  await request
    .post("/rest/project_plan_selected_projects")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "resolution=merge-duplicates")
    .send({
      project_plan_id: planId,
      state_or_version_id: stateOrVersionId,
      project_id: projectId,
      year,
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const deleteProjectPlanSelectedProject = async (
  planId: string | number,
  projectId: string | number
): Promise<void> => {
  await request
    .delete("/rest/project_plan_selected_projects")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      project_plan_id: `eq.${planId}`,
      project_id: `eq.${projectId}`,
    })
    .catch(bugsnagPostgrestErrorHandler);
};

const saveProjectGroupSelectedProjects = async ({
  projectGroupId: project_grouping_id,
  projectId: project_id,
  stateOrVersionId: state_or_version_id,
}: {
  projectGroupId: number;
  projectId: number;
  stateOrVersionId: number;
}): Promise<Array<number>> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'number[]'.
  await request
    .post("/rest/project_grouping_selected_projects")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ project_grouping_id, project_id, state_or_version_id })
    .catch(bugsnagPostgrestErrorHandler);

const deleteProjectGroupSelectedProjects = async (
  projectGroupId: string | number,
  projectId: string | number
): Promise<Array<number>> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'number[]'.
  await request
    .delete("/rest/project_grouping_selected_projects")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      project_grouping_id: `eq.${projectGroupId}`,
      project_id: `eq.${projectId}`,
    })
    .catch(bugsnagPostgrestErrorHandler);

const getProjectGroupDeselectedProjectInputs = async (
  projectGroupId: string | number
): Promise<Array<number>> =>
  await request
    .get("/rest/project_grouping_deselected_project_accountings")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      project_grouping_id: `eq.${projectGroupId}`,
      select: "project_accounting_id",
    })
    .then(({ body }) =>
      // @ts-expect-error - TS7031 - Binding element 'project_accounting_id' implicitly has an 'any' type.
      body.map(({ project_accounting_id }) => project_accounting_id)
    )
    .catch(bugsnagPostgrestErrorHandler);

const saveProjectGroupDeselectedProjectInputs = async ({
  projectGroupId: project_grouping_id,
  projectId: project_id,
  projectInputId: project_accounting_id,
}: {
  projectGroupId: number;
  projectId: number;
  projectInputId: number;
}): Promise<Array<number>> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'number[]'.
  await request
    .post("/rest/project_grouping_deselected_project_accountings")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ project_grouping_id, project_id, project_accounting_id })
    .catch(bugsnagPostgrestErrorHandler);

const deleteProjectGroupDeselectedProjectInputs = async (
  projectGroupId: string | number,
  projectInputId: string | number
): Promise<Array<number>> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'number[]'.
  await request
    .delete("/rest/project_grouping_deselected_project_accountings")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      project_grouping_id: `eq.${projectGroupId}`,
      project_accounting_id: `eq.${projectInputId}`,
    })
    .catch(bugsnagPostgrestErrorHandler);

export const getProjectContractorCostsSummary = (
  projectId: string | number
): Promise<ProjectContractorCostSummary[]> =>
  request
    .get("/rest/project_accounting_contractor_cost_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ project_id: `eq.${projectId}`, order: "id" })
    .then(({ body }) => recursiveCamelCaseCipher(body));

export const createProjectContractorCostCard = async (
  contractorCostId: string | number
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Response' is not assignable to type 'void'.
  request
    .post("/rest/project_accounting_contractor_cost_cards")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({ contractor_cost_id: contractorCostId });

export const createProjectContractorCost = async (
  projectId: string | number
): Promise<void> => {
  const contractorCost = await request
    .post("/rest/project_accounting_contractor_costs")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send({ project_id: projectId })
    .then(({ body }) => recursiveCamelCaseCipher(body[0]));

  await createProjectContractorCostCard(contractorCost.id);
};

export const updateProjectContractorCost = (
  contractorCostId: string | number,
  props: Partial<ProjectContractorCost>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/project_accounting_contractor_costs")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${contractorCostId}` })
    .send(formatDataObj(props, snakeCaseCipher, { allowNulls: true }));

export const updateProjectContractorCostCard = (
  contractorCostCardId: string | number,
  props: Partial<ProjectContractorCostCard>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/project_accounting_contractor_cost_cards")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${contractorCostCardId}` })
    .send(formatDataObj(props, snakeCaseCipher, { allowNulls: true }));

export const deselectContractorCosts = (
  projectId: string | number
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/project_accounting_contractor_costs")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ project_id: `eq.${projectId}` })
    .send({ selected: false });

export const deleteProjectContractorCostCard = (
  contractorCostCardId: string | number
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/project_accounting_contractor_cost_cards")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${contractorCostCardId}` });

export const deleteProjectContractorCost = (
  contractorCostId: string | number
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/project_accounting_contractor_costs")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${contractorCostId}` });

export {
  getProjects,
  getProject,
  getProjectGroupingWithProjects,
  saveProject,
  postAdditionalProjectOptOut,
  getProjectInputs,
  updateProject,
  deleteProject,
  deleteProjectsByStateOrVersionId,
  getOptionGroups,
  updateOptionGroup,
  deleteOptionGroup,
  saveProjectInput,
  copyProjectInputToGroup,
  updateProjectInput,
  deleteProjectInput,
  getEngineeringCalculations,
  getEngineeringCalculationsInKWh,
  saveEngineeringCalculation,
  updateEngineeringCalculation,
  deleteEngineeringCalculation,
  getProjectTypes,
  getSystems,
  saveProjectOptionGroup,
  saveProjectInputInformations,
  updateProjectInputInformations,
  deleteProjectInputInformations,
  getProjectAttachments,
  updateProjectAttachment,
  saveProjectAttachment,
  uploadProjectAttachment,
  deleteProjectAttachment,
  getProjectPlans,
  getProjectPlansForSite,
  getProjectPlan,
  nameProjectPlan,
  deleteProjectPlan,
  resetPlan,
  saveProjectPlanSelectedProject,
  deleteProjectPlanSelectedProject,
  saveProjectGroupSelectedProjects,
  deleteProjectGroupSelectedProjects,
  getProjectGroupDeselectedProjectInputs,
  saveProjectGroupDeselectedProjectInputs,
  deleteProjectGroupDeselectedProjectInputs,
  getIsProjectTimelined,
};
