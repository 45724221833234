import * as React from "react";

export type UnseenContentType = {
  hasUnseenNotification?: boolean;
};

export type PagesToTrack = "scheduleNotifications";

type UnseenContentContextType = {
  unseenContent: UnseenContentType | null | undefined;
  initializeUserUnseenContent: (
    siteId: number
  ) => undefined | Promise<undefined>;
  refreshUnseenContent: (
    siteId: number
  ) => Promise<UnseenContentType> | UnseenContentType | null | undefined;
  updatePageViewTimestamp: (
    siteId: number,
    page: PagesToTrack
  ) => Promise<void>;
};

// @ts-expect-error - TS2322 - Type 'Context<{ refreshUnseenContent: () => null; updatePageViewTimestamp: (siteId: number, page: "scheduleNotifications") => Promise<void>; unseenContent: null; initializeUserUnseenContent: (siteId: number) => void; }>' is not assignable to type 'Context<UnseenContentContextType>'.
const UnseenContent: React.Context<UnseenContentContextType> =
  React.createContext({
    refreshUnseenContent: () => null,
    updatePageViewTimestamp: async (siteId, page) => {},
    unseenContent: null,
    initializeUserUnseenContent: (siteId) => {},
  });

export default UnseenContent;
