import React from "react";
import classnames from "classnames";

import styles from "./Loader.module.scss";

function getStyles(props: {
  align?: "right" | "center" | "left";
  style?: Record<any, any>;
}) {
  const { align, style = {} } = props;

  switch (align) {
    case "right":
      return {
        ...style,
        justifyContent: "flex-end",
      };
    case "left":
      return {
        ...style,
        justifyContent: "flex-start",
      };
    case "center":
      return {
        ...style,
        justifyContent: "center",
      };
    default:
      return style;
  }
}

interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean;
  size: number;
  color?: string;
  blurBackground?: boolean;
  align?: "right" | "center" | "left";
  dark?: boolean;
  inline?: boolean;
}

const Loader = (props: LoaderProps) => {
  const {
    show,
    color = "#4c516a",
    size,
    blurBackground,
    dark,
    inline,
    ...rest
  } = props;
  const style = getStyles(rest);

  const svgColor = dark ? "#FFFFFF" : color;

  if (show) {
    return (
      <div
        data-testid="loading-icon"
        className={classnames(styles.outer_container, {
          [styles.inline!]: inline,
        })}
        {...rest}
        style={style}
      >
        {blurBackground && <div className={styles.blur_background} />}
        <div
          className={classnames(styles.svg_container, {
            [styles.inline!]: inline,
          })}
          // @ts-expect-error - TS2322 - Type '{ children: Element; className: string; width: number; style: { height: number; width: number; }; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>'.
          width={size}
          style={{ height: size, width: size }}
        >
          <svg
            className={styles.circular}
            style={{ height: size, width: size }}
            viewBox="25 25 50 50"
          >
            <circle
              className={styles.circle}
              color={svgColor}
              stroke={svgColor}
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
    );
  }
  return null;
};

Loader.defaultProps = {
  show: true,
  size: 100,
};

export default Loader;
