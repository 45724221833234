import * as React from "react";
import { useParams } from "react-router-dom";

import UnitsContext from "../context/UnitsContext";
import { Modal, Input, Select } from "../global_components";
import type {
  NewEngineeringCalculation,
  EngineeringCalculation,
  EnergyUtility,
} from "../types";
import {
  sortedEnergyUtilities,
  sortedSystems,
  unitsForUtility,
  energyUtilitiesById,
  systemsById,
} from "../global_functions/utilities";

import styles from "./EngineeringCalculationModal.module.scss";

type Props = {
  open: boolean;
  onSave: (
    calculation: NewEngineeringCalculation
  ) => undefined | Promise<undefined>;
  onCancel: () => void;
  calculation: Partial<EngineeringCalculation> | null | undefined;
};

const EngineeringCalculationModal = ({
  open,
  onSave,
  onCancel,
  calculation,
  ...props
}: Props) => {
  // @ts-expect-error - TS2339 - Property 'siteId' does not exist on type '{}'.
  const siteId = Number(useParams().siteId);
  const { getUnitOptions } = React.useContext(UnitsContext);

  const [loading, setLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState<any>(null);

  const [description, setDescription] = React.useState<any>(null);
  const [utility, setUtility] = React.useState<
    EnergyUtility | null | undefined
  >(null);
  const [system, setSystem] = React.useState<any>(null);
  const [unitId, setUnitId] = React.useState<any>(null);

  React.useEffect(() => {
    setDescription(calculation?.description);
    setUtility(calculation?.utility);
    setSystem(calculation?.system);
    setUnitId(calculation?.unitId);
  }, [calculation]);

  React.useEffect(() => {
    if (serverError) {
      const timeout = setTimeout(() => setServerError(null), 4000);

      return () => clearTimeout(timeout);
    }
  }, [serverError]);

  const unitOptions = utility ? getUnitOptions(unitsForUtility(utility)) : [];

  return (
    <Modal
      open={open}
      title={`${
        calculation && calculation.id ? "Edit" : "Add New"
      } Engineering Calculation`}
      buttons
      // @ts-expect-error - TS2322 - Type '(() => void) | null' is not assignable to type '((arg1?: MouseEvent<HTMLDivElement, MouseEvent> | null | undefined) => Promise<undefined> | null | undefined) | undefined'.
      onCancel={loading ? null : onCancel}
      onSave={async () => {
        setLoading(true);

        if (description && utility && system && unitId) {
          try {
            await onSave({ siteId, description, utility, system, unitId });
          } catch (err: any) {
            setServerError(err.message);
          }
        }

        setLoading(false);
      }}
      disableSave={Boolean(
        loading ||
          !open ||
          !description ||
          (calculation &&
            description === calculation.description &&
            utility === calculation.utility &&
            system === calculation.system &&
            unitId === calculation.unitId) ||
          !(utility && energyUtilitiesById[utility]) ||
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ summary: SystemCategory; other: SystemCategory; lighting: SystemCategory; equipment: SystemCategory; fans: SystemCategory; ... 4 more ...; "domestic-hot-water": SystemCategory; }'.
          !(system && systemsById[system]) ||
          !(
            unitId &&
            unitOptions.find(({ id }) => Number(id) === Number(unitId))
          )
      )}
      {...props}
    >
      <div className={styles.engineering_calculations__modal}>
        <Input
          label="Description"
          value={description || ""}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter Description"
          gray
          rounded
        />
        <Select
          label="Category"
          placeholder="Select a Category"
          selected={system}
          onChange={(item) => setSystem(item && item.id)}
          options={sortedSystems.map(({ id, title }) => ({ id, label: title }))}
        />
        <Select
          label="Select Utility"
          placeholder="Select a Utility"
          selected={utility}
          onChange={(item) => setUtility(item && item.id)}
          options={sortedEnergyUtilities.map(({ id, title }) => ({
            id,
            label: title,
          }))}
        />
        <Select
          label="Unit"
          placeholder={utility ? "Select a Unit" : "Select a Utility First"}
          selected={unitId}
          onChange={(item) => setUnitId(item?.id)}
          options={unitOptions}
          disabled={!utility}
        />
        {serverError && <div className={styles.modal_error}>{serverError}</div>}
      </div>
    </Modal>
  );
};

export default EngineeringCalculationModal;
