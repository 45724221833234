import classnames from "classnames";
import { ComponentPropsWithoutRef, useCallback, useContext } from "react";
import { Include, SemanticColors } from "../../../colors";
import CheckboxLabel from "../Checkbox/CheckboxLabel";
import { RadioGroupContext } from "../RadioGroup/RadioGroupContext";
import styles from "./Radio.module.scss";
import { TextVariants } from "@src/straps/base/type/Text/Text";

interface RadioProps extends Omit<ComponentPropsWithoutRef<"input">, "size"> {
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  size?: "default" | "small";
  radioColor?: Include<SemanticColors, "primary" | "positive">;
  labelColor?: ComponentPropsWithoutRef<typeof CheckboxLabel>["labelColor"];
  labelVariant?: TextVariants;
  labelChildren?: JSX.Element | null;
}

const sizeMap = {
  default: {
    radioClassName: "pr-2 text-base font-bold",
    labelClassName: "mr-6 text-base font-bold",
  },
  small: {
    radioClassName: "pr-1 text-sm",
    labelClassName: "ml-1.5 text-xs font-medium",
  },
};

const radioColorMap = {
  primary: {
    checkedClassName: "text-straps-primary",
    uncheckedClassName: "text-straps-tertiary",
    checkedEnabledClassName: "hover:text-straps-primary-hover",
    uncheckedEnabledClassName: "hover:text-straps-secondary",
  },
  positive: {
    checkedClassName: "text-straps-positive",
    uncheckedClassName: "text-straps-positive-bg",
    checkedEnabledClassName: "hover:text-straps-positive-hover",
    uncheckedEnabledClassName: "hover:text-straps-positive-hover",
  },
};

const Radio = ({
  value,
  checked: checkedProp,
  disabled: disabledProp,
  label,
  onChange,
  size = "default",
  radioColor = "primary",
  labelColor = "primary",
  labelVariant,
  labelChildren = null,
  ...inputProps
}: RadioProps) => {
  const radioGroup = useContext(RadioGroupContext);

  const checked =
    checkedProp ??
    Boolean(value && radioGroup?.value && value === radioGroup.value);
  const disabled = disabledProp ?? Boolean(radioGroup?.disabled);

  const handleOnChange = useCallback(
    (e) => {
      if (disabled) {
        return;
      }

      if (onChange) {
        onChange(e);
      }

      if (radioGroup?.onChange) {
        radioGroup?.onChange(e);
      }
    },
    [disabled, onChange, radioGroup]
  );

  const { radioClassName, labelClassName } = sizeMap[size];
  const {
    checkedClassName,
    uncheckedClassName,
    checkedEnabledClassName,
    uncheckedEnabledClassName,
  } = radioColorMap[radioColor];

  const radio = (
    <div
      className={classnames(
        {
          "opacity-25": disabled,
          [checkedClassName]: checked,
          [uncheckedClassName]: !checked,
          [checkedEnabledClassName]: checked && !disabled,
          [uncheckedEnabledClassName]: !checked && !disabled,
        },
        radioClassName,
        "relative inline-flex items-center justify-center"
      )}
    >
      <input
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={handleOnChange}
        value={value}
        className={classnames(
          {
            "cursor-pointer": !disabled,
          },
          "absolute left-0 top-0 m-0 h-full w-full opacity-0"
        )}
        {...inputProps}
      />
      <Circle checked={checked} />
    </div>
  );

  if (!label) {
    return radio;
  }

  return (
    <CheckboxLabel
      disabled={disabled}
      labelVariant={
        labelVariant ?? (size === "small" ? "sb_t-12-500" : "sb_t-14-500")
      }
      labelClassName={labelClassName}
      label={label}
      labelColor={labelColor}
    >
      {radio}
      {labelChildren}
    </CheckboxLabel>
  );
};

function Circle({ checked }: { checked?: boolean }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      className={classnames(
        styles.radio,
        "rounded-full fill-current outline-straps-hyperlink-hover"
      )}
    >
      <circle
        cx="8"
        cy="8"
        r="2"
        fill="#fff"
        className={classnames("transition-opacity duration-300", {
          "opacity-0": !checked,
          "opacity-100": checked,
        })}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000976562 8C0.000976562 3.58172 3.5827 0 8.00098 0C12.4193 0 16.001 3.58172 16.001 8C16.001 12.4183 12.4193 16 8.00098 16C3.5827 16 0.000976562 12.4183 0.000976562 8ZM8.00098 2C4.68727 2 2.00098 4.68629 2.00098 8C2.00098 11.3137 4.68727 14 8.00098 14C11.3147 14 14.001 11.3137 14.001 8C14.001 4.68629 11.3147 2 8.00098 2Z"
        data-testid="radio-unchecked"
        className=""
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00098 16C3.5827 16 0.000976562 12.4183 0.000976562 8C0.000976562 3.58172 3.5827 0 8.00098 0C12.4193 0 16.001 3.58172 16.001 8C16.001 12.4183 12.4193 16 8.00098 16ZM8.00098 10C6.89641 10 6.00098 9.10457 6.00098 8C6.00098 6.89543 6.89641 6 8.00098 6C9.10555 6 10.001 6.89543 10.001 8C10.001 9.10457 9.10555 10 8.00098 10Z"
        data-testid="radio-checked"
        className={classnames(
          "origin-center transition-[opacity,transform,color] duration-150 ease-in-out",
          {
            "scale-0 opacity-0": !checked,
            "scale-100 opacity-100": checked,
          }
        )}
      />
    </svg>
  );
}

export default Radio;
