import { CamelCasedPropertiesDeep } from "type-fest";
import { ClientOutput } from "../client";
import { Building as _Building } from "./building";
import { DetachedParking as _DetachedParking } from "./detachedParking";
import {
  Parking as _Parking,
  LegacyParking as _LegacyParking,
  LegacyParkingBody as _LegacyParkingBody,
} from "./parking";
import { SurfaceLot as _SurfaceLot } from "./surfaceLot";

// replicate legacy /building and /parking routes that returned 404 if no building/parking existed
export class NotFoundError extends Error {
  response: { statusCode: number };
  constructor(message: string) {
    super(message);
    this.name = "NotFoundError";
    this.response = { statusCode: 404 };
  }
}

export type Building = _Building;
export type DetachedParking = _DetachedParking;
export type Parking = _Parking;
export type LegacyParking = _LegacyParking;
export type LegacyParkingBody = _LegacyParkingBody;
export type SurfaceLot = _SurfaceLot;

export type Floor = CamelCasedPropertiesDeep<
  ClientOutput["tenantsAndSpaces"]["Floor"]
>;

/**
 * uuid/v4 is used for generating unique ids when adding new floors
 * so we need to widen the id type to allow strings for use in the form and office site setup viz
 */
export type ClientSideFloor = Omit<Floor, "id"> & {
  id?: string | number | null;
};
