import request from "../../request";
import Cookies from "js-cookie";
import { useMutation } from "react-query";
import { snakeCase } from "lodash";
import { useOnboardingGuideContext } from "../../../backpack-console/components/OnboardingGuide";

const RESOURCE_URL = `/rest/onboarding_opt_out`;

const onboardingOptOut = {
  mutations: {
    usePost,
  },
} as const;

export default onboardingOptOut;

// snake_cased optOutItem must exist in intel.onboarding_opt_out_item_enum or this call will fail
type optOutItem = "leasingDocument";

const postOnboardingOptOut = (
  siteId: string | number,
  optOutItem: optOutItem
) => {
  return request
    .post(RESOURCE_URL)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .send({ site_id: siteId, item: snakeCase(optOutItem) });
};

function usePost(siteId: number) {
  const { refreshGuideData } = useOnboardingGuideContext();
  return useMutation({
    mutationFn: (item: optOutItem) => postOnboardingOptOut(siteId, item),
    onSuccess: () => refreshGuideData(),
  });
}
