import Cookies from "js-cookie";
import { useQuery as useReactQuery } from "react-query";
import { VITE_SITE_SERVICE_BASE_URL } from "../../../env";
import { recursiveCamelCaseCipher } from "../../postgrestApi";
import request from "../../request";

// These are the types that come from the BE that we don't currently display
type OngoingProjectStatusTypes =
  | "onTimeAndWithinBudget"
  | "delayedAndWithinBudget"
  | "onTimeAndOverBudget"
  | "delayedAndOverBudget"
  | "unknown";

// We display these statuses which are different from what the BE sends
export type SimpleOngoingProjectStatusTypes = "onTrack" | "atRisk" | "noStatus";

function getSimpleStatus(
  status: OngoingProjectStatusTypes
): SimpleOngoingProjectStatusTypes {
  if (status === "onTimeAndWithinBudget") {
    return "onTrack";
  }
  if (status === "unknown") {
    return "noStatus";
  }
  return "atRisk";
}

// Represents a summary about status of all projects in a portfolio
interface BackendProjectsSummary {
  totalProjects?: number;
  numberOfBackpacksWithProjects?: number;
  statuses?: {
    [status in OngoingProjectStatusTypes]?: {
      numberOfProjects?: number;
      backpacks?: Array<{
        numberOfProjects?: number;
        siteId?: number;
      }>;
    };
  };
}

export interface ProjectsSummary extends BackendProjectsSummary {
  simpleStatuses?: {
    [status in SimpleOngoingProjectStatusTypes]?: {
      numberOfProjects?: number;
      backpacks?: Array<{
        numberOfProjects?: number;
        siteId?: number;
      }>;
    };
  };
}

const QUERY_KEY = "projects/in-progress";
export const getProjectsInProgressQueryKey = (accountId: number) => [
  QUERY_KEY,
  accountId,
];

const getInProgress = (accountId?: number) =>
  request
    .get(`${VITE_SITE_SERVICE_BASE_URL}/projects/in-progress`)
    .query(accountId ? { account_id: accountId } : {})
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .then(
      (res) => recursiveCamelCaseCipher(res.body) as BackendProjectsSummary
    );

function useQuery(
  accountId?: number,
  opts: {
    enabled?: boolean;
  } = {}
) {
  return useReactQuery({
    ...opts,
    queryKey: [QUERY_KEY, accountId],
    queryFn: () => getInProgress(accountId),
    select(data): ProjectsSummary {
      if (!data.statuses) {
        return data;
      }

      const simpleStatuses: ProjectsSummary["simpleStatuses"] = {
        onTrack: {},
        atRisk: {},
        noStatus: {},
      };

      Object.entries(data.statuses).forEach(
        ([status, { backpacks, numberOfProjects }]) => {
          const simpleStatus = getSimpleStatus(
            status as OngoingProjectStatusTypes
          );

          let updatedNumberOfProjects =
            simpleStatuses[simpleStatus]?.numberOfProjects ?? 0;
          const updatedBackpacks =
            simpleStatuses[simpleStatus]?.backpacks ?? [];

          updatedNumberOfProjects += numberOfProjects ?? 0;
          backpacks?.forEach((backpack) => {
            const updatedBackpack = updatedBackpacks.find(
              ({ siteId }) => backpack.siteId === siteId
            );
            if (updatedBackpack) {
              updatedBackpack.numberOfProjects =
                (updatedBackpack.numberOfProjects ?? 0) +
                (backpack.numberOfProjects ?? 0);
            } else {
              updatedBackpacks.push({ ...backpack });
            }

            simpleStatuses[simpleStatus] = {
              backpacks: updatedBackpacks,
              numberOfProjects: updatedNumberOfProjects,
            };
          });
        }
      );

      return {
        ...data,
        simpleStatuses,
      };
    },
  });
}

const inProgress = {
  useQuery,
} as const;

export default inProgress;
