import classNames from "classnames";

const Skeleton = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={classNames(className, "animate-pulse bg-straps-tertiary/50")}
    />
  );
};

export default Skeleton;
