import * as React from "react";
import { Route, withRouter } from "react-router-dom";
import classnames from "classnames";
import { startCase } from "lodash";

import NavItem from "./NavItem";
import PageLink from "./PageLink";
import * as Icon from "../../icons";

const pageToIcon = (page: string) => {
  switch (page) {
    case "users-and-accounts":
      return <Icon.UsersAndAccounts />;
    case "sensors-and-integrations":
      return <Icon.SensorsAndIntegrations />;
    default:
      return <Icon.BuildingDataModel />;
  }
};

const navPages = ["users-and-accounts", "sensors-and-integrations"];

// @ts-expect-error - TS7031 - Binding element 'match' implicitly has an 'any' type. | TS7031 - Binding element 'location' implicitly has an 'any' type.
const AdminNav = ({ match, location }) => {
  const { page } = match.params;
  const [selected, setSelected] = React.useState(false);
  const [container, setContainer] = React.useState<
    HTMLButtonElement | null | undefined
  >(null);
  const updateContainer = React.useCallback((node) => {
    if (node != null) {
      setContainer(node);
    }
  }, []);
  React.useEffect(() => {
    const handleMouseOut = () => setSelected(false);
    const clickHandler = (e: MouseEvent) => {
      const target = e.target;
      if (
        container &&
        target instanceof Element &&
        !container.contains(target) &&
        target.className !== "nav-title" &&
        target.className !== "nav-item"
      ) {
        setSelected(false);
      }
    };

    if (
      container &&
      container.parentElement &&
      container.parentElement.parentElement &&
      container.parentElement.parentElement instanceof Node
    ) {
      container.parentElement.parentElement.addEventListener(
        "mouseleave",
        handleMouseOut
      );
      document.addEventListener("mousedown", clickHandler, false);
      return () => {
        // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2531 - Object is possibly 'null'.
        container.parentElement.parentElement.removeEventListener(
          "mouseleave",
          handleMouseOut
        );
        document.removeEventListener("mousedown", clickHandler, false);
      };
    }
  });
  const baseURL = "";

  const itemProps = (navPage = "") => ({
    urlLink: `${baseURL}/${navPage}`,
    deactivated: page === navPage,
    title: startCase(navPage),
    icon: pageToIcon(navPage),
    key: navPage,
  });

  const subPages = (() => {
    switch (page) {
      case "building-data-model":
        return [
          {
            url: `${match.url}/elements`,
          },
          {
            url: `${match.url}/data-streams`,
          },
          {
            url: `${match.url}/tree-diagram`,
          },
        ];
      case "users-and-accounts":
        return [
          {
            url: `${match.url}/accounts`,
            title: "Account Management",
          },
          {
            url: `${match.url}/management`,
            title: "Admin Management",
          },
          {
            url: `${match.url}/users`,
            title: "User Analytics",
          },
        ];
      case "sensors-and-integrations":
        return [
          {
            url: `${match.url}/gateways`,
            title: "Gateway Admin",
          },
          {
            url: `${match.url}/utility_accounts`,
            title: "Utility Accounts",
          },
        ];
      default:
        return [];
    }
  })();
  return (
    <div
      className={classnames("nav-container", "admin", { selected: selected })}
    >
      <button
        className={classnames("nav-item admin-button")}
        onClick={() => setSelected(!selected)}
        ref={updateContainer}
      >
        <div className="nav-icon">{pageToIcon(page)}</div>
        <h3 className="nav-title">
          {page == null ? "Admin Pages" : startCase(page)}
        </h3>
        <div className="chevron" />
      </button>
      <div className="admin-page-container">
        <React.Fragment>
          {navPages.map((navPage) => (
            // @ts-expect-error - TS2783 - 'key' is specified more than once, so this usage will be overwritten.
            <NavItem key={navPage} {...itemProps(navPage)} />
          ))}
        </React.Fragment>
      </div>
      <div className="nav-item-separator" />
      <React.Fragment>
        {subPages.map((pageProps) => (
          <PageLink
            key={pageProps.url}
            // @ts-expect-error - TS2322 - Type '{ url: string; key: string; location: any; deactivated: boolean; } | { url: string; title: string; key: string; location: any; deactivated: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Pick<RouteComponentProps<any, StaticContext, unknown>, never>, any, any>> & Readonly<...> & Readonly<...>'.
            location={location}
            deactivated={selected}
            {...pageProps}
          />
        ))}
      </React.Fragment>
    </div>
  );
};

export default () => <Route path="/:page?" component={withRouter(AdminNav)} />;
