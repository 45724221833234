import * as React from "react";

import classnames from "classnames";
import { DropzoneOptions, useDropzone } from "react-dropzone";

type Props = {
  onDrop: DropzoneOptions["onDrop"];
  // Similar to https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker types
  acceptedTypes: {
    [mimeType: string]: string[];
  };
  text: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

const Dropzone = ({
  onDrop,
  acceptedTypes,
  text,
  className,
  disabled,
}: Props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    multiple: false,
    disabled,
  });

  return (
    <button
      type="button"
      {...getRootProps()}
      className={classnames(className, {
        dropzone: true,
        "dropzone-active": isDragActive && !isDragReject,
        "dropzone-valid": isDragAccept,
        "dropzone-invalid": isDragReject,
        "dropzone-disabled": disabled,
      })}
    >
      <input {...getInputProps()} data-testid="dropzone-input" />
      {text}
    </button>
  );
};

export default Dropzone;
