import classNames from "classnames";
import Cookies from "js-cookie";
import { startCase } from "lodash";
import * as R from "ramda";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useSiteIdParam } from "../../../context/SitesContext";
import { energyStarPropertyType } from "../../../global_functions/common";
import request from "../../../global_functions/request";
import { useDetailedSiteSummaryQuery } from "../../../global_functions/sites/sitesQueries";
import { useAsyncLazy } from "../../../global_functions/useAsync";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";
import CollapsibleContainer from "@src/straps/utils/CollapsibleContainer/CollapsibleContainer";
import Icon from "@src/straps/base/icons/Icon/Icon";
import { Text } from "@src/straps/base";
import { OnboardingGuideContext } from "./";
import {
  getHotelSchema,
  getMultifamilySchema,
  getOfficeSchema,
  OnboardingGuideSchemaUnionType,
  OnboardingStatusEnum,
  OnboardingStepType,
} from "./completenessSchema";
import type { GroupType } from "./CompletenessSteps";
import OnboardingGuideCompletenessSteps from "./CompletenessSteps";
import OnboardingGuideOnSiteModal from "./OnSiteModal";
import OnboardingGuideCompletedDate from "./OnboardingGuideCompletedDate";
import { decorateSchema, isOptedOutStepOmittedFromCount } from "./utils";

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  refs: React.RefObject<T>[],
  handler: (event: MouseEvent) => void
) {
  React.useEffect(() => {
    const onDocumentClick = (e: MouseEvent) => {
      if (
        refs.some((ref) => ref.current?.contains(e.target as Node)) ||
        refs.every((ref) => !ref.current)
      ) {
        return;
      }

      handler(e);
    };
    document.addEventListener("mousedown", onDocumentClick);
    return () => {
      document.removeEventListener("mousedown", onDocumentClick);
    };
  }, [refs, handler]);
}

type OnboardingGuidePage = "completeness" | "onSite";

const resetStateMap = {
  [energyStarPropertyType["Multifamily Housing"]]: getMultifamilySchema,
  [energyStarPropertyType["Hotel"]]: getHotelSchema,
};

export default function OnboardingGuide(
  props: Readonly<{ children: React.ReactNode }>
) {
  const siteId = useSiteIdParam();

  const [state, setState] = React.useState<OnboardingGuideSchemaUnionType>(
    getOfficeSchema()
  );

  const [open, setOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<string | null | undefined>();
  const [page, setPage] = React.useState<OnboardingGuidePage>("completeness");

  const [onboardingGuideState, fetchState] = useAsyncLazy(
    () => getOnboardingStatus(siteId),
    [siteId]
  );
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const { data: site } = useDetailedSiteSummaryQuery();
  const propertyType = site?.energyStarPropertyType;

  const resetState = React.useCallback(
    () =>
      setState(
        propertyType &&
          Object.keys(resetStateMap).includes(propertyType.toString())
          ? resetStateMap[propertyType as keyof typeof resetStateMap]()
          : getOfficeSchema()
      ),
    [propertyType]
  );

  React.useEffect(() => {
    resetState();
  }, [resetState]);

  const currentState = React.useMemo(
    () => decorateSchema(state, onboardingGuideState.data),
    [state, onboardingGuideState]
  );

  React.useEffect(() => {
    if (currentState) {
      setIsRefreshing(false);
    }
  }, [currentState]);

  const flattenVisibleSubsteps = (value: GroupType) =>
    Object.values(value)
      .map((step) => {
        if (step.stepType === "aggregateStep") {
          return Object.values(step?.subSteps);
        }
        return [];
      })
      .flat();

  React.useEffect(() => {
    setActiveTab(null);
  }, [page, setActiveTab]);

  const groupedState = React.useMemo(
    () =>
      R.flatten(
        Object.values(currentState.completeness).map((group) => {
          const countedSubSteps = flattenVisibleSubsteps(group);
          return [...Object.values(group), ...countedSubSteps];
        })
      ) || [],
    [currentState]
  );

  const totalSteps = React.useMemo(
    () =>
      groupedState.filter(
        (step) =>
          step.countable &&
          !isOptedOutStepOmittedFromCount(step) &&
          step.status !== OnboardingStatusEnum.N_A
      ).length || 21,
    // default to original value but calculate for futureproofing
    [groupedState]
  );

  const completedSteps = React.useMemo(
    () =>
      groupedState.filter(
        (step) =>
          step.countable &&
          step.complete &&
          !isOptedOutStepOmittedFromCount(step) &&
          step.status !== OnboardingStatusEnum.N_A
      ).length,
    [groupedState]
  );

  const location = useLocation();
  const history = useHistory();

  const [activeStep, _setActiveStep] = React.useState<string | null>(null);

  const setActiveStep = React.useCallback(
    (
      pathId?: string,
      options?: {
        page?: "completeness" | "onSite";
        skipCompleted?: boolean;
      }
    ): void => {
      if (!pathId) return _setActiveStep(null);
      const [group, step, subStep] = pathId.split(".") as [
        Groups,
        string,
        string
      ];

      const { page: _page = "completeness", skipCompleted = false } =
        options || {};

      setActiveTab(group);
      setPage(_page);

      // recursive until uncompleted step
      if (skipCompleted) {
        const { status, nextPathId } = subStep
          ? // @ts-expect-error
            currentState.completeness?.[group]?.[step]?.subSteps?.[subStep]
          : // @ts-expect-error
            (currentState.completeness?.[group]?.[step] as OnboardingStepType);

        if (
          // all conditions besides open
          status >= OnboardingStatusEnum.Processing
        ) {
          // If no nextPathId, then its the last step so reset state
          // if (!nextPathId) return resetState();
          return setActiveStep(nextPathId, {
            skipCompleted: true,
          });
        }
      }

      const nextURI = `/site/${siteId}/${
        getPageLinkOverrides(group, step, subStep) || group
      }`;
      if (group && location.pathname !== nextURI) {
        history.push(nextURI);
      }

      return _setActiveStep(pathId);
    },
    [setActiveTab, location, history, siteId, currentState.completeness]
  );

  const refreshGuideData = React.useCallback(() => {
    setIsRefreshing(true);
    return fetchState();
  }, [fetchState]);

  const ref = React.useRef<HTMLDivElement>(null);
  const [refState, setRefState] = React.useState<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (ref.current) setRefState(ref.current);
  }, []);

  const contextValue = React.useMemo(
    () => ({
      state: currentState,
      resetState,
      activeStep,
      setActiveStep,
      activeTab,
      setActiveTab,
      completedSteps,
      ref: refState,
      setOpen,
      refreshGuideData,
      isRefreshing,
      isLoading: onboardingGuideState.data?.[0]?.site_id !== siteId,
    }),
    [
      currentState,
      resetState,
      activeStep,
      setActiveStep,
      activeTab,
      completedSteps,
      refState,
      refreshGuideData,
      isRefreshing,
      onboardingGuideState.data,
      siteId,
    ]
  );

  return (
    <OnboardingGuideContext.Provider value={contextValue}>
      <OnboardingGuideComponent
        open={open}
        completedSteps={completedSteps}
        setOpen={setOpen}
        totalSteps={totalSteps}
        page={page}
        setPage={setPage}
        currentState={currentState}
      />
      {props.children}
      <OnboardingGuideOnSiteModal />
    </OnboardingGuideContext.Provider>
  );
}

interface OnboardingGuideComponentProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  completedSteps: number;
  totalSteps: number;
  page: OnboardingGuidePage;
  setPage: React.Dispatch<React.SetStateAction<OnboardingGuidePage>>;
  currentState: OnboardingGuideSchemaUnionType;
}

const OnboardingGuideComponent = ({
  open,
  setOpen,
  completedSteps,
  totalSteps,
  page,
  setPage,
  currentState,
}: OnboardingGuideComponentProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const datePickerRef = React.useRef<HTMLDivElement>(null);
  const onClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  useOnClickOutside([ref, datePickerRef], onClose);
  const target = React.useMemo(
    () => document.querySelector("#onboarding-guide-target"),
    []
  );
  const targetOffsetX = target?.getBoundingClientRect().x ?? 0;

  return (
    <div
      ref={ref}
      className={classNames("fixed top-0 z-100 rounded-md")}
      style={{
        right: window.innerWidth - targetOffsetX,
        width: 242,
      }}
    >
      <div className={classNames("overflow-hidden transition-all")}>
        <ClickableDiv
          data-cy="onboarding-guide-container"
          data-testid="onboarding-guide-container"
          className={classNames(
            "my-3 flex h-[46px] cursor-pointer items-center border-l border-l-straps-accent-3 pl-[22px] pr-4 transition-all"
          )}
          onClick={() => setOpen((prev) => !prev)}
        >
          <Text
            variant="sa_t-10-700"
            color="white"
            className="mr-2 rounded-full bg-straps-accent-1 px-1.5 py-1"
          >
            {completedSteps}/{totalSteps}
          </Text>
          <Icon name="onboarding-guide" className="mr-2.5" />
          <Text variant="sa_t-12-700" className="mr-2 whitespace-nowrap">
            Onboarding Guide
          </Text>
          <Icon
            name="caret-right"
            size="small"
            className={classNames("transition-transform", {
              "rotate-90": open,
            })}
          />
        </ClickableDiv>
      </div>
      {/* Collapsible Steps Section */}
      <CollapsibleContainer
        open={open}
        className="flex flex-col overflow-hidden rounded-b-md bg-white shadow-lg transition-all"
      >
        {/* Completed Steps Section */}
        <div className={classNames("pointer-events-none bg-white px-5 py-2")}>
          <div className="relative flex items-center justify-between bg-straps-primary-hover px-2 py-1 text-white">
            <div
              className="absolute bottom-0 left-0 top-0 bg-straps-accent-1"
              style={{
                width: `${(completedSteps * 100) / totalSteps}%`,
              }}
            />
            <Text
              variant="sc_u-10-700"
              color="white"
              className="z-10 whitespace-nowrap"
            >
              COMPLETENESS
            </Text>
            <span
              data-cy="onboarding-guide-completeness-count"
              data-testid="onboarding-guide-completeness-count"
              className="z-10 whitespace-nowrap"
            >
              <strong>{completedSteps}</strong>/{totalSteps}
            </span>
          </div>
        </div>
        <OnboardingGuideCompletedDate ref={datePickerRef} />
        <div data-cy="onboarding-guide-content">
          <div className="flex border-y border-straps-accent-3">
            <Tab id="completeness" tab={page} setTab={setPage} />
            <Tab id="onSite" tab={page} setTab={setPage} />
          </div>
          <div className="flex-1">
            <OnboardingGuideCompletenessSteps
              page={page}
              steps={
                page === "completeness"
                  ? currentState.completeness
                  : currentState.onSite
              }
            />
          </div>
        </div>
      </CollapsibleContainer>
    </div>
  );
};

function Tab(
  props: Readonly<{
    setTab: React.Dispatch<React.SetStateAction<"completeness" | "onSite">>;
    tab: string;
    id: "completeness" | "onSite";
  }>
) {
  return (
    <ClickableDiv
      onClick={() => props.setTab(props.id)}
      className={classNames(
        "flex flex-1 cursor-pointer items-center justify-center py-2 text-xs font-bold transition-colors",
        {
          "bg-white text-straps-primary": props.tab === props.id,
          "bg-straps-primary text-white": props.tab !== props.id,
        }
      )}
    >
      {startCase(props.id)}
    </ClickableDiv>
  );
}

async function getOnboardingStatus(siteId: number): Promise<
  Array<{
    site_id: number;
    item_id: number;
    tab: 1 | 2;
    one: string;
    two: string;
    three: string | null;
    status: number;
  }>
> {
  const response = await request
    .get(`/rest/v_onboarding_status?site_id=eq.${siteId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`);

  if (response.ok) return response.body;

  throw response.error;
}

type S = OnboardingGuideSchemaUnionType["completeness"];
type Groups = keyof S;
type SubmissionSteps = keyof S["submissions"];
type SubmissionSubSteps =
  keyof S["submissions"]["addOtherIntegrations"]["subSteps"];

function getPageLinkOverrides(
  group: Groups,
  step: string, // could type this better
  subStep: string
) {
  // This could probably be more clever but it works for now
  if (
    group === "submissions" &&
    (step as SubmissionSteps) === "addOtherIntegrations" &&
    (subStep as SubmissionSubSteps) === "leed"
  )
    return `submissions/manage/leed`;
  if (
    group === "submissions" &&
    (step as SubmissionSteps) === "addOtherIntegrations" &&
    (subStep as SubmissionSubSteps) === "gresb"
  )
    return `submissions/manage/gresb`;
  if (group === "overview" && step === "vendors") return "overview/vendor-list";

  const overrides: Partial<Record<Groups, string>> = {
    overview: "overview/building-info",
    buildingDesign: "documents",
    utilities: "utilities/management",
    leasing: "projects",
    submissions: "submissions/energy-star",
  } as const;

  return overrides[group];
}
