import { Disposition } from "@src/backpack-console/pages/SiteDashboard/Documents/types";
import Cookies from "js-cookie";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { VITE_SITE_SERVICE_BASE_URL } from "../../../env";
import {
  bugsnagGeneralErrorHandler,
  recursiveCamelCaseCipher,
} from "../../postgrestApi";
import request from "../../request";
import FetchClients from "../client";
import { EquipmentFile, EquipmentFileRow, EquipmentFileType } from "./types";

const attachments = {
  useQueryAttachmentInfo,
  usePost,
  useDownloadAttachements,
  useDeleteEquipmentDocs,
  getEquipmentAttachmentInfo,
  downloadAttachments,
  usePatchEquipmentFile,
};

export default attachments;

const ATTACHMENT_QUERY_KEY = "equipment-attachments";
const SITE_QUERY_PREFIX = "site-";
const EQUIPMENT_QUERY_PREFIX = "equipment-";

// For most queries, we need to pass either siteId or equipmentId.
// If both are passed, we will use equipmentId.
const getQueryKey = ({
  siteId,
  equipmentId,
}: {
  siteId?: number;
  equipmentId?: number;
}) => {
  if (typeof equipmentId === "number")
    return [`${EQUIPMENT_QUERY_PREFIX}${equipmentId}`];
  if (typeof siteId === "number") return [`${SITE_QUERY_PREFIX}${siteId}`];
  return [];
};

async function getEquipmentAttachmentInfo({
  equipmentId,
  siteId,
}: {
  equipmentId?: number;
  siteId?: number;
}) {
  const { data } = await FetchClients.d3.GET("/equipment/list_documents", {
    params: { query: { equipment_id: equipmentId, site_id: siteId } },
  });
  return data && (recursiveCamelCaseCipher(data) as EquipmentFileRow[]);
}

function useQueryAttachmentInfo({
  equipmentId,
  siteId,
}: {
  equipmentId?: number;
  siteId?: number;
}) {
  return useReactQuery({
    queryKey: [
      ATTACHMENT_QUERY_KEY,
      ...getQueryKey({ siteId, equipmentId }),
      "attachment-info",
    ],
    queryFn: async () => getEquipmentAttachmentInfo({ equipmentId, siteId }),
  });
}

async function postAttachment(
  file: File,
  equipmentId: number,
  fileType: EquipmentFileType
) {
  return request
    .post(`${VITE_SITE_SERVICE_BASE_URL}/equipment/upload`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("Content-Type", "text/plain")
    .query({
      equipment_id: equipmentId,
      file_type: fileType,
      filename: file.name,
    })
    .send(file)
    .then((res) => res.body)
    .catch((error) => bugsnagGeneralErrorHandler(error));
}

function usePost(equipmentId: number, siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      file,
      type,
    }: {
      file: File;
      type: EquipmentFileType;
    }) => {
      await postAttachment(file, equipmentId, type);
    },
    onSettled: async () => {
      return Promise.all([
        queryClient.invalidateQueries([
          ATTACHMENT_QUERY_KEY,
          `${EQUIPMENT_QUERY_PREFIX}${equipmentId}`,
        ]),
        queryClient.invalidateQueries([
          ATTACHMENT_QUERY_KEY,
          `${SITE_QUERY_PREFIX}${siteId}`,
        ]),
      ]);
    },
  });
}

type DownloadAttachmentsParams = {
  file_id?: number;
  equipment_id?: number;
  disposition?: Disposition;
  include_preview?: boolean;
  primary?: boolean;
};
async function downloadAttachments({
  disposition,
  equipment_id,
  file_id,
  include_preview,
  primary,
}: DownloadAttachmentsParams) {
  return FetchClients.d3
    .GET(`/equipment/download`, {
      params: {
        query: {
          file_id,
          disposition,
          include_preview,
          primary,
          equipment_id,
        },
      },
    })
    .then((res) => res.data && recursiveCamelCaseCipher(res.data)) as Promise<
    EquipmentFile[]
  >;
}
function useDownloadAttachements(params: DownloadAttachmentsParams) {
  return useReactQuery({
    queryKey: [
      ATTACHMENT_QUERY_KEY,
      ...getQueryKey({ equipmentId: params.equipment_id }),
      "download",
      params,
    ],
    queryFn: async () => downloadAttachments(params),
  });
}

async function deleteEquipmentDocs({
  fileIds,
  equipmentId,
}: {
  fileIds?: number[];
  equipmentId?: number;
}) {
  return FetchClients.d3.DELETE("/equipment/delete", {
    params: { query: { file_ids: fileIds, equipment_id: equipmentId } },
  });
}

// Passing equipmentId to the mutation function will delete all docs for a specific equipment
function useDeleteEquipmentDocs() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      fileIds,
      equipmentId,
    }: {
      fileIds?: number[];
      equipmentId?: number;
    }) => {
      await deleteEquipmentDocs({ fileIds, equipmentId });
    },
    onSettled: async (_data, _error, variables) => {
      return queryClient.invalidateQueries([
        ATTACHMENT_QUERY_KEY,
        // invalidate equipment query if equipmentId is passed
        // otherwise, if bulk deleting files by id, invalidate site query
        ...getQueryKey({
          equipmentId: variables.equipmentId,
          siteId: typeof variables.fileIds !== "undefined" ? 0 : undefined,
        }),
      ]);
    },
  });
}

function patchEquipmentFile({
  fileId,
  filename,
  notes,
}: {
  fileId: number;
  filename: string;
  notes?: { id?: number; description: string; page: string }[];
}) {
  return FetchClients.d3
    .PATCH("/equipment/file", {
      params: { query: { file_id: fileId } },
      body: { filename, notes },
    })
    .then((res) => res.data);
}

function usePatchEquipmentFile({
  siteId,
  equipmentId,
}: {
  siteId?: number;
  equipmentId?: number;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      fileId,
      filename,
      notes,
    }: {
      fileId: number;
      filename: string;
      notes?: { id?: number; description: string; page: string }[];
    }) => patchEquipmentFile({ fileId, filename, notes }),
    onSettled: async () => {
      return queryClient.invalidateQueries([
        ATTACHMENT_QUERY_KEY,
        ...getQueryKey({ siteId, equipmentId }),
      ]);
    },
  });
}
