import Cookies from "js-cookie";
import moment from "moment";
import * as R from "ramda";
import type {
  BaseNotificationEvent,
  BaseSchedule,
  BaseScheduleRevision,
  BaseScheduleSetTime,
  DateRange,
  DateString,
  Equipment,
  EquipmentDailyRuntime,
  EquipmentId,
  EquipmentWithDataStreams,
  EquipmentWithSchedules,
  GroupedNotifications,
  ID,
  ISODateString,
  Notification,
  NotificationClassifications,
  NotificationEvent,
  NotifiedScheduleRevision,
  RuntimesForNotification,
  Schedule,
  ScheduleId,
  ScheduleRevisionId,
  ScheduleSetTime,
} from "../../types";
import request from "../request";

import { alphaNumericSort } from "../util";

import {
  bugsnagPostgrestErrorHandler,
  camelCaseCipher,
  formatDataArray,
  formatDataObj,
  recursiveCamelCaseCipher,
  snakeCaseCipher,
} from "./common";

const getCompositeNotificationEvents = (
  events: Array<NotificationEvent>
): Array<BaseNotificationEvent> => {
  const sortedEvents = R.sortBy(R.prop("startTime"))(events);
  const eventTimes: Array<BaseNotificationEvent> = [];
  for (let i = 0; i < sortedEvents.length; i++) {
    const { startTime, endTime, scheduleType } = sortedEvents[i]!;
    if (i === 0) {
      eventTimes.push({ startTime, endTime, scheduleType });
    } else {
      const lastEvent = eventTimes[eventTimes.length - 1];
      if (lastEvent!.endTime == null) break; // assumes only time end-time is null is when the event is still occurring in real-time
      const startTimeOverlapsLastEvent = moment(startTime).isSameOrBefore(
        lastEvent!.endTime
      );

      if (
        startTimeOverlapsLastEvent &&
        scheduleType === lastEvent!.scheduleType
      ) {
        // @ts-expect-error - TS2322 - Type 'Moment | null' is not assignable to type 'string | null | undefined'.
        lastEvent.endTime =
          endTime == null
            ? null
            : moment.max(moment(lastEvent!.endTime), moment(endTime));
      } else {
        eventTimes.push({ startTime, endTime, scheduleType });
      }
    }
  }

  return eventTimes;
};

const getNotifiedRuntime = (events: Array<BaseNotificationEvent>): number => {
  const coalesce = (maybeTime: ISODateString | null | undefined) =>
    maybeTime != null ? moment(maybeTime) : moment();

  return events.reduce(
    (acc, currVal) =>
      acc + coalesce(currVal.endTime).diff(currVal.startTime, "minutes"),
    0
  );
};

const formatNotificationRow = (row: any): Notification => {
  const notificationEvents = formatDataArray(
    row.notification_events,
    camelCaseCipher
  );
  const compositeNotificationEvents =
    getCompositeNotificationEvents(notificationEvents);
  return {
    ...formatDataObj(row, camelCaseCipher),
    notificationEvents,
    compositeNotificationEvents,
    notifiedRuntime: getNotifiedRuntime(compositeNotificationEvents),
  };
};

const getGroupedNotifications = ({
  date,
  notifications,
}: {
  date: DateString;
  notifications: Array<any>;
}): GroupedNotifications => {
  const formattedNotifications = notifications.map(formatNotificationRow);
  const notifiedRuntime = formattedNotifications.reduce(
    (acc, currVal) => currVal.notifiedRuntime + acc,
    0
  );
  const equipmentIds = formattedNotifications.map(
    (notification) => notification.equipmentId
  );
  return {
    date,
    notifications: formattedNotifications,
    notifiedRuntime,
    equipmentIds,
  };
};

const getNumberOfUnacknowledgedNotifications = (
  siteId: number
): Promise<number> =>
  request
    .get("/rest/notifications_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}`, has_unacknowledged_events: `eq.true` })
    .catch(bugsnagPostgrestErrorHandler)
    // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
    .then((res) => res.body.length);

const getNotifications = async (
  siteId: number,
  notificationIds?: Array<number>
): Promise<Array<Notification>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  if (notificationIds) query.id = `in.(${notificationIds.join(",")})`;

  return (
    request
      .get("/rest/notifications_summary")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query(query)
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
      .then(({ body }) => body.map(formatNotificationRow))
  );
};

const deleteNotifications = async (
  siteId: string | number,
  dates:
    | {
        startDate: DateString;
        endDate: DateString;
      }
    | null
    | undefined,
  equipmentIds: Array<number>
): Promise<Array<number>> => {
  const query: Record<string, any> = {};
  // query.site_id = `eq.${siteId}`; // TODO, should figure out how to do this
  query.equipment_id = `in.(${equipmentIds.join(",")})`;

  if (dates) {
    query.and = `(date.gte.${dates.startDate},date.lte.${dates.endDate})`;
  }

  return request
    .delete("/rest/notifications")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .query(query)
    .then(({ body }) => body.map(R.prop("id")))
    .catch(bugsnagPostgrestErrorHandler);
};

const getRuntimesForNotifications = async (
  notificationIds: Array<number>
): Promise<{
  [notificationId: string]: RuntimesForNotification;
}> =>
  request
    .get("/rest/runtimes_for_notifications")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `in.(${notificationIds.join(",")})` })
    .catch(bugsnagPostgrestErrorHandler)
    // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
    .then(({ body }) => {
      const output: {
        [notificationId: string]: RuntimesForNotification;
      } = {};
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      body.forEach((row) => {
        output[row.id] = {
          activeRuntimeEvents: formatDataArray(
            row.active_runtime_events,
            camelCaseCipher
          ),
          unknownRuntimeEvents: formatDataArray(
            row.unknown_runtime_events,
            camelCaseCipher
          ),
        };
      });
      return output;
    });

type DailyRuntimes = Array<{
  minutesAfterMidnight: number;
  runHours: number;
}>;

const getEquipmentDailyRuntimes = async (
  siteId: number
): Promise<DailyRuntimes> => {
  const [todaysRuntimes, lastEvaluatedTime] = await Promise.all([
    request
      .get("/rest/equipment_runtime_summary")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({
        site_id: `eq.${siteId}`,
        date: `eq.${moment().format("YYYY-MM-DD")}`,
      })
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
      .then(({ body }) =>
        // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
        body.map((row) => ({
          ...formatDataObj(row, camelCaseCipher),
          events: formatDataArray(row.events, camelCaseCipher),
        }))
      ),
    request
      .get("/rest/equipment_runtime_tracking")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ order: "last_checked.desc", limit: 1 })
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
      .then(({ body }) => {
        if (body.length === 0) return 0;
        const lastTimeInMinutesFromMidnight = moment(body[0].last_checked).diff(
          moment().startOf("day"),
          "minutes"
        );
        return lastTimeInMinutesFromMidnight < 0
          ? 0
          : lastTimeInMinutesFromMidnight;
      }),
  ]);

  const evalIntervalInMinutes = 15;
  let totalRuntime = 0;
  const runTimes = R.range(
    0,
    Math.floor(lastEvaluatedTime / evalIntervalInMinutes)
  ).map((idx) => {
    const intervalFromMidnight = idx * evalIntervalInMinutes;
    const runtimesInInterval = todaysRuntimes.filter(
      // @ts-expect-error - TS7006 - Parameter 'runTime' implicitly has an 'any' type.
      (runTime) =>
        runTime.earliestStartMinuteFromMidnight < intervalFromMidnight &&
        (runTime.latestEndMinuteFromMidnight == null ||
          runTime.latestEndMinuteFromMidnight >= intervalFromMidnight)
    );
    totalRuntime += runtimesInInterval.length * evalIntervalInMinutes;
    return {
      minutesAfterMidnight: intervalFromMidnight,
      runHours: Math.round(totalRuntime / 60),
    };
  });
  return runTimes;
};

const getNotifiedScheduleRevisions = (
  siteId: number
): Promise<Array<NotifiedScheduleRevision>> =>
  request
    .get("/rest/notified_schedule_revisions")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ site_id: `eq.${siteId}` })
    .then((res) => res.body.map(recursiveCamelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

const getSchedules = (
  siteId: number,
  options: {
    excludeDeleted?: boolean;
    scheduleIds?: Array<ScheduleId>;
  } = {}
): Promise<Array<Schedule>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  query.order = "created_at.desc";

  if (options.excludeDeleted) query.deleted_at = `is.null`;

  return request
    .get("/rest/schedules_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query(query)
    .then(({ body }) => body.map(recursiveCamelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);
};
const updateSchedule = (
  scheduleId: ScheduleId,
  props: {
    name?: string;
    enabled?: boolean;
    deleted?: boolean;
    activeRevisionId?: number;
  }
): Promise<BaseSchedule> => {
  const updatedProps: Record<string, any> = {};
  if (props.name != null) updatedProps.name = props.name;
  if (props.activeRevisionId)
    updatedProps.active_revision_id = props.activeRevisionId;
  if (props.enabled != null)
    updatedProps.enabled_at = props.enabled ? moment().format() : null;
  if (props.deleted) {
    updatedProps.enabled_at = null;
    updatedProps.deleted_at = moment().format();
  }

  return request
    .patch("/rest/schedules")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .set("Prefer", "return=representation")
    .query({ id: `eq.${scheduleId}` })
    .send(updatedProps)
    .then((res) => formatDataObj(res.body, camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);
};

const saveScheduleSetTimes = (
  setTimes: Array<Partial<ScheduleSetTime>>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .post("/rest/schedule_set_times")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(formatDataArray(setTimes, snakeCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

const deleteSchedule = (scheduleId: ScheduleId): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/schedules")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${scheduleId}` })
    .send({ deleted_at: moment().format(), enabled_at: null })
    .catch(bugsnagPostgrestErrorHandler);

const saveScheduleDateRanges = (
  dateRanges: Array<
    DateRange & {
      scheduleRevisionId: ScheduleRevisionId;
    }
  >
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .post("/rest/schedule_date_ranges")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(formatDataArray(dateRanges, snakeCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

const saveNewScheduleRevision = async (
  scheduleId: number,
  revision: Partial<BaseScheduleRevision>,
  setTimes: Array<BaseScheduleSetTime>,
  dateRanges: Array<DateRange>
) => {
  const scheduleRevisionId = await request
    .post("/rest/schedule_revisions")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send(formatDataObj({ ...revision, scheduleId }, snakeCaseCipher))
    .then((res) => res.body[0].id)
    .catch(bugsnagPostgrestErrorHandler);

  await Promise.all([
    updateSchedule(scheduleId, { activeRevisionId: scheduleRevisionId }),
    saveScheduleSetTimes(setTimes.map((sT) => ({ ...sT, scheduleRevisionId }))),
    saveScheduleDateRanges(
      dateRanges.map((dR) => ({ ...dR, scheduleRevisionId }))
    ),
  ]);
};

const saveNewScheduleWithRevision = async (
  schedule: {
    siteId: number;
    name: string;
  },
  revision: Partial<BaseScheduleRevision>,
  setTimes: Array<BaseScheduleSetTime>,
  dateRanges: Array<DateRange>
): Promise<ScheduleId> => {
  const scheduleId = await request
    .post("/rest/schedules")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Prefer", "return=representation")
    .send(formatDataObj(schedule, snakeCaseCipher))
    .then((res) => res.body[0].id)
    .catch(bugsnagPostgrestErrorHandler);

  await saveNewScheduleRevision(
    scheduleId,
    { ...revision, isOriginal: true },
    setTimes,
    dateRanges
  );

  return scheduleId;
};

const getNotificationDailyTimelineData = ({
  siteId,
  startDate,
  endDate,
}: {
  siteId: number;
  startDate: DateString;
  endDate: DateString;
}): Promise<Array<GroupedNotifications>> =>
  request
    .get("/rest/grouped_notifications_summary")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      site_id: `eq.${siteId}`,
      and: `(date.gte.${startDate}, date.lte.${endDate})`,
    })
    .catch(bugsnagPostgrestErrorHandler)
    .then((res) => {
      const allDatesLen = moment(endDate).diff(startDate, "days") + 1;
      const allDates = R.range(0, allDatesLen).map((i) =>
        moment(endDate).subtract(i, "days").format("YYYY-MM-DD")
      );

      return allDates.map((date) => {
        // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'. | TS7006 - Parameter 'row' implicitly has an 'any' type.
        const notificationsForDay = res.body.find((row) => row.date === date);
        return notificationsForDay
          ? getGroupedNotifications(notificationsForDay)
          : {
              date,
              notifications: [],
              notifiedRuntime: 0,
              equipmentIds: [],
            };
      });
    });

const getEquipment = (
  siteId: ID,
  excludeInactiveEquipment?: boolean
): Promise<Array<Equipment>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  if (excludeInactiveEquipment) {
    query.has_active_on_off_status = "is.true";
  }
  // @ts-expect-error - TS2322 - Type 'Promise<Equipment[] | Partial<Record<"name", string>>[]>' is not assignable to type 'Promise<Equipment[]>'.
  return (
    request
      .get("/rest/equipment")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query(query)
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
      .then(({ body }) =>
        alphaNumericSort("name", formatDataArray(body, camelCaseCipher))
      )
  );
};

const getEquipmentWithSchedules = (
  siteId: ID,
  excludeInactiveEquipment?: boolean
): Promise<Array<EquipmentWithSchedules>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  if (excludeInactiveEquipment) {
    query.has_active_on_off_status = "is.true";
  }

  // @ts-expect-error - TS2322 - Type 'Promise<EquipmentWithSchedules[] | Partial<Record<"name", string>>[]>' is not assignable to type 'Promise<EquipmentWithSchedules[]>'.
  return (
    request
      .get("/rest/equipment_with_enabled_schedule_ids")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query(query)
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
      .then(({ body }) =>
        alphaNumericSort("name", formatDataArray(body, camelCaseCipher))
      )
  );
};

const getEquipmentWithRelatedDatastreams = (
  siteId: ID,
  equipmentId?: EquipmentId
): Promise<Array<EquipmentWithDataStreams>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  if (equipmentId) query.id = `eq.${equipmentId}`;
  // @ts-expect-error - TS2322 - Type 'Promise<EquipmentWithDataStreams[] | Partial<Record<"name", string>>[]>' is not assignable to type 'Promise<EquipmentWithDataStreams[]>'.
  return (
    request
      .get("/rest/equipment_with_datastreams_and_vps")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query(query)
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
      .then(({ body }) =>
        alphaNumericSort(
          "name",
          // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
          body.map((row) => ({
            ...formatDataObj(row, camelCaseCipher),
            dataStreams: formatDataArray(row.data_streams, camelCaseCipher),
            onOffStatus: row.on_off_status
              ? formatDataObj(row.on_off_status, camelCaseCipher)
              : null,
          }))
        )
      )
  );
};

const saveEquipmentScheduleAssignments = (
  assignments: Array<{
    scheduleId: ScheduleId;
    equipmentId: EquipmentId;
  }>
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .post("/rest/equipment_schedule_assignments")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(formatDataArray(assignments, snakeCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

const deleteEquipmentScheduleAssignment = ({
  scheduleId,
  equipmentId,
}: {
  scheduleId: ScheduleId;
  equipmentId: EquipmentId;
}): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/equipment_schedule_assignments")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      schedule_id: `eq.${scheduleId}`,
      equipment_id: `eq.${equipmentId}`,
    })
    .catch(bugsnagPostgrestErrorHandler);

const getEquipmentScheduleAssignments = (
  equipmentId: EquipmentId
): Promise<Array<ScheduleId>> =>
  request
    .get("/rest/equipment_schedule_assignments")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      select: "schedule_id,schedule:schedules(deleted_at)",
      equipment_id: `eq.${equipmentId}`,
    })
    .catch(bugsnagPostgrestErrorHandler)
    // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
    .then(({ body }) =>
      body
        // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
        .filter((row) => row.schedule.deleted_at == null)
        // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
        .map((row) => row.schedule_id)
    );

const getPreviousEquipmentRuntimes = (
  siteId: number,
  options?: {
    lastWeek?: boolean;
    olderThanAWeek?: boolean;
  }
): Promise<Array<EquipmentDailyRuntime>> => {
  const query: Record<string, any> = {};
  query.site_id = `eq.${siteId}`;
  query.latest_end_minute_from_midnight = `not.is.null`;
  query.runtime = `not.is.null`;

  if (options?.lastWeek) {
    query.and = `(date.lt.${moment().format("YYYY-MM-DD")},date.gte.${moment()
      .subtract(1, "week")
      .format("YYYY-MM-DD")})`;
  } else if (options?.olderThanAWeek) {
    query.date = `lt.${moment().subtract(1, "week").format("YYYY-MM-DD")}`;
  } else {
    query.date = `lt.${moment().format("YYYY-MM-DD")}`;
  }

  return (
    request
      .get("/rest/equipment_runtime_summary")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query(query)
      .catch(bugsnagPostgrestErrorHandler)
      // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
      .then(({ body }) =>
        // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
        body.map((row) => ({
          ...formatDataObj(row, camelCaseCipher),
          events: formatDataArray(row.events, camelCaseCipher),
        }))
      )
  );
};

const saveNotificationClassification = (
  notificationIds: Array<number>,
  classification: NotificationClassifications
): Promise<Array<void>> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void[]>'.
  request
    .patch("/rest/notifications")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `in.(${notificationIds.join(",")})` })
    .send({ classification: classification })
    .catch(bugsnagPostgrestErrorHandler);

const createNewBinaryVirtualDataStream = (dataStreamId: number): Promise<ID> =>
  request
    .post("/rest/binary_virtual_data_stream_definitions")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .set("Prefer", "return=representation")
    .send({ reference_data_stream_id: dataStreamId })
    .catch(bugsnagPostgrestErrorHandler)
    // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
    .then(({ body }) => body.id);

const createNewVirtualDataStreamDefinition = (
  binaryVirtualDataStreamId: number
): Promise<ID> =>
  request
    .post("/rest/virtual_data_stream_definitions")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .set("Prefer", "return=representation")
    .send({
      binary_virtual_data_stream_definition_id: binaryVirtualDataStreamId,
    })
    .catch(bugsnagPostgrestErrorHandler)
    // @ts-expect-error - TS2339 - Property 'body' does not exist on type 'void | Response'.
    .then(({ body }) => body.id);

const updateVirtualDataStreams = (
  onOffStreamIds: number | Array<number>,
  propsToUpdate: {
    hide: boolean;
  }
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .patch("/rest/virtual_data_streams")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({
      id: Array.isArray(onOffStreamIds)
        ? `in.(${onOffStreamIds.join(",")})`
        : `eq.${onOffStreamIds}`,
    })
    .send(propsToUpdate)
    .catch(bugsnagPostgrestErrorHandler);

const updateBinaryVirtualDataStream = async (
  binaryVirtualDataStreamDefinitionId: number,
  onOffStreamId: number,
  {
    dataStreamId,
    threshold,
  }: {
    dataStreamId?: number | null;
    threshold?: number | null;
  }
): Promise<Array<void>> => {
  const payload: Record<string, any> = {};
  if (dataStreamId) payload.reference_data_stream_id = dataStreamId;
  if (threshold !== undefined) payload.threshold = threshold;
  // @ts-expect-error - TS2322 - Type '(void | Response | undefined)[]' is not assignable to type 'void[]'.
  return Promise.all([
    request
      .patch("/rest/binary_virtual_data_stream_definitions")
      .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
      .query({ id: `eq.${binaryVirtualDataStreamDefinitionId}` })
      .send(payload)
      .catch(bugsnagPostgrestErrorHandler),
    threshold == null
      ? updateVirtualDataStreams(onOffStreamId, { hide: true })
      : undefined,
  ]);
};

const createNewVirtualDataStream = (newDataStream: {
  name: string;
  siteId: ID;
  parentEquipmentId: EquipmentId;
  virtualDataStreamDefinitionId: ID;
}): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .post("/rest/virtual_data_streams")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      ...formatDataObj(newDataStream, snakeCaseCipher),
      unit_type: "binary",
      stream_type: "virtual",
      brick_point_type: "On_Off_Status",
      hide: true,
    })
    .catch(bugsnagPostgrestErrorHandler);

const deleteVirtualDataStream = (virtualDataStreamId: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/virtual_data_streams")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${virtualDataStreamId}` })
    .catch(bugsnagPostgrestErrorHandler);

const deleteBinaryVirtualDataStreamDefinition = (
  binaryVirtualDataStreamDefinitionId: number
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete("/rest/binary_virtual_data_stream_definitions")
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ id: `eq.${binaryVirtualDataStreamDefinitionId}` })
    .catch(bugsnagPostgrestErrorHandler);

export {
  getNumberOfUnacknowledgedNotifications,
  getNotifications,
  deleteNotifications,
  getRuntimesForNotifications,
  getSchedules,
  getNotifiedScheduleRevisions,
  updateSchedule,
  deleteSchedule,
  saveNewScheduleRevision,
  saveNewScheduleWithRevision,
  getEquipment,
  getEquipmentWithSchedules,
  getEquipmentWithRelatedDatastreams,
  saveEquipmentScheduleAssignments,
  deleteEquipmentScheduleAssignment,
  getEquipmentScheduleAssignments,
  getNotificationDailyTimelineData,
  getPreviousEquipmentRuntimes,
  saveNotificationClassification,
  getEquipmentDailyRuntimes,
  createNewBinaryVirtualDataStream,
  createNewVirtualDataStreamDefinition,
  createNewVirtualDataStream,
  deleteVirtualDataStream,
  deleteBinaryVirtualDataStreamDefinition,
  updateBinaryVirtualDataStream,
  updateVirtualDataStreams,
};
