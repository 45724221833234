import React, { ComponentProps } from "react";
import classNames from "classnames";

export interface ButtonProps extends ComponentProps<"button"> {
  disabled?: boolean;
  size?: "small" | "large" | "default";
  // prettier-ignore
  color?: 'blue' | 'green' | 'gray' | 'light-gray' | 'red' | 'yellow' | 'charcoal' | 'white' | 'orange' | 'transparent',
  outline?: boolean;
  dashed?: boolean;
  className?: string;
  icon?: boolean;
  rounded?: boolean;
  dataCy?: string;
  dataTestId?: string;
}

const Button = ({
  icon,
  color,
  size = "default",
  outline,
  dashed,
  rounded,
  className,
  dataCy,
  dataTestId,
  ...props
}: ButtonProps) => (
  <button
    className={classNames(
      {
        "bractlet-button": true,
        [`bractlet-button--${color}`]:
          color &&
          [
            "blue",
            "green",
            "gray",
            "light-gray",
            "red",
            "yellow",
            "charcoal",
            "white",
            "orange",
            "transparent",
          ].includes(color),
        [`bractlet-button--${size}`]: ["small", "large"].includes(size),
        "bractlet-button--icon": icon,
        "bractlet-button--outline": outline,
        "bractlet-button--rounded": rounded,
        "bractlet-button--dashed": dashed,
      },
      className
    )}
    type="button"
    data-cy={dataCy ?? "bractlet-button"}
    data-testid={dataTestId}
    {...props}
  />
);

export default Button;
