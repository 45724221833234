import numeral from "numeral";
import * as R from "ramda";
import uuid from "uuid/v4";
import { startCase } from "lodash";

import type {
  UtilityBill,
  OptionalUtilityBillInputTypes,
  UtilityBillRateStructure,
  Utility,
} from "../../types";
import {
  getVersionUtilityRate,
  getRawSimulatedBills,
  getParsedDateUtilityData,
  updateSimulatedUtilityBill,
} from "../../global_functions/postgrestApi";
import utilityBillColumns from "./utilityBillColumns";
import { isIP } from "../../env";

export const getTierFromPropName = (
  propName: string
): number | null | undefined => {
  let numStr = "";
  for (const char of [...propName].reverse()) {
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
    if (!isNaN(char)) {
      numStr = char + numStr;
    } else {
      break;
    }
  }
  return numStr ? Number(numStr) - 1 : null;
};

export const looselyEqual = (num1: number, num2: number): boolean =>
  Math.abs(num1 - num2) <= 1.005;

export const isValidRow = (row: UtilityBillRateStructure): boolean => {
  if (row.billedQuantity == null || row.total == null) {
    return false;
  }

  if (row.hasMeterDemand && row.meterDemand == null) return false;
  if (row.hasThreshold) {
    if (row.threshold == null) return false;
    // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2531 - Object is possibly 'null'.
    if (numeral(row.billedQuantity).value() > numeral(row.threshold).value()) {
      return false;
    }
    if (row.meterDemand) {
      // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2531 - Object is possibly 'null'.
      if (numeral(row.meterDemand).value() > numeral(row.threshold).value()) {
        return false;
      }
    }
  }
  return true;
};

export const calcTotalConsumptionEnergy = (bill: UtilityBill): number =>
  R.reduce(
    // @ts-expect-error - TS2345 - Argument of type '{ (a: number, b: number): number; (a: number): (b: number) => number; }' is not assignable to parameter of type '(acc: number, elem: number | null) => number | Reduced<number>'.
    R.add,
    0,
    bill.consumptionRateStructure
      .filter((r) => r.status !== "deleted" && r.status !== "voided")
      .map((row) => numeral(row.billedQuantity).value())
  );

export const calcTotalConsumptionSpend = (bill: UtilityBill): number =>
  R.reduce(
    // @ts-expect-error - TS2345 - Argument of type '{ (a: number, b: number): number; (a: number): (b: number) => number; }' is not assignable to parameter of type '(acc: number, elem: number | null) => number | Reduced<number>'.
    R.add,
    0,
    bill.consumptionRateStructure
      .filter((r) => r.status !== "deleted" && r.status !== "voided")
      .map((row) => numeral(row.total).value())
  );

export const calcTotalDemandEnergy = (bill: UtilityBill): number =>
  R.reduce(
    // @ts-expect-error - TS2345 - Argument of type '{ (a: number, b: number): number; (a: number): (b: number) => number; }' is not assignable to parameter of type '(acc: number, elem: number | null) => number | Reduced<number>'.
    R.add,
    0,
    bill.demandRateStructure
      .filter((r) => r.status !== "deleted" && r.status !== "voided")
      .map((row) =>
        numeral(
          row.hasMeterDemand ? row.meterDemand : row.billedQuantity
        ).value()
      )
  );

export const calcTotalDemandSpend = (bill: UtilityBill): number =>
  R.reduce(
    // @ts-expect-error - TS2345 - Argument of type '{ (a: number, b: number): number; (a: number): (b: number) => number; }' is not assignable to parameter of type '(acc: number, elem: number | null) => number | Reduced<number>'.
    R.add,
    0,
    bill.demandRateStructure
      .filter((r) => r.status !== "deleted" && r.status !== "voided")
      .map((row) => numeral(row.total).value())
  );

export const calcTotalSpend = (bill: UtilityBill): number =>
  calcTotalConsumptionSpend(bill) +
  calcTotalDemandSpend(bill) +
  // @ts-expect-error - TS2531 - Object is possibly 'null'.
  numeral(bill.fixedCost).value() +
  // @ts-expect-error - TS2531 - Object is possibly 'null'.
  numeral(bill.taxes).value();

export const calcBlendedRate = (bill: UtilityBill): number | null | undefined =>
  calcTotalConsumptionEnergy(bill) > 0
    ? calcTotalSpend(bill) / calcTotalConsumptionEnergy(bill)
    : null;

export const optionsInUse = (
  bills: Array<UtilityBill>,
  defaultTrue?: boolean
): Partial<Record<OptionalUtilityBillInputTypes, boolean>> => {
  if (defaultTrue) return { peakOccurred: true, powerFactor: true };
  const options = { peakOccurred: false, powerFactor: false } as const;
  R.keys(options).forEach((option) => {
    // @ts-expect-error - TS2540 - Cannot assign to 'peakOccurred' because it is a read-only property. | TS2540 - Cannot assign to 'powerFactor' because it is a read-only property.
    options[option] = bills.some((bill) => bill[option] != null);
  });
  return options;
};

export const newRateStructureRow = (
  type: "consumption" | "demand",
  props?: Partial<UtilityBillRateStructure>
  // @ts-expect-error - TS2322 - Type '{ id: string | number; tier: number; type: "consumption" | "demand"; rate?: number | null | undefined; billedQuantity?: number | null | undefined; meterDemand?: number | null | undefined; ... 6 more ...; utilityBillId?: number | ... 1 more ... | undefined; }' is not assignable to type 'UtilityBillRateStructure'.
): UtilityBillRateStructure => ({
  type,
  tier: 0,
  isValid: false,
  status: "new",
  id: uuid(),
  ...props,
});

export const generateRateStructureProp = (
  type: "consumption" | "demand",
  tier: number,
  prop: "rate" | "billedQuantity" | "total" | "threshold" | "meterDemand"
): string => `${type}${startCase(prop).split(" ").join("")}${tier + 1}`;

export const generateAllRateStructureProps = (
  type: "consumption" | "demand",
  tier: number
): {
  rate: string;
  billedQuantity: string;
  total: string;
  threshold: string;
  meterDemand: string;
} => {
  const props = ["rate", "billedQuantity", "total", "threshold", "meterDemand"];
  const propNames = props.map((prop) =>
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"rate" | "total" | "billedQuantity" | "threshold" | "meterDemand"'.
    generateRateStructureProp(type, tier, prop)
  );
  // @ts-expect-error - TS2739 - Type '{ [x: string]: string; }' is missing the following properties from type '{ rate: string; billedQuantity: string; total: string; threshold: string; meterDemand: string; }': rate, billedQuantity, total, threshold, meterDemand
  return R.zipObj(props, propNames);
};

const rateStructureFormat = (
  rateType: "consumption" | "demand",
  {
    bills,
    numOfTiers,
  }: {
    bills?: Array<UtilityBill>;
    numOfTiers?: number;
  }
): Array<{
  threshold: boolean;
  meterDemand: boolean;
}> => {
  if (numOfTiers) {
    // @ts-expect-error - TS2322 - Type 'number[]' is not assignable to type '{ threshold: boolean; meterDemand: boolean; }[]'. | TS2345 - Argument of type '{ threshold: boolean; meterDemand: boolean; }' is not assignable to parameter of type 'number'.
    return R.range(0, numOfTiers).fill({
      threshold: true,
      meterDemand: rateType === "demand",
    });
  }
  const struct: Array<{
    meterDemand: boolean;
    threshold: boolean;
  }> = [];
  if (bills) {
    bills.forEach((bill) => {
      bill[`${rateType}RateStructure`].forEach((row, i) => {
        if (struct.length <= i) {
          struct.push({ threshold: false, meterDemand: false });
        }
        if (row.threshold && !struct[i]!.threshold) {
          struct[i]!.threshold = true;
        }
        if (
          rateType === "demand" &&
          row.meterDemand &&
          !struct[i]!.meterDemand
        ) {
          struct[i]!.meterDemand = true;
        }
      });
    });
  }
  return struct;
};

export const generateRateFormats = ({
  bills,
  numOfConsumptionTiers,
  numOfDemandTiers,
}: {
  bills?: Array<UtilityBill>;
  numOfConsumptionTiers?: number;
  numOfDemandTiers?: number;
}): {
  consumptionRateFormat: Array<{
    threshold: boolean;
    meterDemand: boolean;
  }>;
  demandRateFormat: Array<{
    threshold: boolean;
    meterDemand: boolean;
  }>;
  includeUtilizationAndBilled?: boolean;
} => {
  return {
    consumptionRateFormat: rateStructureFormat("consumption", {
      bills,
      numOfTiers: numOfConsumptionTiers,
    }),
    demandRateFormat: rateStructureFormat("demand", {
      bills,
      numOfTiers: numOfDemandTiers,
    }),
  };
};

const flattenRateStructure = (
  type: "consumption" | "demand",
  bill: UtilityBill
): Record<any, any> => {
  const flattenedStructure: Record<string, any> = {};
  bill[`${type}RateStructure`].forEach((row) => {
    const propNameMapping = generateAllRateStructureProps(type, row.tier);
    for (const [shortName, fullName] of R.toPairs(propNameMapping)) {
      flattenedStructure[fullName] = row[shortName];
    }
  });
  return flattenedStructure;
};

export const flattenRateStructures = (
  bills: Array<UtilityBill>
): Array<UtilityBill> =>
  bills.map((bill) => ({
    ...bill,
    ...flattenRateStructure("consumption", bill),
    ...flattenRateStructure("demand", bill),
  }));

export const getActiveBills = (
  selectedMeter: string,
  bills: Array<UtilityBill>
): Array<UtilityBill> => {
  const selectedBills =
    selectedMeter === "all"
      ? bills
      : R.filter(R.propEq("meterId", selectedMeter), bills);

  return R.sortBy(R.prop("startDate"))(
    flattenRateStructures(selectedBills)
  ).reverse();
};

export const getColumnsFromBills = (
  // prettier-ignore
  format: 'ALL_METERS_VIEW' | 'SINGLE_METER_VIEW' | 'CSV_DOWNLOAD' | 'CSV_UPLOAD' | 'PARSED_DATE_VIEW',
  activeBills: Array<UtilityBill>,
  utility: Utility
) => {
  const formatProps = activeBills.length
    ? { bills: activeBills }
    : {
        numOfConsumptionTiers: 1,
        numOfDemandTiers: utility === "electricity" ? 1 : 0,
      };

  return utilityBillColumns({
    format,
    utility,
    rateFormats: generateRateFormats(formatProps),
    optionalColumns: optionsInUse(activeBills),
  });
};

export const recalculateSimBillBlendedRate = async (
  parsedDateIds: number[]
): Promise<void> => {
  try {
    const simBills = await getRawSimulatedBills({
      parsedDateIds,
    });
    // @ts-expect-error - TS2349 - This expression is not callable. | TS2345 - Argument of type '<T>(value: T) => Prop<T, "parsedDateId">' is not assignable to parameter of type '(a: T) => string'.
    const simBillsByParsedDateId = R.groupBy(R.prop("parsedDateId"))(simBills);
    // @ts-expect-error - TS2322 - Type 'string[]' is not assignable to type 'number[]'.
    const parsedDateIdsWithSimBills: number[] = R.keys(simBillsByParsedDateId);
    const observedBillData = await getParsedDateUtilityData({
      parsedDatesIds: parsedDateIdsWithSimBills,
    });
    // @ts-expect-error - TS2349 - This expression is not callable. | TS2769 - No overload matches this call.
    const observedBillDataByParsedDateId = R.indexBy(R.prop("id"))(
      observedBillData
    );

    await Promise.all(
      R.toPairs(simBillsByParsedDateId).map(
        async ([parsedDateId, bills]: [any, any]) => {
          const observedData = observedBillDataByParsedDateId[parsedDateId];
          if (
            observedData &&
            observedData.totalCost != null &&
            observedData.totalConsumption != null &&
            observedData.totalCost !== 0 &&
            observedData.totalConsumption !== 0
          ) {
            const utilityRate =
              observedData.totalCost / observedData.totalConsumption;

            await Promise.all(
              // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
              bills.map(async (bill) => {
                const startVersionSimulatedCost =
                  bill.startVersionSimulatedConsumption != null
                    ? bill.startVersionSimulatedConsumption * utilityRate
                    : null;
                const endVersionSimulatedCost =
                  bill.endVersionSimulatedConsumption != null
                    ? bill.endVersionSimulatedConsumption * utilityRate
                    : null;
                await updateSimulatedUtilityBill(bill.id, {
                  startVersionSimulatedCost,
                  endVersionSimulatedCost,
                });
              })
            );
          } else {
            const versionIds = R.uniq(
              bills
                // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
                .flatMap((bill) => [bill.startVersionId, bill.endVersionId])
                .filter(Boolean)
            );
            const utility = bills[0].utility;
            const utilityRatesByVersionId = await getVersionUtilityRate({
              utility,
              // @ts-expect-error - TS2322 - Type 'unknown[]' is not assignable to type 'number[]'.
              versionIds,
              // @ts-expect-error - TS2345 - Argument of type 'Record<never, unknown[]>' is not assignable to parameter of type '(value: VersionUtilityRate[]) => VersionUtilityRate[] | PromiseLike<VersionUtilityRate[]>'. | TS2345 - Argument of type '<T>(value: T) => Prop<T, "versionId">' is not assignable to parameter of type '(a: VersionUtilityRate) => string'.
            }).then(R.groupBy(R.prop("versionId")));
            await Promise.all(
              // @ts-expect-error - TS7006 - Parameter 'bill' implicitly has an 'any' type.
              bills.map(async (bill) => {
                const startRate = bill.startVersionId
                  ? utilityRatesByVersionId[bill.startVersionId]?.averageRate
                  : null;
                const endRate = bill.endVersionId
                  ? utilityRatesByVersionId[bill.endVersionId]?.averageRate
                  : null;
                const startVersionSimulatedCost =
                  bill.startVersionSimulatedConsumption != null &&
                  startRate != null
                    ? bill.startVersionSimulatedConsumption * startRate
                    : bill.startVersionSimulatedConsumption;
                const endVersionSimulatedCost =
                  bill.endVersionSimulatedConsumption != null && endRate != null
                    ? bill.endVersionSimulatedConsumption * endRate
                    : bill.endVersionSimulatedConsumption;

                await updateSimulatedUtilityBill(bill.id, {
                  startVersionSimulatedCost,
                  endVersionSimulatedCost,
                });
              })
            );
          }
        }
      )
    );
  } catch (e: any) {
    if (!isIP) {
      alert(
        "Error recalculating blended rate for existing simulated utility bills"
      );
    }
  }
};
