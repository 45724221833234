import {
  ComponentPropsWithRef,
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useRef,
} from "react";
import { useMergedRef } from "../../global_functions/hooks";

export const ClickableDiv = forwardRef<
  HTMLDivElement,
  ComponentPropsWithRef<"div"> & { onClick?: MouseEventHandler<HTMLDivElement> }
>((props, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e) => {
    if (e.key === "Enter" || e.key === " ") {
      if (e.target === internalRef.current) {
        e.preventDefault();
        internalRef.current?.click();
      }
    }
  }, []);
  return (
    <div
      ref={mergedRef}
      onKeyDown={onKeyDown}
      tabIndex={0}
      role="button"
      {...props}
    />
  );
});
