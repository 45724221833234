import {
  useMutation,
  useQueries as useReactQueries,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";

import FetchClients, { ClientOutput } from "../client";
import { ARCADIA_STATEMENTS_KEY } from "./statements";

export type AccountStatementCounts =
  ClientOutput["arcadia"]["AccountStatementCounts"];

const accounts = {
  useQueries,
  useQueryStatementCounts,
  usePostTransformFailedStatements,
} as const;

export default accounts;

export const ARCADIA_ACCOUNTS_KEY = "arcadia/accounts";

async function getAccount(arc_account_id: string) {
  const { data, error } = await FetchClients.arcadia.GET(
    "/accounts/{arc_account_id}",
    {
      params: {
        path: {
          arc_account_id,
        },
      },
    }
  );
  if (data) return data;
  throw new Error(error);
}

function useQueries(arc_account_ids: string[] = []) {
  return useReactQueries(
    arc_account_ids.map((arc_account_id) => ({
      queryKey: [ARCADIA_ACCOUNTS_KEY, arc_account_id],
      queryFn: () => getAccount(arc_account_id),
      retry: false,
    }))
  );
}

function useQueryStatementCounts(arc_account_id?: string) {
  return useReactQuery<AccountStatementCounts>({
    queryKey: [ARCADIA_ACCOUNTS_KEY, "counts", arc_account_id],
    queryFn: async () => {
      const { data, error } = await FetchClients.arcadia.GET(
        "/accounts/{arc_account_id}/statements/counts",
        {
          params: {
            path: {
              arc_account_id: arc_account_id!,
            },
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    enabled: arc_account_id !== undefined,
  });
}

function usePostTransformFailedStatements(arc_account_id?: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      if (!arc_account_id) {
        throw new Error(
          '"arc_account_id" is required to post transform failed statements'
        );
      }
      const { data, error } = await FetchClients.arcadia.POST(
        "/accounts/{arc_account_id}/statements/transform",
        {
          params: {
            path: {
              arc_account_id,
            },
          },
        }
      );
      // Wait 5 seconds for retries to process, since request responds before statements are finished processing
      await new Promise((resolve) => setTimeout(resolve, 5000));

      if (data) return data;
      throw new Error(error);
    },
    onError: (e) => {
      throw new Error(
        `Unable to retry failed transforms for account ${arc_account_id}:
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ARCADIA_STATEMENTS_KEY]);
      queryClient.invalidateQueries([
        ARCADIA_ACCOUNTS_KEY,
        "counts",
        arc_account_id,
      ]);
    },
  });
}
