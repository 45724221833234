import classNames from "classnames";
import React, { useRef } from "react";
import { Transition } from "react-transition-group";

export const ANIMATION_DURATION = 250;

/**
 * Notes:
 *  - use this component to wrap collapsible content, in particular where the content is of unknown of varying height.
 *  - be wary of Margins on the inner content as this can affect the height of the collapsed container.
 *  - other than the `open` boolean, this component accepts all the props of a `div`.
 */

export default function CollapsibleContainer({
  children,
  open,
  className,
  style,
  allowOverflow,
  onTransitionEnd,
  ...divProps
}: Omit<React.HTMLAttributes<HTMLDivElement>, "onTransitionEnd"> & {
  open: boolean;
  allowOverflow?: boolean;
  onTransitionEnd?: (newOpenState: boolean) => void;
}) {
  const innerRef = useRef<HTMLDivElement>(null);

  return (
    <Transition
      in={open}
      timeout={{
        enter: ANIMATION_DURATION, // If the duration is changed in future, make sure this value matches the CSS duration.
        exit: 0, // The exit duration is defined in CSS.
      }}
    >
      {(transitionState) => (
        <div
          data-testid="collapsible-container"
          className={classNames(className, "transition-all", {
            "opacity-0": !["entering", "exiting", "entered"].includes(
              transitionState
            ),
            "opacity-100":
              transitionState === "entering" || transitionState === "exiting",
            "h-auto opacity-100": transitionState === "entered",
            "h-0": transitionState === "exited",
            "overflow-hidden":
              !allowOverflow &&
              (transitionState === "exited" || transitionState === "entering"),
          })}
          style={
            transitionState === "entering" || transitionState === "exiting"
              ? { height: innerRef.current?.clientHeight, ...style }
              : style
          }
          onTransitionEnd={(event) => {
            // prevent from firing on bubbled events from inside the container
            if (event.target === event.currentTarget) {
              onTransitionEnd?.(open);
            }
          }}
          {...divProps}
        >
          <div ref={innerRef}>{children}</div>
        </div>
      )}
    </Transition>
  );
}
